import { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { v4 as id } from 'uuid';
import { Accordion as AccordionStyled, AccordionSummary, AccordionDetails } from './AccordionStyled';

const Accordion = (props) => {
  const [expanded, setExpanded] = useState(props.initialExpanded || false);

  const accordionId = props.accordionId || id();

  return (
    <AccordionStyled key={accordionId} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={accordionId} id={props.accordionId}>
        <Typography color="primary" variant="h6">
          {props.labelTitle}
        </Typography>
        {props.headerChildren}
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{props.children}</AccordionDetails>
    </AccordionStyled>
  );
};

Accordion.propTypes = {
  accordionId: PropTypes.any,
  labelTitle: PropTypes.string,
  initialExpanded: PropTypes.bool,
  children: PropTypes.any,
};

export default Accordion;
