import { z } from 'zod';

export const zx = {
  booleanFromString: () =>
    z.union([z.boolean(), z.optional(z.string().transform((val) => val === 'true'))]),
  requiredString: () => z.string().trim().min(1),
  positiveNumber: () => z.number().gt(0),
  dateOrString: () =>
    z.preprocess((input) => {
      if (typeof input === 'string' || input instanceof Date) {
        return new Date(input);
      }
      return input;
    }, z.date()),
};
