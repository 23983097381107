import { AxiosRequestConfig } from 'axios';
import apiRequest from './api-request';
import { Settings } from '../types/settings';
import { UserWithInfo } from '../types/user';

const formDataHeaders = {
  'Content-Type': 'multipart/form-data'
};

export type Model<T> = {
  _id: string;
  _version: number;
  _createdAt: Date;
  _updatedAt: Date;
  _deletedAt?: Date | undefined;
} & T

const crudAPI = <T = any>(model: string, opts: { useFormData?: boolean } = {}) => {
  const config: AxiosRequestConfig<any> = {};
  if (opts.useFormData) {
    config.headers = formDataHeaders;
  }

  return {
    getAll: async (): Promise<Model<T>[]> => (await apiRequest.get(`/admin/${model}`)).data as Model<T>[],
    find: async (id): Promise<Model<T>> => (await apiRequest.get(`/admin/${model}/${id}`)).data as Model<T>,
    delete: async (id) => (await apiRequest.delete(`/admin/${model}/${id}`)).data,
    create: async (props: Partial<T>): Promise<Model<T>> => (await apiRequest.post(`/admin/${model}`, props, config)).data as Model<T>,
    update: async (id, props: Partial<T>): Promise<Model<T>> => (await apiRequest.post(`/admin/${model}/${id}`, props, config)).data as Model<T>,
  }
}

const AdminAPI = {
  dashboards: {
    getAll: async () => (await apiRequest.get('/admin/dashboards')).data,
  },
  banners: crudAPI('banners', { useFormData: true }),
  topHotels: crudAPI('top-hotels'),
  fees: crudAPI('fees'),
  orders: {
    ...crudAPI('orders'),
    getOrderCancellationPolicies: async (orderId) => (await apiRequest.get(`/admin/orders/${orderId}/cancellationPolicies`)).data,
    refund: async (orderId) => (await apiRequest.post(`/admin/orders/${orderId}/refund`)).data,
  },
  logs: crudAPI('logs'),
  messages: crudAPI('messages'),
  coupons: crudAPI('coupons'),
  referrals: {
    ...crudAPI('referrals'),
    paidUnpaidOrder: async (referralId, orderId, paid) =>
      (await apiRequest.post(`/admin/referrals/${referralId}/${orderId}/${paid ? 'paid' : 'unpaid'}`, {})).data,
  },
  settings: crudAPI<Settings>('settings', { useFormData: true }),
  users: crudAPI<UserWithInfo>('users'),
}

export default AdminAPI;
