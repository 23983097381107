import { Image } from 'mui-image';
import AdminAPI from '../../api/admin';
import { getListComponent } from '../../components/CRUDAdmin/list';
import { getEditorComponent } from '../../components/CRUDAdmin/edit';
import { t } from '../../translations';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { FormComponentType } from '../../components/CRUDAdmin/edit';
import { z } from 'zod';
import { Language } from '../../types';

const baseUrl = process.env.REACT_APP_HOTELS_API_URL;
const bannerLocations = ['HomePage'];

const columns = [
  {
    field: 'location',
    headerName: t.Location,
    width: 100,
  },
  {
    field: 'visible',
    headerName: t.Visible,
    width: 80,
  },
  {
    field: 'link',
    headerName: t.Link,
    width: 200,
  },
  {
    field: 'image',
    headerName: t.Images,
    flex: 1000,
    renderCell: ({ row: item }) => {
      return (
        <Box>
          <Box display="inline-block" mr={2}>{item.image ? <Image src={`${baseUrl}${item.image}`} height={100} /> : null}</Box>
          {Object.values(Language).map((lang) => (
            <Box mr={2} display="inline-block">
              {item.imagesPerLanguage?.[lang] && (
                <Box>
                  <Typography variant="body2">{lang}</Typography>
                  <Image src={`${baseUrl}${item.imagesPerLanguage?.[lang]}`} height={100} />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      );
    },
  },
];

const BannerSchema = z.object({
  visible: z.boolean(),
  location: z.string(),
  link: z.string(),
  image: z.any(),
  imagesPerLanguage: z.record(z.nativeEnum(Language), z.any()).optional(),
});

export type Banner = z.infer<typeof BannerSchema>;

const BannerForm: FormComponentType = ({ textInput, booleanInput, selectInput, imageInput }) => {
  return (
    <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative' }}>
      <CardContent>
        <Box sx={{ mt: 4 }}>
          <Box sx={{ mt: 2, pl: 1 }}>{booleanInput('visible', { label: t.Visible })}</Box>
          <Box sx={{ mt: 2 }}>{selectInput('location', bannerLocations, { label: t.Location })}</Box>

          <Box sx={{ mt: 2 }}>{textInput('link', { label: t.Link })}</Box>
          <Typography variant="body1" mt={3}>
            {t.Images}
          </Typography>
          <Box sx={{ mt: 2 }}>{imageInput('image')}</Box>
          {Object.values(Language).map((lang) => (
            <Box key={lang}>
              <Typography variant="body1">{lang}</Typography>
              <Box sx={{ mt: 2 }}>{imageInput(`imagesPerLanguage.${lang}`)}</Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export const ListBanners = getListComponent({
  name: 'Banners',
  baseRoute: 'banners',
  autoHeight: true,
  columns,
  dataLoader: AdminAPI.banners.getAll,
  destroy: AdminAPI.banners.delete,
});

export const CreateBanner = getEditorComponent({
  name: 'Banners',
  baseRoute: 'banners',
  dataLoader: () => Promise.resolve({ visible: false, imagesPerLanguage: {} }),
  save: (props) => AdminAPI.banners.create(getBannerFormData(props)),
  FormComponent: BannerForm,
  schema: BannerSchema,
});

export const EditBanner = getEditorComponent({
  name: 'Banners',
  baseRoute: 'banners',
  dataLoader: (params) => AdminAPI.banners.find(params.id),
  save: (props) => AdminAPI.banners.update(props._id, getBannerFormData(props)),
  FormComponent: BannerForm,
  schema: BannerSchema,
});

const getBannerFormData = (props) => {
  const formData = new FormData();

  ['visible', 'location', 'link', 'image'].forEach((propName) => {
    formData.append(propName, props[propName]);
  });

  // if (props.file) {
  //   formData.append('image', props.file);
  // }

  return props;
};
