import { Container } from '@mui/material';
import Contact from '../../components/Contact/Contact';
import Navbar from './Navbar';

const HomePage = () => {
  return (
    <>
      <Navbar withNavbar/>
      <Container sx={{ pt: 10, maxWidth: '1000px' }}>
        <Contact />
      </Container>
    </>
  );
};

export default HomePage;
