import { useState } from 'react';
import AdminAPI from '../../api/admin';
import Dialog from '../../components/Dialogs/Dialog';
import { formatToDate, isInTheFuture } from '../../helpers/date-time';
import { Box as MuiBox, Typography, Button, BoxProps } from '@mui/material';
import { Policies } from '../../components/HotelPaxSelect/HotelPaxSelect';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { t } from '../../translations';
import { z } from 'zod';
import { getListComponent } from '../../components/CRUDAdmin/list';
import { AdjustmentType } from '../../types';
import { displayPrice } from '../../helpers/utils';
import { COLOR } from '../../helpers/styles';
import { OrderMode } from '../../types/orders';

const Box = (props: BoxProps) => (
  <MuiBox
    {...props}
    sx={{
      ...{ wordBreak: 'break-word', overflowWrap: 'break-word' },
      ...props.sx,
    }}
  />
);

const columns = [
  {
    field: 'status',
    headerName: t.Status,
    width: 120,
    renderCell: ({ row: order }) => {
      return (
        <Box>
          <Box sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{order.status}</Box>
          <Box sx={{ textAlign: 'center', color:order.mode === OrderMode.TEST ? COLOR.BLUE : COLOR.GREEN }}>{order.mode} MODE</Box>
        </Box>
      );
    },
  },
  {
    field: 'hotelName',
    headerName: t.Hotel,
    width: 180,
    renderCell: ({ row: order }) => {
      return (
        <Box>
          <Box>
            <b>{order.hotelName}</b>
          </Box>
          <Box>address: {order.hotelAddress}</Box>
          <Box>category: {order.hotelCategory}</Box>
          <Box>property type: {order.isAnApartment ? t.apartment : t.hotel}</Box>
        </Box>
      );
    },
  },
  {
    field: 'reserve',
    headerName: t.ReservationInfo,
    width: 180,
    renderCell: ({ row: order }) => {
      return (
        <Box>
          <Box>
            reservation No: <b>{order.confirmation?.reservationNumber}</b>
          </Box>
          <Box>checkin: {formatToDate(order.clientRequest.checkin)}</Box>
          <Box>checkout: {formatToDate(order.clientRequest.checkout)}</Box>
          <Box>nights: {order.clientRequest.lines.length}</Box>
          <Box>rooms: {order.clientRequest.amountOfRooms}</Box>
          <Box>guests: {order.clientRequest.guests.length}</Box>
        </Box>
      );
    },
  },
  {
    field: 'user',
    headerName: t.User,
    width: 190,
    renderCell: ({ row: order }) => {
      return (
        <Box>
          {order.userEmail ? (
            <>
              <Box>
                <b>{order.userEmail}</b>
              </Box>
              <Box sx={{mt: 1}}>user id:<br/> <b>{order.userId}</b></Box>
            </>
          ) : (
            <Box>Unauthenticated</Box>
          )}
        </Box>
      );
    },
  },
  {
    field: 'clientRequest',
    headerName: t.Client,
    width: 180,
    renderCell: ({ row: order }) => {
      return (
        <Box>
          <Box>
            <b>{order.clientRequest.client.fullName}</b>
          </Box>
          <Box>email: {order.clientRequest.client.email}</Box>
          <Box>phone: {order.clientRequest.client.phone}</Box>
          <Box>language: {order.language}</Box>
          {order.clientRequest.observations && <Box>observations: {order.clientRequest.observations}</Box>}
        </Box>
      );
    },
  },
  {
    field: 'payment',
    headerName: t.PaymentDetails,
    width: 240,
    renderCell: ({ row: order }) => {
      return (
        <Box>
          <Box>currency: {order.currency}</Box>
          <Box>restel price: {order.price}</Box>
          {order.fees.map(
            (fee) =>
              fee.feeType === 'Markup' && (
                <Box>
                  markup: + {fee.chargeType === AdjustmentType.Percentage ? '%' : ''}
                  {fee.amount}({fee.text})
                </Box>
              )
          )}
          <Box>price with markup: {order.priceWithMarkup}</Box>
          {order.coupon?.applicable && (
            <>
              <Box>with coupon: {order.coupon.code} {displayPrice(order.coupon.discountAmount, order.coupon.discountType, order.currency)}</Box>
              <Box>
                coupon discount: {order.currency} {order.couponDiscount}
                {}
              </Box>
              <Box>price with coupon: {order.priceWithCoupon}</Box>
            </>
          )}
          {order.commission && (
            <>
              <Box>commission: {displayPrice(order.commission.amount, order.commission.type, order.currency)}</Box>
            </>
          )}
          <Box>subtotal: {order.subtotal}</Box>
          {order.fees.map(
            (fee) =>
              fee.feeType !== 'Markup' && (
                <Box>
                  fee: + {fee.chargeType === AdjustmentType.Percentage ? '%' : ''}
                  {fee.amount}({fee.text})
                </Box>
              )
          )}
          <Box>
            total: <b>{order.total}</b>
          </Box>
          {order.amountRefunded && (
            <Box>
              <b>refunded: {order.amountRefunded}</b>
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    field: 'rooms',
    headerName: t.Room,
    width: 180,
    renderCell: ({ row: order }) => {
      return order.clientRequest.roomDetails.map((roomDetails, i) => (
        <Box sx={{ mb: 1 }}>
          <Box>
            <b>room: {roomDetails.name}</b>
          </Box>
          <Box>price: {order.clientRequest.roomPrices[i].finalPrice}</Box>
          <Box>desc: {roomDetails.description}</Box>
        </Box>
      ));
    },
  },

  {
    field: 'guests',
    headerName: t.Guests,
    width: 180,
    renderCell: ({ row: order }) => {
      return (
        <Box>
          {order.clientRequest.guests.map((guest) => (
            <Box>
              <b>{guest.title}</b>
              {[guest.firstName, guest.lastName, guest.age, 'years'].join(' ')}
            </Box>
          ))}
        </Box>
      );
    },
  },

  {
    field: 'cc',
    headerName: t.CreditCard,
    width: 120,
    renderCell: ({ row: order }) => {
      return (
        <Box>
          <Box>
            <b>
              {order.clientRequest.cardInfo.brand} / {order.clientRequest.cardInfo.type}
            </b>
          </Box>
          <Box>
            exp: {order.clientRequest.cardInfo.expMonth} / {order.clientRequest.cardInfo.expYear}
          </Box>
          <Box>last 4: {order.clientRequest.cardInfo.last4}</Box>
        </Box>
      );
    },
  },

  {
    field: 'referred',
    headerName: t.Referral,
    width: 180,
    renderCell: ({ row: order }) => {
      return order.clientRequest.roomDetails.map((roomDetails, i) => (
        <Box>
          {order.clientRequest.agent && <Box>agent: {order.clientRequest.agent}</Box>}
          {order.clientRequest.referralCode && <Box>referral code: {order.clientRequest.referralCode}</Box>}
          {order.clientRequest.referralId && <Box>referral id: {order.clientRequest.referralId}</Box>}
        </Box>
      ));
    },
  },
];

const baseProps = {
  name: t.Orders,
  baseRoute: 'orders',
  schema: z.object({}),
};

let onUpdateItemCb;

export const ListOrders = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  editable: false,
  deletable: true,
  creatable: false,
  isItemDeletable: (order) => order.mode === OrderMode.TEST,
  destroy: AdminAPI.orders.delete,
  dataLoader: AdminAPI.orders.getAll,
  onUpdatedItem: (cb) => {
    onUpdateItemCb = cb;
  },
  renderBeforeView: (order) => (
    <Refund
      order={order}
      onUpdate={(newItem) => {
        onUpdateItemCb?.(newItem);
      }}
    />
  ),
});

const Refund = ({ order, onUpdate }) => {
  const [loadingPolicies, setLoadingPolicies] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const [policies, setPolicies] = useState(false);
  const [refundedCompleted, setRefundedCompleted] = useState(false);
  const [refundedResult, setRefundedResult] = useState<any>('');

  const isRefundable = order.status.toLowerCase() === 'captured' && isInTheFuture(order.clientRequest.checkin);

  const loadPolicies = async () => {
    setLoadingPolicies(true);
    try {
      const newPolicies = await AdminAPI.orders.getOrderCancellationPolicies(order._id);
      setPolicies(newPolicies.policies);
    } catch (error: any) {
      toast.error(error);
      console.error(error);
    }
    setLoadingPolicies(false);
  };

  const refund = async () => {
    setRefunding(true);
    try {
      const newOrder = await AdminAPI.orders.refund(order._id);
      toast.success(t.OrderHaveBeenRefunded);
      setRefundedResult(t.OrderHaveBeenRefunded);
      onUpdate(newOrder);
    } catch (error: any) {
      toast.error(error);
      console.error(error);
      setRefundedResult(t.FailedToRefund);
    }
    setRefunding(false);
    setRefundedCompleted(true);
  };

  return (
    <Dialog
      titleLabel={t.RefundOrder}
      renderTarget={({ openDialog }) => (
        <Button
          variant="contained"
          disabled={!isRefundable}
          onClick={() => {
            openDialog();
            setRefunding(false);
            setRefundedCompleted(false);
            setRefundedResult(undefined);
            loadPolicies();
          }}
        >
          Refund
        </Button>
      )}
      renderContent={({ closeDialog }) => (
        <Box>
          {loadingPolicies && <Typography>{t.LoadingPolicies}...</Typography>}
          {refunding && <Typography>{t.Refunding}...</Typography>}
          {refundedCompleted && (
            <Box textAlign="center">
              <Typography variant="h6">{refundedResult}</Typography>
              <Box mt={2}>
                <Button onClick={closeDialog} variant="contained">
                  {t.Close}
                </Button>
              </Box>
            </Box>
          )}
          {!loadingPolicies && !refunding && !refundedCompleted && (
            <Box textAlign="center">
              <Policies policies={policies} />
              <ConfirmationDialog
                titleLabel={t.ConfirmRefund}
                openDialogLabel={t.ProceedToRefund}
                cancelLabel={t.No}
                okLabel={t.Yes}
                onSave={refund}
              >
                <Box>{t.AreYouSureYouWantToRefundThisOrder}</Box>
              </ConfirmationDialog>
            </Box>
          )}
        </Box>
      )}
    />
  );
};
