import { IpInfo } from '../api/ip-info';
import countries from '../components/CountryInput/countries';
import { Currency } from '../types';
import { existsInLocalStorage } from './utils';

const CURRENCY = 'CURRENCY_v1';

export const getCurrency = () => localStorage.getItem(CURRENCY) || Currency.USD;

export const setCurrency = (currency, opts: { skipReload?: boolean } = {}) => {
  localStorage.setItem(CURRENCY, currency);

  if (!opts.skipReload) {
    window.location.reload();
  }
}

export const initCurrency = (ipInfo?: IpInfo): boolean => {
  if (existsInLocalStorage(CURRENCY)) {
    return false;
  }

  const country = ipInfo?.country
    ? countries.find(country => country.code === ipInfo.country)
    : null;

  const currencyToSelect = isValidCurrency(country?.currency)
    ? country?.currency as Currency
    : Currency.USD

  setCurrency(currencyToSelect);
  return true;
}

const isValidCurrency = (currency: string | undefined | null): boolean => Object.values(Currency).includes(currency as Currency)
