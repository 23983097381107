import AdminAPI from '../../api/admin';
import { Box, Card, CardContent, InputAdornment, MenuItem, Typography } from '@mui/material';
import { t } from '../../translations';
import { z } from 'zod';
import { DataGridColumn, getListComponent } from '../../components/CRUDAdmin/list';
import { UserWithInfo } from '../../types/user';
import Image from 'mui-image';
import { AdjustmentType } from '../../types';
import { getEditorComponent } from '../../components/CRUDAdmin/edit';
import { UserRole } from '../../types/auth';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';

const ROLE_ICONS_MAP: { [key in UserRole]: { Icon: typeof SupportAgentIcon; color: string } } = {
  [UserRole.Customer]: { Icon: PersonIcon, color: '#28A745' },
  [UserRole.Agent]: { Icon: SupportAgentIcon, color: '#007BFF' },
  [UserRole.Admin]: { Icon: ManageAccountsIcon, color: '#6F42C1' },
};

const columns: DataGridColumn<UserWithInfo>[] = [
  {
    field: 'fullName' as 'user',
    headerName: t.FullName,
    width: 120,
    renderCell: ({ row: userWithInfo }) => {
      return <Box sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{userWithInfo.userInfo.fullName}</Box>;
    },
  },
  {
    field: 'user.email' as 'user',
    headerName: t.Email,
    width: 180,
    renderCell: ({ row: userWithInfo }) => {
      return <Box>{userWithInfo.user.email}</Box>;
    },
  },
  {
    field: 'role' as 'user',
    headerName: t.Role,
    width: 140,
    renderCell: ({ row: userWithInfo }) => {
      const { Icon, color } = ROLE_ICONS_MAP[userWithInfo.user.role];

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', color }}>
          <Icon sx={{ fontSize: '30px', color }} />
          <Box sx={{ ml: 0 }}>{userWithInfo.user.role}</Box>
        </Box>
      );
    },
  },
  {
    field: 'authProviders' as 'user',
    headerName: t.AuthProviders,
    width: 180,
    renderCell: ({ row: userWithInfo }) => {
      return (
        <ul>
          {userWithInfo.user.authProviders.map((authProvider) => (
            <li key={authProvider}>{authProvider}</li>
          ))}
        </ul>
      );
    },
  },
  {
    field: 'ips' as 'user',
    headerName: 'IPs',
    width: 100,
    renderCell: ({ row: userWithInfo }) => {
      return (
        <ul>
          {userWithInfo.user.ips.filter(Boolean).map((ip) => (
            <li key={ip}>{ip}</li>
          ))}
        </ul>
      );
    },
  },
  {
    field: 'emailAndPassDataProvider' as 'user',
    headerName: t.Verified,
    width: 80,
    renderCell: ({ row: userWithInfo }) => {
      return <Box>{userWithInfo.user.emailAndPassDataProvider?.verified}</Box>;
    },
  },
  {
    field: 'image' as 'userInfo',
    headerName: t.Images,
    width: 180,
    renderCell: ({ row: userWithInfo }) => {
      if (!userWithInfo.userInfo.image) {
        return null;
      }

      return (
        <Box>
          <Image src={userWithInfo.userInfo.image} width={100} height={100} />
        </Box>
      );
    },
  },
  {
    field: 'discountType' as 'userInfo',
    headerName: t.Discount,
    width: 120,
    renderCell: ({ row: userWithInfo }) => (
      <Box>
        {!userWithInfo.userInfo.discountAmount ? (
          t.NoDiscount
        ) : (
          <Typography>
            {userWithInfo.userInfo.discountAmount}{' '}
            {userWithInfo.userInfo.discountType === AdjustmentType.Percentage ? '%' : AdjustmentType.Fixed}
          </Typography>
        )}
      </Box>
    ),
  },
];

const UserSchema = z.object({
  userInfo: z.object({
    discountType: z.nativeEnum(AdjustmentType).optional(),
    discountAmount: z.coerce.number().optional(),
  }),
});

const UserForm = ({ data, textInput, selectInput }) => {
  return (
    <Box>
      <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative', mb: 2 }}>
        <CardContent>
          <Box mb={3}>
            {selectInput(
              'userInfo.discountType',
              [
                <MenuItem value={AdjustmentType.Percentage}>{t.Percentage}</MenuItem>,
                <MenuItem value={AdjustmentType.Fixed}>{t.Fixed}</MenuItem>,
              ],
              { label: t.DiscountType }
            )}
          </Box>
          <Box mb={3}>
            {textInput('userInfo.discountAmount', {
              label: t.DiscountAmount,
              InputProps: {
                startAdornment: data.discountType === AdjustmentType.Percentage ? <InputAdornment position="start">%</InputAdornment> : '',
              },
            })}
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative' }}>
        <CardContent>
          <Box mb={3}>
            {selectInput(
              'user.role',
              Object.values(UserRole).map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              )),
              { label: t.Role }
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const baseProps = {
  name: t.Users,
  baseRoute: 'users',
  schema: UserSchema,
  FormComponent: UserForm,
};

export const ListUsers = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  editable: true,
  deletable: false,
  creatable: false,
  dataLoader: AdminAPI.users.getAll,
});

export const EditUsers = getEditorComponent({
  ...baseProps,
  dataLoader: (params) => AdminAPI.users.find(params.id),
  save: (props) => AdminAPI.users.update(props._id, props),
});
