import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Box } from '@mui/system';
import Image from 'mui-image';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const FullScreenCarousel = ({ show, initialSlide = 0, onClose, images }: any) => {
  const settings = {
    dots: false,
    // infinite: true,
    speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Adjust the speed of the carousel,
    centerMode: true,
    centerPadding: '0px',
    // slidesToShow: 1,
  };

  if (!show) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: '0',
        padding: '10px 30px',
        width: '100vw',
        height: '100vh',
        background: '#000000cf',
        zIndex: 1401,
        overflow: 'hidden',
      }}
    >
      <CancelOutlinedIcon
        sx={{
          fontSize: '30px',
          position: 'absolute',
          zIndex: 1402,
          background: 'black',
          borderRadius: '50%',
          cursor: 'pointer',
          right: '10px',
          color: 'white',
          opacity: 0.75,
          '&:hover': {
            opacity: 1,
          }
        }}
        onClick={onClose}
      />
      <Slider {...settings} initialSlide={initialSlide} style={{ width: '100%', height: '100%' }}>
        {images.map((image, index) => (
          <Box key={index} sx={{ height: 'calc(100vh - 35px)', borderRadius: '5px' }}>
            <Image src={image} alt={`Slide ${index + 1}`} {...{sx:{ margin: '0 auto' }}} fit="contain" duration={0} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default FullScreenCarousel;
