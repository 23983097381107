import { v4 as id } from 'uuid';

export type Room = {
  id: string;
  adults: number;
  children: number;
  ages: number[];
};

export const paxesStringToObj = (paxesStr: string): Room[] => {
  const result: Room[] = []
  const roomsStrSplitted = paxesStr.split('-')
  while (roomsStrSplitted.length > 0) {
    const room: Room = {
      id: id(),
      adults: Number.parseInt(roomsStrSplitted.shift() || '', 10),
      children: Number.parseInt(roomsStrSplitted.shift() || '', 10),
      ages: [],
    }
    result.push(room)

    let ages = room.children
    while (ages > 0) {
      room.ages.push(Number.parseInt(roomsStrSplitted.shift() || '', 10))
      ages -= 1
    }
  }

  return result
}

export const paxesObjToString = (rooms: Room[]): string => {
  const roomStr = (room) => [room.adults, room.children, ...(room.ages || [])].join('-')
  return rooms.map(roomStr).join('-')
}
