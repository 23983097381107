export enum Language {
  EN = 'en',
  ES = 'es',
  IT = 'it',
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
}

export type DistanceMetric = 'km' | 'mt';
export type TimeMetric = 'hr' | 'min';

export enum AdjustmentType {
  Percentage = 'Percentage',
  Fixed = 'Fixed',
}
