import { Box, Paper, Typography, Fab, Stack, Theme } from '@mui/material';
import MinMaxSliderFilter from './MinMaxSliderFilter';
import ToggleFilter from './ToggleFilter';
import CheckboxesFilter from './CheckboxesFilter';
import TextFilter from './TextFilter';
import useMediaQuery from '@mui/material/useMediaQuery';
import { t } from '../../translations';

const Filters = (props) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const FILTER_TYPES = {
    minMaxSlider: MinMaxSliderFilter,
    checkboxes: CheckboxesFilter,
    toggle: ToggleFilter,
    text: TextFilter,
  };

  return (
    <Paper elevation={1} sx={{ padding: 2, width: '100%', ...(matches && { pb: '70px' }) }}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} mb={2}>
        <Typography variant="h5" sx={{ mb: 2, ml: 1 }}>
          {t.FilterBy}
        </Typography>
        <Fab
          variant="extended"
          size="small"
          color="primary"
          onClick={() => props.onCleanup?.()}
        >
          {t.Clear}
        </Fab>
      </Stack>

      {props.filters.map((filter) => {
        const Filter = FILTER_TYPES[filter.type];
        return (
          <Box
            key={filter.title || filter.label}
            sx={{
              borderTop: '1px solid',
              borderColor: '#dfe0e4',
              color: 'text.secondary',
              p: 2,
              mb: 1,
            }}
          >
            <Filter filter={filter} />
          </Box>
        );
      })}

      {matches && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '95%',
            left: '50%',
            maxWidth: '500px',
            transform: 'translate(-50%, 0)',
            backgroundImage: 'linear-gradient(0deg,hsla(0,0%,100%,.75) 88%,transparent)',
            p: 2,
          }}
        >
          <Fab
            variant="extended"
            color="primary"
            sx={{
              width: '100%',
            }}
            onClick={() => props.onCloseDialog?.()}
          >
            {t.Done}
          </Fab>
        </Box>
      )}
    </Paper>
  );
};

export default Filters;
