import apiRequest from './api-request';
// import {parse, stringify, toJSON, fromJSON} from 'flatted';

export const reportClientError = async (error) => {
  const { data } = await apiRequest.post('/client-errors', {
    message: error?.name,
    payload: {
      name: error?.name,
      message: error?.message,
      stack: error?.stack,
      asString: `${error}`
    }
  });

  return data;
};
