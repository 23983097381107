import { buildQueryString } from '../helpers/utils';
import { language } from '../translations';
import { MarkupFee } from '../types/fees';
import { HotelAvailability } from '../types/hotels';
import { SearchProps, SearchResult, SearchType } from '../types/search';
import { FetchTopDestinationsProps, Settings, TopDestination, TrendingDestination } from '../types/settings';
import apiRequest from './api-request';

export interface SearchHotelsAvailabilitiesProps {
  type: SearchType,
  country?: string,
  province?: string,
  city?: string,
  hotel?: string,
  code?: string,
  checkin: string,
  checkout: string,
  rooms: string,
  shortResponse?: boolean,
  cancellationFees?: boolean,
}

export interface SearchAvailabilityResult {
  hotelsResult?: (HotelAvailability)[] | null;
  index: number;
}

export const API = {
  coupons: {
    get: async ({ code, ...queryStringProps }) => (await apiRequest.get(`/coupons/${code}?${buildQueryString(queryStringProps)}`)).data,
    getPromotionsCoupons: async () => (await apiRequest.get('/coupons/promotions')).data
  },
  referrals: {
    get: async (referralCode) => (await apiRequest.get(`/referrals/${referralCode}`)).data
  },
  searcher: {
    search: async (props: { searchText: string, index?: number, count?: number, type?: SearchType[], filterQuery?: SearchProps['filterQuery'], }): Promise<SearchResult> => {
      const searchProps: SearchProps = {
        searchText: props.searchText,
        index: props.index,
        count: props.count,
        typesToSearch: props.type ? props.type.join(',') : undefined,
        searchQuery: {
          language
        },
        filterQuery: props.filterQuery
      }

      return (await apiRequest.get(`/search?${buildQueryString(searchProps)}`)).data as SearchResult;
    }
  },
  settings: {
    topDestinations: async (props: FetchTopDestinationsProps): Promise<TopDestination[]> =>
      (await apiRequest.get(`/settings/top-destinations?${buildQueryString(props)}`)).data as TopDestination[],
    trendingDestinations: async (): Promise<TrendingDestination[]> =>
      (await apiRequest.get('/settings/trending-destinations')).data as TrendingDestination[],
    siteSettings: async (): Promise<Settings> => (await apiRequest.get('/settings/site-settings')).data as Settings
  },
  fees: {
    fetchMarkupFees: async (): Promise<MarkupFee[]> =>
      (await apiRequest.get('/fees/markup')).data as MarkupFee[],
  },
  hotels: {
    searchHotelsAvailabilities: async (params: SearchHotelsAvailabilitiesProps): Promise<SearchAvailabilityResult> => {
      return (await apiRequest.get('/hotels', { params })).data as SearchAvailabilityResult
    },
  }
}
