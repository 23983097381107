import { Typography } from '@mui/material';

const RoomDescription = ({ desc }) => (
  <Typography
    component="span"
    sx={{
      fontWeight: 'bold',
      marginRight: 2,
      '::before': {
        content: '" "',
        display: 'inline-block',
        border: '3px solid',
        borderRadius: '50%',
        width: 5,
        height: 5,
        marginRight: '5px',
        marginBottom: '2px',
      },
    }}
  >
    {desc}
  </Typography>
);

export default RoomDescription;
