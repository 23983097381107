import { Box, Container, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Link as LinkRouter, useLoaderData } from 'react-router-dom';
import Image from 'mui-image';
import SearchPage from './SearchPage';
import { formatToDate, getNextWeekend } from '../../helpers/date-time';
import { COLOR } from '../../helpers/styles';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MapIcon from '@mui/icons-material/Map';
import Navbar from './Navbar';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import MyTabs from '../../components/MyTabs/MyTabs';
import ShowPromotionCoupon from '../../components/ShowPromotionCoupon/ShowPromotionCoupon';
import { HOTEL_SERVICES_ICONS, ROOM_SERVICES_ICONS } from '../../components/icons';
import HomePageBanner from '../../components/Banners/HomePageBanner';
import { t } from '../../translations';
import { FetchTopDestinationsProps, TopDestination, TopHotelDestService, TrendingDestination } from '../../types/settings';
import { API } from '../../api/home-api';
import HotelAvailabilityPage from './HotelAvailabilityPage';
import Popover from '../../components/Popover/Popover';
import { Currency } from '../../types';
import { money } from '../../helpers/utils';
import GoogleAuth from '../auth/GoogleAuth';
import FavoriteHotelButton from '../../components/FavoriteHotelButton/FavoriteHotelButton';
import Carrousel from '../../components/ImagesContainer/Carrousel';

const baseUrl = process.env.REACT_APP_HOTELS_API_URL;

const underline = `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`;

const sx = {
  container: {
    pt: 2,
    position: 'relative',
    maxWidth: '1300px',
    px: {
      xs: '20px !important',
      md: '50px !important',
      lg: '0px !important',
    },
  },
};

const getServiceAv = ({ description, serviceCode, roomServiceCode }: TopHotelDestService) => {
  const Icon = ROOM_SERVICES_ICONS[roomServiceCode || ''] || HOTEL_SERVICES_ICONS[serviceCode || ''] || HOTEL_SERVICES_ICONS.default;

  return (
    <Box key={serviceCode} sx={{ display: 'flex' }}>
      <Icon sx={{ ml: 0.3, mr: 1, opacity: 0.8, color: 'primary.main' }} />
      <Typography sx={{ mr: 2, fontSize: 13, fontWeight: 'bold' }}>{description}</Typography>
    </Box>
  );
};

type TopHotelsProps = {
  topDestinationProps: FetchTopDestinationsProps;
  topDestinations: TopDestination[];
};

const TopHotels = ({ topDestinationProps, topDestinations }: TopHotelsProps) => {
  const getAvLink = (hotelCode: string) =>
    HotelAvailabilityPage.link({
      code: hotelCode,
      checkin: topDestinationProps.checkin,
      checkout: topDestinationProps.checkout,
      rooms: topDestinationProps.rooms,
    });

  return (
    <>
      <Typography fontSize={18} fontWeight="bold" pt={2} pb={1}>
        {t.RecommendedAllInclusiveHotelsForYouToConsider}
      </Typography>
      <MyTabs
        labels={topDestinations.map((topDest) => topDest.destinationName)}
        children={topDestinations.map((topDest, i) => () => (
          <Grid container spacing={4} key={topDest.destinationName}>
            {topDest.topHotelsDest.map((topHotelDest) => {
              let finalPrice: number | undefined;
              let currency: Currency | undefined;

              (topHotelDest.paxes || []).forEach((pax) => {
                pax.rooms.forEach((room) => {
                  room.packages.forEach((pkg) => {
                    if (pkg.finalPrice && (!finalPrice || pkg.finalPrice < finalPrice)) {
                      finalPrice = pkg.finalPrice;
                      currency = pkg.currency;
                    }
                  });
                });
              });

              return (
                <Grid item key={topHotelDest.hotelCode} xs={12} md={4}>
                  <Paper elevation={8} sx={{ borderRadius: '20px', overflow: 'hidden' }}>
                    <Link
                      component={LinkRouter}
                      to={getAvLink(topHotelDest.hotelCode)}
                      underline="none"
                      target={isMobile ? '_self' : '_blank'}
                      sx={{ color: 'black', position: 'relative' }}
                    >
                      <FavoriteHotelButton hotelCode={topHotelDest.hotelCode} />
                      <Carrousel images={topHotelDest.images} imageProps={{ height: 250 }} />
                      <Box px={1.3} py={1.7}>
                        <Typography
                          fontWeight="bold"
                          fontSize={18}
                          sx={{
                            textOverflow: 'ellipsis',
                            textWrap: 'nowrap',
                            overflow: 'hidden',
                            mb: 1,
                          }}
                        >
                          {topHotelDest.hotelName}
                        </Typography>
                        {finalPrice && currency && (
                          <Box sx={{ mb: 1, textAlign: 'left', pl: 1 }}>
                            <Typography component="span" variant="h3" fontSize={16} fontWeight={400}>
                              {`${currency} $${money(finalPrice)}`}
                            </Typography>
                          </Box>
                        )}
                        <Box sx={{ display: 'flex', mb: 1 }}>
                          <LocationOnIcon sx={{ opacity: 0.8, ml: 0.3, mr: 0.8, fontSize: 18 }} />
                          <Typography color="gray" sx={{ fontSize: 13 }}>
                            {topDest.destinationName}
                          </Typography>
                        </Box>

                        <Box sx={{ display: '' }}>
                          {topHotelDest.services.slice(0, 2).map(getServiceAv)}
                          {topHotelDest.services.length > 2 && (
                            <Box
                              onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                              }}
                            >
                              <Popover openOn="click" horizontal={10} target={<Box sx={{ pl: 3 }}>{t.viewMore}</Box>}>
                                <Box sx={{ p: 2 }}>{topHotelDest.services.map(getServiceAv)}</Box>
                              </Popover>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Link>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        ))}
      />
    </>
  );
};

type TrendingDestinationsProps = {
  trendingDestinations: TrendingDestination[];
};

const TrendingDestinations = ({ trendingDestinations }: TrendingDestinationsProps) => {
  const { startDate, endDate } = getNextWeekend();
  const defaultSearchTerms = {
    dates: { startDate, endDate },
    roomsStr: '2-0',
  };

  return (
    <Box mt={6} pb={3} sx={{ borderBottom: underline }}>
      <Typography fontSize={18} fontWeight="bold" pt={2} pb={1}>
        {t.TrendingDestinations}
      </Typography>
      <Grid container spacing={2}>
        {trendingDestinations.map((destination, i) => {
          const linkTo = SearchPage.link({ ...defaultSearchTerms, location: destination.record });
          return (
            <Grid item key={destination.name} xs={12} sm={i <= 1 ? 6 : 4}>
              <Paper sx={{ position: 'relative', borderRadius: '5px', overflow: 'hidden' }} elevation={4}>
                <Link component={LinkRouter} to={linkTo} underline="none">
                  <Image
                    width="100%"
                    height="250px"
                    duration={0}
                    src={`${baseUrl}${destination.image}`}
                    alt={destination.name}
                    // errorIcon={<NoImage />}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      // background: 'linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%)',
                      boxShadow: '0 0 50px 24px rgba(0, 0, 0, 0.5);',

                      borderRadius: '15px',
                      bottom: '1px',
                      left: '1px',
                      // filter: 'blur(1px)',
                      background: '#00000066',
                      // top: '70%',
                      width: '100%',
                      textAlign: 'center',
                      p: 1,
                    }}
                  >
                    <Typography color="white" fontWeight="bold" fontSize={18}>
                      {destination.name}
                    </Typography>
                  </Box>
                </Link>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const Tags = () => {
  const tags = [
    {
      title: t.FreeCancelation,
      text: t.ExploreOurProperties,
      icon: EventAvailableIcon,
    },
    {
      title: t.GuaranteedPrices,
      text: t.LowHotelRates,
      icon: LocalOfferIcon,
    },
    {
      title: t.LocallyOwned,
      text: t.ServingYouSince2018,
      icon: MapIcon,
    },
  ];
  return (
    <Box mt={7}>
      <Grid container spacing={10}>
        {tags.map(({ title, text, icon: Icon }) => (
          <Grid item xs={12} sm={4} key={title}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Box
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '40px',
                    color: 'primary.main',
                  },
                }}
              >
                <Icon />
              </Box>
              <Box ml={3}>
                <Typography variant="h5">{title}</Typography>
                <Typography>{text}</Typography>
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

type HomePageDataLoader = {
  topDestinationProps: FetchTopDestinationsProps;
  topDestinations: TopDestination[];
  trendingDestinations: TrendingDestination[];
};

const HomePage = () => {
  const dataLoader = useLoaderData() as HomePageDataLoader;

  return (
    <Box>
      <GoogleAuth hidden autoDisplay />
      <Navbar withNavbar />
      <Container sx={sx.container}>
        <ShowPromotionCoupon />
        <HomePageBanner />
        <TopHotels topDestinationProps={dataLoader.topDestinationProps} topDestinations={dataLoader.topDestinations} />
        <TrendingDestinations trendingDestinations={dataLoader.trendingDestinations} />
        <Tags />
      </Container>
    </Box>
  );
};

HomePage.dataLoader = async (params): Promise<HomePageDataLoader> => {
  const { startDate, endDate } = getNextWeekend({ daysAhead: 30 });

  const topDestinationProps: FetchTopDestinationsProps = {
    checkin: formatToDate(startDate),
    checkout: formatToDate(endDate),
    rooms: '2-0',
  };
  const [topDestinations, trendingDestinations] = await Promise.all([
    API.settings.topDestinations(topDestinationProps),
    API.settings.trendingDestinations(),
  ]);

  return { topDestinations, topDestinationProps, trendingDestinations };
};

export default HomePage;
