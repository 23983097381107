import { Box, Button, Typography, useTheme } from '@mui/material';
import { COLOR } from '../../helpers/styles';
import { t } from '../../translations';
import { useState } from 'react';
import { SearchType } from '../../types/search';
import { toast } from 'react-toastify';
import { formatToDate, getAllDatesOfWeek, getDateRanges, getTotalOfNights, humanizeRange } from '../../helpers/date-time';
import { batchPromises, getRgb, money, tryCatch } from '../../helpers/utils';
import { LoadingIcon } from '../../contexts/LoadingContext/Loading';
import HotelAvailabilityPage from '../../pages/home/HotelAvailabilityPage';
import useBreakpoints from '../../hooks/use-breakpoints';
import WeekPicker from '../Inputs/WeekPickerInput';
import { API } from '../../api/home-api';
import { HotelAvailability } from '../../types/hotels';
import { Currency } from '../../types';
import { isSameDay } from 'date-fns';

type AdvancedSearchPricesProps = {
  hotelCode: string;
  rooms: string;
  startDate: Date;
  endDate: Date;
  onSelectedDates: (dates: { startDate: Date; endDate: Date }) => void;
};

const getWeekStartAndEndDates = (date: Date) => {
  const allDatesOfTheWeek = getAllDatesOfWeek(date);
  const firstDayOfWeek = allDatesOfTheWeek[0];
  const lastDayOfWeek = allDatesOfTheWeek[allDatesOfTheWeek.length - 1];

  return {
    startDate: firstDayOfWeek,
    endDate: lastDayOfWeek,
  };
};

const AdvancedSearchPrices = ({
  hotelCode,
  rooms,
  startDate: initialStartDate,
  endDate: initialEndDate,
  onSelectedDates,
}: AdvancedSearchPricesProps) => {
  const { md } = useBreakpoints();
  const theme = useTheme();

  const [totalOfNights] = useState(() => getTotalOfNights(initialStartDate, initialEndDate));
  const [{ startDate, endDate }, setDates] = useState(() => getWeekStartAndEndDates(initialStartDate));

  const [searching, setSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [pricesPerDay, setPricesPerDay] = useState<{ start: Date; end: Date; price: number; currency: Currency }[]>([]);

  const search = async () => {
    setSearching(true);
    setSearched(true);
    setPricesPerDay([]);

    const startAndEndDates = getDateRanges(startDate, endDate, totalOfNights);
    const tasks = startAndEndDates.map(
      ([start, end]) =>
        () =>
          API.hotels.searchHotelsAvailabilities({
            code: hotelCode,
            type: SearchType.Hotel,
            checkin: formatToDate(start),
            checkout: formatToDate(end),
            rooms: rooms,
            shortResponse: false,
            cancellationFees: true,
          })
    );

    const [availabilities, error] = await tryCatch(() =>
      batchPromises(tasks, {
        concurrency: 2,
        delayInSeconds: 1,
      })
    );

    setSearching(false);

    if (error) {
      toast.error(t.Error);
      return;
    }

    const newPricesPerDay: any[] = [];
    startAndEndDates.forEach(([start, end], i) => {
      const availability = availabilities[i].hotelsResult?.[0];
      if (!availability) {
        return;
      }

      const { minPrice, currency } = getMinPriceWithCurrency(availability);
      const link = HotelAvailabilityPage.link(
        {
          checkin: formatToDate(start),
          checkout: formatToDate(end),
          rooms,
          code: hotelCode,
        },
        new URLSearchParams(window.location.search).get('returnUrl')! || ''
      );

      newPricesPerDay.push({
        start,
        end,
        price: minPrice,
        currency,
        link,
      });
    });

    setPricesPerDay(newPricesPerDay);
  };

  let minPrice: number = Number.MAX_VALUE;
  let maxPrice: number = 0;
  pricesPerDay.forEach(({ price }) => {
    if (price < minPrice) {
      minPrice = price;
    }
    if (price > maxPrice) {
      maxPrice = price;
    }
  });

  return (
    <Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button variant="contained" color="primary" onClick={search} disabled={searching}>
          {t.Search}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: md ? 'row' : 'column' }}>
        <Box>
          <Box sx={{ mr: searched ? 6 : 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <WeekPicker initialStartDate={initialStartDate} onChange={(newDate) => setDates(getWeekStartAndEndDates(newDate))} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: searched ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', minWidth: 340 }}>
          {searching && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ mb: 2 }}>
                <LoadingIcon />
              </Box>
              <Typography>{t.SearchingAvailabilitiesInTheSelectedWeek}</Typography>
            </Box>
          )}
          {!searching && pricesPerDay.length > 0 && (
            <Box>
              {pricesPerDay.map(({ start, end, price, currency }) => {
                const { r, g, b } = getRgb({
                  value: price,
                  min: minPrice,
                  max: maxPrice,
                });
                const isSelected = isSameDay(initialStartDate, start) && isSameDay(initialEndDate, end);

                return (
                  <Box
                    key={start.toString()}
                    onClick={() => onSelectedDates({ startDate: start, endDate: end })}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 1.5,
                      border: `2px solid ${COLOR.GRAY_LINE_SEPARATOR}`,
                      px: 2,
                      py: 0.5,
                      borderRadius: 3,
                      color: `rgb(${r}, ${g}, ${b})`,
                      cursor: 'pointer',
                      transition: 'border-color .3s',
                      borderColor: isSelected ? theme.palette.primary.main : COLOR.GRAY_LINE_SEPARATOR,
                      '&:hover': {
                        borderColor: theme.palette.primary.main,
                      },
                    }}
                  >
                    <Box sx={{ mr: 10, fontSize: 12 }}>{humanizeRange(start, end)}</Box>
                    <Box>
                      <Typography component="span" variant="h6" fontSize={11} fontWeight={400}>
                        {currency}{' '}
                      </Typography>
                      <Typography component="span" variant="h6" fontSize={13} fontWeight={400}>
                        {' $'}
                        {`${money(price)} ${t.total}`}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
          {!searching && pricesPerDay.length === 0 && <Typography variant="body1">{t.NoResults}</Typography>}
        </Box>
      </Box>
    </Box>
  );
};

const getMinPriceWithCurrency = (availability: HotelAvailability): { minPrice?: number; currency?: Currency } => {
  let minPrice: number | undefined = undefined;
  let currency: Currency | undefined = undefined;

  availability.paxes?.forEach((pax) => {
    pax.rooms?.forEach((room) => {
      room.packages?.forEach((pkg) => {
        if (pkg.finalPrice && (!minPrice || pkg.finalPrice < minPrice)) {
          minPrice = pkg.finalPrice;
          currency = pkg.currency;
        }
      });
    });
  });

  return { minPrice, currency };
};

export default AdvancedSearchPrices;
