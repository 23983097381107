import { useSearchParams } from 'react-router-dom';
import { getObjFromLocalStorage, getQueryString, removeObjFromLocalStorage, saveObjToLocalStorage } from '../helpers/utils';
import { useState } from 'react';
import { Box } from '@mui/material';
import { addDays } from 'date-fns';
import { isInThePast } from '../helpers/date-time';
import { t } from '../translations';
import { API } from '../api/home-api';

const REFERRAL = 'referral';

export const getReferralId = () => getObjFromLocalStorage(REFERRAL)?.referralId;

const Referral = ({ children }) => {
  const [referral, setReferral] = useState(() => getObjFromLocalStorage(REFERRAL));
  const { referral: newReferralCode } = getQueryString();

  if (newReferralCode && referral?.referralCode !== newReferralCode) {
    API.referrals.get(newReferralCode).then(response => {
      const newReferral = {
        ...response.referral,
        expires: addDays(new Date(), response.referral.durationInDays)
      }
      setReferral(newReferral);
      saveObjToLocalStorage(REFERRAL, newReferral);
    });
  }

  if (referral?.expires && isInThePast(referral.expires)) {
    removeObjFromLocalStorage(REFERRAL);
    setReferral(undefined);
    referral.name = undefined;
  }

  return (
    <>
      {referral?.name && <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        textAlign: 'center',
        zIndex: 1300,
        padding: '5px',
        backgroundImage: 'linear-gradient(to right, rgb(1, 10, 62), rgb(70, 157, 207))',
        color: 'white',
      }}>{t.WelcomeYouVeBeenReferredToOurWebsiteBy}{' '}{referral?.name}. {t.ExploreEnjoy}</Box>}
      <Box mt={referral?.name ? '28px' : 0}>
        {children}
      </Box>
    </>
  );
};

export default Referral;
