import { useEffect, useState, useRef, useMemo } from 'react';
import { Box, Grid, Typography, Select, MenuItem, Stack } from '@mui/material';
import useBreakpoints from '../../hooks/use-breakpoints';
import HotelFilters from '../HotelFilters';
import HotelAvailabilityCardList from '../HotelAvailabilityCardList';
import { amountOfNights, formatToDate, parseDate } from '../../helpers/date-time';
import { buildQueryString, getQueryString, tryCatch } from '../../helpers/utils';
import HotelAvailabilityPage from '../../pages/home/HotelAvailabilityPage';
import { paxesObjToString, paxesStringToObj, Room } from '../../helpers/paxes';
import FullScreenDialog from '../Dialogs/FullScreenDialog';
import { searchHotelsAvailabilities } from '../../api/hotels';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchHotelsInputs from '../Inputs/SearchHotelsInput';
import ShowPromotionCoupon from '../ShowPromotionCoupon/ShowPromotionCoupon';
import { Image } from 'mui-image';
import loadingGif from '../assets/loading.gif';
import { makeStyles } from '@mui/styles';
import HotelsMap from '../HotelsMap/HotelsMap';
import { t } from '../../translations';
import { DateRange } from '../DateRangePicker/DateRangePicker';
import { CityRecord, HotelRecord, ProvinceRecord, RecordResult, SearchType } from '../../types/search';

const SORT_BY = {
  PriceMinToMax: 'Sort by Price: Low to Hight',
  PriceMaxToMin: 'Sort by Price: Hight to Low',
  CategoryMinToMax: 'Sort by Category: Low to Hight',
  CategoryMaxToMin: 'Sort by Category: Hight to Low',
  NameAsc: 'Sort by Name: Asc',
  NameDesc: 'Sort by Name: Desc',
};

const SORT_BY_LIST = [
  SORT_BY.PriceMinToMax,
  SORT_BY.PriceMaxToMin,
  SORT_BY.CategoryMinToMax,
  SORT_BY.CategoryMaxToMin,
  SORT_BY.NameAsc,
  SORT_BY.NameDesc,
];

const useStyles = makeStyles(() => ({
  button: {
    '& button': {
      color: 'white',
    },
  },
}));

export type SearchTerms = {
  country?: string;
  province?: string;
  city?: string;
  hotel?: string;
  code?: string;
  type?: string;
  checkin?: string;
  checkout?: string;
  rooms?: string;
  shortResponse?: boolean;
};

export type BuildNewSearchTermsProps = {
  location?: RecordResult;
  dates?: { startDate: Date; endDate: Date };
  rooms?: Room[];
  roomsStr?: string;
};

export const buildNewSearchTerms = ({ location, dates, rooms, roomsStr }: BuildNewSearchTermsProps): SearchTerms => {
  const newSearchTerms = {
    type: location?.type,
    country: location?.item?.country,
    province: (location?.item as ProvinceRecord)?.province,
    city: (location?.item as CityRecord)?.city,
    hotel: (location?.item as HotelRecord)?.hotel,
    code: location?.item?.code,
    checkin: formatToDate(dates?.startDate),
    checkout: formatToDate(dates?.endDate),
    rooms: roomsStr || (rooms === undefined ? undefined : paxesObjToString(rooms)),
    shortResponse: true,
  };

  return newSearchTerms;
};

const buildInitialParamsFromQueryString = (): { location?: RecordResult; dates?: DateRange; rooms?: Room[] } => {
  const queryStringAsObj = getQueryString();

  const initialParams = {
    location: {
      type: queryStringAsObj.type as SearchType,
      item: {
        country: queryStringAsObj.country,
        countryCode: '', // dummy field to satisfy a Record type
        province: queryStringAsObj.province,
        city: queryStringAsObj.city,
        hotel: queryStringAsObj.hotel,
        code: queryStringAsObj.code,
      },
    },
    dates: {
      startDate: parseDate(queryStringAsObj.checkin),
      endDate: parseDate(queryStringAsObj.checkout),
    },
    rooms: queryStringAsObj.rooms ? paxesStringToObj(queryStringAsObj.rooms) : undefined,
  };

  if (
    !initialParams.location.type ||
    !initialParams.location.item.code ||
    (!initialParams.location.item.country &&
      !initialParams.location.item.province &&
      !initialParams.location.item.city &&
      !initialParams.location.item.hotel) ||
    !initialParams.dates.startDate ||
    !initialParams.dates.endDate ||
    !initialParams.rooms
  ) {
    return {};
  }

  return initialParams;
};

const HotelsSearch = (props) => {
  const [searched, setSearched] = useState(false);
  const [searching, setSearching] = useState(false);
  const [hotelsAvailabilities, setHotelsAvailabilities] = useState<any[]>([]);
  const [searchTerms, setSearchTerms] = useState<any>({});
  const [filtersSet, setFiltersSet] = useState<any>({});
  const [filtersApplied, setFiltersApplied] = useState<any>({});
  const [filteredHotelAvailabilities, setFilteredHotelAvailabilities] = useState<any[]>([]);
  const breakpoints = useBreakpoints();
  const [sortBy, setSortBy] = useState('');
  const [loadMap, setLoadMap] = useState(false);

  const nights = useMemo(() => {
    return amountOfNights(searchTerms.checkin, searchTerms.checkout);
  }, [searchTerms?.checkin, searchTerms?.checkout]);

  const styles = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const [initialParams] = useState(() => buildInitialParamsFromQueryString());

  const closeFilterDialogRef = useRef<any>(null);
  const closeMapDialogRef = useRef<any>(null);

  const updateUrl = (newSearchTerms) => {
    const newUrl = `${location.pathname}?${buildQueryString(newSearchTerms)}`;
    navigate(newUrl);
  };

  const searchHotels = async ({
    location,
    dates,
    rooms,
  }: {
    location?: RecordResult;
    dates?: { startDate: Date; endDate: Date };
    rooms?: Room[];
  }) => {
    setSearched(true);
    setSearching(true);

    const [newHotelsAvailabilities, err] = await tryCatch(async () => {
      const newSearchTerms = buildNewSearchTerms({ location, dates, rooms });
      setSearchTerms(newSearchTerms);
      updateUrl(newSearchTerms);

      const result = await searchHotelsAvailabilities({ params: newSearchTerms });
      return result.hotelsResult;
    });

    setSearching(false);

    if (err || !newHotelsAvailabilities?.length) {
      if (err) {
        console.error(err);
        alert(t.UpsSomethingWasWrong);
      }
      setHotelsAvailabilities([]);
      setFiltersSet({});
      setFiltersApplied({});
      setFilteredHotelAvailabilities([]);
      return;
    }

    startFilters(newHotelsAvailabilities);
  };

  const startFilters = (newHotelsAvailabilities) => {
    const newFiltersSet = getNewFiltersSet(newHotelsAvailabilities, {
      updatePrices: true,
    });
    const newFiltersApplied = getNewFiltersApplied(newFiltersSet);
    const newFilteredHotelAvailabilities = filterHotelAvailabilities(newHotelsAvailabilities, newFiltersApplied);

    setHotelsAvailabilities(newHotelsAvailabilities);
    setFiltersSet(newFiltersSet);
    setFiltersApplied(newFiltersApplied);
    setFilteredHotelAvailabilities(newFilteredHotelAvailabilities);
  };

  const cleanupFilters = () => startFilters(hotelsAvailabilities);

  const updateFiltersApplied = (newFiltersApplied) => {
    const newFilteredHotelAvailabilities = filterHotelAvailabilities(hotelsAvailabilities, newFiltersApplied);
    const newFiltersSet = getNewFiltersSet(newFilteredHotelAvailabilities, {
      updatePrices: false,
      oldFilterSet: filtersSet,
    });

    setFiltersSet(newFiltersSet);
    setFiltersApplied(newFiltersApplied);
    setFilteredHotelAvailabilities(newFilteredHotelAvailabilities);
  };

  const hotelFiltersCpm = (
    <HotelFilters
      checkboxesLimit={5}
      filtersSet={filtersSet}
      filtersApplied={filtersApplied}
      onChange={updateFiltersApplied}
      onCleanup={cleanupFilters}
      onCloseDialog={closeFilterDialogRef.current}
    />
  );

  const showFiltersInANewDialog = breakpoints.only.xs || breakpoints.only.sm || breakpoints.only.md;

  useEffect(() => {
    if (initialParams.location && initialParams.dates && initialParams.rooms) {
      searchHotels(initialParams);
    }
  }, []);

  const hotelAvailabilities = searched && !searching && parseHotelAvailabilities(filteredHotelAvailabilities, searchTerms, sortBy);

  const fullScreenDialogFilters = (
    <FullScreenDialog
      titleLabel={t.Filters}
      openDialogLabel={t.Filters}
      onSetCloseDialog={(closeDialog) => {
        closeFilterDialogRef.current = closeDialog;
      }}
      children={<Box sx={{}}>{hotelFiltersCpm}</Box>}
    />
  );

  const fullScreenDialogMap = (
    <FullScreenDialog
      openDialogLabel={t.ShowInMap}
      openDialogButtonProps={{ sx: { minWidth: '120px' } }}
      customTitle={() => (
        <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {hotelAvailabilities.length === 1 ? t.Found1Property : t.foundNProperties(hotelAvailabilities.length)}
          </Typography>
          <Box className={styles.button}>{fullScreenDialogFilters}</Box>
        </Stack>
      )}
      onSetCloseDialog={(closeDialog) => {
        closeMapDialogRef.current = closeDialog;
      }}
      onCloseDialog={() => {
        setLoadMap(false);
        return true;
      }}
      onOpenDialog={() => {
        setLoadMap(true);
        return true;
      }}
      children={loadMap && <HotelsMap hotelAvailabilities={hotelAvailabilities} />}
    />
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} mb={2}>
          <SearchHotelsInputs
            initialLocation={initialParams.location}
            initialDates={initialParams.dates}
            initialRooms={initialParams.rooms}
            searching={searching}
            onSearch={searchHotels}
          ></SearchHotelsInputs>
        </Grid>

        {searched && !hotelsAvailabilities.length && !searching && (
          <Box sx={{ margin: '0 auto', my: '100px', width: '400px', maxHeight: '100px' }}>
            <Typography variant="h5" textAlign="center">
              {t.NoResults}
            </Typography>
          </Box>
        )}
        {searched && (
          <>
            {searching && (
              <Box sx={{ margin: '0 auto', my: '100px', width: '400px', maxHeight: '100px' }}>
                <Image src={loadingGif} duration={0} width={'100%'} height={'100%'} />
              </Box>
            )}

            {!searching && (hotelsAvailabilities.length > 0 || loadMap) && (
              <Grid item xs={12} mb={1}>
                <Stack direction="row" spacing={1} alignItems="baseline" flexWrap={showFiltersInANewDialog ? 'wrap' : undefined}>
                  <Box sx={{ /*flex: '1 1 auto',*/ width: '100%' }}>
                    <ShowPromotionCoupon />
                  </Box>
                  {showFiltersInANewDialog && fullScreenDialogFilters}
                  <Box>{(hotelAvailabilities.length > 0 || loadMap) && fullScreenDialogMap}</Box>
                  <Box sx={{ marginLeft: 'auto !important', pr: 1, pl: 3 }}>
                    <Select
                      value={sortBy}
                      onChange={(event) => setSortBy(event.target.value)}
                      // label="Sort By"
                      sx={{ background: 'white' }}
                      size="small"
                      displayEmpty
                      renderValue={(value) => {
                        return value?.length ? (Array.isArray(value) ? value.join(', ') : value) : t.SortBy;
                      }}
                    >
                      {SORT_BY_LIST.map((item, idx) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Stack>
              </Grid>
            )}

            {!searching && hotelsAvailabilities.length > 0 && !showFiltersInANewDialog && (
              <Grid item lg={3}>
                {!searching && hotelFiltersCpm}
              </Grid>
            )}

            {!searching && (
              <Grid item xs={12} p={3} pr={0} lg={9} sx={breakpoints.md ? { maxHeight: '1498px', overflowX: 'hidden' } : {}}>
                <HotelAvailabilityCardList skeleton={searching ? 5 : 0} hotelAvailabilities={hotelAvailabilities} nights={nights} />
                {hotelAvailabilities.length === 0 && <Typography textAlign="center" variant='h6'>{t.NoPropertiesFound}</Typography>}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

const getPackages = (hotelAvailability) => hotelAvailability.paxes.flatMap((p) => p.rooms.flatMap((r) => r.packages));

const getNewFiltersSet = (hotelAvailabilities, { updatePrices, oldFilterSet }: any) => {
  const filtersSet = {
    price: { min: 0, max: 0, currency: '' },
    byName: true,
    reimbursable: true,
    ratings: [`2 ${t.stars}`, `3 ${t.stars}`, `4 ${t.stars}`, `5 ${t.stars}`, t.Unrated],
    types: [] as any[],
    amenities: [] as any[],
    accommodationTypes: [] as any[],
  };

  if (!updatePrices) {
    filtersSet.price = oldFilterSet.price;
  }

  hotelAvailabilities.forEach((av) => {
    const pkgs = getPackages(av);
    const maxPrice = Math.max(...pkgs.map((pkg) => pkg.finalPrice));
    const currencyForMaxPrice = pkgs.find((pkg) => pkg.finalPrice === maxPrice)?.currency;

    if (updatePrices) {
      filtersSet.price.max = Math.max(filtersSet.price.max, maxPrice);
      filtersSet.price.currency = maxPrice > filtersSet.price.max && currencyForMaxPrice ? currencyForMaxPrice : filtersSet.price.currency;
    }

    filtersSet.types.push(av.type);
    filtersSet.amenities.push(...(av.services?.map((service) => service.description) || []));
    filtersSet.accommodationTypes.push(...pkgs.flatMap((p) => p.accommodationType));
  });

  const isAValidFilterValue = (value) => Boolean(value && value.trim());

  filtersSet.types = [...new Set(filtersSet.types.filter(isAValidFilterValue).sort())];
  filtersSet.amenities = [...new Set(filtersSet.amenities.filter(isAValidFilterValue).sort())];
  filtersSet.accommodationTypes = [...new Set(filtersSet.accommodationTypes.filter(isAValidFilterValue).sort())];

  return filtersSet;
};

const getNewFiltersApplied = (filtersSet) => ({
  price: [filtersSet.price.min, filtersSet.price.max],
  certificate: false,
  byName: '',
  reimbursable: false,
  ratings: [],
  types: [],
  accommodationTypes: [],
  amenities: [],
});

const filterHotelAvailabilities = (hotelAvailabilities, filtersApplied) => {
  const [minPrice, maxPrice] = filtersApplied.price;
  const ratingValues = filtersApplied.ratings.map(
    (rating) =>
      ({
        [`1 ${t.stars}`]: 1,
        [`2 ${t.stars}`]: 2,
        [`3 ${t.stars}`]: 3,
        [`4 ${t.stars}`]: 4,
        [`5 ${t.stars}`]: 5,
        Unrated: undefined,
      }[rating])
  );

  return hotelAvailabilities.filter((av) => {
    const pkgs = getPackages(av);

    const containsAtLeastOnePkgPriceInRange = pkgs.find((pkg) => minPrice < pkg.finalPrice && pkg.finalPrice < maxPrice);
    if (!containsAtLeastOnePkgPriceInRange) {
      return false;
    }

    if (filtersApplied.certificate && !av.certificate) {
      return false;
    }

    const filterHotelsByText = (filtersApplied.byName || '').trim().toLowerCase();
    if (filterHotelsByText && !av.hotelName.trim().toLowerCase().includes(filterHotelsByText)) {
      return false;
    }

    const containsAtLeastOnePkgReimbursable = pkgs.find((pkg) => pkg.reimbursable);
    if (filtersApplied.reimbursable && !containsAtLeastOnePkgReimbursable) {
      return false;
    }

    const avRating = av.category ? Number.parseInt(av.category, 10) : undefined;
    const isOneOfTheRatings = ratingValues.includes(avRating);
    if (ratingValues.length && !isOneOfTheRatings) {
      return false;
    }

    const isOneOfTheTypes = filtersApplied.types.includes(av.type);
    if (filtersApplied.types.length && !isOneOfTheTypes) {
      return false;
    }

    const matchAllSelectedAmenities = filtersApplied.amenities.every((amenity) =>
      av.services.map((service) => service.description).includes(amenity)
    );
    if (filtersApplied.amenities.length && !matchAllSelectedAmenities) {
      return false;
    }

    const pkgAccommodationTypes = pkgs.map((pkg) => pkg.accommodationType);
    const matchAllSelectedAccommodationTypes = filtersApplied.accommodationTypes.every((accType) =>
      pkgAccommodationTypes.includes(accType)
    );
    if (filtersApplied.accommodationTypes.length && !matchAllSelectedAccommodationTypes) {
      return false;
    }

    return true;
  });
};

const parseHotelAvailabilities = (hotelAvailabilities, searchTerms, sortBy) => {
  const result = hotelAvailabilities.map((av) => {
    const pkgs = getPackages(av);
    const finalPrice = Math.min(...pkgs.map((pkg) => pkg.finalPrice));

    const { pathname, search } = window.location;
    const returnUrl = `${pathname}${search}`;

    return {
      linkTo: HotelAvailabilityPage.link(
        {
          ...searchTerms,
          code: av.hotelCode,
          // hotelCodes: av.hotelCode,
        },
        returnUrl
      ),
      hotelCode: av.hotelCode,
      hotelName: av.hotelName,
      hotelDescription: av.hotelDescription,
      type: av.type,
      category: av.category,
      images: av.images,
      reimbursable: !!pkgs.find((pkg) => pkg.reimbursable),
      finalPrice,
      currency: pkgs.find((pkg) => pkg.finalPrice === finalPrice)?.currency,
      certificated: av.certificate,
      roomsDescriptions: [...new Set(av.paxes.flatMap((pax) => pax.rooms.flatMap((room) => room.roomDescription)))],
      accommodationTypes: [...new Set(pkgs.map((pkg) => pkg.accommodationType))],
      amenities: av.services?.map((service) => service.description) || [],
      closedPlaces: av.closePlaces,
      location: av.location,
    };
  });

  const getSort = (prop, order) => (a, b) => (a[prop] > b[prop] ? 1 : -1) * order;
  const sortFn =
    sortBy === SORT_BY.PriceMinToMax
      ? getSort('finalPrice', 1)
      : sortBy === SORT_BY.PriceMaxToMin
      ? getSort('finalPrice', -1)
      : sortBy === SORT_BY.CategoryMinToMax
      ? getSort('category', 1)
      : sortBy === SORT_BY.CategoryMaxToMin
      ? getSort('category', -1)
      : sortBy === SORT_BY.NameAsc
      ? getSort('hotelName', 1)
      : sortBy === SORT_BY.NameDesc
      ? getSort('hotelName', -1)
      : undefined;

  return sortFn ? result.sort(sortFn) : result;
};

export default HotelsSearch;
