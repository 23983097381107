import React, { useState, useRef, useEffect } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { HotelAvailabilityCardMap } from '../HotelAvailabilityCard/HotelAvailabilityCard';
import { createPortal } from 'react-dom';
import { Box } from '@mui/material';
import useBreakpoints from '../../hooks/use-breakpoints';
import './HotelMap.styles.css';

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID;
const GOOGLE_MAP_LIBRARIES = ['places', 'marker'];

const MAP_CONTAINER_ID = 'hotels-map';
const CARD_CONTAINER_ID = 'hotels-map-card';

const loadGoogleMapScript = () => {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=${GOOGLE_MAP_LIBRARIES.join('&')}`;
  script.setAttribute('loading', 'async');
  window.document.body.appendChild(script);

  return new Promise((resolve) => script.addEventListener('load', resolve))
    .then(() => window.google.maps.importLibrary('marker'))
    .then((markerLib) => (window.markerLib = markerLib));
};

const googleMapScriptsLoaded = () => Boolean(window.markerLib);

const createMap = (container) => {
  return new window.google.maps.Map(container, {
    // id: GOOGLE_MAP_ID,
    mapId: GOOGLE_MAP_ID,
    zoom: 10,
  });
};

const createClusterMap = (map) => {
  return new MarkerClusterer({
    marker: [],
    map,
    maxDistance: 10,
    algorithmOptions: {
      // https://www.npmjs.com/package/supercluster
      minPoints: 5,
      // radius: 10,
    },
    // gridSize: 1
    // algorithm: new SuperClusterAlgorithm()
  } as any);
};

const zIndex = (value) => 10000000 + value;

const unSelectMarker = (selectedMarkerRef, setSelectedHotel) => {
  if (!selectedMarkerRef.current) {
    return;
  }

  selectedMarkerRef.current.content.classList.remove('highlight');
  selectedMarkerRef.current.zIndex = zIndex(0);
  setSelectedHotel(undefined);
};

const selectMarker = (hotel, marker, selectedMarkerRef, setSelectedHotel) => {
  marker.content.classList.add('highlight');
  marker.zIndex = zIndex(10);
  selectedMarkerRef.current = marker;
  setSelectedHotel(hotel);
};

const createMarker = (hotel, map, cluster, selectedMarkerRef, setSelectedHotel) => {
  const marker = new window.markerLib.AdvancedMarkerElement({
    map,
    position: { lat: hotel.location.latitude, lng: hotel.location.longitude },
    title: hotel.hotelName,
  });
  cluster.addMarker(marker);

  const $markerEl = document.createElement('div');
  $markerEl.classList.add('hotel-marker');

  marker.addListener('click', () => {
    unSelectMarker(selectedMarkerRef, setSelectedHotel);
    selectMarker(hotel, marker, selectedMarkerRef, setSelectedHotel);
  });

  $markerEl.addEventListener('mouseenter', () => {
    if (marker !== selectedMarkerRef.current) {
      marker.zIndex = zIndex(1);
    }
  });

  $markerEl.addEventListener('mouseleave', () => {
    if (marker !== selectedMarkerRef.current) {
      marker.zIndex = zIndex(0);
    }
  });

  $markerEl.innerHTML = `
    <div class="price-tag">${hotel.currency} ${hotel.finalPrice}</div>
    <div class="details" />
  `;

  marker.content = $markerEl;

  return marker;
};

const removeMarkers = (markers, map, cluster) => {
  cluster.clearMarkers();
  markers.forEach((marker) => marker.setMap(null));
};

const calculateMapCenter = (hotelAvailabilities) => {
  const bounds = new window.google.maps.LatLngBounds();
  hotelAvailabilities.forEach((hotel) => {
    bounds.extend(new window.google.maps.LatLng(hotel.location.latitude, hotel.location.longitude));
  });

  return {
    lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
    lng: (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2,
  };
};

const HotelsMap = ({ hotelAvailabilities }) => {
  const [{ map, clusterMap }, setMapState] = useState<any>({});
  const markersRef = useRef<any>(undefined);;
  const selectedMarkerRef = useRef<any>(undefined);;
  const [selectedHotel, setSelectedHotel] = useState<any>(undefined);

  useEffect(function loadMap() {
    const loadNewMap = () => {
      const mapContainer = document.getElementById(MAP_CONTAINER_ID);
      const newMap = createMap(mapContainer);
      const newClusterMap = createClusterMap(newMap);

      setMapState({
        map: newMap,
        clusterMap: newClusterMap,
      });
    };

    if (googleMapScriptsLoaded()) {
      loadNewMap();
    } else {
      loadGoogleMapScript().then(loadNewMap);
    }
  }, []);

  useEffect(
    function addMarkers() {
      if (!map) {
        return;
      }

      map.setCenter(calculateMapCenter(hotelAvailabilities));
      markersRef.current = hotelAvailabilities.map((hotel) => {
        return createMarker(hotel, map, clusterMap, selectedMarkerRef, setSelectedHotel);
      });

      return () => removeMarkers(markersRef.current, map, clusterMap);
    },
    [map, hotelAvailabilities]
  );

  const isXs = useBreakpoints().only.xs;
  const target = document.querySelector(isXs ? `#${CARD_CONTAINER_ID}` : '.hotel-marker.highlight .details');

  return (
    <React.Fragment>
      <Box id={MAP_CONTAINER_ID} width="100%" height="100%">
        {selectedMarkerRef.current && selectedHotel && target && (
          <React.Fragment>
            {createPortal(
              <HotelAvailabilityCardMap
                hotelAvailability={selectedHotel}
                onClose={(event) => {
                  unSelectMarker(selectedMarkerRef, setSelectedHotel);
                  event.stopPropagation();
                }}
              />,
              target
            )}
          </React.Fragment>
        )}
      </Box>
      <Box id={CARD_CONTAINER_ID} position="absolute" bottom={0} />
    </React.Fragment>
  );
};

export default HotelsMap;
