import { Button, Link } from '@mui/material';
import { Link as LinkRouter, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useWithDataLoader } from '../../hooks/use-data-loader';
import MyDataGrid, { MyDataGridProps } from '../MyDataGrid/MyDataGrid';
import { t } from '../../translations';
import { tryCatch } from '../../helpers/utils';

export type DataGridColumn <T> = {
  field: keyof T,
  headerName: string,
  width?: string|number,
  renderCell?: (prop: { row: T }) => JSX.Element | null,
}

type getListComponentProps = {
  baseRoute: string;
  dataLoader: Parameters<typeof useWithDataLoader>[0];
  columns: Parameters<typeof MyDataGrid>[0]['columns'];
  autoHeight: Parameters<typeof MyDataGrid>[0]['autoHeight'];
  editable?: boolean;
  deletable?: boolean;
  isItemDeletable?: (item: any) => boolean;
  creatable?: boolean;
  destroy?: (record: any) => Promise<any>;
  onUpdatedItem?: (cb: (item: any) => void) => void;
};

export const getListComponent =
  ({
    baseRoute,
    dataLoader,
    destroy,
    columns,
    autoHeight,
    editable = true,
    deletable = true,
    creatable = true,
    isItemDeletable,
    onUpdatedItem,
    ...myDatGridProps
  }: getListComponentProps & Omit<MyDataGridProps, 'rows' | 'columns'>) =>
  () => {
    const navigate = useNavigate();
    const { data, setData, loadedData, isOk, message } = useWithDataLoader(dataLoader);

    if (!isOk) {
      return message;
    }

    const deleteItem = async (item) => {
      if (!destroy) {
        throw new Error('Destroy operation is not provided');
      }

      const [, err] = await tryCatch(() => destroy(item._id));

      if (err) {
        toast.error(`${t.Error}. ${err?.response?.data?.message}`);
        return;
      }

      setData((data || []).filter((item2) => item._id !== item2._id));
    };

    onUpdatedItem?.((newItem) => {
      setData((data || []).map((item) => (newItem._id === item._id ? newItem : item)));
    });

    const goToEditItem = async (item) => {
      navigate(`/admin/${baseRoute}/edit/${item._id}`);
    };

    return (
      <MyDataGrid
        autoHeight={autoHeight}
        columns={columns}
        rows={data}
        onDelete={deletable ? deleteItem : undefined}
        isItemDeletable={isItemDeletable}
        onEdit={editable ? goToEditItem : undefined}
        additionalToolbarOptions={
          creatable && (
            <Link component={LinkRouter} to={`/admin/${baseRoute}/new`} underline="none">
              <Button>{t.Create}</Button>
            </Link>
          )
        }
        {...myDatGridProps}
      />
    );
  };
