import { useRef, useState } from 'react';
import { Box, Button, Grid, Link, Stack, TextField, Typography } from '@mui/material';
// import NavBar from '../../components/NavBar';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { Outlet, useLocation } from 'react-router-dom';
import { COLOR } from '../../helpers/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import cert1 from '../../components/assets/cert1.jpeg';
import cert2 from '../../components/assets/cert2.png';
import Image from 'mui-image';
import useBreakpoints from '../../hooks/use-breakpoints';
import { InstallAppPrompt, LinkToInstallAppButton, useLinkToInstallApp } from '../../components/LinkToInstallApp/LinkToInstallApp';
import subscriptionBell from '../../components/assets/subscription-bell.png';
import isEmail from 'validator/lib/isEmail';
import { t } from '../../translations';

const underline = `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`;

const phoneNumber = process.env.REACT_APP_BELLITOURS_PHONE;
const adminEmail = process.env.REACT_APP_BELLITOURS_ADMIN_EMAIL;

const FooterTitle = ({ title }) => (
  <Box>
    <Typography component="div" sx={{ display: 'inline-block', mb: 2, borderBottom: underline, pb: 1, px: 1, fontWeight: 'bold' }}>
      {title}
    </Typography>
  </Box>
);

const FooterHelpBox = ({ text, children }) => (
  <Box p={2} pl={0}>
    <Box borderLeft="3px solid" borderColor="info.main" pl={2}>
      <Typography color="text.secondary">{text}</Typography>
      {children}
    </Box>
  </Box>
);

const FooterLink = ({ to, text }) => (
  <Box mb={2}>
    <Link href={to} underline="none" color="text.primary">
      {text}
    </Link>
  </Box>
);

export const Footer = ({ skipSubscription }: { skipSubscription?: boolean }) => {
  const linkToInstallApp = useLinkToInstallApp();

  return (
    <Box mt={4}>
      <InstallAppPrompt {...linkToInstallApp} />

      {skipSubscription || <Subscription />}
      <Box sx={{ background: 'white', borderTop: underline, pt: 5, pb: 6, px: { xs: 3, sm: 5, md: 14 } }}>
        <Box sx={{ maxWidth: '1000px', margin: '0 auto' }}>
          <Grid container spacing={6}>
            {/* {linkToInstallApp.canBeInstalled && (
              <Grid item xs={12} sm={6} md={3}>
                <FooterTitle title="Install App" />
                <Box ml={-2}>
                  <LinkToInstallAppButton {...linkToInstallApp} />
                </Box>
              </Grid>
            )} */}

            <Grid item xs={12} sm={6} md={3}>
              <FooterTitle title={t.NEED_HELP} />

              <FooterHelpBox text={`${t.CallUs}:`}>
                <Link href={`tel:${phoneNumber}`} color="info.main" sx={{ textDecoration: 'none' }}>
                  {phoneNumber}
                </Link>
              </FooterHelpBox>

              <FooterHelpBox text={`${t.ByEmail}:`}>
                <Link href={`mailto:${adminEmail}`} color="info.main" sx={{ textDecoration: 'none' }}>
                  {adminEmail}
                </Link>
              </FooterHelpBox>

              <FooterHelpBox text={`${t.FollowUs}:`}>
                <Stack direction="row" alignItems="center" spacing={2} pt={1}>
                  <Link href="https://www.facebook.com/bellitours">
                    <FacebookIcon />
                  </Link>
                  <Link href="https://twitter.com/bellitours">
                    <TwitterIcon />
                  </Link>
                  <Link href="https://www.instagram.com/bellitours/">
                    <InstagramIcon />
                  </Link>
                </Stack>
              </FooterHelpBox>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FooterTitle title={t.Company} />

              <Box mt={1} />
              <FooterLink to="/about" text={t.AboutBellitours} />
              <FooterLink to="/contact" text={t.ContactSupport} />
              <FooterLink to="/terms" text={t.BookingTermsConditions} />
              <FooterLink to="/privacy" text={t.PrivacyCookiesPolicy} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FooterTitle title={t.OurServices} />

              <Box mt={1} />
              <Box pl={2}>
                <FooterLink to="/search" text={t.Hotel} />
                <FooterLink to="https://bellitours.mybookingplatform.com/us/Tour/Search" text={t.Tours} />
                <FooterLink to="https://bellitours.mybookingplatform.com/us/Car/Search " text={t.Cars} />
                <FooterLink to="https://bellitours.mybookingplatform.com/us/Activities/Search" text={t.Activities} />
                <FooterLink to="https://bellitours.mybookingplatform.com/us/Insurance/Search" text={t.TravelInsurance} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FooterTitle title={t.Certifications} />

              <Box mt={1} />

              <Box sx={{ width: '100px', mb: 3 }}>
                <Image src={cert1} duration={0} />
              </Box>

              <Box sx={{ width: '100px' }}>
                <Image src={cert2} duration={0} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ background: 'white', borderTop: underline, p: 4, textAlign: 'center' }}>{t.Copyright2024ByBellitours}</Box>
    </Box>
  );
};

const useSubscriptionWebkitAutoFillStyles = makeStyles((theme) => ({
  styles: [
    '& input:-webkit-autofill',
    '& input:-webkit-autofill:hover',
    '& input:-webkit-autofill:focus',
    '& input:-webkit-autofill:active',
  ].reduce(
    (acc, item) => ({
      ...acc,
      [item]: {
        '-webkit-box-shadow': '0 0 0 30px rgb(1,8,65) inset !important',
        'box-shadow': '0 0 0 30px rgb(1,8,65) inset !important',
        '-webkit-text-fill-color': 'white !important',
        color: 'white !important',
      },
    }),
    {}
  ),
}));

const Subscription = () => {
  const isXs = useBreakpoints().only.xs;
  const classes = useSubscriptionWebkitAutoFillStyles();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(t.EmailIsRequired);
  const [fullName, setFullName] = useState('');
  const formRef = useRef<any>(null);
  const [showErrors, setShowErrors] = useState(false);

  const submit = (ev) => {
    setShowErrors(true);
    if (emailError) {
      return;
    }

    formRef.current.submit();
    formRef.current.reset();
    ev.preventDefault();
  };

  return (
    <Box sx={{ background: 'rgb(1,8,65)', color: 'white', p: 3 }}>
      <Stack
        direction={isXs ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
        gap={isXs ? 2 : 14}
        sx={{
          '& .MuiFormLabel-root': {
            color: 'white !important',
          },
          '& .MuiInput-underline:before': {
            borderBottom: '4px solid #006FBE !important',
          },
          '& .MuiInput-underline:after': {
            borderBottom: '4px solid #006FBE !important',
          },
          '& input': {
            caretColor: 'white',
            color: 'white',
          },
        }}
      >
        <Image duration={0} width="40px" src={subscriptionBell} alt="sub-bell" style={{ width: '100px' }} />
        <Box
          width={isXs ? '100%' : '315px'}
          component="form"
          ref={formRef}
          action="https://bellitours.us8.list-manage.com/subscribe/post?u=9733c1e27709ca2a6d126b6fd&amp;id=3c32f508c7&amp;f_id=00de6ae3f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
        >
          <Box mb={1}>
            <Typography fontSize={16} fontWeight="bold">
              {t.UnlockExclusiveTravelInspirationForYourNextGetawaySubscribeToOurNewsletter}
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="right" fontSize={11} fontWeight="bold">
              {t.IndicatesRequired} *
            </Typography>
          </Box>
          <Box mb={2}>
            <TextField
              className={classes.styles}
              id="mce-EMAIL"
              name="EMAIL"
              type="email"
              required
              label={t.Email}
              variant="standard"
              fullWidth
              value={email}
              error={!!(showErrors && emailError)}
              helperText={showErrors && emailError}
              onChange={(ev) => {
                const newEmail = (ev.target.value || '').trim();
                setEmail(newEmail);
                setEmailError(!newEmail ? t.EmailIsRequired : !isEmail(newEmail) ? t.InvalidEmail : '');
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className={classes.styles}
              required
              name="FNAME"
              id="mce-FNAME"
              label={t.NameLastName}
              variant="standard"
              fullWidth
              value={fullName}
              onChange={(ev) => setFullName(ev.target.value || '')}
            />
          </Box>
          <Button onClick={submit} variant="contained">
            {t.Subscribe}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

const HomeLayout = () => {
  const { pathname } = useLocation();
  const isXs = useBreakpoints().only.xs;

  const hideFooter = (isMobile || isXs) && Boolean(pathname.match(/^\/(search|availability)/));

  return (
    <>
      <Outlet />
      {hideFooter || <Footer />}
    </>
  );
};

export default HomeLayout;
