import { useEffect, useState } from 'react';
import { CurrentUserBooking } from '../../types/user';
import { UserAPI } from '../../api/user-api';
import { Box, Button, Paper, Rating, Typography } from '@mui/material';
import { t } from '../../translations';
import { LoadingIcon } from '../../contexts/LoadingContext/Loading';
import Image from 'mui-image';
import Grid from '@mui/material/Unstable_Grid2';
import { formatToDate } from '../../helpers/date-time';

const HOTELS_API_URL = process.env.REACT_APP_HOTELS_API_URL;

const BookingsPage = () => {
  const [orders, setOrders] = useState<CurrentUserBooking[] | undefined>(undefined);
  useEffect(() => {
    UserAPI.getBookings().then(setOrders);
  }, []);

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t.Bookings}
      </Typography>
      {orders === undefined ? (
        <LoadingIcon />
      ) : orders.length === 0 ? (
        <Typography variant="body1">{t.NoResults}</Typography>
      ) : (
        <Box>
          {orders.map((order) => {
            return (
              <Paper variant="outlined" sx={{ width: '100%', mb: 3, p: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} sm={6} md={6} lg={4}>
                    <Box sx={{}}>
                      <Image src={order.hotelImages[0]} duration={0} />
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} md={6} lg={8}>
                    <Box sx={{ pt: 2 }}>
                      <Typography variant="h6">{order.hotelName}</Typography>
                      <Rating name="read-only" value={order.hotelCategory} readOnly precision={1} size="small" />
                      <Typography variant="body1">{order.hotelAddress}</Typography>
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        <b>checkin: </b>
                        {formatToDate(order.checkin)}
                      </Typography>
                      <Typography variant="body1">
                        <b>checkout: </b>
                        {formatToDate(order.checkout)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} lg={4}>
                    <Box sx={{ ml: 2, mt: 3 }}>
                      <Box sx={{ mb: 2 }}>
                        <Button variant="outlined" href={`${HOTELS_API_URL}hotels/voucher/payment/${order.orderId}`} target="_blank">
                          {t.PaymentDetails}
                        </Button>
                      </Box>
                      <Button variant="outlined" href={`${HOTELS_API_URL}hotels/voucher/reservation/${order.orderId}`} target="_blank">
                        {t.BookingDetails}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} lg={8}>
                    <Box p={2} sx={{}}>
                      <Typography variant="h6">{order.clientName}</Typography>
                      <Typography variant="body1">
                        <b>{order.clientEmail}</b>
                      </Typography>
                      <Typography variant="body1">
                        <b>{order.clientPhone}</b>
                      </Typography>
                    </Box>
                    <Box p={2} pt={0} sx={{}}>
                      <Typography variant="h6">
                        {order.totalPrice} {order.currency}
                      </Typography>
                      <Typography>{order.cancelled && t.Cancelled}</Typography>
                      <Typography>{order.refunded && t.Refunded}</Typography>
                      <Typography>{order.refunded && order.totalRefunded}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default BookingsPage;
