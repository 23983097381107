import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import { format, differenceInCalendarMonths, addMonths, isPast, lastDayOfMonth } from 'date-fns';
import Month from './Month';
import DefinedRanges from './DefinedRanges';
import { MARKERS } from './DateRangePicker';
import { Theme } from '@mui/material';
import { t } from '../../translations';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '20px 70px',
  },
  headerItem: {
    flex: 3,
    textAlign: 'center',
  },
  headerItemArrow: {
    flex: 1,
    textAlign: 'center',
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.action.hover}`,
    marginBottom: 20,
  },
}));

const Menu = (props) => {
  const {
    // classes,
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    helpers,
    handlers,
  } = props;
  const classes = useStyles();
  const { startDate, endDate } = dateRange;
  const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const prevMonth = lastDayOfMonth(addMonths(firstMonth, -1));

  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };
  return (
    <Paper elevation={props.elevation || 5} square sx={{ width: '100%', overflowX: 'auto' }}>
      <Grid container direction="row" wrap="nowrap">
        <Grid>
          {props.selectedDatesDisabled ? (
            ''
          ) : (
            <Grid container className={classes.header} alignItems="center">
              <Grid item className={classes.headerItem}>
                <Typography variant="subtitle1">
                  {startDate ? (props.startDateLabel ?? '') + format(startDate, 'MMM dd, yyyy') : props.emptyStartDateLabel ?? t.StartDate}
                </Typography>
              </Grid>
              <Grid item className={classes.headerItemArrow}>
                <TrendingFlatRoundedIcon color="action" />
              </Grid>
              <Grid item className={classes.headerItem}>
                <Typography variant="subtitle1">
                  {endDate ? (props.endDateLabel ?? '') + format(endDate, 'MMM dd, yyyy') : props.emptyEndDateLabel ?? t.EndDate}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Divider />
          <Grid container direction="row" justifyContent="center" wrap="nowrap">
            <Month
              {...props}
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[props.allowPastDates || !isPast(prevMonth), props.onlyFirstMonthVisible || canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
            />
            {props.onlyFirstMonthVisible || (
              <>
                <div className={classes.divider} />
                <Month
                  {...props}
                  {...commonProps}
                  value={secondMonth}
                  setValue={setSecondMonth}
                  navState={[canNavigateCloser, true]}
                  marker={MARKERS.SECOND_MONTH}
                />
              </>
            )}
          </Grid>
        </Grid>
        {ranges?.length ? (
          <>
            <div className={classes.divider} />
            <Grid>
              <DefinedRanges selectedRange={dateRange} ranges={ranges} setRange={setDateRange} />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Paper>
  );
};

export default Menu;
