import { useRef, useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useAuth } from './AuthProvider';
import { t } from '../../translations';
import { z } from 'zod';
import { useForm } from '../../hooks/useForm';
import { getProp } from '../../helpers/utils';
import AuthMessageInfo from './components/AuthMessageInfo';
import { SigninOrSignupLink } from './components/MessageWithLink';

const Schema = z.object({
  email: z.string().min(1, t.Required).email(t.InvalidEmail),
});

type ResendData = z.infer<typeof Schema>;

const SendResetPasswordPage = () => {
  const { sendPassRecoveryVerification } = useAuth();

  const [sentSuccessfully, setSentSuccessfully] = useState(false);

  const dataLoader = useRef(
    async (): Promise<ResendData> => ({
      email: '',
    })
  );

  const save = useRef(async (data: ResendData) => {
    await sendPassRecoveryVerification(data.email);
    setSentSuccessfully(true);
  });

  const { errorMessage, textInput, saveForm, editable, saving } = useForm({
    schema: Schema,
    defaultSaveErrorMessage: t.FailedToResendEmailPleaseTryAgain,
    dataLoader: dataLoader.current,
    save: save.current,
  });

  if (sentSuccessfully) {
    return (
      <Box>
        <AuthMessageInfo title={t.EmailHasBeenSent} message={t.EmailToRecoverPassHasBeenSend} />
        <SigninOrSignupLink />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" mb={4} textAlign="center" fontWeight="bold">
        {t.RecoverPass}
      </Typography>

      <Box component="form" onSubmit={saveForm}>
        {textInput(getProp<ResendData>('email'), { label: t.Email, sx: { mb: 2 } })}

        {errorMessage && !saving && (
          <Alert sx={{ mb: 3 }} severity="error">
            {errorMessage}
          </Alert>
        )}

        <Button type="submit" variant="contained" disabled={!editable || saving} fullWidth size="large" sx={{ mb: 2 }}>
          {t.RecoverPass}
        </Button>

        <SigninOrSignupLink />
      </Box>
    </Box>
  );
};

export default SendResetPasswordPage;
