import { useRef, useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useAuth } from './AuthProvider';
import { t } from '../../translations';
import { z } from 'zod';
import { useForm } from '../../hooks/useForm';
import { getProp } from '../../helpers/utils';
import AuthMessageInfo from './components/AuthMessageInfo';
import { SigninOrSignupLink } from './components/MessageWithLink';
import { useParams } from 'react-router-dom';
import { TokenActionResult } from '../../types/auth';

const RecoverPassSchema = z
  .object({
    pass: z.string().min(5, t.RequireAtLeastNChars(5)),
    passConfirmation: z.string().min(1, t.Required),
  })
  .refine((data) => data.pass === data.passConfirmation, {
    message: t.PasswordsDonTMatch,
    path: ['passConfirmation'],
  });

type RecoverPassData = z.infer<typeof RecoverPassSchema>;

const RecoverPasswordPage = () => {
  const { verifyPassRecovery } = useAuth();
  const params = useParams<{ token: string }>();

  const [passRecoveredCompleted, setPassRecoveredCompleted] = useState(false);
  const [tokenResult, setTokenResult] = useState<TokenActionResult | undefined>(undefined);

  const dataLoader = useRef(
    async (): Promise<RecoverPassData> => ({
      pass: '',
      passConfirmation: '',
    })
  );

  const save = useRef(async (recoverPassData: RecoverPassData) => {
    const newTokenResult = await verifyPassRecovery({ ...recoverPassData, jwtToken: params.token! });
    setTokenResult(newTokenResult);
    setPassRecoveredCompleted(true);
  });

  const { errorMessage, passwordInput, saveForm, editable, saving } = useForm({
    schema: RecoverPassSchema,
    defaultSaveErrorMessage: t.UpdatePasswordFailed,
    dataLoader: dataLoader.current,
    save: save.current,
  });

  if (passRecoveredCompleted) {
    if (!tokenResult) {
      return (
        <Box>
          <AuthMessageInfo title={t.UpdatePasswordFailed} message={t.TheVerificationLinkYouUsedIsInvalid} />
          <SigninOrSignupLink />
        </Box>
      );
    }

    if (tokenResult === TokenActionResult.TokenInvalid || tokenResult === TokenActionResult.TokenExpired) {
      return (
        <Box>
          <AuthMessageInfo title={t.UpdatePasswordFailed} message={t.ThePasswordResetLinkIsInValidOrExpired} />
          <SigninOrSignupLink />
        </Box>
      );
    }

    if (tokenResult === TokenActionResult.Success) {
      return (
        <Box>
          <AuthMessageInfo title={t.PasswordSuccessfullyChanged} message={t.PasswordUpdatedSuccessfully} />
          <SigninOrSignupLink />
        </Box>
      );
    }

    return (
      <Box>
        <AuthMessageInfo title={t.UpdatePasswordFailed} message={t.TheVerificationLinkYouUsedIsInvalid} />
        <SigninOrSignupLink />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" mb={4} textAlign="center" fontWeight="bold">
        {t.UpdateYourPassword}
      </Typography>

      <Box component="form" onSubmit={saveForm}>
        {passwordInput(getProp<RecoverPassData>('pass'), { label: t.Password, sx: { mb: 2 } })}
        {passwordInput(getProp<RecoverPassData>('passConfirmation'), { label: t.Password, sx: { mb: 2 } })}

        {errorMessage && !saving && (
          <Alert sx={{ mb: 3 }} severity="error">
            {errorMessage}
          </Alert>
        )}

        <Button type="submit" variant="contained" disabled={!editable || saving} fullWidth size="large" sx={{ mb: 2 }}>
          {t.UpdateYourPassword}
        </Button>
      </Box>
    </Box>
  );
};

export default RecoverPasswordPage;
