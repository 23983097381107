import { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';

// https://dev.to/rashidshamloo/material-ui-textfield-label-shrink-with-startadornment-2nle
const TextFieldWithIcon = (props) => {
  const [shrink, setShrink] = useState(false);
  // const [errorShrink, setErrorShrink] = useState(false);

  const { icon: Icon, onChange, ...otherProps } = props;

  return (
    <TextField
      onFocus={() => setShrink(true)}
      onChange={(e) => {
        setShrink(!!e.target.value)
        onChange?.(e)
      }}
      onBlur={(e) => setShrink(!!e.target.value)}
      InputLabelProps={{
        sx: (theme) => ({
          '& .Mui-focused .MuiInputAdornment-root': {
            color: theme.palette.primary.main,
          },
          '& .Mui-error .MuiInputAdornment-root': {
            color: theme.palette.error.main,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            px: 5.5,
          },
          '&.MuiInputLabel-shrink': {
            ml: 0,
          },
          ml: 4,
        }),
        shrink,
      }}
      InputProps={{
        notched: true,
        ...(Icon && {
          startAdornment: (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          ),
        }),
      }}
      {...otherProps}
    />
  );
};

export default TextFieldWithIcon;
