import { Box, Container, Link, Paper, Stack } from '@mui/material';
import Image from 'mui-image';
import logo2 from '../../components/assets/logo2.png';
import { Link as LinkRouter, Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const AuthLayout = ({ children }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Container maxWidth="xs" sx={{ height: '100vh', pt: '15vh' }}>
      <Stack justifyContent="center" alignItems="flex-start" sx={{ pb: 10 }}>
        <Box sx={{}}>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 4 }}>
            <Link component={LinkRouter} to="/">
              <Image src={logo2} duration={0} />
            </Link>
          </Box>
          <Paper variant="outlined" sx={{ px: 4, py: 3 }}>
            {children}
          </Paper>
        </Box>
      </Stack>
    </Container>
  );
};

export default AuthLayout;
