import { Box, Stack, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  actionItem: {
    textAlign: 'center',
  },
  icon: {
    fontSize: 24,
  },
  label: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'gray',
  },
  labelWithValue: {
    fontSize: 10,
  },
  value: {
    fontWeight: 'bold',
  },
  closeIcon: {},
  inputContainer: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  error: {
    fontSize: 11,
    // color: 'red'
  }
}));

const PlaceholderForInput = (props) => {
  const { label, value, error, icon: Icon, withCloseIcon, onRemoveValue, onChange } = props;

  const classes = useStyles();

  return (
    <Box>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        {Icon && <Icon className={classes.icon} />}
        <Box className={classes.inputContainer} onClick={onChange}>
          <Box className={clsx(classes.label, value && classes.labelWithValue)}>{label}</Box>
          {value && <Box className={classes.value}>{value}</Box>}
        </Box>
        {value && withCloseIcon && (
          <IconButton aria-label="delete" size="small" className={classes.closeIcon} onClick={onRemoveValue}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      </Stack>
      {error && <Typography variant="caption" color="error" className={classes.error}>{error}</Typography>}
    </Box>
  );
};

export default PlaceholderForInput;
