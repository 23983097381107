import { Box, Container, Grid, Typography } from '@mui/material';
import mission1 from '../../components/assets/mission1.jpeg';
import mission2 from '../../components/assets/mission2.jpeg';
import Image from 'mui-image';
import Flipper from '../../components/Flipper/Flipper';
import member1 from '../../components/assets/team/member1.png';
import member2 from '../../components/assets/team/member2.jpeg';
import member3 from '../../components/assets/team/member3.jpeg';
import member4 from '../../components/assets/team/member4.jpeg';
import Navbar from './Navbar';
import { t } from '../../translations';

const flipperCards = [
  [
    '+50',
    t.VacationPackages,
    t.IfYouReLookingAtAFamilyResort,
  ],
  [
    '+20K',
    t.Hotels,
    t.PartneredWithLeadingHotelChains,
  ],
  [
    '+300',
    t.ToursDestinations,
    t.WeHaveSpecialToursDestinationsAndPackages
  ],
  [
    '+40K',
    t.Reservations,
    t.WeAreContinuouslyDevelopingOurSystem,
  ],
];

const team = [
  {
    name: 'Kevin Palacios',
    title: `${t.Manager} / ${t.CEO}`,
    image: member1,
  },
  {
    name: 'Josue Garcia',
    title: `${t.MarketingDirector} / ${t.TravelAgent}`,
    image: member2,
  },
  {
    name: 'Amanda Hernandez',
    title: `${t.AccountManager} / ${t.TravelAdvisor}`,
    image: member3,
  },
  {
    name: 'Dania Marin',
    title: `${t.SalesManager}`,
    image: member4,
  },
];

const AboutPage = () => {
  return (
    <>
    <Navbar withNavbar />
      <Container sx={{ pt: 10, maxWidth: '1200px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box /*heigth="400px"*/>
              <Image src={mission1} width="100%" height="100%" duration={0} />
            </Box>
            <Typography variant="h5" color="primary.main" mt={4} mb={1}>
              {t.OurStory}
            </Typography>
            <Typography>
              {t.BellitoursEstablishedIn2018takesPride}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box /*heigth="400px"*/>
              <Image src={mission2} width="100%" height="100%" duration={0} />
            </Box>
            <Typography variant="h5" color="primary.main" mt={4} mb={1}>
              {t.OurMission}
            </Typography>
            <Typography>
              {t.OurUtmostGoalIsToMeticulouslyPlan}
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={5} mb={4}>
          {flipperCards.map(([title, text, desc]) => (
            <Grid item xs={12} sm={6} md={3} mb={2}>
              <Box width="100%">
                <Flipper
                  sx={{ width: '200px', height: '130px', textAlign: 'center', cursor: 'pointer', margin: '0 auto' }}
                  front={
                    <Box sx={{ border: '6px solid', borderColor: 'primary.main', p: 3, height: '100%' }}>
                      <Typography variant="h4">{title}</Typography>
                      <Typography>{text}</Typography>
                    </Box>
                  }
                  back={
                    <Box sx={{ backgroundColor: 'primary.main', color: 'white', p: 1, height: '100%' }}>
                      <Typography fontSize={14}>{desc}</Typography>
                    </Box>
                  }
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box mt={9}>
          <Typography maxWidth={'700px'} sx={{ margin: '0 auto', textAlign: 'center' }}>
            {t.KevinEmbarkedOnHisJourneyInTheTravelIndustry}
          </Typography>
        </Box>

        {/* <Grid container mt={10} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center" mb={1}>
              Meet Our Team
            </Typography>
          </Grid>
          {team.map((member) => (
            <Grid item xs={12} sm={6} md={3} mt={3} textAlign="center">
              <Box
                sx={{
                  margin: '0 auto',
                  width: '200px',
                  height: '200px',
                  overflow: 'hidden',
                  borderRadius: '50%',
                  border: '2px solid',
                  borderColor: 'primary.main',
                  cursor: 'pointer',
                  '&:hover': { opacity: 0.78 },
                }}
              >
                <Image src={member.image} />
              </Box>
              <Typography fontWeight="bold" mt={2} mb={1}>
                {member.name}
              </Typography>
              <Typography color="text.secondary">{member.title}</Typography>
            </Grid>
          ))}
        </Grid> */}

        <Box mb={7} />
      </Container>
    </>
  );
};

export default AboutPage;
