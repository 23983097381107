import { useEffect, useState } from 'react';
import getIPInfo, { IpInfo } from '../api/ip-info';
import { existsInLocalStorage, getObjFromLocalStorage, saveObjToLocalStorage } from '../helpers/utils';

const IP_INFO = 'IP_INFO_v1';

export const useIpInfo = () => {
  const [ipInfoState, setIpInfo] = useState<{ ipInfo?: IpInfo, loaded: boolean }>(
    () => existsInLocalStorage(IP_INFO)
      ? { ipInfo: getObjFromLocalStorage(IP_INFO) as IpInfo, loaded: true }
      : { ipInfo: undefined, loaded: false }
  );

  useEffect(() => {
    if (existsInLocalStorage(IP_INFO)) {
      return;
    }

    getIPInfo()
      .then(ipInfo => {
        saveObjToLocalStorage(IP_INFO, ipInfo);
        setIpInfo({ ipInfo, loaded: true });
      })
      .catch(() => {
        setIpInfo({ ipInfo: {}, loaded: true });
      })
  }, []);

  return ipInfoState
}
