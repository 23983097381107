import AdminAPI, {  } from '../../api/admin';
import { t } from '../../translations';
import { z } from 'zod';
import { getListComponent } from '../../components/CRUDAdmin/list';

const columns = [
  {
    field: 'category',
    headerName: t.Category,
    // flex: 1,
    width: 250,
  },
  {
    field: 'level',
    headerName: t.Level,
    // flex: 1,
    width: 200,
  },
  {
    field: 'message',
    headerName: t.Message,
    width: 200,
  },
  {
    field: 'payload',
    headerName: t.Payload,
    flex: 1,
    renderCell: ({ row: item }) => {
      return JSON.stringify(item);
    },
  },
];

const baseProps = {
  name: t.Logs,
  baseRoute: 'logs',
  schema: z.object({}),
};

export const ListLogs = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  editable: false,
  creatable: false,
  dataLoader: AdminAPI.logs.getAll,
  destroy: AdminAPI.logs.delete,
});
