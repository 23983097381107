import React from 'react';
import { Backdrop, Box, CircularProgress, Theme } from '@mui/material';
import { useLoading } from './LoadingContext';
import { makeStyles } from '@mui/styles';

// https://mui.com/material-ui/customization/default-theme/?expand-path=$.zIndex
const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

export const LoadingIcon = ({ center }: { center?: boolean }) => {
  if (center) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return <CircularProgress />;
};

const Loading = () => {
  const classes = useStyles();
  const { loading } = useLoading();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
