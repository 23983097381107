import { Box, Button, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Dialog from '../Dialogs/Dialog';
import { t } from '../../translations';
import { API } from '../../api/home-api';

const Coupon = ({ price, clientName, agentName, coupon, onSetCoupon, showRemoveCoupon }: any) => {
  const [couponCode, setCouponCode] = useState('');
  const [loading, setLoading] = useState(false);

  const applyCoupon = (closeDialog) => async () => {
    setLoading(true);

    const coupon = await API.coupons.get({
      code: couponCode,
      price,
      agentName,
      clientName,
    });

    if (!coupon.applicable) {
      toast.error(coupon.reason);
      setLoading(false);
      return;
    }

    toast.success(t.CouponApplied);
    onSetCoupon?.(coupon);
    setCouponCode('');
    setLoading(false);
    closeDialog();
  };

  const removeCoupon = () => onSetCoupon?.(null);

  return (
    <Box mb={3}>
      {coupon ? (
        showRemoveCoupon && (
          <Button variant="text" onClick={removeCoupon}>
            {t.RemoveCoupon}
          </Button>
        )
      ) : (
        <Dialog
          titleLabel={t.AddCoupon}
          renderTarget={({ openDialog }) => (
            <Button variant="text" onClick={openDialog}>
              {t.IHaveACoupon}
            </Button>
          )}
          renderContent={({ closeDialog }) => (
            <Stack direction="row" spacing={2} p={4} alignItems="center">
              <TextField value={couponCode} onChange={(ev) => setCouponCode(ev.target.value)} label={t.EnterYourCoupon} />
              <Button variant="contained" disabled={(couponCode || '').trim().length === 0 || loading} onClick={applyCoupon(closeDialog)}>
                {t.Apply}
              </Button>
            </Stack>
          )}
        />
      )}
    </Box>
  );
};

export default Coupon;
