import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { getBanners } from '../../api/hotels';
import { Banner } from '../../pages/admin/BannersPage';
import { language } from '../../translations';

const baseUrl = process.env.REACT_APP_HOTELS_API_URL;

const getBannerImage = (banner: Banner) => banner.imagesPerLanguage?.[language] || banner.image;

const HomePageBanner = () => {
  const [banners, setBanners] = useState<any[]>([]);

  useEffect(() => {
    getBanners({ location: 'HomePage' }).then((homePageBanners) => {
      setBanners(homePageBanners);
    });
  }, []);

  return (
    <Box>
      {banners
        .filter((banner) => banner.visible && getBannerImage(banner))
        .map((banner) => (
          <Box
            component="a"
            key={banner._id}
            target="_blank"
            rel="noopener noreferrer"
            {...banner.link && { href: banner.link}}
            sx={{
              display: 'inline-block',
              width: '100%',
              textAlign: 'center',
              py: 0.7,
              borderRadius: '8px',
              mb: 2,
            }}
          >
            <Box component="img" src={`${baseUrl}${getBannerImage(banner)}`} alt="banner" sx={{ maxWidth: '100%' }} />
          </Box>
        ))}
    </Box>
  );
};

export default HomePageBanner;
