import { Box, Button, Container, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { v4 as uuid } from 'uuid';

const CustomTabPanel = (props) => {
  const { id, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${id}-${index}`}
      aria-labelledby={`simple-tab-${id}-${index}`}
      {...other}
    >
      {value === index && <Box mt={2}>{children}</Box>}
    </div>
  );
};

const a11yProps = (id, index) => {
  return {
    id: `simple-tab-${id}-${index}`,
    'aria-controls': `simple-tabpanel-${id}-${index}`,
  };
};

const MyTabs = (props) => {
  const [value, setValue] = useState(0);
  const [id] = useState(() => uuid());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {props.labels.map((label, i) => (
            <Tab  key={label}label={label} {...a11yProps(id, i)} sx={{ fontWeight: 'bold' }} />
          ))}
        </Tabs>
      </Box>
      {props.children.map((child, i) => (
        <CustomTabPanel key={`${id}-${i}`} id={id} value={value} index={i}>
          {child()}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default MyTabs;
