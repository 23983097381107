import { Box, IconButton, Paper, Rating, Typography } from '@mui/material';
import { t } from '../../translations';
import { useEffect, useState } from 'react';
import { FavoriteHotel } from '../../types/user';
import { UserAPI } from '../../api/user-api';
import { LoadingIcon } from '../../contexts/LoadingContext/Loading';
import Image from 'mui-image';
import ClearIcon from '@mui/icons-material/Clear';
import { useAuth } from '../auth/AuthProvider';

const FavoritesPage = () => {
  const { setCurrentUser } = useAuth();
  const [favorites, setFavorites] = useState<FavoriteHotel[] | undefined>(undefined);
  useEffect(() => {
    UserAPI.getFavorites().then(setFavorites);
  }, []);

  const removeFav = (hotelCode: string) => {
    UserAPI.setFavorite(hotelCode, false);
    setCurrentUser((currentUser) => ({
      ...currentUser,
      favoriteProperties: (currentUser.favoriteProperties || []).filter((code) => code !== hotelCode),
    }));
    setFavorites((currentFav) => currentFav?.filter((item) => item.hotelCode !== hotelCode));
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t.YourFavoriteProperties}
      </Typography>
      {favorites === undefined ? (
        <LoadingIcon />
      ) : favorites.length === 0 ? (
        <Typography variant="body1">{t.NoResults}</Typography>
      ) : (
        <Box>
          {favorites.map((fav) => {
            return (
              <Paper variant="outlined" sx={{ display: 'flex', height: '150px', width: '100%', mb: 3, px: 3, py: 1, alignItems: 'center' }}>
                <Box sx={{ width: '150px', mr: 2 }}>
                  <Image src={fav.hotelImages[0]} duration={0} />
                </Box>
                <Box>
                  <Typography variant="h6">{fav.hotelName}</Typography>
                  <Rating name="read-only" value={fav.hotelCategory} readOnly precision={1} size="small" />
                  <Typography variant="body1">{fav.hotelAddress}</Typography>
                </Box>
                <Box sx={{ marginLeft: 'auto' }}>
                  <IconButton aria-label="delete" size="medium" onClick={() => removeFav(fav.hotelCode)}>
                    <ClearIcon />
                  </IconButton>
                </Box>
              </Paper>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default FavoritesPage;
