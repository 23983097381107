import { Box, Paper, Typography } from '@mui/material';
import { t } from '../../translations';

const HotelDescription = (props) => {
  const { hotel } = props;

  return (
    <Paper sx={{ px: 3, py: 2, mb: 2 }}>
      <Typography variant="h5" mb={3}>
        {t.Description}
      </Typography>

      <Box>
        {hotel.description}
      </Box>
    </Paper>
  );
};

export default HotelDescription;
