import { Container } from '@mui/material';
import HotelsSearch, { buildNewSearchTerms, BuildNewSearchTermsProps } from '../../components/HotelsSearch/HotelsSearch';
import Navbar from './Navbar';
import { buildQueryString } from '../../helpers/utils';

const SearchPage = () => {
  return (
    <>
    <Navbar/>
      <Container sx={{ pt: 2, maxWidth: '1300px' }}>
        <HotelsSearch />
      </Container>
    </>
  );
};

SearchPage.linkPattern = '/search';

SearchPage.link = (searchProps: BuildNewSearchTermsProps) => {
  console.log('SEARCH PAGE LINK');
  const searchTerms = buildNewSearchTerms(searchProps);
  const queryString = buildQueryString(searchTerms);
  return `/search?${queryString}`;
};

export default SearchPage;
