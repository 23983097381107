import apiRequest from './api-request';
import { CurrentUser } from '../types/auth';
import { CurrentUserBooking, FavoriteHotel } from '../types/user';

export const UserAPI = {
  currentUser: async (): Promise<CurrentUser> =>
    (await apiRequest.get('/account/current-user')).data?.currentUser as CurrentUser,
  getFavorites: async (): Promise<FavoriteHotel[]> =>
    (await apiRequest.get('/account/favorites')).data?.favorites as FavoriteHotel[],
  setFavorite: async (hotelCode: string, favorite: boolean): Promise<void> =>
    (await apiRequest.post('/account/favorites', { hotelCode, favorite })).data,
  getBookings: async (): Promise<CurrentUserBooking[]> =>
    (await apiRequest.get('/account/bookings')).data?.bookings as CurrentUserBooking[],
}
