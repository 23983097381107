import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import AdminNavbar from '../../components/AdminNavbar';
import PollIcon from '@mui/icons-material/Poll';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useLocation, Link as LinkRouter } from 'react-router-dom';
import ForumIcon from '@mui/icons-material/Forum';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import SettingsIcon from '@mui/icons-material/Settings';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import BugReportIcon from '@mui/icons-material/BugReport';
import { t } from '../../translations';
import PeopleIcon from '@mui/icons-material/People';

const capitalizeFLetter = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const getNavigationLinks = (pathname) => {
  const segments = pathname.split('/').filter(Boolean);
  return segments.map((segment, i) => ({
    url: i <= segments.length - 2 ? `/${segments.slice(0, i + 1).join('/')}` : null,
    text: capitalizeFLetter(segment),
  }));
};

const AdminLayout = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <Box sx={{ display: 'flex' }}>
      <AdminNavbar
        title={t.Admin}
        items={[
          [
            {
              text: t.Dashboard,
              url: '/admin/dashboard',
              icon: PollIcon,
              selected: pathname === '/admin/dashboard',
            },
            {
              text: t.Fees,
              url: '/admin/fees',
              icon: MonetizationOnIcon,
              selected: pathname === '/admin/fees',
            },
            {
              text: t.Orders,
              url: '/admin/orders',
              icon: ReceiptLongIcon,
              selected: pathname === '/admin/orders',
            },
            {
              text: t.Messages,
              url: '/admin/messages',
              icon: ForumIcon,
              selected: pathname === '/admin/messages',
            },
            {
              text: t.Coupons,
              url: '/admin/coupons',
              icon: ConfirmationNumberIcon,
              selected: pathname.startsWith('/admin/coupons'),
            },
            {
              text: t.Banners,
              url: '/admin/banners',
              icon: PhotoAlbumIcon,
              selected: pathname.startsWith('/admin/banners'),
            },
            {
              text: t.Referrals,
              url: '/admin/referrals',
              icon: Diversity1Icon,
              selected: pathname.startsWith('/admin/referrals'),
            },
            {
              text: t.Users,
              url: '/admin/users',
              icon: PeopleIcon,
              selected: pathname.startsWith('/admin/users'),
            },
            {
              text: t.Settings,
              url: '/admin/settings',
              icon: SettingsIcon,
              selected: pathname.startsWith('/admin/settings'),
            },
            {
              text: t.Logs,
              url: '/admin/logs',
              icon: BugReportIcon,
              selected: pathname.startsWith('/admin/logs'),
            },
          ],
        ]}
        breadcrumbs={
          <Breadcrumbs color="white">
            {getNavigationLinks(pathname).map(({ url, text }) =>
              url ? (
                <Link key={url} component={LinkRouter} to={url} underline="hover" color="white">
                  {text}
                </Link>
              ) : (
                <Typography key={url} color="white">
                  {text}
                </Typography>
              )
            )}
          </Breadcrumbs>
        }
      />
      <Box component="main" sx={{ flexGrow: 1, p: 1, pt: '75px', height: '100vh', overflowX: 'hidden' }}>
        {children}
      </Box>
    </Box>
  );
};

export default AdminLayout;
