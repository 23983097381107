import AdminAPI from '../../api/admin';
import { t } from '../../translations';
import { z } from 'zod';
import { getListComponent } from '../../components/CRUDAdmin/list';

const columns = [
  {
    field: 'name',
    headerName: t.Name,
    flex: 1,
  },
  {
    field: 'email',
    headerName: t.Email,
    flex: 1,
  },
  {
    field: 'subject',
    headerName: t.Subject,
    flex: 1,
  },
  {
    field: 'message',
    headerName: t.Message,
    flex: 2,
  },
];

const baseProps = {
  name: t.Messages,
  baseRoute: 'messages',
  schema: z.object({}),
};

export const ListMessages = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  editable: false,
  creatable: false,
  dataLoader: AdminAPI.messages.getAll,
  destroy: AdminAPI.messages.delete,
});
