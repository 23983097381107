import { Box, FormControlLabel, Switch, Card, CardContent, Button, Paper, Stack, IconButton, Typography } from '@mui/material';
import AdminAPI from '../../api/admin';
import { t } from '../../translations';
import { FormComponentType, getEditorComponent } from '../../components/CRUDAdmin/edit';
import { getListComponent } from '../../components/CRUDAdmin/list';
import SearchInput, { sourceWithLabel } from '../../components/Inputs/SearchInput';
import { v4 as id } from 'uuid';
import { cloneDeep, get } from 'lodash';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { HotelRecord, RecordResult, SearchType } from '../../types/search';
import { Location } from '../../types/locations';
import { Settings, SettingsSchema, SettingsType } from '../../types/settings';
import Image from 'mui-image';
import { setEmptyArrayInFormData } from '../../helpers/utils';

const baseUrl = process.env.REACT_APP_HOTELS_API_URL;

const columns = [
  {
    field: 'type',
    headerName: t.Type,
    width: 220,
    renderCell: ({ row: setting }) => {
      return (
        <Box>
          <Box mb={1}>{setting.type}</Box>
        </Box>
      );
    },
  },
  {
    field: 'settings',
    headerName: t.Settings,
    width: '1200',
    renderCell: ({ row: settings }: { row: Settings }) => {
      if (settings.type === SettingsType.Site && settings.siteSettings) {
        return (
          <Box>
            <Box mb={1}>
              <FormControlLabel
                label={t.ProductionMode}
                control={<Switch checked={Boolean(settings.siteSettings.isProductionMode)} />}
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel label={t.AllowNewBookings} control={<Switch checked={Boolean(settings.siteSettings.allowNewBookings)} />} />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                label={t.AllowNewBookingsOutsideMoldova}
                control={<Switch checked={Boolean(settings.siteSettings.allowNewBookingsOutsideMoldova)} />}
              />
            </Box>
          </Box>
        );
      }

      if (settings.type === SettingsType.TopDestinations && settings.topDestinations) {
        return (
          <Box>
            {settings.topDestinations.map((topDestination) => (
              <Box key={topDestination.destination.code} sx={{ mb: 2 }}>
                <Box>
                  <b>{topDestination.destination.name}</b>
                </Box>
                <ul>
                  {topDestination.hotels.map((hotel) => (
                    <li key={hotel.code}>{hotel.name}</li>
                  ))}
                </ul>
              </Box>
            ))}
          </Box>
        );
      }

      if (settings.type === SettingsType.TrendingDestinations && settings.trendingDestinations) {
        return (
          <Box>
            {settings.trendingDestinations.map((trendingDestination) => (
              <Box key={trendingDestination.name} sx={{ mr: 2, display: 'inline-block' }}>
                <Box>
                  <b>{trendingDestination.name}</b>
                  <Box>
                  {trendingDestination.image ? <Image src={`${baseUrl}${trendingDestination.image}`} height={100} /> : null}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        );
      }

      return null;
    },
  },
];

const SettingForm: FormComponentType<Settings> = (props) => {
  const { data } = props;

  return (
    <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative' }}>
      <CardContent>
        {data.type === SettingsType.Site && data.siteSettings && <SiteSettingsForm {...props} />}
        {data.type === SettingsType.TopDestinations && data.topDestinations && <TopDestinationSettingForm {...props} />}
        {data.type === SettingsType.TrendingDestinations && data.trendingDestinations && <TrendingDestinationSettingForm {...props} />}
      </CardContent>
    </Card>
  );
};

const SiteSettingsForm: FormComponentType<Settings> = ({ booleanInput }) => {
  return (
    <>
      <Box mb={3}>{booleanInput('siteSettings.isProductionMode', { label: t.ProductionMode })}</Box>
      <Box mb={3}>{booleanInput('siteSettings.allowNewBookings', { label: t.AllowNewBookings })}</Box>
      <Box mb={3}>{booleanInput('siteSettings.allowNewBookingsOutsideMoldova', { label: t.AllowNewBookingsOutsideMoldova })}</Box>
    </>
  );
};

const TopDestinationSettingForm: FormComponentType<Settings> = ({ data, setData, booleanInput, errors }) => {
  const addDestination = () => {
    setData((currentData) => {
      const newData = cloneDeep(currentData);
      newData.topDestinations!.push({
        id: id(),
        destination: {
          code: '',
          name: '',
          type: Location.City,
          record: null as any,
        },
        hotels: [],
      });
      return newData;
    });
  };

  const onChangeDestination = (record: RecordResult | undefined, topDestinationIndex: number) => {
    if (record === undefined) {
      return;
    }

    setData((currentData) => {
      const newData = cloneDeep(currentData);
      newData.topDestinations?.forEach((topDest, i) => {
        if (i === topDestinationIndex) {
          topDest.destination.code = record.item.code;
          topDest.destination.name = sourceWithLabel(record)?.label.main!;
          topDest.destination.type = record.type as any;
          topDest.destination.record = record;
        }
      });

      return newData;
    });
  };

  const removeDestination = (topDestinationIndex: number) => {
    setData((currentData) => {
      const newData = cloneDeep(currentData);
      newData.topDestinations = newData.topDestinations!.filter((_, i) => topDestinationIndex !== i);
      return newData;
    });
  };

  const addHotel = (destinationIndex: number) => {
    setData((currentData) => {
      const newData = cloneDeep(currentData);
      newData.topDestinations?.forEach((topDest, i) => {
        if (i === destinationIndex) {
          topDest.hotels.push({
            id: id(),
            code: '',
            name: '',
            record: null as any,
          });
        }
      });

      return newData;
    });
  };

  const onChangeHotel = (record: RecordResult | undefined, topDestinationIndex: number, hotelIndex: number) => {
    if (record === undefined) {
      return;
    }

    setData((currentData) => {
      const newData = cloneDeep(currentData);
      newData.topDestinations?.forEach((topDest, i) => {
        if (i === topDestinationIndex) {
          topDest.hotels.forEach((hotel, j) => {
            if (j === hotelIndex) {
              hotel.code = record.item.code;
              hotel.name = (record.item as HotelRecord).hotel;
              hotel.record = record;
            }
          });
        }
      });

      return newData;
    });
  };

  const removeHotel = (hotelIndex: number) => {
    setData((currentData) => {
      const newData = cloneDeep(currentData);
      newData.topDestinations!.forEach((topDest, i) => {
        topDest.hotels = topDest.hotels.filter((_, i) => hotelIndex !== i);
      });
      return newData;
    });
  };

  return (
    <Box>
      {(data.topDestinations || []).map((topDestination, i) => (
        <Paper key={topDestination.id} sx={{ p: 2, mb: 2 }}>
          <Box sx={{ mb: 1 }}>
            <b>Destination - {i + 1}</b>
          </Box>
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <IconButton onClick={() => removeDestination(i)}>
              <DeleteForeverIcon />
            </IconButton>
            <Paper sx={{ p: 2, mb: 2, flexGrow: 1, mr: 2 }}>
              <SearchInput
                placeholder={t.Destination}
                searchLocationLabel={t.SearchDestinations}
                initialValue={topDestination.destination.record}
                type={[SearchType.Country, SearchType.Province, SearchType.City]}
                onChange={(record: RecordResult | undefined) => onChangeDestination(record, i)}
              />
              <Typography variant="caption" color="error" sx={{ display: 'block', fontSize: 11, mt: '-7px' }}>
                {get(errors, `topDestinations.${i}.destination.code`)}
              </Typography>
            </Paper>
            <Box sx={{ px: 1, border: '1px solid gray' }}>code: {topDestination.destination.code}</Box>
          </Stack>

          <Box sx={{ mt: 2 }}>
            {topDestination.hotels.map((hotel, j) => (
              <Stack key={hotel.id} direction="row" sx={{ alignItems: 'center' }}>
                <IconButton onClick={() => removeHotel(j)}>
                  <DeleteForeverIcon />
                </IconButton>
                <Paper sx={{ p: 2, mb: 2, flexGrow: 1, mr: 2 }}>
                  <SearchInput
                    placeholder={t.Hotel}
                    searchLocationLabel={t.SearchHotels}
                    initialValue={hotel.record}
                    type={[SearchType.Hotel]}
                    filterQuery={{
                      filterHotelsBy: {
                        locationType: topDestination.destination.type,
                        code: topDestination.destination.code,
                      },
                    }}
                    minSearchText={0}
                    onChange={(record: RecordResult | undefined) => onChangeHotel(record, i, j)}
                  />
                  <Typography variant="caption" color="error" sx={{ display: 'block', fontSize: 11, mt: '-7px' }}>
                    {get(errors, `topDestinations.${i}.hotels.${j}.code`)}
                  </Typography>
                </Paper>
                <Box sx={{ px: 1, border: '1px solid gray' }}>code: {hotel.code}</Box>
              </Stack>
            ))}
          </Box>
          <Button onClick={() => addHotel(i)}>Add Hotel</Button>
        </Paper>
      ))}
      <Button onClick={addDestination}>Add Destination</Button>
    </Box>
  );
};

const TrendingDestinationSettingForm: FormComponentType<Settings> = ({ data, setData, imageInput, errors }) => {
  const onChangeDestination = (record: RecordResult | undefined, trendingDestinationIndex: number) => {
    if (record === undefined) {
      return;
    }

    setData((currentData) => {
      const newData = cloneDeep(currentData);
      newData.trendingDestinations?.forEach((trendingDest, i) => {
        if (i === trendingDestinationIndex) {
          trendingDest.code = record.item.code;
          trendingDest.name = sourceWithLabel(record)?.label.main!;
          trendingDest.type = record.type as any;
          trendingDest.record = record;
        }
      });

      return newData;
    });
  };

  return (
    <Box>
      {data.trendingDestinations!.map((trendingDest, i) => {
        return (
          <Paper key={i} sx={{ mb: 3, p: 3 }}>
            <SearchInput
              minSearchText={0}
              placeholder={`${t.Destination}-${i + 1}`}
              searchLocationLabel={t.SearchDestinations}
              initialValue={
                trendingDest.record
                // trendingDest.record ? { type: trendingDest.type as unknown as SearchType, item: trendingDest.record } : undefined
              }
              type={[SearchType.Country, SearchType.Province, SearchType.City]}
              onChange={(record: RecordResult | undefined) => onChangeDestination(record, i)}
            />
            <Typography variant="caption" color="error" sx={{ display: 'block', fontSize: 11, mt: '-7px' }}>
              {get(errors, `trendingDestinations.${i}.code`)}
            </Typography>

            <Box sx={{ mt: 2 }}>{imageInput(`trendingDestinations.${i}.image`)}</Box>
          </Paper>
        );
      })}
    </Box>
  );
};

const baseProps = {
  name: t.Settings,
  baseRoute: 'settings',
  schema: SettingsSchema,
  FormComponent: SettingForm,
};

export const ListSettings = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  deletable: false,
  creatable: false,
  dataLoader: AdminAPI.settings.getAll,
});

export const EditSettings = getEditorComponent<Settings>({
  ...baseProps,
  dataLoader: (params) =>
    AdminAPI.settings.find(params.id).then((result) => {
      result.topDestinations?.forEach((item) => {
        item.id = id();
        item.hotels.forEach((hotel) => {
          hotel.id = id();
        });
      });
      return result;
    }),
  save: (props) => {
    setEmptyArrayInFormData<Settings>(props, ['topDestinations'])
    return AdminAPI.settings.update(props._id, props)
  },
});
