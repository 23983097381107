import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { t } from '../../translations';

const baseUrl = process.env.REACT_APP_HOTELS_API_URL;

export const ImagePicker = ({ image, editable, onChangeFile, error, helperText }) => {
  const [file, _setFile] = useState<any>(null);
  const [previewUrl, setPreviewUrl] = useState<any>(null);
  const [inputKey, setInputKey] = useState<any>(1);

  const setFile = (newFile) => {
    _setFile(newFile);
    onChangeFile(newFile);
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewUrl(null);
    }
  };

  const handleFileCancel = () => {
    setFile(null);
    setPreviewUrl(null);
    setInputKey(new Date().toISOString());
  };

  const imageSrcToDisplay = previewUrl || (image && `${baseUrl}${image}`);

  return (
    <Box>
      {editable && (
        <TextField type="file" fullWidth onChange={handleFileInputChange} key={inputKey} error={error} helperText={helperText} />
      )}

      {imageSrcToDisplay && (
        <Box sx={{ mt: 2 }}>
          {editable && file && <Button onClick={handleFileCancel}>{t.RemoveImage}</Button>}
          <img src={imageSrcToDisplay} alt="Preview" style={{ maxWidth: '100%' }} />
        </Box>
      )}
    </Box>
  );
};
