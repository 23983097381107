import { useState } from 'react';
import { v4 as id } from 'uuid';

type ReactCmpt = keyof React.JSX.IntrinsicElements | React.JSXElementConstructor<any>

export type CmptReloaderProps<T extends ReactCmpt> = {
  reloadRef: React.MutableRefObject<() => void>;
  cmpt: React.ComponentType<T>;
} & React.ComponentProps<T>;

const CmptReloader = <T extends ReactCmpt>({ reloadRef, cmpt: Cmpt, ...otherProps }: CmptReloaderProps<T>) => {
  const [key, setKey] = useState(() => id());
  reloadRef.current = () => {
    setKey(id())
  };

  return <Cmpt key={key} {...otherProps} />
}

export default CmptReloader;
