import { Container } from '@mui/material';
import { fetchHotelInfo, searchHotelsAvailabilities } from '../../api/hotels';
import HotelAvailability from '../../components/Controllers/HotelAvailability';
import Navbar from './Navbar';
import { v4 as id } from 'uuid';
import useDataLoader from '../../hooks/use-data-loader';
import loadingGif from '../../components/assets/loading.gif';
import { Image } from 'mui-image';
import { Box, Typography } from '@mui/material';
import { t } from '../../translations';
import { SearchTerms } from '../../components/HotelsSearch/HotelsSearch';
import { SearchType } from '../../types/search';
import { API } from '../../api/home-api';

const HotelAvailabilityPage = (props) => {
  const { loaded, error, data } = useDataLoader(HotelAvailabilityPage.dataLoader);
  const loading = !loaded;
  const { hotelCode, hotelAvailability, hotelInfo, checkin, checkout, rooms, urlParams, searchId, markupFees } = data || {};

  return (
    <>
      <Navbar />
      <Container sx={{ pt: 0, px: '0px !important' }}>
        {error ? (
          <Box sx={{ margin: '0 auto', textAlign: 'center', my: 20 }}>
            <Typography variant="h2">{t.AnErrorHasOccurred('')}</Typography>
          </Box>
        ) : loading ? (
          <Box sx={{ margin: '0 auto', my: '150px', width: '400px', maxHeight: '100px' }}>
            <Image src={loadingGif} duration={0} width={'100%'} height={'100%'} />
          </Box>
        ) : (
          <HotelAvailability
            hotelCode={hotelCode}
            hotel={hotelInfo}
            availability={hotelAvailability}
            markupFees={markupFees}
            checkin={checkin}
            checkout={checkout}
            paxes={rooms}
            rooms={rooms}
            urlParams={urlParams}
            searchId={searchId}
          />
        )}
      </Container>
    </>
  );
};

HotelAvailabilityPage.linkPattern = 'availability/:code/:checkin/:checkout/:rooms';

HotelAvailabilityPage.link = (searchTerms: SearchTerms, returnUrl = '') => {
  return (
    [
      '/availability',
      searchTerms.code,
      searchTerms.checkin,
      searchTerms.checkout,
      searchTerms.rooms,
    ].join('/') + (returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : '')
  );
};

// TODO: react-router v6 loader is executed before the rendering, so the AuthProvider is executed after. change it to use the useDataLoader
HotelAvailabilityPage.dataLoader = async (params) => {
  const searchHotelParams = {
    code: params.code,
    // destinationType: params.destinationType,
    // destinationCode: params.destinationCode,
    // [params.destinationType]: params.destinationCode,
    type: SearchType.Hotel,
    checkin: params.checkin,
    checkout: params.checkout,
    rooms: params.rooms,
    shortResponse: false,
    cancellationFees: true,
  };
  const hotelAvailabilitiesPromise = searchHotelsAvailabilities({ params: searchHotelParams });
  const hotelInfoPromise = fetchHotelInfo({ hotelCode: params.code });
  const markupFeesPromise = API.fees.fetchMarkupFees();

  const [result, hotelInfo, markupFees] = await Promise.all([hotelAvailabilitiesPromise, hotelInfoPromise, markupFeesPromise]);

  return {
    hotelCode: params.code,
    searchHotelParams,
    markupFees,
    hotelAvailability: result.hotelsResult.length ? addIdsToPaxRoomAndPkgs(result.hotelsResult[0]) : undefined,
    searchId: JSON.stringify(searchHotelParams),
    hotelInfo,
    checkin: params.checkin,
    checkout: params.checkout,
    rooms: params.rooms,
    urlParams: params,
  };
};

const addIdsToPaxRoomAndPkgs = (hotelAvailability) => {
  hotelAvailability.paxes.forEach(pax => {
    pax.id = id();

    pax.rooms.forEach(room => {
      room.id = id();

      room.packages.forEach(pkg => {
        pkg.id = id();
      })
    })
  })

  return hotelAvailability;
}

export default HotelAvailabilityPage;
