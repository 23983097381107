import React, { useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../../pages/auth/AuthProvider';
import { UserAPI } from '../../api/user-api';
import { styled, SxProps } from '@mui/material/styles';

export type FavoriteHotelButtonProps = {
  hotelCode: string;
  sx?: SxProps;
  onToggleFavorite?: (val: boolean) => void;
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  // transition: 'transform 0.2s, color 0.2s',
  position: 'absolute',
  right: 2,
  top: 2,
  zIndex: 1,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: '50%',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.2s, color 0.2s',
  '&:hover': {
    color: theme.palette.error.main,
    transform: 'scale(1.1)',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
}));

const FavoriteHotelButton = ({ hotelCode, sx, onToggleFavorite }: FavoriteHotelButtonProps) => {
  const { currentUser, setCurrentUser } = useAuth();
  if (!currentUser) {
    return null;
  }

  const isFavorite = currentUser?.favoriteProperties?.includes(hotelCode);

  const toggle: React.MouseEventHandler<HTMLButtonElement> = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    await UserAPI.setFavorite(hotelCode, !isFavorite);
    setCurrentUser((currentUser) => ({
      ...currentUser,
      favoriteProperties: isFavorite
        ? (currentUser.favoriteProperties || []).filter((code) => code !== hotelCode)
        : [...(currentUser.favoriteProperties || []), hotelCode],
    }));
  };

  return (
    <StyledIconButton onClick={toggle} color="primary" sx={sx} size="small">
      {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
    </StyledIconButton>
  );
};

export default FavoriteHotelButton;
