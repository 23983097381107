import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { API } from '../../api/home-api';
import { Coupon } from '../../pages/admin/CouponsPage';
import { language } from '../../translations';

const ShowPromotionCoupon = () => {
  const [promotionCoupons, setPromotionCoupons] = useState<Coupon[]>([]);

  useEffect(() => {
    API.coupons.getPromotionsCoupons().then((newPromotionCoupons) => {
      setPromotionCoupons(newPromotionCoupons);
    });
  }, []);

  return (
    <Box>
      {promotionCoupons.map((coupon) => (
        <Box
          key={(coupon as any)._id}
          sx={{
            textAlign: 'center',
            py: 0.7,
            color: 'white',
            background: 'rgb(24, 66, 129)',
            fontWeight: 'bold',
            borderRadius: '8px',
            mb: 2,
          }}
        >
          <Typography variant="h6">{coupon.promotionTextPerLanguage?.[language] || coupon.promotionText}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ShowPromotionCoupon;
