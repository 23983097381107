import axios from 'axios';

export type IpInfo = {
  city?: string,
  country?: string,
  hostname?: string,
  ip?: string,
  loc?: string,
  org?: string
  postal?: string,
  region?: string,
  timezone?: string,
};

const getIPInfo = async (): Promise<IpInfo> => {
  try {
    // TODO: store the response in the local-storage to reuse the value and avoid calling it again
    const { data } = await axios.get('http://ipinfo.io/?token=d896365570b98d');
    return data as IpInfo;
  } catch (err: any) {
    console.error(err);
    return {};
  }
};

export default getIPInfo;
