import * as React from 'react';
import { addMonths, isSameDay, isWithinInterval, isAfter, isBefore, isSameMonth, addYears, max, min } from 'date-fns';
import Menu from './Menu';
import { parseOptionalDate, defaultRanges } from './utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material';

export const MARKERS = {
  FIRST_MONTH: Symbol('firstMonth'),
  SECOND_MONTH: Symbol('secondMonth'),
};

const getValidatedMonths = (range, minDate, maxDate) => {
  let { startDate, endDate } = range;
  if (startDate && endDate) {
    const newStart = max([startDate, minDate]);
    const newEnd = min([endDate, maxDate]);

    return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
  } else {
    return [startDate, endDate];
  }
};

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

export type DateRangePickerProps = {
  open?: boolean;
  initialDateRange?: DateRange;
  minDate: Date;
  monthSelectorDisabled: boolean;
  yearSelectorDisabled: boolean;
  selectedDatesDisabled: boolean;
  startDateLabel: string;
  emptyStartDateLabel: string;
  endDateLabel: string;
  emptyEndDateLabel: string;
  allowPastDates: boolean;
  navigateBimonthly: boolean;
  toggle: () => void;
  onChange: (range: DateRange) => void;
};

const DateRangePicker = (props) => {
  const today = new Date();

  const {
    open = true,
    onChange = () => {},
    initialDateRange,
    minDate,
    maxDate,
    definedRanges = props.useDefaultRanges ? defaultRanges : [],
  } = props;

  const minDateValid = parseOptionalDate(minDate, addYears(today, -10));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today, 10));
  const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid);

  // console.log("rendering DateRangePicker");
  const [dateRange, setDateRange] = React.useState({ ...initialDateRange });
  const [hoverDay, setHoverDay] = React.useState<any>();
  const [firstMonth, setFirstMonth] = React.useState(intialFirstMonth || today);
  const [secondMonth, setSecondMonth] = React.useState(/*initialSecondMonth || */ addMonths(firstMonth, 1));

  const { startDate, endDate } = dateRange;

  const onlyFirstMonthVisible = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  // handlers
  const setFirstMonthValidated = (date) => {
    if (isBefore(date, secondMonth)) {
      setFirstMonth(date);
    }
  };

  const setSecondMonthValidated = (date) => {
    if (isAfter(date, firstMonth)) {
      setSecondMonth(date);
    }
  };

  const setDateRangeValidated = (range) => {
    let { startDate: newStart, endDate: newEnd } = range;
    if (newStart && newEnd) {
      range.startDate = newStart = max([newStart, minDateValid]);
      range.endDate = newEnd = min([newEnd, maxDateValid]);
      setDateRange(range);
      onChange(range);
      setFirstMonth(newStart);
      setSecondMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd);
    }
  };

  const onDayClick = (day) => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      const newRange = { startDate, endDate: day };
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  const onMonthNavigate = (marker, action) => {
    const dates = { firstMonth, secondMonth };

    const checkFirstMonth = () => {
      if (marker !== MARKERS.FIRST_MONTH && !props.navigateBimonthly) {
        return;
      }

      const firstNew = addMonths(dates.firstMonth, action);
      if (
        isBefore(firstNew, dates.secondMonth) ||
        // this fix an issue on mobile view that the first click in the next month didn't work
        (isSameDay(firstNew, dates.secondMonth) && onlyFirstMonthVisible)
      ) {
        setFirstMonth(firstNew);
        dates.firstMonth = firstNew;
      }
    };

    const checkSecondMonth = () => {
      if (marker !== MARKERS.SECOND_MONTH && !props.navigateBimonthly) {
        return;
      }

      const secondNew = addMonths(dates.secondMonth, action);
      if (isBefore(dates.firstMonth, secondNew)) {
        setSecondMonth(secondNew);
        dates.secondMonth = secondNew;
      }
    };

    const actionsInOrder = marker === MARKERS.FIRST_MONTH ? [checkFirstMonth, checkSecondMonth] : [checkSecondMonth, checkFirstMonth];

    actionsInOrder.forEach((action) => action());
  };

  const onDayHover = (date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  // helpers
  const inHoverRange = (day) => {
    return (
      startDate &&
      !endDate &&
      hoverDay &&
      isAfter(hoverDay, startDate) &&
      isWithinInterval(day, {
        start: startDate,
        end: hoverDay,
      })
    );
  };

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  };

  return open ? (
    <Menu
      {...props}
      dateRange={dateRange}
      minDate={minDateValid}
      maxDate={maxDateValid}
      ranges={definedRanges}
      firstMonth={firstMonth}
      secondMonth={secondMonth}
      setFirstMonth={setFirstMonthValidated}
      setSecondMonth={setSecondMonthValidated}
      setDateRange={setDateRangeValidated}
      helpers={helpers}
      handlers={handlers}
      onlyFirstMonthVisible={onlyFirstMonthVisible}
    />
  ) : null;
};

export default DateRangePicker;
