import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  flipCard: {
    backgroundColor: 'transparent',

    perspective: '1000px',
    '&:hover $flipCardInner': { transform: 'rotateY(180deg)' },
  },
  flipCardInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  },
  flipCardPage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    WebkitBackfaceVisibility: 'hidden',
    backfaceVisibility: 'hidden',
  },
  flipCardBack: {
    transform: 'rotateY(180deg)',
  },
}));

const Flipper = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.flipCard} sx={{ width: '300px', height: '300px', ...props.sx }}>
      <Box className={classes.flipCardInner}>
        <Box className={[classes.flipCardPage] as any}>{props.front}</Box>
        <Box className={[classes.flipCardPage, classes.flipCardBack] as any}>{props.back}</Box>
      </Box>
    </Box>
  );
};

export default Flipper;
