import { Box, Container, Typography, Link } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { t } from '../translations';

const NotFoundPage = () => {
  return (
    <Container sx={{mt: 5}}>
      <Box>
      <Typography component="h2" variant="h2">{t.NothingToSeeHere}</Typography>
      <Link component={LinkRouter} to="/">{t.GoToTheHomePage}</Link>
    </Box>
    </Container>
  );
};

export default NotFoundPage;
