import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog as MuiDialog } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Dialog = (props) => {
  const [open, setOpen] = useState(props.startOpen);

  const handleOpen = () => {
    setOpen(true);
    props.onOpen?.();
  };

  const handleClose = (event, reason) => {
    if (props.preventToClickWithClickOutside && reason === 'backdropClick') {
      return;
    }

    setOpen(false);
    props.onClose?.();
  };

  props.onSetOpenDialog?.(handleOpen);
  props.onSetCloseDialog?.(handleClose);

  return (
    <>
      {props.renderTarget?.({ openDialog: handleOpen })}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        {...props.dialogProps}
        // maxWidth
      >
        {props.withTitle && (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {props.titleLabel}
          </DialogTitle>
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose as any}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{props.renderContent ? props.renderContent({ closeDialog: handleClose }) : props.children}</DialogContent>
      </BootstrapDialog>
    </>
  );
};

Dialog.defaultProps = {
  startOpen: false,
  withTitle: true,
  withCloseIcon: true,
};

export default Dialog;
