import AdminAPI from '../../api/admin';
import { Box, Button, Card, CardContent, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { t } from '../../translations';
import { z } from 'zod';
import { DataGridColumn, getListComponent } from '../../components/CRUDAdmin/list';
import { getEditorComponent } from '../../components/CRUDAdmin/edit';
import { randomCouponCode } from '../../helpers/utils';
import { v4 as id } from 'uuid';
import { AdjustmentType, Language } from '../../types';

export enum CouponFilterType {
  StartAt = 'startAt',
  EndAt = 'endAt',
  PriceGt = 'priceGt',
  Limit = 'limit',
  AgentName = 'agentName',
  ClientName = 'clientName',
}

const filterTypesWithTranslations = [
  { id: CouponFilterType.StartAt, name: t.StartAt },
  { id: CouponFilterType.EndAt, name: t.EndAt },
  { id: CouponFilterType.PriceGt, name: t.PriceGreaterThan },
  { id: CouponFilterType.Limit, name: t.Limit },
  { id: CouponFilterType.AgentName, name: t.AgentName },
  { id: CouponFilterType.ClientName, name: t.ClientName },
];

const columns: DataGridColumn<Coupon>[] = [
  {
    field: 'code',
    headerName: t.Code,
    width: 200,
    renderCell: ({ row: coupon }) => (
      <Box>
        <b>{coupon.code}</b>
      </Box>
    ),
  },
  {
    field: 'active',
    headerName: t.Status,
    width: 140,
    renderCell: ({ row: coupon }) => <Box>{coupon.active ? t.Active : t.Inactive}</Box>,
  },
  {
    field: 'discountType',
    headerName: t.Discount,
    width: 120,
    renderCell: ({ row: coupon }) => (
      <Box>
        {coupon.discountAmount} {coupon.discountType === AdjustmentType.Percentage ? '%' : AdjustmentType.Fixed}
      </Box>
    ),
  },
  {
    field: 'orderIds',
    headerName: t.Used,
    width: 70,
    renderCell: ({ row: coupon }) => <Box>total: {coupon.orderIds.length}</Box>,
  },
  {
    field: 'isPromotionCoupon',
    headerName: t.Promotion,
    width: 120,
    renderCell: ({ row: coupon }) => (
      <Box>
        <Box>
          {t.Promotion}: {coupon.isPromotionCoupon ? 'true' : 'false'}
        </Box>
        <Typography variant="body1">{coupon.promotionText}</Typography>
        {Object.values(Language).map((lang) => (
          <Typography variant="body2">
            {lang} - {coupon.promotionTextPerLanguage?.[lang]}
          </Typography>
        ))}
      </Box>
    ),
  },
  {
    field: 'filters',
    headerName: t.Filters,
    width: 300,
    renderCell: ({ row: coupon }) => {
      return (
        <Box>
          {coupon.filters.map((filter, i) => (
            <Box key={`${i}_${filter.type}_${filter.value}`} sx={{ mb: 1 }}>
              <Typography fontWeight="bold" component="span">
                - {filterTypesWithTranslations.find((item) => item.id === filter.type)?.name}
              </Typography>
              : {(['startAt', 'endAt'].includes(filter.type) ? 'date' + new Date(filter.value).toLocaleString() : filter.value) as any}
            </Box>
          ))}
        </Box>
      );
    },
  },
];

// const discountTypes = Object.keys(filterTypes) as any;

const CouponSchema = z.object({
  active: z.coerce.boolean(),
  code: z.string().min(3).max(30).trim(),
  discountType: z.nativeEnum(AdjustmentType),
  discountAmount: z.coerce.number().min(1),
  isPromotionCoupon: z.coerce.boolean(),
  promotionText: z.string().trim().optional(),
  promotionTextPerLanguage: z.record(z.nativeEnum(Language), z.string()).optional(),
  filters: z
    .array(
      z.object({
        id: z.any(),
        type: z.nativeEnum(CouponFilterType),
        value: z.union([z.string().trim().min(1), z.number(), z.date()]),
      })
    )
    .refine(
      (val) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const { type, value } of val!) {
          const expectNumberGt0 = type === CouponFilterType.PriceGt || type === CouponFilterType.Limit;
          if (expectNumberGt0 && (Number.isNaN(Number(value)) || Number(value) < 0)) {
            return false;
          }

          const expectedDate = type === CouponFilterType.StartAt || type === CouponFilterType.EndAt;
          if (expectedDate && Number.isNaN(new Date(value).getTime())) {
            return false;
          }
        }

        return true;
      },
      {
        message: 'Invalid filter',
      }
    ),
});

export type Coupon = z.infer<typeof CouponSchema> & {
  orderIds: string[];
};

const CouponForm = ({ data, setData, textInput, booleanInput, selectInput, dateTimeInput }) => {
  const addFilter = () => {
    setData((currentData) => ({
      ...currentData,
      filters: [...currentData.filters, { id: id(), type: null, value: null }],
    }));
  };
  const removeFilter = (filterId) => {
    setData((currentData) => ({
      ...currentData,
      filters: currentData.filters.filter((filter) => filter.id !== filterId),
    }));
  };
  return (
    <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative' }}>
      <CardContent>
        <Box mb={3}>{booleanInput('active', { label: t.Active })}</Box>
        <Box mb={3}>
          {textInput('code', { label: `${t.Code}*` })}
          <Button sx={{ mt: 1 }} variant="outlined" onClick={() => setData((currenData) => ({ ...currenData, code: randomCouponCode() }))}>
            {t.Generate}
          </Button>
        </Box>
        <Box mb={3}>
          {selectInput(
            'discountType',
            [<MenuItem value={AdjustmentType.Percentage}>{t.Percentage}</MenuItem>, <MenuItem value={AdjustmentType.Fixed}>{t.Fixed}</MenuItem>],
            { label: t.DiscountType }
          )}
        </Box>
        <Box mb={3}>
          {textInput('discountAmount', {
            label: t.DiscountAmount,
            InputProps: {
              startAdornment: data.discountType === AdjustmentType.Percentage ? <InputAdornment position="start">%</InputAdornment> : '',
            },
          })}
        </Box>
        <Box mb={3}>{booleanInput('isPromotionCoupon', { label: t.IsAPromotionCoupon })}</Box>
        <Box mb={1}>{textInput('promotionText', { label: t.PromotionCouponText })}</Box>
        {Object.values(Language).map((lang) => (
          <Box mb={2}>{textInput(`promotionTextPerLanguage.${lang}`, { label: `${lang} - ${t.PromotionCouponText}` })}</Box>
        ))}

        <Box mt={2} />
        {(data.filters || []).map((filter, i) => (
          <Stack direction="row" p={1} spacing={2} ml={-1} key={filter.id}>
            {selectInput(
              `filters.${i}.type`,
              filterTypesWithTranslations.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              )),
              {
                label: t.Select,
              }
            )}

            {(filter.type === 'startAt' || filter.type === 'endAt') &&
              dateTimeInput(`filters.${i}.value`, {
                label: t.SelectDateAndTime,
              })}

            {(filter.type === 'priceGt' || filter.type === 'limit') &&
              textInput(`filters.${i}.value`, {
                label: t.Value,
                type: 'number',
              })}

            {(filter.type === 'agentName' || filter.type === 'clientName') &&
              textInput(`filters.${i}.value`, {
                label: t.Value,
              })}

            <Box>
              <Button onClick={() => removeFilter(filter.id)}>{t.Remove}</Button>
            </Box>
          </Stack>
        ))}
        <Button onClick={addFilter}>{t.AddFilter}</Button>
      </CardContent>
    </Card>
  );
};

const baseProps = {
  name: t.Coupons,
  baseRoute: 'coupons',
  schema: CouponSchema,
  FormComponent: CouponForm,
};

export const ListCoupons = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  dataLoader: AdminAPI.coupons.getAll,
  destroy: AdminAPI.coupons.delete,
});

export const CreateCoupons = getEditorComponent({
  ...baseProps,
  dataLoader: () => Promise.resolve({ orderIds: [], filters: [], promotionTextPerLanguage: {} }),
  save: AdminAPI.coupons.create,
});

export const EditCoupons = getEditorComponent({
  ...baseProps,
  dataLoader: (params) => AdminAPI.coupons.find(params.id),
  save: (props) => AdminAPI.coupons.update(props._id, props),
});
