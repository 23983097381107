import { Paper, Box, Button, Grid, Chip, Link, Rating, Stack, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RoomDescriptionPopover from './RoomDescriptionPopover';
import { Link as LinkRouter } from 'react-router-dom';
import RoomDescription from './RoomDescription';
import { FEATURED_ICONS } from '../icons';
import { COLOR } from '../../helpers/styles';
import noImage from '../assets/no-image-available.png';
import { money } from '../../helpers/utils';
import React, { Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useBreakpoints from '../../hooks/use-breakpoints';
import { t } from '../../translations';
import FavoriteHotelButton from '../FavoriteHotelButton/FavoriteHotelButton';
import { Currency } from '../../types';
import Carrousel from '../ImagesContainer/Carrousel';

const HotelLink = ({ to, linkProps, children }: any) => (
  <Link component={LinkRouter} to={to} target={isMobile ? '_self' : '_blank'} rel="noopener norefer" underline="none" {...linkProps}>
    {children}
  </Link>
);

const HotelImage = ({ hotelAvailability, linkProps }) => {
  const { md, sm } = useBreakpoints();

  return (
    <HotelLink to={hotelAvailability.linkTo} linkProps={linkProps}>
      <Carrousel
        images={hotelAvailability.images?.[0] ? hotelAvailability.images : [noImage]}
        imageProps={{ height: md ? '200px' : sm ? '400px' : '300px' }}
      />
    </HotelLink>
  );
};

const HotelNameForCard = ({ hotelAvailability }) => (
  <Stack direction="row" spacing={{ xs: 1 }} alignItems="center">
    <HotelLink to={hotelAvailability.linkTo} linkProps={{ variant: 'h6' }}>
      {hotelAvailability.hotelName}
    </HotelLink>
    {hotelAvailability.category && <Rating name="read-only" value={hotelAvailability.category} readOnly precision={1} size="small" />}
  </Stack>
);

const HotelNameForCardMap = ({ hotelAvailability }) => (
  <Stack direction="column" spacing={{ xs: 1 }} alignItems="flex-start" sx={{ mb: 0.5, pr: 2 }}>
    <HotelLink to={hotelAvailability.linkTo} linkProps={{ variant: 'h6' }} sx={{ mb: 0 }}>
      {hotelAvailability.hotelName}
    </HotelLink>
    {hotelAvailability.category && <Rating name="read-only" value={hotelAvailability.category} readOnly precision={1} size="small" />}
  </Stack>
);

const ClosedPlaces = ({ hotelAvailability }) => {
  const distanceInMt = (x) => x.distance * (x.distanceMetric === 'km' ? 1000 : 1);
  const closedPlaces = (hotelAvailability.closedPlaces || [])
    .filter(({ name, distance, distanceMetric }) => name && distance && distanceMetric)
    .sort((a, b) => distanceInMt(a) - distanceInMt(b))
    .slice(0, 2);

  return (
    <Box sx={{ mb: 0.5 }}>
      {closedPlaces.map(({ name, distance, distanceMetric }) => (
        <Fragment key={name}>
          <Typography component="span" sx={{ fontWeight: 'bold', ml: 1, fontSize: 12 }}>
            {distance}
            {distanceMetric}{' '}
          </Typography>
          <Typography component="span" sx={{ fontSize: 12, mr: 1, mb: 1 }}>
            {t.from} {name}
          </Typography>
        </Fragment>
      ))}
    </Box>
  );
};

const Ships = ({ hotelAvailability }) => {
  const establishmentType = hotelAvailability.type?.toLowerCase();
  const isXs = useBreakpoints().only.xs;
  const chips = [
    {
      component: FEATURED_ICONS[establishmentType],
      text: establishmentType || '',
      visible: true,
    },
    {
      component: FEATURED_ICONS.reimbursable,
      text: t.Reimbursable,
      visible: hotelAvailability.reimbursable,
    },
    {
      component: FEATURED_ICONS.certificated,
      text: t.Certificated,
      visible: !isXs && hotelAvailability.certificated,
    },
  ];
  return (
    <Stack direction="row" alignItems="center" spacing={{ xs: 1 }}>
      {chips.map(
        ({ visible, text, component: IconComponent }) =>
          visible && IconComponent && <Chip icon={<IconComponent />} key={text} label={text} size="small" />
      )}
    </Stack>
  );
};

const Rooms = ({ hotelAvailability }) => {
  const rooms: any[] = [...new Set(hotelAvailability.roomsDescriptions || [])];
  return (
    <Stack direction="column" sx={{ mt: 2, pl: 1, pb: 1 }}>
      {(rooms || []).slice(0, 2).map((desc) => (
        <RoomDescription key={desc} desc={desc} />
      ))}
      {rooms?.length > 2 && <RoomDescriptionPopover roomsDescriptions={rooms} />}
    </Stack>
  );
};

export type AvailabilityPriceProps = {
  price: number;
  currency: Currency;
  numberOfNights: number;
};

export const AvailabilityPrice = ({ price, currency, numberOfNights }) => (
  <Box>
    <Box>
      <Typography component="span" variant="h5">
        {currency}{' '}
      </Typography>
      <Typography component="span" variant="h5">
        {' $'}
        {numberOfNights > 1 ? money(Number(Number(price) / numberOfNights).toFixed(2)) : money(price)}
      </Typography>
    </Box>
    {numberOfNights > 1 && (
      <Box>
        <Typography component="span" variant="h6" fontSize={11} fontWeight={400}>
          {currency}{' '}
        </Typography>
        <Typography component="span" variant="h6" fontSize={13} fontWeight={400}>
          {' $'}
          {`${money(price)} ${t.total}`}
        </Typography>
      </Box>
    )}
  </Box>
);

const AvailabilityButton = ({ hotelAvailability, nights }: any) => {
  return (
    <React.Fragment>
      <AvailabilityPrice price={hotelAvailability.finalPrice} currency={hotelAvailability.currency} numberOfNights={nights} />
      <HotelLink to={hotelAvailability.linkTo}>
        <Button sx={{ mt: '0px', minWidth: 210 }} variant="contained" endIcon={<ArrowForwardIcon />}>
          {t.CheckAvailability}
        </Button>
      </HotelLink>
    </React.Fragment>
  );
};

const HotelAvailabilityCard = ({ hotelAvailability = {}, nights }) => {
  return (
    <>
      <Paper elevation={1} sx={{ border: `0.5px solid ${COLOR.GRAY_LINE_SEPARATOR}` }} /*variant="outlined"*/>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} sx={{ position: 'relative' }}>
            <FavoriteHotelButton hotelCode={(hotelAvailability as unknown as any).hotelCode as string} sx={{ mt: 1 }} />
            <HotelImage
              hotelAvailability={hotelAvailability}
              linkProps={{ display: 'block', height: { xs: '300px', sm: '400px', md: '200px' } }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Box p={2} pb={1}>
              <HotelNameForCard hotelAvailability={hotelAvailability} />
              <Grid container spacing={0}>
                <Grid item xs={12} md={8}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ flex: '1 0 auto' }}>
                      <ClosedPlaces hotelAvailability={hotelAvailability} />
                      <Ships hotelAvailability={hotelAvailability} />
                      <Rooms hotelAvailability={hotelAvailability} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
                    <AvailabilityButton hotelAvailability={hotelAvailability} nights={nights} />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export const HotelAvailabilityCardMap = ({ hotelAvailability = {}, onClose = () => {} }: any) => {
  return (
    <>
      <Paper
        elevation={1}
        sx={{
          border: `0.5px solid ${COLOR.GRAY_LINE_SEPARATOR}`,
          // minWidth: '500px',
          width: { xs: '100vw', sm: '500px' },
          position: 'relative',
        }} /*variant="outlined"*/
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: -3,
            top: -3,
            color: 'primary.main',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container>
          <Grid item xs={4} sx={{ p: 1 }}>
            <HotelImage hotelAvailability={hotelAvailability} linkProps={{ display: 'block', width: '100%', height: '100%' }} />
          </Grid>
          <Grid item xs={8}>
            <Box p={1} pb={0.5}>
              <HotelNameForCardMap hotelAvailability={hotelAvailability} />
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ flex: '1 0 auto' }}>
                      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <ClosedPlaces hotelAvailability={hotelAvailability} />
                      </Box>
                      <Ships hotelAvailability={hotelAvailability} />
                      <Stack
                        // spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          mb: 2,
                          mt: 2,
                          flexDirection: { xs: 'column', sm: 'row' },
                          alignItems: { xs: 'center', sm: 'end' },
                          gap: { xs: 0, sm: 4 },
                        }}
                      >
                        <AvailabilityButton hotelAvailability={hotelAvailability} />
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const numberOrEmptyString = PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([''])]);

export const HotelAvailabilityProps = PropTypes.shape({
  hotelName: PropTypes.string,
  type: PropTypes.string,
  reimbursable: PropTypes.bool,
  certificated: PropTypes.bool,
  category: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.string),
  finalPrice: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  roomsDescriptions: PropTypes.arrayOf(PropTypes.string),
  closedPlaces: PropTypes.arrayOf(
    PropTypes.shape({
      distance: numberOrEmptyString,
      distanceMetric: PropTypes.string,
      name: PropTypes.string,
    })
  ),
});

HotelAvailabilityCard.propTypes = {
  hotelAvailability: HotelAvailabilityProps,
};

export default HotelAvailabilityCard;
