import { Box, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Image from 'mui-image';
import { isBlank } from '../../helpers/utils';
import { t } from '../../translations';

const HotelLocation = (props) => {
  const { hotel } = props;

  const address = [hotel.address, hotel.cityName, hotel.provinceName, hotel.countryCode].join(' / ');

  let map;
  let mapWidth = '100%';
  let mapHeight = '300px';

  if (hotel.location?.latitude && hotel.location?.longitude) {
    map = (
      <iframe
        title="map"
        height={mapHeight}
        width={mapWidth}
        src={`https://maps.google.com/maps?q=${hotel.location.latitude},${hotel.location.longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
      />
    );
  } else if (hotel.map) {
    map = <Image src={hotel.map} width={mapWidth} height={mapHeight} duration={0} />;
  }

  const closePlaces = (hotel.closePlaces || [])
    .map(({ name, distance, distanceMetric, walking, walkingMetric, driving, drivingMetric }) => {
      if (walking && !isBlank(walking) && walkingMetric) {
        return `${name} - ${walking} ${walkingMetric} ${t.walking}`;
      }

      if (driving && !isBlank(driving) && drivingMetric) {
        return `${name} - ${driving} ${drivingMetric} ${t.driving}`;
      }

      if (distance && !isBlank(distance) && distanceMetric) {
        return `${name} - ${distance} ${distanceMetric} ${t.distance}`;
      }

      return null;
    })
    .filter(Boolean);

  return (
    <Paper sx={{ px: 3, py: 2, mb: 2 }}>
      <Grid container spacing={4}>
        <Grid xs={12} md={8}>
          <Box sx={{ ml: 1 }} color="text.secondary">
            <Typography variant="h6">{address}</Typography>
            <Typography variant="h6">{t.PostalCode} {hotel.postalCode}</Typography>
          </Box>
          {map && <Box>{map}</Box>}

          {hotel.howToGo && (
            <Box>
              <Typography fontWeight="bold" ml={1} mt={2}>
                {t.HowToGo}:
              </Typography>{' '}
              <Typography color="text.secondary">{hotel.howToGo}</Typography>
            </Box>
          )}
        </Grid>
        <Grid xs={12} md={4}>
          <Box pt={{ md: 4 }}>
            {closePlaces.length > 0 && (
              <Typography fontWeight="bold" ml={3}>
                {t.ClosedPlaces}:
              </Typography>
            )}
          </Box>
          <Box component="ul" pl={3} mt={1}>
            {closePlaces.map((item) => (
              <Box component="li" mb={1} key={item}>
                {item}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HotelLocation;
