import { Stack } from '@mui/material';
import americanExpress from '../assets/card-brands/americanexpress.png';
import discover from '../assets/card-brands/discover.png';
import maestro from '../assets/card-brands/maestro.png';
import mastercard from '../assets/card-brands/mastercard.png';
import visa from '../assets/card-brands/visa.png';
import { Image } from 'mui-image';

// https://www.iconfinder.com/iconsets/credit-card-debit-card-payment-PNG
const BRANDS = [
  { name: 'Visa', img: visa },
  { name: 'MasterCard', img: mastercard },
  { name: 'AmericanExpress', img: americanExpress },
  { name: 'Discover', img: discover },
  { name: 'Maestro', img: maestro },
];

const CreditCardBrands = () => {
  return (
    <Stack direction="row" spacing={{ xs: 0, sm: 1 }} sx={{ flexWrap: 'wrap' }}>
      {BRANDS.map((brand) => (
        <Image
          duration={0}
          key={brand.name}
          width="40px"
          // height="50px"
          // duration={100}
          src={brand.img}
          alt={brand.name}
          // errorIcon={<NoImage />}
          style={{ width: '40px' }}
        />
      ))}
    </Stack>
  );
};

export default CreditCardBrands;
