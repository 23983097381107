import { Box, Typography } from '@mui/material';
import Navbar from './Navbar';
import { Language } from '../../types';
import { language } from '../../translations';

const termsContentEn = (
  <Box maxWidth="1200px" margin="0 auto" p={4} pt={6}>
    <h2 id="important-information-regarding-your-booking">IMPORTANT INFORMATION REGARDING YOUR BOOKING</h2>
    <p>
      <strong>You must not accept any booking unless you understand and agree with the following terms and conditions.</strong>
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>All Terms and Conditions to be performed in the name of Bellitours LLC (hereinafter referred to as &quot;Bellitours&quot;)</li>
    </Box>
    <p>
      <strong>Baggage and Fees</strong>
      <br />
      Additional fees for baggage may apply. Please contact your airline or refer to its website for detailed information regarding their
      checked baggage policies, or alternatively please click here for more information.
    </p>
    <p>
      <strong>Travel Agent</strong>
      <br />
      Bellitours or any of its affiliated entities acts as a wholesale agent or direct Travel Service Provider. We sell various travel
      related products on behalf of numerous transport service providers, including, but not limited to hotel, tours, airlines and rail.
      Bellitours does not own, operate, manage or control these independent suppliers of services and is not liable for their acts or
      omissions. Bel- litours obligations to you are to make travel bookings on your behalf for your client's and to arrange relevant
      contracts between you, your clients and the travel service providers. We have no responsibility for these services, nor do we have the
      authority to make any warranty or representation regarding their standard. A request cannot be guaranteed. All bookings are subject to
      the terms and conditions and limitations of liability imposed by these travel service providers. You understand that you and your cli-
      ent's legal recourse are against the specific provider not Bellitours
    </p>
    <p>
      <strong>Deposits and Final Payment</strong>
      <br />
      Bellitours will accept the following credit cards: VISA, MASTERCARD, AMERICAN EXPRESS and DISCOVER as valid forms of payment for
      bookings made through this website. Bellitours reserves the right to modify this policy based on booking agency status and prior
      credit approval.
    </p>
    <p>
      All reservations require a non-refundable $100 per person deposit. There will be a $35.00 charge for returned checks. If the book- ing
      is for a vacation more than 45 days before departure, payment can be made in full at time of booking, or (if applicable) an initial
      deposit can be made and the remaining balance paid no less than 45 days before departure. The initial deposit will be charged to a
      credit card at time of booking or may be payable by check. AIR: If the booking includes air travel, such airfare is subject to the
      poli- cies of the issuing Airline and may be non-refundable once ticketed. Subject to the Airline policies and to the extent permitted
      by law, you will have 24 hours from the time of your original booking to cancel your reservation without being charged a cancellation
      if the travel was booked 7 days or more prior to the departure date. Thereafter, any amendment or any changes to a reservation once
      booked and paid or deposited constitutes a cancellation and any ticket exchanges, changes, reissues and cancellations may require
      additional funds. In addition to retaining the non-refundable $150 per person deposit, cancelled bookings may incur charg- es of up to
      100% of the cost of the booking depending on the Airline rules. In the event the airfare is cancelled, the airfare may be exchanged
      for another trip, under the same passenger names, so long as the trip is completed within 365 days of the original ticket issue date.
    </p>
    <p>
      <strong>Credit Card Transactions</strong>
      <br />
      If for any reason, any travel service provider is unable to provide the services for which you or your client have contracted, you or
      your client's remedy lies against the provider, and not against Bellitours and in the event that payment has been made to Bellitours
      by credit card, you agree that you will not seek to charge back your payment to Bellitours or that of your client's. If Bellitours in-
      curs any costs, including but not limited to attorneys' fees, to recover any payments charged back by you or your client's credit card
      company, you agree that you will be liable for these costs. If the credit card is declined, you guarantee that you will settle any
      amounts owing Bellitours via money order or cash immediately.
    </p>
    <p>
      <strong>Other Conditions</strong>
      <br />
      Prices are subject to increase prior to the time you or your client makes full payment. Prices are not subject to increase after you
      or your client makes full payment, except for charges resulting from increases in government-imposed taxes or fees. By accepting these
      terms and conditions, you expressly acknowledge your acceptance of these conditions (i.e. increases before full payment and increases
      attributable to government-imposed taxes or fees after full payment will be your responsibility) applicable to your cli- ent's
      purchase. You agree that you will be responsible for obtaining your client's written consent to any increases to prices in ac-
      cordance with this section and you agree to indemnify and hold harmless Bellitours against any claims arising out of such a failure to
      obtain written consent.
    </p>

    <p>
      <strong>Cancellation Policy</strong>
      <br />
      It is a condition of this booking that you specifically communicate in writing to your client, Bellitours refund and/or cancellation
      policy of this booking, including without limitation the refund and/or cancellation policy of the third party suppliers. To the extent
      permitted by law, any bookings cancelled will be subject to a:
    </p>
    <p>1) $100 per person fee paid to Bellitours</p>
    <p>2) forfeiture of any deposit paid to Bellitours;</p>
    <p>
      3) Any additional fees or penalties required by third party-suppliers; and any additional fees or penalties required by third party-
      suppliers;
    </p>
    <p>
      4) Any non-refundable portion payable to third party suppliers such as airlines whether paid in advance by your client or if pay- ment
      in full is due prior to departure (collectively “Cancellation Fees”). Your failure to pay or obtain payment from your client for any
      or all portion of the Cancellation Fees may result in Bellitours withholding, setting off or deducting such portion of the
      Cancellation Fees which are outstanding from future commissions due to you for this booking and any other bookings you have with
      Bellitours until the Cancellation Fees owing is received. If your client has trip protection insurance, any Cancellation Fees must be
      paid to Bellitours. Your client must submit an insurance claim for the Cancellation Fees. Agency indemnifies Belli- tours against all
      losses, damages, claims and costs (including reasonable attorney fees) which Bellitours may sustain or incur directly from or
      concerning:
    </p>
    <p>
      5) Any failure by Agency to obtain the Agency's Client written acknowledgement of the refund and/or cancellation policy of this
      booking as set out above in this paragraph
    </p>
    <p>
      6) Any failure by the Agency's Client to pay the full amount owed for this booking, including without limitation Cancellation Fees;
      and/or (c) any chargebacks or bounced checks by the Agency or the Agency's Client relating to this booking or Cancellation Fees.
    </p>
    <p>
      Any failure by the Agency's Client to pay the full amount owed for this booking, including without limitation Cancellation Fees; and/
      or (c) any chargebacks or bounced checks by the Agency or the Agency's Client relating to this booking or Cancellation Fees.
    </p>
    <p>
      <strong>Hazardous Material</strong>
      <br />
      Federal law forbids the carriage of certain hazardous materials, such as aerosols, fireworks, and flammable liquids, aboard the air-
      craft. If your do not understand these restrictions, contact your airline or click here
    </p>
    <p>
      <strong>Modifications or Revisions</strong>
      <br />A $30 non-refundable commissionable fee will be charged by Bellitours for any changes or revisions made after initial deposit in
      addition to any fees imposed by third party suppliers for the change or revisions.
    </p>
    <p>
      <strong>Not Included Items</strong>
      <br />
      Your client may incur addition expenses whilst travelling that are not included in the package price, including but not limited to the
      following:
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>
        Tipping and porterage: our package price does not include porterage at airport and hotels; tips to local representatives, sightsee-
        ing guides, restaurant employees, hotel bellmen and chambermaids; wines, liquors, laundry, or any other items of a personal na- ture
        as well as any items not specifically mentioned within the itinerary. Tipping is left to your client's discretion.
      </li>
      <li>
        Car, Moped or Scooter Rental: Car rental rates for packages that include a car do not include gasoline, taxes, optional insurance,
        CDW, underage and additional driver charges or airport charges. These charges are payable to the car rental company directly.
      </li>
      <li>
        Local taxes and service charges: Local room tax, energy surcharges and service charges on room and meals are not included un- less
        specified. Taxes and service charges are based on the hotel's daily room tariff and not on the land package price. Local govern-
        ments may impose additional taxes including but not limited to departure taxes which may not be included in the package price.
      </li>
    </Box>
    <p>
      <strong>Travel Protection</strong>
      <br />
      Travel Protection plans are recommended to help cover your investment, offset expenses from travel mishaps and provide you with
      emergency travel assistance. For your protection, Bellitours will offer and quote an insurance plan with your booking.
    </p>
    <p>
      Bellitours is not an insurance company and has no responsibility for the representation, description of coverage, submission, bind-
      ing coverage, payment or adjustment of any insurance claims. Any insurance claims that may fall under the relevant travel insur- ance
      policy must be submitted to the insurance company identified in the policy.
    </p>
    <p>
      Refunds for Unused Services Unused hotel nights are refundable only to the extent that we receive a refund from the hotels, after
      cancellation charges are lev- ied by the hotel, if any. Unused transfers and/or sightseeing tours included in the basic tours will
      only be refunded to the extent that we receive a refund from the supplier. To get a prompt refund for unused services, clients must
      submit to their travel agent written evidence from the hotel or other operator that services were unused and are refundable. A copy of
      the checkout bill will help to expedite the processing of any refund due, if applicable. If no proof is furnished, a refund may be
      made pending an investi- gation, which may take up to three months. TO BE ACCEPTED, ALL REFUND CLAIMS MUST BE PRESENTED NO LATER THAN
      30 DAYS AFTER THE TRIP HAS ENDED. All refund claims must be submitted in writing to: Bellitours 14135 sw 147th ct, Miami, FL, 33196
      Attn: Refunds. Bellitours cannot accept faxed refund requests. No refund or adjustment will be made for any changes, cancellations or
      modifications of services provided in this itinerary, if such changes are made by the tour participant during the tour. Travel agent's
      commission is not protected on any cancelled or refunded bookings.
    </p>

    <p>
      <strong>Responsibilities</strong>
      <br />
      We always do our best to make sure your travel arrangements are satisfactory. However, Bellitours and its employees, sharehold- ers,
      subsidiaries, affiliates, officers, directors, successors, agents, and assigns do not own or operate any entity which provides goods
      and services for your travel including without limitation, lodging facilities, airline, vessel, motor coach, or other transporta- tion
      companies, guides or guide services, local ground operators, providers or organizers of optional excursions, food service pro- viders,
      etc. All such persons and entities are independent contractors and are in no way affiliated any of Bellitours affiliated enti- ties.
    </p>
    <p>
      <strong>Liability</strong>
      <br />
      Bellitours and its employees, affiliates, officers, directors, successors, agents, and assigns do not own or operate any entity which
      provides goods and services for your travel including without limitation, lodging facilities, airline, vessel, motor coach, or other
      transportation companies, guides or guide services, local ground operators, providers or organizers of optional excursions, food
      service providers, etc. All such persons and entities are independent contractors and are in no way affiliated to Bellitours. Belli-
      tours” is not liable for any negligent or willful act or failure to act of any travel service provider or of any third party. 2Fix
      Corp, DBA ”Bellitours is not responsible for any injury, loss, death, inconvenience, delay, or damage to person or property in
      connection with the provision of any goods or services whether resulting from, but not limited to, acts of God or force majeure,
      illness, disease, acts of war or civil unrest, insurrection or revolt, animals, strikes or other labor activities, criminal or
      terrorist activities of any kind, overbooking or downgrading of accommodations, mechanical or other failure of airplanes or other
      means of transportation, or for any failure of any transportation mechanism to arrive or depart on time. If due to weather, flight
      schedules or other uncontrollable factors you are required to spend additional night(s), you will be responsible for your own hotel,
      transfers and meal costs. Baggage is entirely at owner's risk. You may see the name ”Bellitours” affixed to motor vehicles, on signs
      around the hotel or elsewhere. This use of our name is purely for reasons of identification and does not denote ownership,
      supervision, or control by Bellitours in any way. You specifically agree that Bellitours is not liable for any consequential loss or
      damage.
    </p>
    <p>
      <strong>Passports and Documents</strong>
      <br />
      All individuals departing from the United States of America must be in possession of a valid passport. It is your responsibility to
      ensure that you have valid travel documentation, including but not limited to passports and visas, which meet the requirements of
      immigration and other government authorities at every destination. You are responsible for confirming with the United States De-
      partment of State or representative government agency of the country to which you are traveling to confirm the requirements for visas
      and/or other requirements for admission to your destination. Any fines, penalties, payments or expenditures incurred as a result of
      such documents not meeting the requirements of those authorities will be your sole responsibility. A government issued ID is required
      for travel within the USA.
    </p>
    <p>
      Travel documents including, without limitation, airline tickets, hotel vouchers, tour vouchers or any other document (whether in
      electronic form or otherwise) used to confirm an arrangement with a service provider. Travel documents may be subject to certain
      conditions and/or restrictions such as, but not limited to, being non refundable, non-date-changeable, and subject to cancellation
      and/or amendment fees. Travel documents cannot be transferred to another person to use. All airline tickets must be issued in the name
      of the passport/photo identity holder. An incorrect name on a booking may result in an inability to use that booking and the booking
      being cancelled. Any errors in names, dates and timings may result in an inability to use that booking and the booking be- ing
      cancelled. Any errors in names, dates and timings on your documentation will be your responsibility if not advised at the time of
      booking.
    </p>
    <p>
      <strong>Flight Confirmation</strong>
      <br />
      We strongly suggest that clients reconfirm exact flight times with the carrier prior to departure. Air travelers are required to check
      in at least 2 hours prior to departure time for domestic flights and 3 hours for international flights and must report to the gat at
      least 30 minutes prior to departure time. Failure to comply may result in the loss of the seat to another passenger.
    </p>
    <p>
      <strong>Health and Special Requirements</strong>
      <br />
      It is your responsibility to ensure you are aware of any health requirements for your travel destinations and to ensure that you carry
      all necessary vaccination documentation. Please advise your consultant of any special requirements you may have, for exam- ple,
      special meals, medical requirements and so on.
    </p>
    <p>
      International Flights Some countries require insecticide spraying of aircraft prior to a flight or while you are on the aircraft.
      Federal law requires that we refer you to the Department of Transport disinfection website. Click Here
    </p>
    <p>
      <strong>Photographs &amp; Maps</strong>
      <br />
      Room photographs are shown for informational purposes only and may not represent the actual room category purchased. Maps represented
      on the site are shown for informational purposes only and display only approximate locations and attractions and are not intended to
      be exact in every detail.
    </p>
    <p>
      <strong>Refusal of Service</strong>
      <br />
      Service providers reserve the right to refuse service to travelers at their sole discretion and including without limitation if the
      trav- eler:
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>Lacks proper documentation for the country of destination;</li>
      <li>Has a contagious disease;</li>
      <li>Is under the influence of alcohol, drugs or narcotics; and/or</li>
      <li>
        Manifests disruptive and/or unruly behavior. Bellitours assumes no liability for the acts of the service provider in refusing
        service.
      </li>
    </Box>
    <p>
      <strong>Privacy Policy</strong>
      <br />
      Bellitours is committed to protecting the privacy and confidentiality of your personal information. Our Privacy Policy is available
      for viewing here
    </p>
    <p>
      <strong>Jurisdiction</strong>
      <br />
      All disputes and matters arising under or in connection with, these terms and conditions shall be submitted for trial without jury in
      the Federal Court of Florida. These terms and conditions shall be construed in accordance with the laws of the State of Florida.
    </p>
    <p>Bellitours. 15420 SW 136th ST Unit 3, Miami, FL,</p>
  </Box>
);

const termsContentEs = (
  <Box maxWidth="1200px" margin="0 auto" p={4} pt={6}>
    <h2 id="important-information-regarding-your-booking">INFORMACIÓN IMPORTANTE SOBRE SU RESERVA</h2>
    <p>
      <strong>No debe aceptar ninguna reserva a menos que entienda y esté de acuerdo con los siguientes términos y condiciones.</strong>
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>Todos los Términos y Condiciones se realizarán en nombre de Bellitours LLC (en adelante, &quot;Bellitours&quot;)</li>
    </Box>
    <p>
      <strong>Equipaje y Tarifas</strong>
      <br />
      Pueden aplicarse tarifas adicionales por el equipaje. Comuníquese con su aerolínea o consulte su sitio web para obtener información
      detallada sobre sus políticas de equipaje facturado, o alternativamente haga clic aquí para obtener más información.
    </p>
    <p>
      <strong>Agente de Viajes</strong>
      <br />
      Bellitours o cualquiera de sus entidades afiliadas actúa como agente mayorista o Proveedor de Servicios de Viaje directo. Vendemos
      varios productos relacionados con viajes en nombre de numerosos proveedores de servicios de transporte, incluidos, entre otros,
      hoteles, tours, aerolíneas y ferrocarriles. Bellitours no posee, opera, administra ni controla a estos proveedores de servicios
      independientes y no es responsable de sus actos u omisiones. Las obligaciones de Bellitours hacia usted son realizar reservas de viaje
      en su nombre para sus clientes y organizar los contratos correspondientes entre usted, sus clientes y los proveedores de servicios de
      viaje. No tenemos responsabilidad por estos servicios, ni tenemos la autoridad para hacer ninguna garantía o representación respecto a
      su estándar. Una solicitud no puede ser garantizada. Todas las reservas están sujetas a los términos y condiciones y limitaciones de
      responsabilidad impuestas por estos proveedores de servicios de viaje. Usted entiende que su recurso legal y el de sus clientes es
      contra el proveedor específico, no contra Bellitours.
    </p>
    <p>
      <strong>Depósitos y Pago Final</strong>
      <br />
      Bellitours aceptará las siguientes tarjetas de crédito: VISA, MASTERCARD, AMERICAN EXPRESS y DISCOVER como formas válidas de pago para
      reservas realizadas a través de este sitio web. Bellitours se reserva el derecho de modificar esta política según el estado de la
      agencia de reservas y la aprobación de crédito previa.
    </p>
    <p>
      Todas las reservas requieren un depósito no reembolsable de $100 por persona. Habrá un cargo de $35.00 por cheques devueltos. Si la
      reserva es para unas vacaciones con más de 45 días antes de la salida, el pago puede hacerse en su totalidad en el momento de la
      reserva, o (si es aplicable) puede hacerse un depósito inicial y el saldo restante pagado no menos de 45 días antes de la salida. El
      depósito inicial se cargará a una tarjeta de crédito en el momento de la reserva o puede ser pagadero mediante cheque. AÉREO: Si la
      reserva incluye viaje aéreo, dicha tarifa aérea está sujeta a las políticas de la Aerolínea emisora y puede no ser reembolsable una
      vez emitido el boleto. Sujeto a las políticas de la Aerolínea y en la medida permitida por la ley, usted tendrá 24 horas desde el
      momento de su reserva original para cancelar su reserva sin ser cobrado por una cancelación si el viaje fue reservado 7 días o más
      antes de la fecha de salida. Posteriormente, cualquier enmienda o cualquier cambio a una reserva una vez reservada y pagada o
      depositada constituye una cancelación y cualquier intercambio de boletos, cambios, reemisiones y cancelaciones pueden requerir fondos
      adicionales. Además de retener el depósito no reembolsable de $150 por persona, las reservas canceladas pueden incurrir en cargos de
      hasta el 100% del costo de la reserva dependiendo de las reglas de la Aerolínea. En caso de que se cancele la tarifa aérea, la tarifa
      aérea puede ser intercambiada por otro viaje, bajo los mismos nombres de pasajeros, siempre que el viaje se complete dentro de los 365
      días desde la fecha de emisión del boleto original.
    </p>
    <p>
      <strong>Transacciones con Tarjeta de Crédito</strong>
      <br />
      Si por alguna razón, cualquier proveedor de servicios de viaje no puede proporcionar los servicios para los cuales usted o su cliente
      han contratado, su recurso o el de su cliente es contra el proveedor, y no contra Bellitours y en el caso de que el pago haya sido
      realizado a Bellitours por tarjeta de crédito, usted acepta que no buscará revertir el pago a Bellitours o el de su cliente. Si
      Bellitours incurre en cualquier costo, incluidos pero no limitados a honorarios de abogados, para recuperar cualquier pago revertido
      por la compañía de tarjeta de crédito de usted o de su cliente, usted acepta que será responsable de estos costos. Si la tarjeta de
      crédito es rechazada, usted garantiza que liquidará cualquier monto adeudado a Bellitours mediante giro postal o efectivo
      inmediatamente.
    </p>
    <p>
      <strong>Otras Condiciones</strong>
      <br />
      Los precios están sujetos a aumento antes de que usted o su cliente realicen el pago completo. Los precios no están sujetos a aumento
      después de que usted o su cliente realicen el pago completo, excepto por cargos resultantes de aumentos en impuestos o tarifas
      impuestas por el gobierno. Al aceptar estos términos y condiciones, usted reconoce expresamente su aceptación de estas condiciones (es
      decir, aumentos antes del pago completo y aumentos atribuibles a impuestos o tarifas impuestas por el gobierno después del pago
      completo serán su responsabilidad) aplicables a la compra de su cliente. Usted acepta que será responsable de obtener el
      consentimiento escrito de su cliente para cualquier aumento de precios de acuerdo con esta sección y acepta indemnizar y mantener
      indemne a Bellitours contra cualquier reclamo que surja de dicho incumplimiento para obtener el consentimiento escrito.
    </p>

    <p>
      <strong>Política de Cancelación</strong>
      <br />
      Es una condición de esta reserva que usted comunique específicamente por escrito a su cliente, la política de reembolso y/o
      cancelación de Bellitours para esta reserva, incluyendo sin limitación la política de reembolso y/o cancelación de los proveedores
      externos. En la medida permitida por la ley, cualquier reserva cancelada estará sujeta a:
    </p>
    <p>1) Tarifa de $100 por persona pagada a Bellitours</p>
    <p>2) Pérdida de cualquier depósito pagado a Bellitours;</p>
    <p>
      3) Cualquier tarifa o penalización adicional requerida por proveedores externos; y cualquier tarifa o penalización adicional requerida
      por proveedores externos;
    </p>
    <p>
      4) Cualquier parte no reembolsable pagadera a proveedores externos como aerolíneas, ya sea pagada por adelantado por su cliente o si
      el pago completo es debido antes de la salida (colectivamente “Tarifas de Cancelación”). Su falta de pago u obtención del pago de su
      cliente para cualquier o toda la porción de las Tarifas de Cancelación puede resultar en que Bellitours retenga, compense o deduzca
      dicha porción de las Tarifas de Cancelación que estén pendientes de futuras comisiones debidas a usted por esta reserva y cualquier
      otra reserva que tenga con Bellitours hasta que se reciba el pago de las Tarifas de Cancelación adeudadas. Si su cliente tiene seguro
      de protección de viaje, cualquier Tarifa de Cancelación debe ser pagada a Bellitours. Su cliente debe presentar una reclamación de
      seguro para las Tarifas de Cancelación. La Agencia indemniza a Bellitours contra todas las pérdidas, daños, reclamaciones y costos
      (incluidos honorarios razonables de abogados) que Bellitours pueda sufrir o incurrir directamente de o en relación con:
    </p>
    <p>
      5) Cualquier falla de la Agencia para obtener el reconocimiento por escrito del Cliente de la Agencia de la política de reembolso y/o
      cancelación de esta reserva como se establece en este párrafo
    </p>
    <p>
      6) Cualquier falla del Cliente de la Agencia para pagar el monto total adeudado por esta reserva, incluidas sin limitación las Tarifas
      de Cancelación; y/o (c) cualquier devolución de cargo o cheques devueltos por la Agencia o el Cliente de la Agencia en relación con
      esta reserva o Tarifas de Cancelación.
    </p>
    <p>
      Cualquier falla del Cliente de la Agencia para pagar el monto total adeudado por esta reserva, incluidas sin limitación las Tarifas de
      Cancelación; y/o (c) cualquier devolución de cargo o cheques devueltos por la Agencia o el Cliente de la Agencia en relación con esta
      reserva o Tarifas de Cancelación.
    </p>
    <p>
      <strong>Material Peligroso</strong>
      <br />
      La ley federal prohíbe el transporte de ciertos materiales peligrosos, como aerosoles, fuegos artificiales y líquidos inflamables, a
      bordo del avión. Si no entiende estas restricciones, comuníquese con su aerolínea o haga clic aquí
    </p>
    <p>
      <strong>Modificaciones o Revisiones</strong>
      <br />
      Se cobrará una tarifa no reembolsable de $30 por cualquier cambio o revisión realizada después del depósito inicial, además de
      cualquier tarifa impuesta por proveedores externos por el cambio o revisión.
    </p>
    <p>
      <strong>Artículos No Incluidos</strong>
      <br />
      Su cliente puede incurrir en gastos adicionales mientras viaja que no están incluidos en el precio del paquete, incluidos, entre
      otros, los siguientes:
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>
        Propinas y porteadores: el precio de nuestro paquete no incluye porteadores en el aeropuerto y hoteles; propinas a representantes
        locales, guías de excursiones, empleados de restaurantes, botones de hotel y camareras; vinos, licores, lavandería, o cualquier otro
        artículo de carácter personal así como cualquier artículo no mencionado específicamente en el itinerario. Las propinas quedan a
        discreción de su cliente.
      </li>
      <li>
        Alquiler de coches, motos o scooters: las tarifas de alquiler de coches para paquetes que incluyen un coche no incluyen gasolina,
        impuestos, seguro opcional, CDW, cargos por conductor adicional o por menor de edad o cargos en el aeropuerto. Estos cargos se pagan
        directamente a la empresa de alquiler de coches.
      </li>
      <li>
        Impuestos locales y cargos por servicios: El impuesto local sobre habitaciones, recargos energéticos y cargos por servicios en
        habitaciones y comidas no están incluidos a menos que se especifique. Los impuestos y cargos por servicios se basan en la tarifa
        diaria de la habitación del hotel y no en el precio del paquete terrestre. Los gobiernos locales pueden imponer impuestos
        adicionales incluidos, entre otros, impuestos de salida que pueden no estar incluidos en el precio del paquete.
      </li>
    </Box>
    <p>
      <strong>Protección de Viajes</strong>
      <br />
      Se recomienda planes de Protección de Viajes para ayudar a cubrir su inversión, compensar gastos por contratiempos de viaje y
      brindarle asistencia de viaje de emergencia. Para su protección, Bellitours ofrecerá y cotizará un plan de seguro con su reserva.
    </p>
    <p>
      Bellitours no es una compañía de seguros y no tiene responsabilidad por la representación, descripción de la cobertura, presentación,
      aseguramiento de la cobertura, pago o ajuste de cualquier reclamación de seguro. Cualquier reclamación de seguro que pueda caer bajo
      la póliza de seguro de viaje relevante debe ser presentada a la compañía de seguros identificada en la póliza.
    </p>
    <p>
      Reembolsos por Servicios No Utilizados Las noches de hotel no utilizadas son reembolsables solo en la medida en que recibamos un
      reembolso de los hoteles, después de que se apliquen cargos de cancelación por parte del hotel, si los hubiera. Los traslados y/o
      excursiones no utilizados incluidos en los tours básicos solo serán reembolsables en la medida en que recibamos un reembolso del
      proveedor. Para obtener un reembolso rápido por los servicios no utilizados, los clientes deben presentar a su agente de viajes
      evidencia escrita del hotel u otro operador de que los servicios no fueron utilizados y son reembolsables. Una copia de la factura de
      salida ayudará a acelerar el procesamiento de cualquier reembolso debido, si corresponde. Si no se proporciona prueba, se puede
      realizar un reembolso pendiente de una investigación, que puede tardar hasta tres meses. PARA SER ACEPTADAS, TODAS LAS RECLAMACIONES
      DE REEMBOLSO DEBEN PRESENTARSE A MÁS TARDAR 30 DÍAS DESPUÉS DE QUE EL VIAJE HAYA TERMINADO. Todas las reclamaciones de reembolso deben
      presentarse por escrito a: Bellitours 14135 sw 147th ct, Miami, FL, 33196 Attn: Reembolsos. Bellitours no puede aceptar solicitudes de
      reembolso enviadas por fax. No se hará ningún reembolso o ajuste por cualquier cambio, cancelación o modificación de los servicios
      proporcionados en este itinerario, si dichos cambios son realizados por el participante del tour durante el tour. La comisión del
      agente de viajes no está protegida en reservas canceladas o reembolsadas.
    </p>

    <p>
      <strong>Responsabilidades</strong>
      <br />
      Siempre hacemos nuestro mejor esfuerzo para asegurarnos de que sus arreglos de viaje sean satisfactorios. Sin embargo, Bellitours y
      sus empleados, accionistas, subsidiarias, afiliados, funcionarios, directores, sucesores, agentes y cesionarios no poseen ni operan
      ninguna entidad que proporcione bienes y servicios para su viaje, incluyendo, sin limitación, instalaciones de alojamiento,
      aerolíneas, embarcaciones, autocares u otras empresas de transporte, guías o servicios de guías, operadores terrestres locales,
      proveedores u organizadores de excursiones opcionales, proveedores de servicios de alimentos, etc. Todas estas personas y entidades
      son contratistas independientes y no están afiliadas de ninguna manera con ninguna de las entidades afiliadas de Bellitours.
    </p>
    <p>
      <strong>Responsabilidad</strong>
      <br />
      Bellitours y sus empleados, afiliados, funcionarios, directores, sucesores, agentes y cesionarios no poseen ni operan ninguna entidad
      que proporcione bienes y servicios para su viaje, incluyendo, sin limitación, instalaciones de alojamiento, aerolíneas, embarcaciones,
      autocares u otras empresas de transporte, guías o servicios de guías, operadores terrestres locales, proveedores u organizadores de
      excursiones opcionales, proveedores de servicios de alimentos, etc. Todas estas personas y entidades son contratistas independientes y
      no están afiliadas de ninguna manera con Bellitours. Bellitours no es responsable de ningún acto negligente o intencionado o de la
      falta de acción de cualquier proveedor de servicios de viaje o de cualquier tercero. 2Fix Corp, DBA Bellitours no es responsable de
      ninguna lesión, pérdida, muerte, inconveniencia, demora o daño a personas o propiedad en relación con la provisión de cualquier bien o
      servicio, ya sea resultante de, pero no limitado a, actos de Dios o fuerza mayor, enfermedad, actos de guerra o disturbios civiles,
      insurrección o revuelta, animales, huelgas u otras actividades laborales, actividades criminales o terroristas de cualquier tipo,
      sobreventa o degradación de alojamiento, fallas mecánicas u otras de aviones u otros medios de transporte, o por cualquier falla de
      cualquier mecanismo de transporte para llegar o salir a tiempo. Si debido al clima, horarios de vuelos u otros factores incontrolables
      se requiere que pase noches adicionales, usted será responsable de sus propios costos de hotel, traslados y comidas. El equipaje es
      completamente responsabilidad del propietario. Usted puede ver el nombre Bellitours en vehículos motorizados, en letreros alrededor
      del hotel o en otros lugares. Este uso de nuestro nombre es puramente por razones de identificación y no denota propiedad, supervisión
      o control por parte de Bellitours de ninguna manera. Usted acepta específicamente que Bellitours no es responsable de ninguna pérdida
      o daño consecuente.
    </p>
    <p>
      <strong>Pasaportes y Documentos</strong>
      <br />
      Todas las personas que salgan de los Estados Unidos de América deben estar en posesión de un pasaporte válido. Es su responsabilidad
      asegurarse de que tiene la documentación de viaje válida, incluidos, entre otros, pasaportes y visas, que cumplan con los requisitos
      de inmigración y otras autoridades gubernamentales en cada destino. Usted es responsable de confirmar con el Departamento de Estado de
      los Estados Unidos o la agencia gubernamental representativa del país al que viaja para confirmar los requisitos de visas y/o otros
      requisitos para la admisión a su destino. Cualquier multa, penalidad, pago o gasto incurrido como resultado de que dichos documentos
      no cumplan con los requisitos de esas autoridades será su responsabilidad exclusiva. Se requiere una identificación emitida por el
      gobierno para viajar dentro de los EE. UU.
    </p>
    <p>
      Los documentos de viaje, incluidos, sin limitación, boletos de avión, vales de hotel, vales de excursión u otros documentos (ya sea en
      formato electrónico o de otra manera) utilizados para confirmar un arreglo con un proveedor de servicios, pueden estar sujetos a
      ciertas condiciones y/o restricciones, tales como, pero no limitados a, ser no reembolsables, no cambiables de fecha y sujetos a
      tarifas de cancelación y/o enmienda. Los documentos de viaje no pueden ser transferidos para que los use otra persona. Todos los
      boletos de avión deben emitirse a nombre del titular del pasaporte/identificación con foto. Un nombre incorrecto en una reserva puede
      resultar en la imposibilidad de usar esa reserva y la cancelación de la reserva. Cualquier error en nombres, fechas y horarios puede
      resultar en la imposibilidad de usar esa reserva y la cancelación de la reserva. Cualquier error en nombres, fechas y horarios en su
      documentación será su responsabilidad si no se informa en el momento de la reserva.
    </p>
    <p>
      <strong>Confirmación de Vuelo</strong>
      <br />
      Recomendamos encarecidamente que los clientes reconfirmen los horarios exactos de los vuelos con la aerolínea antes de la salida. Los
      viajeros aéreos deben registrarse al menos 2 horas antes de la hora de salida para vuelos nacionales y 3 horas para vuelos
      internacionales y deben presentarse en la puerta al menos 30 minutos antes de la hora de salida. No cumplir con esto puede resultar en
      la pérdida del asiento a favor de otro pasajero.
    </p>
    <p>
      <strong>Salud y Requisitos Especiales</strong>
      <br />
      Es su responsabilidad asegurarse de conocer cualquier requisito de salud para sus destinos de viaje y de llevar toda la documentación
      necesaria de vacunación. Por favor, informe a su consultor de cualquier requisito especial que pueda tener, por ejemplo, comidas
      especiales, requisitos médicos, etc.
    </p>
    <p>
      Vuelos Internacionales Algunos países requieren la fumigación de insecticidas en los aviones antes de un vuelo o mientras se está en
      el avión. La ley federal requiere que lo refiramos al sitio web de desinfección del Departamento de Transporte. Haga clic aquí
    </p>
    <p>
      <strong>Fotografías y Mapas</strong>
      <br />
      Las fotografías de habitaciones se muestran solo con fines informativos y pueden no representar la categoría real de la habitación
      adquirida. Los mapas representados en el sitio se muestran solo con fines informativos y solo muestran ubicaciones aproximadas y
      atracciones y no están destinados a ser exactos en todos los detalles.
    </p>
    <p>
      <strong>Negativa de Servicio</strong>
      <br />
      Los proveedores de servicios se reservan el derecho de negar el servicio a los viajeros a su entera discreción e incluyendo, sin
      limitación, si el viajero:
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>No tiene la documentación adecuada para el país de destino;</li>
      <li>Tiene una enfermedad contagiosa;</li>
      <li>Está bajo la influencia de alcohol, drogas o narcóticos; y/o</li>
      <li>
        Manifiesta comportamiento disruptivo y/o indisciplinado. Bellitours no asume ninguna responsabilidad por los actos del proveedor de
        servicios al negar el servicio.
      </li>
    </Box>
    <p>
      <strong>Política de Privacidad</strong>
      <br />
      Bellitours se compromete a proteger la privacidad y la confidencialidad de su información personal. Nuestra Política de Privacidad
      está disponible para su consulta aquí
    </p>
    <p>
      <strong>Jurisdicción</strong>
      <br />
      Todas las disputas y asuntos que surjan bajo o en relación con estos términos y condiciones se someterán a juicio sin jurado en la
      Corte Federal de Florida. Estos términos y condiciones se interpretarán de acuerdo con las leyes del Estado de Florida.
    </p>
    <p>Bellitours. 15420 SW 136th ST Unit 3, Miami, FL,</p>
  </Box>
);

const TERMS_CONTENT_CONFIG = {
  [Language.EN]: termsContentEn,
  [Language.ES]: termsContentEs,
};

const TermsPage = () => (
  <>
    <Navbar withNavbar />
    {TERMS_CONTENT_CONFIG[language] || termsContentEn}
  </>
);

export default TermsPage;
