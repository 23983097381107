import request from '../../api/api-request';
import { useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import { getCurrency } from '../../helpers/currencies';

const axiosInterceptorListeners: (() => void)[] = [];

export const onSetAxiosInterceptor = (cb: () => void): void => {
  axiosInterceptorListeners.push(cb);
};

// https://dev.to/arianhamdi/react-hooks-in-axios-interceptors-3e1h
const AxiosInterceptor = ({ children }) => {
  const { isLoggedIn, authToken, logout } = useAuth();
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    const requestInterceptor = request.interceptors.request.use(
      (config) => {
        if (isLoggedIn && authToken) {
          config.headers['x-auth-token'] = authToken;
        } else {
          delete config.headers['x-auth-token'];
        }

        config.headers['x-currency'] = getCurrency();

        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = request.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401 && isLoggedIn) {
          logout();
        }

        return Promise.reject(error);
      }
    );

    if (!isSet) {
      setIsSet(true);
      axiosInterceptorListeners.forEach((cb) => {
        cb();
      });
    }

    return () => {
      request.interceptors.request.eject(requestInterceptor);
      request.interceptors.response.eject(responseInterceptor);
    };
  }, [isLoggedIn, authToken]);

  return isSet && children;
};

export default AxiosInterceptor;
