import React from 'react';
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useAuth } from './AuthProvider';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

type GoogleAuthProps = {
  hidden?: boolean;
  autoDisplay?: boolean;
};

const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!;

const GoogleAuth = ({ hidden, autoDisplay }: GoogleAuthProps) => {
  const { isLoggedIn, googleSignin } = useAuth();

  if (isLoggedIn) {
    return null;
  }

  return (
    <Box
      sx={{
        display: hidden ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box>
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
          {/* https://www.npmjs.com/package/@react-oauth/google#api */}
          <GoogleLogin
            width={'100%'}
            theme="filled_blue"
            auto_select={false}
            text="signin_with"
            useOneTap={autoDisplay}
            onSuccess={async (credentialResponse: CredentialResponse) => {
              try {
                await googleSignin({ credentialResponse });
              } catch (err: any) {
                toast.error(err?.response?.data?.message);
              }
            }}
            onError={() => {
              toast.error('Failed to sign in');
            }}
          />
        </GoogleOAuthProvider>
      </Box>
    </Box>
  );
};

export default GoogleAuth;
