import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Link, Slide, SlideProps } from '@mui/material';
import Dialog from '../Dialogs/Dialog';
import { t } from '../../translations';

const COOKIE_CONSENT = 'COOKIE_CONSENT_v1';

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CookieConsent = () => {
  const accepted = localStorage.getItem(COOKIE_CONSENT) === 'true';
  const [renderConsent, setRenderConsent] = useState(false);

  useEffect(() => {
    if (!accepted) {
      setTimeout(() => setRenderConsent(true), 2000);
    }
  }, []);

  const acceptCookieConsent = () => {
    localStorage.setItem(COOKIE_CONSENT, 'true');
  };

  if (accepted || !renderConsent) {
    return null;
  }

  return (
    <Dialog
      startOpen
      withTitle={false}
      preventToClickWithClickOutside
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        TransitionComponent: Transition,
      }}
      renderContent={({ closeDialog }) => (
        <Box sx={{ backgroundColor: 'rgb(238, 239, 243)', margin: -2 }}>
          <Box width={'90%'} sx={{ display: { md: 'flex' }, py: 4, px: 2 }}>
            <Typography fontSize={13}>
              {t.ThisWebsiteUsesCookiesToEnhanceUserExperience}
              <Link href="/privacy">{t.CookiePolicy}</Link>
            </Typography>

            <Button
              sx={{ minWidth: '180px', ml: 4, maxHeight: '80px' }}
              variant="contained"
              onClick={() => {
                acceptCookieConsent();
                closeDialog();
              }}
              color="error"
            >
              {t.AcceptCookies}
            </Button>
          </Box>
        </Box>
      )}
    />
  );
};

export default CookieConsent;
