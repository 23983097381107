import { Language } from '.';

export enum SearchType {
  Country = 'Country',
  Province = 'Province',
  City = 'City',
  Hotel = 'Hotel',
}

export type CountryRecord = {
  code: string;
  country: string;
  countryCode: string;
};

export type ProvinceRecord = CountryRecord & {
  province: string;
  provinceCode: string;
};

export type CityRecord = ProvinceRecord & {
  city: string;
  cityCode: string;
};

export type HotelRecord = CityRecord & {
  hotel: string;
  hotelCode: string;
};

export type Record = CountryRecord | ProvinceRecord | CityRecord | HotelRecord;

export type RecordResult = {
  type: SearchType;
  item: Record;
};

export type SearchResult = {
  hasMore: boolean;
  data: RecordResult[],
}

export type SearchProps = {
  searchText: string;
  typesToSearch?: string;
  searchQuery?: {
    language?: Language;
  };
  filterQuery?: {
    filterHotelsBy?: {
      code: string;
      locationType: string;
    }
  }
  index?: number;
  count?: number;
}
