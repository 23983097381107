import HotelIcon from '@mui/icons-material/Hotel';
import CheckIcon from '@mui/icons-material/Check';
import GppGoodIcon from '@mui/icons-material/GppGood';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import WifiIcon from '@mui/icons-material/Wifi';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import GarageIcon from '@mui/icons-material/Garage';
import ElevatorIcon from '@mui/icons-material/Elevator';
import MoneyIcon from '@mui/icons-material/Money';
import CarRentalIcon from '@mui/icons-material/CarRental';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CribIcon from '@mui/icons-material/Crib';
import KitchenIcon from '@mui/icons-material/Kitchen';
import HotTubIcon from '@mui/icons-material/HotTub';
import AccessibleIcon from '@mui/icons-material/Accessible';
import ImageIcon from '@mui/icons-material/Image';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HikingIcon from '@mui/icons-material/Hiking';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SportsGolfIcon from '@mui/icons-material/SportsGolf';
import WavesIcon from '@mui/icons-material/Waves';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import KayakingIcon from '@mui/icons-material/Kayaking';
import SpaIcon from '@mui/icons-material/Spa';
import AttractionsIcon from '@mui/icons-material/Attractions';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import LightModeIcon from '@mui/icons-material/LightMode';
import CasinoIcon from '@mui/icons-material/Casino';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import VideocamIcon from '@mui/icons-material/Videocam';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BusinessIcon from '@mui/icons-material/Business';
import DeckIcon from '@mui/icons-material/Deck';
import PoolIcon from '@mui/icons-material/Pool';
import SecurityIcon from '@mui/icons-material/Security';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import StrollerIcon from '@mui/icons-material/Stroller';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import PetsIcon from '@mui/icons-material/Pets';
import AlbumIcon from '@mui/icons-material/Album';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import DryIcon from '@mui/icons-material/Dry';
import RadioIcon from '@mui/icons-material/Radio';
import LockIcon from '@mui/icons-material/Lock';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import DeskIcon from '@mui/icons-material/Desk';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import CountertopsIcon from '@mui/icons-material/Countertops';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import IronIcon from '@mui/icons-material/Iron';
import BathroomIcon from '@mui/icons-material/Bathroom';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import FaxIcon from '@mui/icons-material/Fax';
import RedeemIcon from '@mui/icons-material/Redeem';

export const FEATURED_ICONS = {
  hotel: HotelIcon,
  apartment: HomeWorkIcon,
  apartamentos: HomeWorkIcon,
  reimbursable: CheckIcon,
  certificated: GppGoodIcon,
  default: ImageIcon,
};

export const HOTEL_SERVICES_ICONS = {
  1: AccessibleIcon, // |Access for Disabled|
  2: AcUnitIcon, // |Air Conditioned|
  3: HeatPumpIcon, // |Heating|
  4: null, // |Chapel|
  5: LocalPrintshopIcon, // |Fax|
  6: KayakingIcon, // |Kayak|
  7: CasinoIcon, // |Casino|
  8: CasinoIcon, // |Bingo|
  9: LightModeIcon, // |Discotheque|
  10: DirectionsBikeIcon, // |Bike Rental|
  11: CarRentalIcon, // |Rent A Car|
  12: AttractionsIcon, // |Entertainment|
  13: FitnessCenterIcon, // |Gym|
  14: SpaIcon, // |Health Resort|
  15: null, // |Turkish Bath|
  16: WavesIcon, // |Sauna|
  17: KayakingIcon, // |Paddle|
  18: SportsTennisIcon, // |Tennis|
  19: BedroomBabyIcon, // |Horse riding|
  20: HotTubIcon, // |Jacuzzi|
  21: SportsGolfIcon, // |Golf|
  22: SportsGolfIcon, // |Mini-Golf|
  23: HikingIcon, // |Adventure Sports|
  24: null, // |Bell Boy|
  25: MoneyIcon, // |Safe Box|
  26: ElevatorIcon, // |Elevator|
  27: GarageIcon, // |Garage|
  28: LocalParkingIcon, // |Outdoor Parking|
  29: LocalParkingIcon, // |Coach Parking|
  30: LocalParkingIcon, // |Free Parking|
  31: CarCrashIcon, // |Pay Parking|
  32: LocalBarIcon, // |Bar/CafÃ© (24h)|
  33: LocalBarIcon, // |Bar/Snack/CafÃ©|
  34: LocalBarIcon, // |Piano Bar|
  35: RestaurantIcon, // |Restaurant|
  36: RoomServiceIcon, // |Partial Room Service|
  37: RoomServiceIcon, // |Room Service (24h)|
  38: ConnectWithoutContactIcon, // |Social Meeting Rooms|
  39: LiveTvIcon, // |TV SalÃ³n|
  40: VideocamIcon, // |Video-Club Service|
  41: ShoppingCartIcon, // |Shopping|
  42: CurrencyExchangeIcon, // |Currency Exchange|
  43: BusinessIcon, // |Business Centre|
  44: BusinessIcon, // |Business Floor|
  45: DeckIcon, // |Garden Area|
  46: PoolIcon, // |Indoor Swimming pool|
  47: PoolIcon, // |Outdoor Swimming pool|
  48: PoolIcon, // |Children Swimming pool|
  49: SecurityIcon, // |24h Security|
  50: StorefrontIcon, // |Shops|
  51: null, // |News Stand|
  52: LocalFloristIcon, // |Flower Shop|
  53: StrollerIcon, // |Baby Sitting|
  54: null, // |Hairdresser|
  55: LocalHospitalIcon, // |Doctor Service|
  56: LocalLaundryServiceIcon, // |Laundry Service|
  57: AirportShuttleIcon, // |Free Shuttle Service|
  58: AirportShuttleIcon, // |Pay Shuttle Service|
  59: BakeryDiningIcon, // |Contintental Breakfast|
  60: EmojiFoodBeverageIcon, // |Buffet Breakfast|
  61: AccessibleIcon, // |Access for Disabled|
  62: WifiPasswordIcon, // |Wi-Fi|
  63: HotTubIcon, // |Spa|
  64: WifiIcon, // |Free Wi-Fi|
  65: LocalConvenienceStoreIcon, // |24H RECEPTION*|
  66: PoolIcon, // |SWIMMING POOL|
  default: ImageIcon,
};

export const ROOM_SERVICES_ICONS = {
  1: AcUnitIcon, // |Aire acondicionado|
  2: HeatPumpIcon, // |CalefacciÃ³n|
  3: RedeemIcon, // |Productos de acogida|
  4: SensorDoorIcon, // |Cerradura magnÃ©tica|
  5: AccessibleIcon, // |MinusvÃ¡lidos|
  6: null, // |AlÃ©rgicos|
  7: PetsIcon, // |Perros|
  8: PetsIcon, // |Otros animales|
  9: LiveTvIcon, // |TV|
  10: LiveTvIcon, // |Pay TV|
  11: ConnectedTvIcon, // |TV SatÃ©lite|
  12: null, // |Canal Plus|
  13: null, // |TV interactiva|
  14: AlbumIcon, // |VÃ­deo/DVD|
  15: LocalPhoneIcon, // |TelÃ©fono|
  16: null, // |TelÃ©fono baÃ±o|
  17: CribIcon, // |Cunas|
  18: null, // |Habitaciones conectadas|
  19: null, // |Otros|
  20: FireplaceIcon, // |Chimenea|
  21: CountertopsIcon, // |Kitchenette|
  22: DryIcon, // |Secador|
  23: KitchenIcon, // |Minibar|
  24: null, // |Hilo musical|
  25: RadioIcon, // |Radio|
  26: LockIcon, // |Caja fuerte|
  27: null, // |Hi-fi|
  28: SurroundSoundIcon, // |InsonorizaciÃ³n|
  29: FaxIcon, // |ConexiÃ³n Fax|
  30: null, // |ConexiÃ³n ADSL|
  31: null, // |2 toma P.C.|
  32: null, // |Con. LÃ­nea Telf. EstÃ¡ndar|
  33: VoicemailIcon, // |BuzÃ³n de voz|
  34: null, // |Prensa diaria|
  35: DeskIcon, // |Mesa de trabajo|
  36: LocalCafeIcon, // |TÃ© y cafÃ©|
  37: null, // |Voltaje|
  38: ConnectedTvIcon, // |Web T.V.|
  39: KitchenIcon, // |MiniBar Gratuito|
  40: CellWifiIcon, // |Wi-Fi|
  41: ConnectedTvIcon, // |TV por cable*|
  42: null, // |Conserje*|
  43: DeckIcon, // |Terraza*|
  44: KitchenIcon, // |Nevera*|
  45: CountertopsIcon, // |Cocina|
  46: IronIcon, // |Tabla de planchar*|
  47: DeckIcon, // |BalcÃ³n|
  48: PlaylistAddCheckCircleIcon, // |Aparato de DVD*|
  49: WbTwilightIcon, // |Vistas al mar|
  50: LocalLaundryServiceIcon, // |Lavadora*|
  51: IronIcon, // |Plancha*|
  52: MicrowaveIcon, // |Microondas*|
  53: BathroomIcon, // |Cuarto de baÃ±o|
  54: TapAndPlayIcon, // |Wifi Gratis|
  default: ImageIcon,
};
