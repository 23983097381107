import AdminAPI from '../../api/admin';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { t } from '../../translations';
import { z } from 'zod';
import { FormComponentType, getEditorComponent } from '../../components/CRUDAdmin/edit';
import { DataGridColumn, getListComponent } from '../../components/CRUDAdmin/list';
import { AdjustmentType, Language } from '../../types';

enum FeeType {
  Markup = 'Markup',
  Expense = 'Expense',
}

const columns: DataGridColumn<Fee>[] = [
  {
    field: 'feeType',
    headerName: t.Type,
    width: 120,
  },
  {
    field: 'chargeType',
    headerName: t.Unit,
    width: 120,
  },
  {
    field: 'amount',
    headerName: t.Amount,
    width: 120,
  },
  {
    field: 'text',
    headerName: t.Description,
    width: 230,
    renderCell: ({ row: fee }) => (
      <Box>
        <Typography variant="body1">{fee.text}</Typography>
        {Object.values(Language).map((lang) => (
          <Typography key={lang} variant="body2">
            {lang} - {fee?.textPerLanguage?.[lang]}
          </Typography>
        ))}
      </Box>
    ),
  },
];

const FeeSchema = z.object({
  text: z.string().trim(),
  feeType: z.nativeEnum(FeeType),
  chargeType: z.nativeEnum(AdjustmentType),
  amount: z.coerce.number().gt(0),
  textPerLanguage: z.record(z.nativeEnum(Language), z.string()).optional(),
});

type Fee = z.infer<typeof FeeSchema>;

const FeeForm: FormComponentType = ({ textInput, selectInput }) => {
  return (
    <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative' }}>
      <CardContent>
        <Box mb={1}>{textInput('text', { label: t.Text })}</Box>
        <Box>
          {Object.values(Language).map((lang) => (
            <Box key={lang} mb={1}>
              {textInput(`textPerLanguage.${lang}`, { label: `${lang} - ${t.Text}` })}
            </Box>
          ))}
        </Box>

        <Box mt={3} mb={3}>
          {selectInput('feeType', Object.values(FeeType), {
            label: t.FeeType,
          })}
        </Box>

        <Box mb={3}>
          {selectInput('chargeType', Object.values(AdjustmentType), {
            label: t.ChargeType,
          })}
        </Box>

        <Box mb={3}>
          <Box mb={3}>{textInput('amount', { label: t.Amount, type: 'number' })}</Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const baseProps = {
  name: t.Fees,
  baseRoute: 'fees',
  schema: FeeSchema,
  FormComponent: FeeForm,
};

export const ListFees = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  dataLoader: AdminAPI.fees.getAll,
  destroy: AdminAPI.fees.delete,
});

export const CreateFees = getEditorComponent({
  ...baseProps,
  dataLoader: () => Promise.resolve<Partial<Fee>>({
    textPerLanguage: {},
  }),
  save: AdminAPI.fees.create,
});

export const EditFees = getEditorComponent({
  ...baseProps,
  dataLoader: (params) => AdminAPI.fees.find(params.id),
  save: (props) => AdminAPI.fees.update(props._id, props),
});
