import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Image from 'mui-image';
import { Box } from '@mui/material';
import { useState } from 'react';

export type CarrouselProps = {
  images: string[];
  imageProps?: Partial<React.ComponentProps<typeof Image>>;
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <Box
      className={className}
      style={{
        ...style,
        right: 0,
        zIndex: 1,
        display: 'none',
        width: '25px',
        transform: 'translate(0, -50%) scale(1.3)',
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        left: 0,
        zIndex: 1,
        display: 'none',
        width: '0',
        transform: 'translate(0, -50%) scale(1.5)',
      }}
      onClick={onClick}
    />
  );
}

const Carrousel = ({ images, imageProps }: CarrouselProps) => {
  const [nextImage, setNextImage] = useState<string>(images[1]);

  return (
    <Box
      sx={{
        position: 'relative',
        '&:hover .slick-prev, &:hover .slick-next': {
          display: 'block !important', // Show arrows on hover
        },
      }}
    >
      {nextImage && (
        <Box sx={{ display: 'none' }}>
          <Image src={nextImage} />
        </Box>
      )}
      <Slider
        lazyLoad
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
        afterChange={(index) => {
          setNextImage(images[index + 1]);
        }}
      >
        {images.map((imageUrl) => (
          <Image key={imageUrl} src={imageUrl} duration={0} width="100%" height="100%" {...imageProps} />
        ))}
      </Slider>
    </Box>
  );
};

export default Carrousel;
