import 'react-international-phone/style.css';
import { InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { defaultCountries, FlagEmoji, parseCountry, usePhoneInput } from 'react-international-phone';
import { useEffect } from 'react';
import getIPInfo from '../../api/ip-info';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { t } from '../../translations';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error: any) {
    return false;
  }
};

const PhoneInput = ({ label, displayValidationErrors, value, onChange, onValidationChange, ...restProps }) => {
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'us',
    value: value || '',
    countries: defaultCountries,
    onChange: (data) => {
      onChange?.(data.phone);
      onValidationChange?.(isPhoneValid(data.phone));
    },
  });

  const isValid = isPhoneValid(phone);
  const showError = displayValidationErrors && !isValid;

  useEffect(() => {
    onChange?.(phone);
    onValidationChange?.(isValid);
    getIPInfo().then((ipInfo) => setCountry(ipInfo.country?.toLowerCase() || 'us'));
  }, []);

  return (
    <TextField
      variant="outlined"
      label={label || t.PhoneNumber}
      color="primary"
      placeholder={t.PhoneNumber}
      value={phone}
      onChange={handlePhoneValueChange}
      helperText={showError && t.InvalidPhoneNumber}
      error={showError}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px' }}>
            <Select
              MenuProps={{
                style: {
                  height: '300px',
                  width: '360px',
                  top: '10px',
                  left: '-34px',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                width: 'max-content',
                // Remove default outline (display only on focus)
                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                // Update default spacing
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              renderValue={(value) => <FlagEmoji iso2={value} style={{ display: 'flex' }} />}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagEmoji iso2={country.iso2} style={{ marginRight: '8px' }} />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};

export default PhoneInput;
