import { Box, Typography } from '@mui/material';

const AuthMessageInfo = ({ title, message }: { title: string; message?: string }) => {
  return (
    <Box>
      <Typography variant="h5" mb={4} textAlign="center" fontWeight="bold">
        {title}
      </Typography>

      <Typography variant="body1" mb={4} textAlign="center">
        {message}
      </Typography>
    </Box>
  );
};

export default AuthMessageInfo;
