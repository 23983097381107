import { ZodEffects, ZodObject, ZodRawShape } from 'zod';

export const getSchemaErrors = <T, K extends ZodRawShape>(data, schema: ZodObject<K> | ZodEffects<any, any, any>): [boolean, { [key in keyof T]: string; }] => {
  const errors: { [key in keyof T]: string } = Object.fromEntries(Object.keys(data as any).map((key) => [key, ''])) as any;

  let hasError = false;
  const result = schema.safeParse(data);
  result.error?.errors?.forEach((error) => {
    if (error.path[0]) {
      errors[error.path.join('.')] = error.message;
      hasError = true;
    }
  });

  return [hasError, errors];
};
