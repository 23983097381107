import { Box, Typography } from '@mui/material';
import { z } from 'zod';
import { t } from '../../translations';
import { Gender } from '../../types/auth';
import { useAuth } from '../auth/AuthProvider';
import Grid from '@mui/material/Unstable_Grid2';
import { formatToDate } from '../../helpers/date-time';

const ProfileSchema = z.object({
  fullName: z.string().trim().min(3, t.MinLengthIs(3)).optional(),
  bio: z.string().trim().optional(),
  gender: z.nativeEnum(Gender).optional(),
  phoneNumber: z.string().optional(),
  dateOfBirth: z.date().optional(),
});

export type Profile = z.infer<typeof ProfileSchema>;

const ProfileItem = ({ title, value }: { title: string; value: string | undefined }) => {
  return (
    <Box sx={{mb: 2}}>
      <Box>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box>
        <Typography variant="body1">{value || t.NotProvided}</Typography>
      </Box>
    </Box>
  );
};

const ProfilePage = () => {
  const { currentUser } = useAuth();

  // TODO: allow to edit profile

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6}>
        <ProfileItem title={t.Name} value={currentUser?.fullName} />
        <ProfileItem title={t.Email} value={currentUser?.email} />
      </Grid>
      {/* <Grid xs={12} md={6}>
        <ProfileItem title={t.Gender} value={currentUser?.gender ? t.GetGender(currentUser.gender) : undefined} />
        <ProfileItem title={t.DateOfBirth} value={currentUser?.dateOfBirth ? formatToDate(currentUser.dateOfBirth) : undefined} />
      </Grid>
      <Grid xs={12} sx={{mt: -2}}>
        <ProfileItem title={t.Bio} value={currentUser?.bio} />
      </Grid> */}
    </Grid>
  );
};

export default ProfilePage;
