import { Container } from '@mui/material';
import { useRouteError } from 'react-router-dom';
import ErrorMsg from '../components/ErrorMsg';
import { useEffect } from 'react';
import { reportClientError } from '../api/client-errors';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  useEffect(() => {
    reportClientError(error)
  }, [error])

  return (
    <Container id="error-page">
      <ErrorMsg />
    </Container>
  );
}
