import { useState, useEffect, useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LanguageIcon from '@mui/icons-material/Language';
import { t } from '../../translations';
import { API } from '../../api/home-api';
import { v4 as id } from 'uuid';
import { Link } from '@mui/material';
import { CityRecord, CountryRecord, HotelRecord, ProvinceRecord, RecordResult, SearchProps, SearchType } from '../../types/search';

const SEARCH_DEBOUNCE_MS = 400;
const MIN_SEARCH_TEXT = 3;
const MAX_RESULTS = 100;

type Label = { primary: string; secondary?: string; main?: string };

type RecordResultWithLabel = { recordResult: RecordResult; label: Label };

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontSize: '15px',
  },
}));

const ICON_CONFIG = {
  [SearchType.City]: LocationOnIcon,
  [SearchType.Province]: LocationOnIcon,
  [SearchType.Country]: LanguageIcon,
  [SearchType.Hotel]: ApartmentIcon,
};

const LABEL_CONFIG: { [key in SearchType]: (recordResult: RecordResult) => Label } = {
  [SearchType.City]: (recordResult) => {
    const cityRecord = recordResult.item as CityRecord;

    return {
      primary: cityRecord.city,
      secondary: [cityRecord.province, cityRecord.country].filter(Boolean).join(', '),
    };
  },
  [SearchType.Province]: (recordResult) => {
    const provinceRecord = recordResult.item as ProvinceRecord;

    return {
      primary: provinceRecord.province,
      secondary: [provinceRecord.country].filter(Boolean).join(', '),
    };
  },
  [SearchType.Country]: (recordResult) => ({
    primary: (recordResult.item as CountryRecord).country,
  }),
  [SearchType.Hotel]: (recordResult) => {
    const hotelRecord = recordResult.item as HotelRecord;

    return {
      primary: hotelRecord.hotel,
      secondary: [hotelRecord.province, hotelRecord.country].filter(Boolean).join(', '),
    };
  },
};

export const sourceWithLabel = (recordResult: RecordResult | undefined): RecordResultWithLabel | null => {
  if (!recordResult) {
    return null;
  }

  const labelConfig = LABEL_CONFIG[recordResult.type];
  if (!labelConfig) {
    return null;
  }

  const label = labelConfig(recordResult);
  if (!label.main) {
    label.main = [label.primary, label.secondary].filter(Boolean).join(', ');
  }

  return { recordResult, label };
};

const LOAD_MORE_OPT: RecordResultWithLabel = {
  label: { primary: 'load more' },
  // dummy record
  recordResult: {} as any,
};

const getDisplayValue = (value: RecordResultWithLabel | string | null | undefined) =>
  typeof value === 'string' ? value : value?.label?.main || '';

export type SearchInputProps = {
  initialValue?: RecordResult;
  required?: boolean;
  displayValidationErrors?: boolean;
  errorLabel?: string;
  noLocationsLabel?: string;
  searchLocationLabel?: string;
  placeholder?: string;
  type?: SearchType[];
  filterQuery?: SearchProps['filterQuery'],
  minSearchText?: number,
  onChange?: (value: RecordResult | undefined) => void;
};

const SearchInput = ({
  initialValue: initialValueParam,
  required,
  displayValidationErrors,
  errorLabel,
  noLocationsLabel,
  searchLocationLabel,
  placeholder,
  type,
  filterQuery,
  minSearchText = MIN_SEARCH_TEXT,
  onChange,
}: SearchInputProps) => {
  const [initialValue] = useState(sourceWithLabel(initialValueParam) || undefined);
  const [value, setValue] = useState(initialValue);
  const [textInputValue, setTextInputValue] = useState(initialValue?.label?.main || '');
  const [searching, setSearching] = useState(false);
  const [options, setOptions] = useState<RecordResultWithLabel[]>([]);
  const [hideError, setHideError] = useState(false);

  const classes = useStyles();
  const inputRef = useRef<any>();

  const [paging, setPaging] = useState({ index: 0, hasMore: false });

  const searchIdRef = useRef<string>('');

  const search = (index: number) => {
    const searchId = id();

    searchIdRef.current = searchId;
    setSearching(true);
    if (index === 0) {
      setPaging({
        index: 0,
        hasMore: false,
      });
    }

    API.searcher
      .search({
        searchText: textInputValue.trim(),
        type,
        count: MAX_RESULTS,
        index,
        filterQuery,
      })
      .then((result) => {
        if (searchId !== searchIdRef.current) {
          return;
        }

        const newOptions = result.data.map(sourceWithLabel).filter((x) => x !== null) as RecordResultWithLabel[];

        setOptions((currentOptions) => (index === 0 ? newOptions : [...currentOptions, ...newOptions]));
        setSearching(false);
        setPaging({
          index: index + result.data.length + 1,
          hasMore: result.hasMore,
        });
      });
  };

  const searchLocations = useMemo(() => debounce(() => search(0), SEARCH_DEBOUNCE_MS), [textInputValue]);

  useEffect(() => {
    if ((textInputValue?.length || 0) < minSearchText) {
      setPaging({
        hasMore: false,
        index: 0,
      });
      return;
    }

    searchLocations();

    return () => {
      setSearching(false);
      searchLocations.cancel();
    };
  }, [textInputValue?.trim()]);

  useEffect(() => {
    onChange?.(value?.recordResult);
  }, [value]);

  const error = required && !value && displayValidationErrors && !hideError ? errorLabel || t.DestinationRequired : undefined;

  return (
    <Box>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
        <SearchIcon sx={{ fontSize: 24, marginRight: '-10px' }} />
        <Autocomplete
          // disablePortal
          fullWidth
          openOnFocus
          sx={{ width: '100%' }}
          size="small"
          classes={classes}
          getOptionLabel={getDisplayValue}
          filterOptions={(x) => x}
          options={[...options, ...(paging.hasMore ? [LOAD_MORE_OPT] : [])]}
          // freeSolo
          // disableClearable
          autoComplete
          value={value || null}
          noOptionsText={
            textInputValue?.length >= minSearchText
              ? searching
                ? `${t.searching}...`
                : noLocationsLabel || t.NoResults
              : searchLocationLabel || t.SearchADestinationsAndHotels
          }
          onChange={(event, newValue) => setValue(newValue || undefined)}
          inputValue={textInputValue}
          onInputChange={(event, newInputValue) => {
            setTextInputValue(newInputValue);
            if (!newInputValue || newInputValue.length < minSearchText) {
              setOptions([]);
            }
          }}
          isOptionEqualToValue={(option, value) => {
            const optionCode = option.recordResult?.item?.code;

            return optionCode === value?.recordResult?.item?.code;
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              value={value}
              fullWidth
              // placeholder='Location'
              inputRef={inputRef}
              label={placeholder || t.Location}
              onFocus={() => setHideError(true)}
              onBlur={() => setHideError(false)}
              InputProps={
                {
                  ...params.InputProps,
                  shrink: 'true',
                  sx: {
                    ...params.InputProps.sx,
                    fontWeight: 'bold',
                    fontSize: '13.7px !important',
                    marginBottom: value ? '-15px !important' : 0,
                    // color: 'gray',
                    // '& ::placeholder': {
                    //   fontWeight: 'bold',
                    //   fontSize: 12,
                    //   opacity: 1, /* Firefox */
                    // },

                    '& .MuiInputLabel-formControl': {
                      fontSize: '16px !important',
                    },
                  },
                } as any
              }
              sx={{
                width: '100%',
                '& fieldset': {
                  border: 'none',
                },
                '& .MuiInputLabel-formControl': {
                  color: 'gray !important',
                  fontWeight: 'bold !important',
                  fontSize: '13.7px',
                },
                '& .MuiAutocomplete-endAdornment': {
                  marginTop: value ? '-6px' : 0,
                },
                '& . MuiInputLabel-shrink': {
                  color: 'red !important',
                  transform: 'translate(14px, -9px) scale(1)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  fontSize: '12px',
                },
                '& .MuiInputBase-fullWidth': {
                  paddingRight: '20px !important',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  display: 'none',
                },
              }}
              // fullWidth
            />
          )}
          renderOption={(props, option) => {
            const Icon = ICON_CONFIG[option.recordResult.type as SearchType] || LocationOnIcon;

            if (option === LOAD_MORE_OPT) {
              return (
                <li
                  {...props}
                  key="load-more"
                  onClick={() => {
                    if (!searching) {
                      search(paging.index);
                    }
                  }}
                >
                  <Link sx={{ display: 'inline-block', textDecoration: 'none' }}>{searching ? t.Searching : t.SearchMore}</Link>
                </li>
              );
            }

            return (
              <li
                {...props}
                key={option.recordResult.item.code}
                onClick={(ev) => {
                  // close(ev);
                  props.onClick?.(ev);
                }}
              >
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <Icon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    <Box component="span" sx={{ fontWeight: 'regular' }}>
                      {option.label.primary}
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {option.label.secondary}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </Stack>
      {error && (
        <Typography variant="caption" color="error" sx={{ display: 'block', fontSize: 11, mt: '-7px' }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default SearchInput;
