import { useState } from 'react';
import { useWithDataLoader } from '../../hooks/use-data-loader';
import { Box, Button, FormControlLabel, FormControlLabelProps, MenuItem, Switch, TextField, TextFieldProps } from '@mui/material';
import { t } from '../../translations';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSchemaErrors } from '../../helpers/validations';
import { get, isString, set } from 'lodash';
import { DateTimePicker, DateTimePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ImagePicker } from '../Inputs/ImagePicker';
import { Model } from '../../api/admin';

export type FormComponentType<T = any> = (props: {
  data: T;
  errors: any;
  showErrors: boolean;
  editable: boolean;
  setData: (newData: T | ((currentData: T) => T)) => void;
  onChange: (arg: any) => void;
  onInputChange: (prop: string) => (ev: { target: { value: string } }) => void;
  onCheckedChange: (prop: string) => (ev: { target: { checked: boolean } }) => void;
  textInput: (prop: string, props: TextFieldProps) => JSX.Element;
  booleanInput: (prop: string, props: Omit<FormControlLabelProps, 'control'>) => JSX.Element;
  selectInput: (prop: string, menuItems: (JSX.Element | string)[], props: TextFieldProps) => JSX.Element;
  dateTimeInput: (prop: string, props: DateTimePickerProps<any>) => JSX.Element;
  imageInput: (prop: string) => JSX.Element;
}) => JSX.Element;

type getEditorComponentProps<T = any> = {
  name: string;
  baseRoute: string;
  dataLoader: Parameters<typeof useWithDataLoader>[0];
  schema: Parameters<typeof getSchemaErrors>[1];
  FormComponent: FormComponentType;
  save: (record: Partial<Model<T>>) => Promise<Model<T>>;
};

export const getEditorComponent = function <T = any>({ baseRoute, dataLoader, schema, FormComponent, save }: getEditorComponentProps<T>) {
  return () => {
    const navigate = useNavigate();

    const { data, setData, isOk, message: loadMessage } = useWithDataLoader(dataLoader);
    const [showErrors, setShowErrors] = useState(false);
    const [saving, setSaving] = useState(false);

    const editable = Boolean(save);
    const [hasError, errors] = data ? getSchemaErrors(data, schema) : [false, {}];

    const onChange = (arg: Parameters<typeof setData>[0]) => setData(arg);

    const onInputChange =
      (propName, targetProp: 'value' | 'checked' = 'value') =>
      (ev) =>
        setData((currentData) => {
          const result = { ...currentData };
          set(result, propName, ev.target[targetProp]);
          return result;
        });

    const onCheckedChange = (propName) => (ev) =>
      setData((currentData) => ({
        ...currentData,
        [propName]: ev.target.checked,
      }));

    const goToList = () => navigate(`/admin/${baseRoute}`);

    const cancelForm = goToList;

    const saveForm = async (data) => {
      setShowErrors(true);

      if (hasError) {
        toast.error('Error');
        return;
      }

      setSaving(true);

      try {
        await save(data);
        goToList();
        return;
      } catch (err: any) {
        toast.error(`${t.Error}. ${err?.response?.data?.message}`);
      }

      setSaving(false);
    };

    if (!isOk) {
      return loadMessage;
    }

    return (
      <Box>
        {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
        <FormComponent
          data={data}
          errors={errors}
          showErrors={showErrors}
          editable={editable}
          setData={setData}
          onChange={onChange}
          onInputChange={onInputChange}
          onCheckedChange={onCheckedChange}
          textInput={(prop, opts) => (
            <TextField
              fullWidth
              label={`${t.Text}*`}
              value={get(data, prop) || ''}
              error={!!(showErrors && get(errors, prop))}
              onChange={onInputChange(prop)}
              helperText={showErrors && get(errors, prop)}
              {...opts}
              InputProps={{
                readOnly: !editable,
                ...opts?.InputProps,
              }}
            />
          )}
          booleanInput={(prop, opts) => (
            <FormControlLabel {...opts} control={<Switch checked={get(data, prop)} onChange={onInputChange(prop, 'checked')} />} />
          )}
          selectInput={(prop, menuItems, opts) => (
            <TextField
              select
              fullWidth
              value={get(data, prop) || ''}
              error={!!(showErrors && get(errors, prop))}
              helperText={showErrors && get(errors, prop)}
              onChange={onInputChange(prop)}
              {...opts}
              InputProps={{
                readOnly: !editable,
                ...opts?.InputProps,
              }}
            >
              {menuItems.map((value) =>
                isString(value) ? (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ) : (
                  value
                )
              )}
            </TextField>
          )}
          dateTimeInput={(prop, opts) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                sx={{ minWidth: 300 }}
                value={get(data, prop)}
                onChange={(value) => onInputChange(prop)({ target: { value } })}
                slotProps={{
                  textField: {
                    error: !!(showErrors && get(errors, prop)),
                    helperText: showErrors && get(errors, prop),
                  },
                }}
              />
            </LocalizationProvider>
          )}
          imageInput={(prop) => (
            <ImagePicker
              image={get(data, prop)}
              editable={editable}
              error={!!(showErrors && get(errors, prop))}
              helperText={showErrors && get(errors, prop)}
              onChangeFile={(file) => onInputChange(prop)({ target: { value: file } })}
            />
          )}
        />
        {editable && (
          <Box mt={2}>
            <Button size="small" variant="outlined" disabled={saving} sx={{ mr: 1 }} onClick={cancelForm}>
              {t.Cancel}
            </Button>
            <Button size="small" variant="contained" disabled={saving} onClick={() => saveForm(data)}>
              {saving ? t.Saving + '...' : t.Save}
            </Button>
          </Box>
        )}
      </Box>
    );
  };
};
