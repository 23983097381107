import {
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  isBefore,
  addDays,
  isSameDay,
  isWithinInterval,
  toDate,
  parseISO,
  isValid,
  addWeeks,
  addMonths,
} from 'date-fns';
import { t } from '../../translations';

export const identity = (x) => x;

export const chunks = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (v, i) => array.slice(i * size, i * size + size));
};

export const combine = (...args) => args.filter(identity).join(' ');

// Date
export const getDaysInMonth = (date) => {
  const startWeek = startOfWeek(startOfMonth(date));
  const endWeek = endOfWeek(endOfMonth(date));
  const days: any[] = [];
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};

export const isStartOfRange = ({ startDate }, day) => startDate && isSameDay(day, startDate);

export const isEndOfRange = ({ endDate }, day) => endDate && isSameDay(day, endDate);

export const inDateRange = ({ startDate, endDate }, day) =>
  (startDate &&
    endDate &&
    isWithinInterval(day, {
      start: startDate,
      end: endDate,
    })) ||
  isSameDay(day, startDate) ||
  isSameDay(day, endDate);

export const isRangeSameDay = ({ startDate, endDate }) => {
  if (startDate && endDate) {
    return isSameDay(startDate, endDate);
  }
  return false;
};

export const parseOptionalDate = (date, defaultValue) => {
  if (date instanceof Date) {
    const parsed = toDate(date);
    if (isValid(parsed)) return parsed;
  }

  if (date instanceof String || typeof date === 'string') {
    const parsed = parseISO(date as string);
    if (isValid(parsed)) return parsed;
  }

  return defaultValue;
};

const getDefaultRanges = (date) => [
  {
    label: t.Today,
    startDate: date,
    endDate: date,
  },
  {
    label: t.Yesterday,
    startDate: addDays(date, -1),
    endDate: addDays(date, -1),
  },
  {
    label: t.ThisWeek,
    startDate: startOfWeek(date),
    endDate: endOfWeek(date),
  },
  {
    label: t.LastWeek,
    startDate: startOfWeek(addWeeks(date, -1)),
    endDate: endOfWeek(addWeeks(date, -1)),
  },
  {
    label: t.Last7Days,
    startDate: addWeeks(date, -1),
    endDate: date,
  },
  {
    label: t.ThisMonth,
    startDate: startOfMonth(date),
    endDate: endOfMonth(date),
  },
  {
    label: t.LastMonth,
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(date, -1)),
  },
];

export const defaultRanges = getDefaultRanges(new Date());
