import { useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import { t } from '../../translations';
import { useParams } from 'react-router-dom';
import { TokenActionResult } from '../../types/auth';
import AuthMessageInfo from './components/AuthMessageInfo';
import { SigninOrSignupLink } from './components/MessageWithLink';
import { Box } from '@mui/material';

const EmailVerificationPage = () => {
  const params = useParams<{ token: string }>();
  const { verifyEmailWithToken } = useAuth();

  const [verifying, setVerifying] = useState(true);
  const [tokenResult, setTokenResult] = useState<TokenActionResult | undefined>(undefined);

  useEffect(() => {
    if (!params.token) {
      setVerifying(false);
      return;
    }

    verifyEmailWithToken(params.token).then((newTokenResult) => {
      setVerifying(false);
      setTokenResult(newTokenResult);
    });
  }, []);

  if (verifying) {
    return <AuthMessageInfo title={`${t.VerifyingEmail}...`} />;
  }

  if (!tokenResult || tokenResult === TokenActionResult.TokenInvalid) {
    return <AuthMessageInfo title={t.InvalidLink} message={t.TheVerificationLinkYouUsedIsInvalid} />;
  }

  if (!tokenResult || tokenResult === TokenActionResult.TokenExpired) {
    return <AuthMessageInfo title={t.LinkExpired} message={t.TheVerificationLinkHasExpired} />;
  }

  if (tokenResult === TokenActionResult.AlreadyVerified) {
    return (
      <Box>
        <AuthMessageInfo title={t.EmailValidatedSuccessfully} message={t.YourAccountHasAlreadyBeenVerified} />
        <SigninOrSignupLink signin />
      </Box>
    );
  }

  if (tokenResult === TokenActionResult.Success) {
    return (
      <Box>
        <AuthMessageInfo title={t.EmailValidatedSuccessfully} message={t.YourEmailHasBeenSuccessfullyVerified} />
        <SigninOrSignupLink signin />
      </Box>
    );
  }

  return null;
};

export default EmailVerificationPage;
