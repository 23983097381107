import { useState } from 'react';
import { Box, Button, Chip, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CheckIcon from '@mui/icons-material/Check';
import { COLOR } from '../../helpers/styles';
import Popover from '../Popover/Popover';
import { money } from '../../helpers/utils';
import { sortBy } from 'lodash';
import { t } from '../../translations';

const getSortedPackages = (pax) => {
  let allPackages: any[] = [];
  pax.rooms.forEach((room) => {
    room.packages.forEach((pkg) => {
      allPackages.push({ pax, room, pkg });
    });
  });

  return sortBy(allPackages, ({ pkg }) => Number(pkg.finalPrice));
};

const HotelPaxSelect = (props) => {
  const [selectedPackages, setSelectedPackages] = useState<any[]>([]); // { pax, room, pkg }
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);

  props.onSetCleanupSelectedPackage?.(() => {
    setSelectedPackages([]);
    setDisplayValidationErrors(false);
    props.onChange?.({ packages: [], completed: false });
  });

  const errorStyles = { border: '2px solid', borderColor: 'error.main' };

  const { roomsPerPaxes } = props;

  const selectedPackagesPerPaxes = selectedPackages.reduce((acc, { pax }) => {
    acc[pax.pax] = (acc[pax.pax] || 0) + 1;
    return acc;
  }, {});

  return (
    <>
      {props.availability.paxes.map((pax) => {
        const paxHasError = selectedPackagesPerPaxes[pax.pax] !== roomsPerPaxes[pax.pax];

        return (
          <Paper key={`${pax.pax}-${pax.id}`} sx={{ mb: 5, ...(paxHasError && displayValidationErrors && errorStyles) }}>
            <Box sx={{ px: 3, py: 2 }}>
              <Typography variant="h5" sx={{ ml: 3 }} color={paxHasError && displayValidationErrors ? 'error.main' : undefined}>
                {t.selectNRoomForPax({ room: roomsPerPaxes[pax.pax], pax: paxTitle(pax) })}
              </Typography>
            </Box>
            <Box px={3} pb={2}>
              {getSortedPackages(pax).map(({ room, pkg }) => (
                <PaxDetails
                  key={`${room.code}-${pkg.accommodationType}-${pkg.finalPrice}-${pkg.id}`}
                  {...{
                    props,
                    selectedPackages,
                    pax,
                    roomDescription: room.roomDescription,
                    room,
                    pkg,
                    setSelectedPackages,
                    setDisplayValidationErrors,
                    selectedPackagesPerPaxes
                  }}
                />
              ))}
            </Box>
          </Paper>
        );
      })}
    </>
  );
};

const PaxDetails = ({
  props: {
    roomsPerPaxes,
    totalOfRooms,
    onChange
  },
  selectedPackages,
  pax,
  roomDescription,
  room,
  setSelectedPackages,
  setDisplayValidationErrors,
  pkg,
  selectedPackagesPerPaxes
}) => {
  const isPkgSelected = !!selectedPackages.find((item) => pkg.id === item.pkg.id);
  const isOnlyOnePax = totalOfRooms === 1;
  const allRequiredPackagesSelected = totalOfRooms === selectedPackages.length;

  const changeSelectedPkg = (event) => {
    const newSelectedPackages = selectedPackages.filter((item) => item.pkg.id !== pkg.id);
    const wasRemoved = selectedPackages.length !== newSelectedPackages.length;
    if (!wasRemoved) {
      newSelectedPackages.push({ pax, room, pkg });
    }

    setSelectedPackages(newSelectedPackages);
    setDisplayValidationErrors(true);

    const hasSelectedAllPaxes = newSelectedPackages.length === totalOfRooms;
    onChange?.({ packages: newSelectedPackages, completed: hasSelectedAllPaxes });
  };

  const disabled = !isPkgSelected && selectedPackagesPerPaxes[pax.pax] === roomsPerPaxes[pax.pax];

  return (
    <Paper sx={{ mb: 3, background: COLOR.LIGHT_BACKGROUND }} elevation={2}>
      <Grid
        container
        sx={{
          py: 3,
          px: 5,
          borderBottom: `1px solid ${COLOR.GRAY_LINE_SEPARATOR_2}`,
          cursor: 'pointer',
          ':hover': { background: COLOR.PRIMARY_ITEM_OVER },
          ':last-child': { borderBottom: 'none' },
          ...(isPkgSelected && {
            background: `${COLOR.PRIMARY_ITEM_SELECTED} !important`,
          }),
        }}
      >
        <Grid xs={12} sm={8}>
          <Typography component="div" variant="h5">
            {roomDescription}
          </Typography>
          <Typography component="div" variant="h6" pb={1} color={'text.secondary'} pt={2}>
            {pkg.accommodationType}
          </Typography>
          <Popover
            openOn="click"
            horizontal={10}
            target={<Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>{t.CancellationPolicies}</Typography>}
          >
            <Policies policies={pkg.cancellationFees} />
          </Popover>
          <Box pt={2}>{pkg.reimbursable && <Chip icon={<CheckIcon />} label={t.Reimbursable} color="info" {...{ size: 'large' } as any} />}</Box>
        </Grid>
        <Grid xs={12} sm={4} textAlign="center">
          <Typography component="div" mb={1}>
            {t.TotalPrice}:
          </Typography>
          <Box mb={1}>
            <Typography component="span" variant="h5">
              {pkg.currency}
            </Typography>
            <Typography component="span" variant="h4" fontWeight="bold" fontSize={30}>
              {' '}
              ${money(pkg.finalPrice)}
            </Typography>
          </Box>
          <Typography component="span" variant="h5" fontSize={18}>
            ({t.Avg}{' '}
            <Typography component="span" variant="h5" fontWeight="bold" fontSize={18}>
              ${money(pkg.pricePerNight)}
            </Typography>{' '}
            {t.perNight})
          </Typography>
          <Box>
            <Button
              variant={isPkgSelected || isOnlyOnePax ? 'contained' : 'outlined'}
              onClick={changeSelectedPkg}
              disabled={disabled}
              sx={{ margin: '0 auto', maxWidth: '200px', display: 'block', mt: 3 }}
            >
              {isPkgSelected ? (allRequiredPackagesSelected ? t.Booking + '...' : t.Unselect) : (isOnlyOnePax ? t.Book : t.Select)}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const Policies = ({ policies, omitHeaderWhenIsOnlyOne, titleSx }: any) => {
  if (!policies?.length) {
    return (
      <Box sx={{ textAlign: 'center', pt: { xs: 0, md: 6 } }}>
        <Typography variant="h6" color={'text.secondary'}>
          {t.NoReimbursable}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={2}>
      {policies.map((policy, policyIndex) => (
        <Box key={policyIndex} sx={{ p: 1, textAlign: { xs: 'center', md: 'initial' } }}>
          {/* {(policies.length > 1 || !omitHeaderWhenIsOnlyOne) && (
        <Box>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{ display: 'inline-block', borderBottom: '2px solid gray', mb: 1, px: 2, pt: 2, ...titleSx }}
          >
            Policy {policies.length > 1 ? policyIndex + 1 : ''}
          </Typography>
        </Box>
      )} */}

          <Box component="ul" display="inline-block">
            <Box component="li" sx={{}}>
              {policy.summary}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const paxTitle = (pax) => {
  const adultsText = `${pax.adults} ${pax.adults === 1 ? t.adult : t.adults}`;
  const childrenText = pax.children == 0 ? '' : ` ${pax.children === 1 ? t.and1Child : t.andNChildren(pax.children)}`;

  return pax.children === 0 ? adultsText : `${adultsText} ${childrenText}`;
};

export default HotelPaxSelect;
