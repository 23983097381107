import format from 'date-fns/format';
import parse from 'date-fns/parse';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import differenceInDays from 'date-fns/differenceInDays';
import { capitalizeFirstLetter } from './utils';
import { addDays, isSaturday, isSunday, startOfWeek } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Language } from '../types';

dayjs.extend(utc);

const DATE_FORMAT = 'MM-dd-yyyy';

export const formatToDate = (dateTime) => format(new Date(dateTime), DATE_FORMAT);

export const parseDate = (dateStr) => parse(dateStr, DATE_FORMAT, new Date());

export const humanize = ({ dateStr, time, language = Language.EN }) => {
  const date = parseDate(dateStr);

  if (language === Language.ES) {
    return capitalizeFirstLetter(format(date, `EEEE, d 'de' MMMM 'de' yyyy, 'a las ${time}'`, { locale: es }));
  }

  return capitalizeFirstLetter(format(date, `EEEE, MMMM do, yyyy, 'at ${time}'`, { locale: en }));
};

export const formatDateTime = (dateTime) => format(new Date(dateTime), 'yyyy-MM-dd hh:mm:ss a');

export const humanizeRange = (start: Date | undefined, end: Date | undefined): string => start && end
  ? [format(start, 'MMM dd'), format(end, 'MMM dd, yyyy')].join(' - ')
  : '';

export const amountOfNights = (dateStr1, dateStr2) => Math.abs(differenceInDays(parseDate(dateStr1), parseDate(dateStr2)));

export const getNextWeekend = ({ startDate, daysAhead }: { startDate?: Date, daysAhead?: number } = {}) => {
  // Start from the next Monday to ensure we're looking forward
  let date = startOfWeek(addDays(startDate || new Date(), daysAhead || 1));

  // Find the next Saturday
  while (!isSaturday(date)) {
    date = addDays(date, 1);
  }

  // Find the next Sunday
  let nextSunday = addDays(date, 1);
  while (!isSunday(nextSunday)) {
    nextSunday = addDays(nextSunday, 1);
  }

  return { startDate: date, endDate: nextSunday };
}

export const isInTheFuture = date => (new Date().getTime() - new Date(date).getTime()) < 0
export const isInThePast = date => !isInTheFuture(date);

export const getDateRanges = (start: Date, end: Date, totalOfDays: number): [Date, Date][] => {
  const result: [Date, Date][] = [];
  let currentStart = dayjs(start);

  while (!currentStart.isAfter(dayjs(end))) {
    result.push([
      currentStart.toDate(),
      currentStart.add(totalOfDays, 'day').toDate(),
    ]);

    currentStart = currentStart.add(1, 'day');
  }

  return result;
};

export const getTotalOfNights = (startDate: Date, endDate: Date): number => Math.abs(dayjs(startDate).diff(dayjs(endDate), 'days'));

export const getAllDatesOfWeek = (date: Date): Date[] => {
  const dates: Date[] = [];
  const currentDay = date.getDay(); // (0 for Sunday, 1 for Monday, etc.)

  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - currentDay);

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startOfWeek);
    currentDate.setDate(startOfWeek.getDate() + i);

    dates.push(currentDate);
  }

  return dates;
}
