import { Language } from '../../types';

export type Country = {
  code: string,
  language: string,
  currency: string,
  phone: string
} & { [key in Language]: string }

const {EN, ES, IT} = Language;

// https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries: Country[] = [
  { code: 'US', language: 'en', currency: 'USD', phone: '1', [EN]: 'United States', [ES]: 'Estados Unidos', [IT]: 'Stati Uniti' },
  { code: 'AD', language: 'ca', currency: 'EUR', phone: '376', [EN]: 'Andorra', [ES]: 'Andorra', [IT]: 'Andorra' },
  { code: 'AE', language: 'ar', currency: 'AED', phone: '971', [EN]: 'United Arab Emirates', [ES]: 'Emiratos Árabes Unidos', [IT]: 'Emirati Arabi Uniti' },
  { code: 'AF', language: 'ps', currency: 'AFN', phone: '93', [EN]: 'Afghanistan', [ES]: 'Afganistán', [IT]: 'Afghanistan' },
  { code: 'AG', language: 'en', currency: 'XCD', phone: '1-268', [EN]: 'Antigua and Barbuda', [ES]: 'Antigua y Barbuda', [IT]: 'Antigua e Barbuda' },
  { code: 'AI', language: 'en', currency: 'XCD', phone: '1-264', [EN]: 'Anguilla', [ES]: 'Anguila', [IT]: 'Anguilla' },
  { code: 'AL', language: 'sq', currency: 'ALL', phone: '355', [EN]: 'Albania', [ES]: 'Albania', [IT]: 'Albania' },
  { code: 'AM', language: 'hy', currency: 'AMD', phone: '374', [EN]: 'Armenia', [ES]: 'Armenia', [IT]: 'Armenia' },
  { code: 'AO', language: 'pt', currency: 'AOA', phone: '244', [EN]: 'Angola', [ES]: 'Angola', [IT]: 'Angola' },
  { code: 'AQ', language: 'en', currency: 'XXX', phone: '672', [EN]: 'Antarctica', [ES]: 'Antártida', [IT]: 'Antartide' },
  { code: 'AR', language: 'es', currency: 'ARS', phone: '54', [EN]: 'Argentina', [ES]: 'Argentina', [IT]: 'Argentina' },
  { code: 'AS', language: 'en', currency: 'USD', phone: '1-684', [EN]: 'American Samoa', [ES]: 'Samoa Americana', [IT]: 'Samoa Americana' },
  { code: 'AT', language: 'de', currency: 'EUR', phone: '43', [EN]: 'Austria', [ES]: 'Austria', [IT]: 'Austria' },
  { code: 'AU', language: 'en', currency: 'AUD', phone: '61', [EN]: 'Australia', [ES]: 'Australia', [IT]: 'Australia' },
  { code: 'AW', language: 'nl', currency: 'AWG', phone: '297', [EN]: 'Aruba', [ES]: 'Aruba', [IT]: 'Aruba' },
  { code: 'AX', language: 'sv', currency: 'EUR', phone: '358', [EN]: 'Alland Islands', [ES]: 'Islas Aland', [IT]: 'Isole Åland' },
  { code: 'AZ', language: 'az', currency: 'AZN', phone: '994', [EN]: 'Azerbaijan', [ES]: 'Azerbaiyán', [IT]: 'Azerbaigian' },
  { code: 'BA', language: 'bs', currency: 'BAM', phone: '387', [EN]: 'Bosnia and Herzegovina', [ES]: 'Bosnia y Herzegovina', [IT]: 'Bosnia ed Erzegovina' },
  { code: 'BB', language: 'en', currency: 'BBD', phone: '1-246', [EN]: 'Barbados', [ES]: 'Barbados', [IT]: 'Barbados' },
  { code: 'BD', language: 'bn', currency: 'BDT', phone: '880', [EN]: 'Bangladesh', [ES]: 'Bangladesh', [IT]: 'Bangladesh' },
  { code: 'BE', language: 'nl', currency: 'EUR', phone: '32', [EN]: 'Belgium', [ES]: 'Bélgica', [IT]: 'Belgio' },
  { code: 'BF', language: 'fr', currency: 'XOF', phone: '226', [EN]: 'Burkina Faso', [ES]: 'Burkina Faso', [IT]: 'Burkina Faso' },
  { code: 'BG', language: 'bg', currency: 'BGN', phone: '359', [EN]: 'Bulgaria', [ES]: 'Bulgaria', [IT]: 'Bulgaria' },
  { code: 'BH', language: 'ar', currency: 'BHD', phone: '973', [EN]: 'Bahrain', [ES]: 'Baréin', [IT]: 'Bahrein' },
  { code: 'BI', language: 'fr', currency: 'BIF', phone: '257', [EN]: 'Burundi', [ES]: 'Burundi', [IT]: 'Burundi' },
  { code: 'BJ', language: 'fr', currency: 'XOF', phone: '229', [EN]: 'Benin', [ES]: 'Benín', [IT]: 'Benin' },
  { code: 'BL', language: 'fr', currency: 'EUR', phone: '590', [EN]: 'Saint Barthelemy', [ES]: 'San Bartolomé', [IT]: 'Saint-Barthélemy' },
  { code: 'BM', language: 'en', currency: 'BMD', phone: '1-441', [EN]: 'Bermuda', [ES]: 'Bermudas', [IT]: 'Bermuda' },
  { code: 'BN', language: 'ms', currency: 'BND', phone: '673', [EN]: 'Brunei Darussalam', [ES]: 'Brunéi Darussalam', [IT]: 'Brunei Darussalam' },
  { code: 'BO', language: 'es', currency: 'BOB', phone: '591', [EN]: 'Bolivia', [ES]: 'Bolivia', [IT]: 'Bolivia' },
  { code: 'BR', language: 'pt', currency: 'BRL', phone: '55', [EN]: 'Brazil', [ES]: 'Brasil', [IT]: 'Brasile' },
  { code: 'BS', language: 'en', currency: 'BSD', phone: '1-242', [EN]: 'Bahamas', [ES]: 'Bahamas', [IT]: 'Bahamas' },
  { code: 'BT', language: 'dz', currency: 'BTN', phone: '975', [EN]: 'Bhutan', [ES]: 'Bután', [IT]: 'Bhutan' },
  { code: 'BV', language: 'no', currency: 'NOK', phone: '47', [EN]: 'Bouvet Island', [ES]: 'Isla Bouvet', [IT]: 'Isola Bouvet' },
  { code: 'BW', language: 'en', currency: 'BWP', phone: '267', [EN]: 'Botswana', [ES]: 'Botsuana', [IT]: 'Botswana' },
  { code: 'BY', language: 'be', currency: 'BYN', phone: '375', [EN]: 'Belarus', [ES]: 'Bielorrusia', [IT]: 'Bielorussia' },
  { code: 'BZ', language: 'en', currency: 'BZD', phone: '501', [EN]: 'Belize', [ES]: 'Belice', [IT]: 'Belize' },
  { code: 'CA', language: 'en', currency: 'CAD', phone: '1', [EN]: 'Canada', [ES]: 'Canadá', [IT]: 'Canada' },
  { code: 'CC', language: 'en', currency: 'AUD', phone: '61', [EN]: 'Cocos (Keeling) Islands', [ES]: 'Islas Cocos (Keeling)', [IT]: 'Isole Cocos (Keeling)' },
  { code: 'CD', language: 'fr', currency: 'CDF', phone: '243', [EN]: 'Congo, Democratic Republic of the', [ES]: 'República Democrática del Congo', [IT]: 'Repubblica Democratica del Congo' },
  { code: 'CF', language: 'fr', currency: 'XAF', phone: '236', [EN]: 'Central African Republic', [ES]: 'República Centroafricana', [IT]: 'Repubblica Centrafricana' },
  { code: 'CG', language: 'fr', currency: 'XAF', phone: '242', [EN]: 'Congo, Republic of the', [ES]: 'República del Congo', [IT]: 'Repubblica del Congo' },
  { code: 'CH', language: 'de', currency: 'CHF', phone: '41', [EN]: 'Switzerland', [ES]: 'Suiza', [IT]: 'Svizzera' },
  { code: 'CI', language: 'fr', currency: 'XOF', phone: '225', [EN]: 'Ivory Coast', [ES]: 'Costa de Marfil', [IT]: 'Costa d’Avorio' },
  { code: 'CK', language: 'en', currency: 'NZD', phone: '682', [EN]: 'Cook Islands', [ES]: 'Islas Cook', [IT]: 'Isole Cook' },
  { code: 'CL', language: 'es', currency: 'CLP', phone: '56', [EN]: 'Chile', [ES]: 'Chile', [IT]: 'Cile' },
  { code: 'CM', language: 'fr', currency: 'XAF', phone: '237', [EN]: 'Cameroon', [ES]: 'Camerún', [IT]: 'Camerun' },
  { code: 'CN', language: 'zh', currency: 'CNY', phone: '86', [EN]: 'China', [ES]: 'China', [IT]: 'Cina' },
  { code: 'CO', language: 'es', currency: 'COP', phone: '57', [EN]: 'Colombia', [ES]: 'Colombia', [IT]: 'Colombia' },
  { code: 'CR', language: 'es', currency: 'CRC', phone: '506', [EN]: 'Costa Rica', [ES]: 'Costa Rica', [IT]: 'Costa Rica' },
  { code: 'CU', language: 'es', currency: 'CUP', phone: '53', [EN]: 'Cuba', [ES]: 'Cuba', [IT]: 'Cuba' },
  { code: 'CV', language: 'pt', currency: 'CVE', phone: '238', [EN]: 'Cape Verde', [ES]: 'Cabo Verde', [IT]: 'Capo Verde' },
  { code: 'CW', language: 'nl', currency: 'ANG', phone: '599', [EN]: 'Curacao', [ES]: 'Curazao', [IT]: 'Curaçao' },
  { code: 'CX', language: 'en', currency: 'AUD', phone: '61', [EN]: 'Christmas Island', [ES]: 'Isla Christmas', [IT]: 'Isola di Natale' },
  { code: 'CY', language: 'el', currency: 'EUR', phone: '357', [EN]: 'Cyprus', [ES]: 'Chipre', [IT]: 'Cipro' },
  { code: 'CZ', language: 'cs', currency: 'CZK', phone: '420', [EN]: 'Czech Republic', [ES]: 'República Checa', [IT]: 'Repubblica Ceca' },
  { code: 'DE', language: 'de', currency: 'EUR', phone: '49', [EN]: 'Germany', [ES]: 'Alemania', [IT]: 'Germania' },
  { code: 'DJ', language: 'fr', currency: 'DJF', phone: '253', [EN]: 'Djibouti', [ES]: 'Yibuti', [IT]: 'Gibuti' },
  { code: 'DK', language: 'da', currency: 'DKK', phone: '45', [EN]: 'Denmark', [ES]: 'Dinamarca', [IT]: 'Danimarca' },
  { code: 'DM', language: 'en', currency: 'XCD', phone: '1-767', [EN]: 'Dominica', [ES]: 'Dominica', [IT]: 'Dominica' },
  { code: 'DO', language: 'es', currency: 'DOP', phone: '1-809', [EN]: 'Dominican Republic', [ES]: 'República Dominicana', [IT]: 'Repubblica Dominicana' },
  { code: 'DZ', language: 'ar', currency: 'DZD', phone: '213', [EN]: 'Algeria', [ES]: 'Argelia', [IT]: 'Algeria' },
  { code: 'EC', language: 'es', currency: 'USD', phone: '593', [EN]: 'Ecuador', [ES]: 'Ecuador', [IT]: 'Ecuador' },
  { code: 'EE', language: 'et', currency: 'EUR', phone: '372', [EN]: 'Estonia', [ES]: 'Estonia', [IT]: 'Estonia' },
  { code: 'EG', language: 'ar', currency: 'EGP', phone: '20', [EN]: 'Egypt', [ES]: 'Egipto', [IT]: 'Egitto' },
  { code: 'EH', language: 'ar', currency: 'MAD', phone: '212', [EN]: 'Western Sahara', [ES]: 'Sáhara Occidental', [IT]: 'Sahara Occidentale' },
  { code: 'ER', language: 'ti', currency: 'ERN', phone: '291', [EN]: 'Eritrea', [ES]: 'Eritrea', [IT]: 'Eritrea' },
  { code: 'ES', language: 'es', currency: 'EUR', phone: '34', [EN]: 'Spain', [ES]: 'España', [IT]: 'Spagna' },
  { code: 'ET', language: 'am', currency: 'ETB', phone: '251', [EN]: 'Ethiopia', [ES]: 'Etiopía', [IT]: 'Etiopia' },
  { code: 'FI', language: 'fi', currency: 'EUR', phone: '358', [EN]: 'Finland', [ES]: 'Finlandia', [IT]: 'Finlandia' },
  { code: 'FJ', language: 'en', currency: 'FJD', phone: '679', [EN]: 'Fiji', [ES]: 'Fiyi', [IT]: 'Figi' },
  { code: 'FK', language: 'en', currency: 'FKP', phone: '500', [EN]: 'Falkland Islands (Malvinas)', [ES]: 'Islas Malvinas', [IT]: 'Isole Falkland (Malvine)' },
  { code: 'FM', language: 'en', currency: 'USD', phone: '691', [EN]: 'Micronesia', [ES]: 'Micronesia', [IT]: 'Micronesia' },
  { code: 'FO', language: 'fo', currency: 'DKK', phone: '298', [EN]: 'Faroe Islands', [ES]: 'Islas Feroe', [IT]: 'Isole Færøe' },
  { code: 'FR', language: 'fr', currency: 'EUR', phone: '33', [EN]: 'France', [ES]: 'Francia', [IT]: 'Francia' },
  { code: 'GA', language: 'fr', currency: 'XAF', phone: '241', [EN]: 'Gabon', [ES]: 'Gabón', [IT]: 'Gabon' },
  { code: 'GB', language: 'en', currency: 'GBP', phone: '44', [EN]: 'United Kingdom', [ES]: 'Reino Unido', [IT]: 'Regno Unito' },
  { code: 'GD', language: 'en', currency: 'XCD', phone: '1-473', [EN]: 'Grenada', [ES]: 'Granada', [IT]: 'Grenada' },
  { code: 'GE', language: 'ka', currency: 'GEL', phone: '995', [EN]: 'Georgia', [ES]: 'Georgia', [IT]: 'Georgia' },
  { code: 'GF', language: 'fr', currency: 'EUR', phone: '594', [EN]: 'French Guiana', [ES]: 'Guayana Francesa', [IT]: 'Guiana Francese' },
  { code: 'GG', language: 'en', currency: 'GBP', phone: '44', [EN]: 'Guernsey', [ES]: 'Guernsey', [IT]: 'Guernsey' },
  { code: 'GH', language: 'en', currency: 'GHS', phone: '233', [EN]: 'Ghana', [ES]: 'Ghana', [IT]: 'Ghana' },
  { code: 'GI', language: 'en', currency: 'GIP', phone: '350', [EN]: 'Gibraltar', [ES]: 'Gibraltar', [IT]: 'Gibilterra' },
  { code: 'GL', language: 'kl', currency: 'DKK', phone: '299', [EN]: 'Greenland', [ES]: 'Groenlandia', [IT]: 'Groenlandia' },
  { code: 'GM', language: 'en', currency: 'GMD', phone: '220', [EN]: 'Gambia', [ES]: 'Gambia', [IT]: 'Gambia' },
  { code: 'GN', language: 'fr', currency: 'GNF', phone: '224', [EN]: 'Guinea', [ES]: 'Guinea', [IT]: 'Guinea' },
  { code: 'GP', language: 'fr', currency: 'EUR', phone: '590', [EN]: 'Guadeloupe', [ES]: 'Guadalupe', [IT]: 'Guadalupa' },
  { code: 'GQ', language: 'es', currency: 'XAF', phone: '240', [EN]: 'Equatorial Guinea', [ES]: 'Guinea Ecuatorial', [IT]: 'Guinea Equatoriale' },
  { code: 'GR', language: 'el', currency: 'EUR', phone: '30', [EN]: 'Greece', [ES]: 'Grecia', [IT]: 'Grecia' },
  { code: 'GS', language: 'en', currency: 'GBP', phone: '500', [EN]: 'South Georgia and the South Sandwich Islands', [ES]: 'Islas Georgias del Sur y Sandwich del Sur', [IT]: 'Georgia del Sud e Isole Sandwich del Sud' },
  { code: 'GT', language: 'es', currency: 'GTQ', phone: '502', [EN]: 'Guatemala', [ES]: 'Guatemala', [IT]: 'Guatemala' },
  { code: 'GU', language: 'en', currency: 'USD', phone: '1-671', [EN]: 'Guam', [ES]: 'Guam', [IT]: 'Guam' },
  { code: 'GW', language: 'pt', currency: 'XOF', phone: '245', [EN]: 'Guinea-Bissau', [ES]: 'Guinea-Bisáu', [IT]: 'Guinea-Bissau' },
  { code: 'GY', language: 'en', currency: 'GYD', phone: '592', [EN]: 'Guyana', [ES]: 'Guyana', [IT]: 'Guyana' },
  { code: 'HK', language: 'zh', currency: 'HKD', phone: '852', [EN]: 'Hong Kong', [ES]: 'Hong Kong', [IT]: 'Hong Kong' },
  { code: 'HM', language: 'en', currency: 'AUD', phone: '672', [EN]: 'Heard Island and McDonald Islands', [ES]: 'Islas Heard y McDonald', [IT]: 'Isole Heard e McDonald' },
  { code: 'HN', language: 'es', currency: 'HNL', phone: '504', [EN]: 'Honduras', [ES]: 'Honduras', [IT]: 'Honduras' },
  { code: 'HR', language: 'hr', currency: 'HRK', phone: '385', [EN]: 'Croatia', [ES]: 'Croacia', [IT]: 'Croazia' },
  { code: 'HT', language: 'fr', currency: 'HTG', phone: '509', [EN]: 'Haiti', [ES]: 'Haití', [IT]: 'Haiti' },
  { code: 'HU', language: 'hu', currency: 'HUF', phone: '36', [EN]: 'Hungary', [ES]: 'Hungría', [IT]: 'Ungheria' },
  { code: 'ID', language: 'id', currency: 'IDR', phone: '62', [EN]: 'Indonesia', [ES]: 'Indonesia', [IT]: 'Indonesia' },
  { code: 'IE', language: 'en', currency: 'EUR', phone: '353', [EN]: 'Ireland', [ES]: 'Irlanda', [IT]: 'Irlanda' },
  { code: 'IL', language: 'he', currency: 'ILS', phone: '972', [EN]: 'Israel', [ES]: 'Israel', [IT]: 'Israele' },
  { code: 'IM', language: 'en', currency: 'GBP', phone: '44', [EN]: 'Isle of Man', [ES]: 'Isla de Man', [IT]: 'Isola di Man' },
  { code: 'IN', language: 'hi', currency: 'INR', phone: '91', [EN]: 'India', [ES]: 'India', [IT]: 'India' },
  { code: 'IO', language: 'en', currency: 'USD', phone: '246', [EN]: 'British Indian Ocean Territory', [ES]: 'Territorio Británico del Océano Índico', [IT]: 'Territorio Britannico dell’Oceano Indiano' },
  { code: 'IQ', language: 'ar', currency: 'IQD', phone: '964', [EN]: 'Iraq', [ES]: 'Irak', [IT]: 'Iraq' },
  { code: 'IR', language: 'fa', currency: 'IRR', phone: '98', [EN]: 'Iran, Islamic Republic of', [ES]: 'Irán', [IT]: 'Iran' },
  { code: 'IS', language: 'is', currency: 'ISK', phone: '354', [EN]: 'Iceland', [ES]: 'Islandia', [IT]: 'Islanda' },
  { code: 'IT', language: 'it', currency: 'EUR', phone: '39', [EN]: 'Italy', [ES]: 'Italia', [IT]: 'Italia' },
  { code: 'JE', language: 'en', currency: 'GBP', phone: '44', [EN]: 'Jersey', [ES]: 'Jersey', [IT]: 'Jersey' },
  { code: 'JM', language: 'en', currency: 'JMD', phone: '1-876', [EN]: 'Jamaica', [ES]: 'Jamaica', [IT]: 'Giamaica' },
  { code: 'JO', language: 'ar', currency: 'JOD', phone: '962', [EN]: 'Jordan', [ES]: 'Jordania', [IT]: 'Giordania' },
  { code: 'JP', language: 'ja', currency: 'JPY', phone: '81', [EN]: 'Japan', [ES]: 'Japón', [IT]: 'Giappone' },
  { code: 'KE', language: 'en', currency: 'KES', phone: '254', [EN]: 'Kenya', [ES]: 'Kenia', [IT]: 'Kenya' },
  { code: 'KG', language: 'ky', currency: 'KGS', phone: '996', [EN]: 'Kyrgyzstan', [ES]: 'Kirguistán', [IT]: 'Kirgizistan' },
  { code: 'KH', language: 'km', currency: 'KHR', phone: '855', [EN]: 'Cambodia', [ES]: 'Camboya', [IT]: 'Cambogia' },
  { code: 'KI', language: 'en', currency: 'AUD', phone: '686', [EN]: 'Kiribati', [ES]: 'Kiribati', [IT]: 'Kiribati' },
  { code: 'KM', language: 'ar', currency: 'KMF', phone: '269', [EN]: 'Comoros', [ES]: 'Comoras', [IT]: 'Comore' },
  { code: 'KN', language: 'en', currency: 'XCD', phone: '1-869', [EN]: 'Saint Kitts and Nevis', [ES]: 'San Cristóbal y Nieves', [IT]: 'Saint Kitts e Nevis' },
  { code: 'KP', language: 'ko', currency: 'KPW', phone: '850', [EN]: "Korea, Democratic People's Republic of", [ES]: 'Corea del Norte', [IT]: 'Corea del Nord' },
  { code: 'KR', language: 'ko', currency: 'KRW', phone: '82', [EN]: 'Korea, Republic of', [ES]: 'Corea del Sur', [IT]: 'Corea del Sud' },
  { code: 'KW', language: 'ar', currency: 'KWD', phone: '965', [EN]: 'Kuwait', [ES]: 'Kuwait', [IT]: 'Kuwait' },
  { code: 'KY', language: 'en', currency: 'KYD', phone: '1-345', [EN]: 'Cayman Islands', [ES]: 'Islas Caimán', [IT]: 'Isole Cayman' },
  { code: 'KZ', language: 'kk', currency: 'KZT', phone: '7', [EN]: 'Kazakhstan', [ES]: 'Kazajistán', [IT]: 'Kazakistan' },
  { code: 'LA', language: 'lo', currency: 'LAK', phone: '856', [EN]: "Lao People's Democratic Republic", [ES]: 'Laos', [IT]: 'Repubblica Democratica Popolare Lao' },
  { code: 'LB', language: 'ar', currency: 'LBP', phone: '961', [EN]: 'Lebanon', [ES]: 'Líbano', [IT]: 'Libano' },
  { code: 'LC', language: 'en', currency: 'XCD', phone: '1-758', [EN]: 'Saint Lucia', [ES]: 'Santa Lucía', [IT]: 'Santa Lucia' },
  { code: 'LI', language: 'de', currency: 'CHF', phone: '423', [EN]: 'Liechtenstein', [ES]: 'Liechtenstein', [IT]: 'Liechtenstein' },
  { code: 'LK', language: 'si', currency: 'LKR', phone: '94', [EN]: 'Sri Lanka', [ES]: 'Sri Lanka', [IT]: 'Sri Lanka' },
  { code: 'LR', language: 'en', currency: 'LRD', phone: '231', [EN]: 'Liberia', [ES]: 'Liberia', [IT]: 'Liberia' },
  { code: 'LS', language: 'en', currency: 'LSL', phone: '266', [EN]: 'Lesotho', [ES]: 'Lesoto', [IT]: 'Lesotho' },
  { code: 'LT', language: 'lt', currency: 'LTL', phone: '370', [EN]: 'Lithuania', [ES]: 'Lituania', [IT]: 'Lituania' },
  { code: 'LU', language: 'lb', currency: 'EUR', phone: '352', [EN]: 'Luxembourg', [ES]: 'Luxemburgo', [IT]: 'Lussemburgo' },
  { code: 'LV', language: 'lv', currency: 'EUR', phone: '371', [EN]: 'Latvia', [ES]: 'Letonia', [IT]: 'Lettonia' },
  { code: 'LY', language: 'ar', currency: 'LYD', phone: '218', [EN]: 'Libya', [ES]: 'Libia', [IT]: 'Libia' },
  { code: 'MA', language: 'ar', currency: 'MAD', phone: '212', [EN]: 'Morocco', [ES]: 'Marruecos', [IT]: 'Marocco' },
  { code: 'MC', language: 'fr', currency: 'EUR', phone: '377', [EN]: 'Monaco', [ES]: 'Mónaco', [IT]: 'Monaco' },
  { code: 'MD', language: 'ro', currency: 'MDL', phone: '373', [EN]: 'Moldova', [ES]: 'Moldavia', [IT]: 'Moldova' },
  { code: 'ME', language: 'sr', currency: 'EUR', phone: '382', [EN]: 'Montenegro', [ES]: 'Montenegro', [IT]: 'Montenegro' },
  { code: 'MF', language: 'fr', currency: 'EUR', phone: '590', [EN]: 'Saint Martin', [ES]: 'San Martín', [IT]: 'Saint Martin' },
  { code: 'MG', language: 'mg', currency: 'MGA', phone: '261', [EN]: 'Madagascar', [ES]: 'Madagascar', [IT]: 'Madagascar' },
  { code: 'MH', language: 'en', currency: 'USD', phone: '692', [EN]: 'Marshall Islands', [ES]: 'Islas Marshall', [IT]: 'Isole Marshall' },
  { code: 'MK', language: 'mk', currency: 'MKD', phone: '389', [EN]: 'North Macedonia', [ES]: 'Macedonia del Norte', [IT]: 'Macedonia del Nord' },
  { code: 'ML', language: 'fr', currency: 'XOF', phone: '223', [EN]: 'Mali', [ES]: 'Malí', [IT]: 'Mali' },
  { code: 'MM', language: 'my', currency: 'MMK', phone: '95', [EN]: 'Myanmar', [ES]: 'Birmania', [IT]: 'Birmania' },
  { code: 'MN', language: 'mn', currency: 'MNT', phone: '976', [EN]: 'Mongolia', [ES]: 'Mongolia', [IT]: 'Mongolia' },
  { code: 'MO', language: 'zh', currency: 'MOP', phone: '853', [EN]: 'Macao', [ES]: 'Macao', [IT]: 'Macao' },
  { code: 'MP', language: 'en', currency: 'USD', phone: '1-670', [EN]: 'Northern Mariana Islands', [ES]: 'Islas Marianas del Norte', [IT]: 'Isole Marianne Settentrionali' },
  { code: 'MQ', language: 'fr', currency: 'EUR', phone: '596', [EN]: 'Martinique', [ES]: 'Martinica', [IT]: 'Martinica' },
  { code: 'MR', language: 'ar', currency: 'MRU', phone: '222', [EN]: 'Mauritania', [ES]: 'Mauritania', [IT]: 'Mauritania' },
  { code: 'MS', language: 'en', currency: 'XCD', phone: '1-664', [EN]: 'Montserrat', [ES]: 'Montserrat', [IT]: 'Montserrat' },
  { code: 'MT', language: 'mt', currency: 'EUR', phone: '356', [EN]: 'Malta', [ES]: 'Malta', [IT]: 'Malta' },
  { code: 'MU', language: 'en', currency: 'MUR', phone: '230', [EN]: 'Mauritius', [ES]: 'Mauricio', [IT]: 'Mauritius' },
  { code: 'MV', language: 'dv', currency: 'MVR', phone: '960', [EN]: 'Maldives', [ES]: 'Maldivas', [IT]: 'Maldive' },
  { code: 'MW', language: 'en', currency: 'MWK', phone: '265', [EN]: 'Malawi', [ES]: 'Malawi', [IT]: 'Malawi' },
  { code: 'MX', language: 'es', currency: 'MXN', phone: '52', [EN]: 'Mexico', [ES]: 'México', [IT]: 'Messico' },
  { code: 'MY', language: 'ms', currency: 'MYR', phone: '60', [EN]: 'Malaysia', [ES]: 'Malasia', [IT]: 'Malesia' },
  { code: 'MZ', language: 'pt', currency: 'MZN', phone: '258', [EN]: 'Mozambique', [ES]: 'Mozambique', [IT]: 'Mozambico' },
  { code: 'NA', language: 'en', currency: 'NAD', phone: '264', [EN]: 'Namibia', [ES]: 'Namibia', [IT]: 'Namibia' },
  { code: 'NC', language: 'fr', currency: 'XPF', phone: '687', [EN]: 'New Caledonia', [ES]: 'Nueva Caledonia', [IT]: 'Nuova Caledonia' },
  { code: 'NE', language: 'fr', currency: 'XOF', phone: '227', [EN]: 'Niger', [ES]: 'Níger', [IT]: 'Niger' },
  { code: 'NF', language: 'en', currency: 'AUD', phone: '672', [EN]: 'Norfolk Island', [ES]: 'Isla Norfolk', [IT]: 'Isola Norfolk' },
  { code: 'NG', language: 'en', currency: 'NGN', phone: '234', [EN]: 'Nigeria', [ES]: 'Nigeria', [IT]: 'Nigeria' },
  { code: 'NI', language: 'es', currency: 'NIO', phone: '505', [EN]: 'Nicaragua', [ES]: 'Nicaragua', [IT]: 'Nicaragua' },
  { code: 'NL', language: 'nl', currency: 'EUR', phone: '31', [EN]: 'Netherlands', [ES]: 'Países Bajos', [IT]: 'Paesi Bassi' },
  { code: 'NO', language: 'no', currency: 'NOK', phone: '47', [EN]: 'Norway', [ES]: 'Noruega', [IT]: 'Norvegia' },
  { code: 'NP', language: 'ne', currency: 'NPR', phone: '977', [EN]: 'Nepal', [ES]: 'Nepal', [IT]: 'Nepal' },
  { code: 'NR', language: 'na', currency: 'AUD', phone: '674', [EN]: 'Nauru', [ES]: 'Nauru', [IT]: 'Nauru' },
  { code: 'NU', language: 'en', currency: 'NZD', phone: '683', [EN]: 'Niue', [ES]: 'Niue', [IT]: 'Niue' },
  { code: 'NZ', language: 'en', currency: 'NZD', phone: '64', [EN]: 'New Zealand', [ES]: 'Nueva Zelanda', [IT]: 'Nuova Zelanda' },
  { code: 'OM', language: 'ar', currency: 'OMR', phone: '968', [EN]: 'Oman', [ES]: 'Omán', [IT]: 'Oman' },
  { code: 'PA', language: 'es', currency: 'PAB', phone: '507', [EN]: 'Panama', [ES]: 'Panamá', [IT]: 'Panama' },
  { code: 'PE', language: 'es', currency: 'PEN', phone: '51', [EN]: 'Peru', [ES]: 'Perú', [IT]: 'Perù' },
  { code: 'PF', language: 'fr', currency: 'XPF', phone: '689', [EN]: 'French Polynesia', [ES]: 'Polinesia Francesa', [IT]: 'Polinesia Francese' },
  { code: 'PG', language: 'en', currency: 'PGK', phone: '675', [EN]: 'Papua New Guinea', [ES]: 'Papúa Nueva Guinea', [IT]: 'Papua Nuova Guinea' },
  { code: 'PH', language: 'en', currency: 'PHP', phone: '63', [EN]: 'Philippines', [ES]: 'Filipinas', [IT]: 'Filippine' },
  { code: 'PK', language: 'en', currency: 'PKR', phone: '92', [EN]: 'Pakistan', [ES]: 'Pakistán', [IT]: 'Pakistan' },
  { code: 'PL', language: 'pl', currency: 'PLN', phone: '48', [EN]: 'Poland', [ES]: 'Polonia', [IT]: 'Polonia' },
  { code: 'PM', language: 'fr', currency: 'EUR', phone: '508', [EN]: 'Saint Pierre and Miquelon', [ES]: 'San Pedro y Miquelón', [IT]: 'San Pietro e Miquelone' },
  { code: 'PN', language: 'en', currency: 'NZD', phone: '870', [EN]: 'Pitcairn', [ES]: 'Pitcairn', [IT]: 'Pitcairn' },
  { code: 'PR', language: 'es', currency: 'USD', phone: '1', [EN]: 'Puerto Rico', [ES]: 'Puerto Rico', [IT]: 'Porto Rico' },
  { code: 'PT', language: 'pt', currency: 'EUR', phone: '351', [EN]: 'Portugal', [ES]: 'Portugal', [IT]: 'Portogallo' },
  { code: 'PW', language: 'en', currency: 'USD', phone: '680', [EN]: 'Palau', [ES]: 'Palau', [IT]: 'Palau' },
  { code: 'PY', language: 'es', currency: 'PYG', phone: '595', [EN]: 'Paraguay', [ES]: 'Paraguay', [IT]: 'Paraguay' },
  { code: 'QA', language: 'ar', currency: 'QAR', phone: '974', [EN]: 'Qatar', [ES]: 'Catar', [IT]: 'Qatar' },
  { code: 'RE', language: 'fr', currency: 'EUR', phone: '262', [EN]: 'Reunion', [ES]: 'Reunión', [IT]: 'Riunione' },
  { code: 'RO', language: 'ro', currency: 'RON', phone: '40', [EN]: 'Romania', [ES]: 'Rumanía', [IT]: 'Romania' },
  { code: 'RS', language: 'sr', currency: 'RSD', phone: '381', [EN]: 'Serbia', [ES]: 'Serbia', [IT]: 'Serbia' },
  { code: 'RU', language: 'ru', currency: 'RUB', phone: '7', [EN]: 'Russian Federation', [ES]: 'Rusia', [IT]: 'Federazione Russa' },
  { code: 'RW', language: 'rw', currency: 'RWF', phone: '250', [EN]: 'Rwanda', [ES]: 'Ruanda', [IT]: 'Ruanda' },
  { code: 'SA', language: 'ar', currency: 'SAR', phone: '966', [EN]: 'Saudi Arabia', [ES]: 'Arabia Saudita', [IT]: 'Arabia Saudita' },
  { code: 'SB', language: 'en', currency: 'SBD', phone: '677', [EN]: 'Solomon Islands', [ES]: 'Islas Salomón', [IT]: 'Isole Salomone' },
  { code: 'SC', language: 'fr', currency: 'SCR', phone: '248', [EN]: 'Seychelles', [ES]: 'Seychelles', [IT]: 'Seychelles' },
  { code: 'SD', language: 'ar', currency: 'SDG', phone: '249', [EN]: 'Sudan', [ES]: 'Sudán', [IT]: 'Sudan' },
  { code: 'SE', language: 'sv', currency: 'SEK', phone: '46', [EN]: 'Sweden', [ES]: 'Suecia', [IT]: 'Svezia' },
  { code: 'SG', language: 'en', currency: 'SGD', phone: '65', [EN]: 'Singapore', [ES]: 'Singapur', [IT]: 'Singapore' },
  { code: 'SH', language: 'en', currency: 'SHP', phone: '290', [EN]: 'Saint Helena', [ES]: 'Santa Elena', [IT]: 'Sant’Elena' },
  { code: 'SI', language: 'sl', currency: 'EUR', phone: '386', [EN]: 'Slovenia', [ES]: 'Eslovenia', [IT]: 'Slovenia' },
  { code: 'SJ', language: 'no', currency: 'NOK', phone: '47', [EN]: 'Svalbard and Jan Mayen', [ES]: 'Svalbard y Jan Mayen', [IT]: 'Svalbard e Jan Mayen' },
  { code: 'SK', language: 'sk', currency: 'EUR', phone: '421', [EN]: 'Slovakia', [ES]: 'Eslovaquia', [IT]: 'Slovacchia' },
  { code: 'SL', language: 'en', currency: 'SLL', phone: '232', [EN]: 'Sierra Leone', [ES]: 'Sierra Leona', [IT]: 'Sierra Leone' },
  { code: 'SM', language: 'it', currency: 'EUR', phone: '378', [EN]: 'San Marino', [ES]: 'San Marino', [IT]: 'San Marino' },
  { code: 'SN', language: 'fr', currency: 'XOF', phone: '221', [EN]: 'Senegal', [ES]: 'Senegal', [IT]: 'Senegal' },
  { code: 'SO', language: 'so', currency: 'SOS', phone: '252', [EN]: 'Somalia', [ES]: 'Somalia', [IT]: 'Somalia' },
  { code: 'SR', language: 'nl', currency: 'SRD', phone: '597', [EN]: 'Suriname', [ES]: 'Surinam', [IT]: 'Suriname' },
  { code: 'SS', language: 'en', currency: 'SSP', phone: '211', [EN]: 'South Sudan', [ES]: 'Sudán del Sur', [IT]: 'Sudan del Sud' },
  { code: 'ST', language: 'pt', currency: 'STN', phone: '239', [EN]: 'Sao Tome and Principe', [ES]: 'Santo Tomé y Príncipe', [IT]: 'Sao Tomé e Principe' },
  { code: 'SV', language: 'es', currency: 'USD', phone: '503', [EN]: 'El Salvador', [ES]: 'El Salvador', [IT]: 'El Salvador' },
  { code: 'SX', language: 'nl', currency: 'ANG', phone: '1-721', [EN]: 'Sint Maarten (Dutch part)', [ES]: 'Sint Maarten (parte holandesa)', [IT]: 'Sint Maarten (parte olandese)' },
  { code: 'SY', language: 'ar', currency: 'SYP', phone: '963', [EN]: 'Syrian Arab Republic', [ES]: 'Siria', [IT]: 'Repubblica Araba Siriana' },
  { code: 'SZ', language: 'en', currency: 'SZL', phone: '268', [EN]: 'Swaziland', [ES]: 'Suazilandia', [IT]: 'Swaziland' },
  { code: 'TC', language: 'en', currency: 'USD', phone: '1-649', [EN]: 'Turks and Caicos Islands', [ES]: 'Islas Turcas y Caicos', [IT]: 'Isole Turks e Caicos' },
  { code: 'TD', language: 'fr', currency: 'XAF', phone: '235', [EN]: 'Chad', [ES]: 'Chad', [IT]: 'Ciad' },
  { code: 'TF', language: 'fr', currency: 'EUR', phone: '262', [EN]: 'French Southern Territories', [ES]: 'Territorios Australes Franceses', [IT]: 'Territori del Sud della Francia' },
  { code: 'TG', language: 'fr', currency: 'XOF', phone: '228', [EN]: 'Togo', [ES]: 'Togo', [IT]: 'Togo' },
  { code: 'TH', language: 'th', currency: 'THB', phone: '66', [EN]: 'Thailand', [ES]: 'Tailandia', [IT]: 'Thailandia' },
  { code: 'TJ', language: 'tg', currency: 'TJS', phone: '992', [EN]: 'Tajikistan', [ES]: 'Tayikistán', [IT]: 'Tagikistan' },
  { code: 'TK', language: 'en', currency: 'NZD', phone: '690', [EN]: 'Tokelau', [ES]: 'Tokelau', [IT]: 'Tokelau' },
  { code: 'TL', language: 'pt', currency: 'USD', phone: '670', [EN]: 'Timor-Leste', [ES]: 'Timor-Leste', [IT]: 'Timor Est' },
  { code: 'TM', language: 'tk', currency: 'TMT', phone: '993', [EN]: 'Turkmenistan', [ES]: 'Turkmenistán', [IT]: 'Turkmenistan' },
  { code: 'TN', language: 'ar', currency: 'TND', phone: '216', [EN]: 'Tunisia', [ES]: 'Túnez', [IT]: 'Tunisia' },
  { code: 'TO', language: 'en', currency: 'TOP', phone: '676', [EN]: 'Tonga', [ES]: 'Tonga', [IT]: 'Tonga' },
  { code: 'TR', language: 'tr', currency: 'TRY', phone: '90', [EN]: 'Turkey', [ES]: 'Turquía', [IT]: 'Turkey' },
  { code: 'TT', language: 'en', currency: 'TTD', phone: '1-868', [EN]: 'Trinidad and Tobago', [ES]: 'Trinidad y Tobago', [IT]: 'Trinidad e Tobago' },
  { code: 'TV', language: 'en', currency: 'AUD', phone: '688', [EN]: 'Tuvalu', [ES]: 'Tuvalu', [IT]: 'Tuvalu' },
  { code: 'TZ', language: 'sw', currency: 'TZS', phone: '255', [EN]: 'Tanzania', [ES]: 'Tanzania', [IT]: 'Tanzania' },
  { code: 'UA', language: 'uk', currency: 'UAH', phone: '380', [EN]: 'Ukraine', [ES]: 'Ucrania', [IT]: 'Ucraina' },
  { code: 'UG', language: 'en', currency: 'UGX', phone: '256', [EN]: 'Uganda', [ES]: 'Uganda', [IT]: 'Uganda' },
  { code: 'US', language: 'en', currency: 'USD', phone: '1', [EN]: 'United States of America', [ES]: 'Estados Unidos de América', [IT]: 'Stati Uniti d\'America' },
  { code: 'UY', language: 'es', currency: 'UYU', phone: '598', [EN]: 'Uruguay', [ES]: 'Uruguay', [IT]: 'Uruguay' },
  { code: 'UZ', language: 'uz', currency: 'UZS', phone: '998', [EN]: 'Uzbekistan', [ES]: 'Uzbekistán', [IT]: 'Uzbekistan' },
  { code: 'VA', language: 'it', currency: 'EUR', phone: '379', [EN]: 'Holy See', [ES]: 'Ciudad del Vaticano', [IT]: 'Città del Vaticano' },
  { code: 'VC', language: 'en', currency: 'XCD', phone: '1-784', [EN]: 'Saint Vincent and the Grenadines', [ES]: 'San Vicente y las Granadinas', [IT]: 'Saint Vincent e Grenadine' },
  { code: 'VE', language: 'es', currency: 'VES', phone: '58', [EN]: 'Venezuela', [ES]: 'Venezuela', [IT]: 'Venezuela' },
  { code: 'VG', language: 'en', currency: 'USD', phone: '1-284', [EN]: 'British Virgin Islands', [ES]: 'Islas Vírgenes Británicas', [IT]: 'Isole Vergini Britanniche' },
  { code: 'VI', language: 'en', currency: 'USD', phone: '1-340', [EN]: 'United States Virgin Islands', [ES]: 'Islas Vírgenes de los Estados Unidos', [IT]: 'Isole Vergini Americane' },
  { code: 'VN', language: 'vi', currency: 'VND', phone: '84', [EN]: 'Viet Nam', [ES]: 'Vietnam', [IT]: 'Vietnam' },
  { code: 'VU', language: 'bi', currency: 'VUV', phone: '678', [EN]: 'Vanuatu', [ES]: 'Vanuatu', [IT]: 'Vanuatu' },
  { code: 'WF', language: 'fr', currency: 'XPF', phone: '681', [EN]: 'Wallis and Futuna', [ES]: 'Wallis y Futuna', [IT]: 'Wallis e Futuna' },
  { code: 'WS', language: 'sm', currency: 'WST', phone: '685', [EN]: 'Samoa', [ES]: 'Samoa', [IT]: 'Samoa' },
  { code: 'XK', language: 'sq', currency: 'EUR', phone: '967', [EN]: 'Yemen', [ES]: 'Yemen', [IT]: 'Yemen' },
  { code: 'YE', language: 'ar', currency: 'YER', phone: '262', [EN]: 'Mayotte', [ES]: 'Mayotte', [IT]: 'Mayotte' },
  { code: 'YT', language: 'fr', currency: 'EUR', phone: '383', [EN]: 'Kosovo', [ES]: 'Kosovo', [IT]: 'Kosovo' },
  { code: 'ZA', language: 'af', currency: 'ZAR', phone: '27', [EN]: 'South Africa', [ES]: 'Sudáfrica', [IT]: 'Sudafrica' },
  { code: 'ZM', language: 'en', currency: 'ZMW', phone: '260', [EN]: 'Zambia', [ES]: 'Zambia', [IT]: 'Zambia' },
  { code: 'ZW', language: 'en', currency: 'ZWL', phone: '263', [EN]: 'Zimbabwe', [ES]: 'Zimbabue', [IT]: 'Zimbabwe' }
];

export default countries;
