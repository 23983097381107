import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorMsg from '../components/ErrorMsg';

const useDataLoader = (getData) => {
  const params = useParams();
  const [state, setState] = useState<any>({ loaded: false });

  useEffect(() => {
    const loadData = async () => {
      let data, error;

      try {
        data = await getData(params);
      } catch (err: any) {
        error = err;
      }

      setState({ loaded: true, data, error });
    };

    loadData();
  }, [getData, params]);

  return state;
};

export const withDataLoader = (WrappedCmp, dataLoader) => (props) => {
  const { loaded, data, error } = useDataLoader(dataLoader);

  if (!loaded) {
    return null;
  }

  if (error) {
    return <ErrorMsg />;
  }

  return <WrappedCmp {...props} data={data} />;
};

export const useWithDataLoader = (dataLoader) => {
  const { loaded, data: loadedData, error: loadedDataError } = useDataLoader(dataLoader);
  const [data, setData] = useState<any>();

  useEffect(() => {
    setData(loadedData);
  }, [loadedData]);

  let isOk, message;

  if (loadedDataError) {
    message = <ErrorMsg />;
  } else if (!loaded || !data) {
    message = null;
  } else {
    isOk = true;
  }

  return { data, setData, loadedData, isOk, message };
};

export default useDataLoader;
