import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { UserRole } from '../../types/auth';
import { toast } from 'react-toastify';
import { t } from '../../translations';

type ProtectedRouteProps = {
  children: JSX.Element;
  requiredRoles?: UserRole[];
};

const ProtectedRoute = ({ children, requiredRoles }: ProtectedRouteProps) => {
  const { isLoggedIn, currentUser } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/auth/signin" />;
  }

  if (requiredRoles && requiredRoles.length > 0 && (!currentUser?.role || !requiredRoles.includes(currentUser?.role))) {
    toast.error(t.YouDontHaveAccessToTheRequestedPage);

    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
