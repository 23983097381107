import { z } from 'zod';
import { RestelPax } from './restel';
import { zx } from '../helpers/zod';
import { Location } from './locations';
import { RecordResult } from './search';

// Top Destination

export type TopDestination = {
  destinationName: string;
  topHotelsDest: TopHotelDest[];
}

export type FetchTopDestinationsProps = {
  checkin: string;
  checkout: string;
  rooms: string;
}

export type TopHotelDest = {
  hotelCode: string;
  hotelName: string;
  images: string[];
  services: TopHotelDestService[];
  paxes?: RestelPax[];
};

export type TopHotelDestService = {
  roomServiceCode?: string;
  serviceCode?: string;
  description: string;
}

// Trending Destinations

export type TrendingDestination = {
  name: string;
  type: Location,
  code: string,
  image: string,
  record: RecordResult,
}

export enum SettingsType {
  Site = 'Site',
  TopDestinations = 'Top Destinations',
  TrendingDestinations = 'Trending Destinations',
}

export const SettingsSchema = z.object({
  type: z.nativeEnum(SettingsType),
  siteSettings: z
    .object({
      allowNewBookings: zx.booleanFromString(),
      allowNewBookingsOutsideMoldova: zx.booleanFromString(),
      isProductionMode: zx.booleanFromString(),
    })
    .optional(),
  topDestinations: z
    .array(
      z.object({
        id: z.string().optional(),
        destination: z.object({
          id: z.string().optional(),
          type: z.nativeEnum(Location),
          code: z.string().trim().min(1),
          name: z.string().trim().min(1),
          record:z.any() as z.ZodType<RecordResult>,
        }),
        hotels: z.array(
          z.object({
            id: z.string().optional(),
            code: z.string().trim().min(1),
            name: z.string().trim().min(1),
            record: z.any() as z.ZodType<RecordResult>,
          })
        ),
      })
    )
    .optional(),
  trendingDestinations: z
    .array(
      z.object({
        type: z.nativeEnum(Location),
        image: z.any(),
        name: z.string(),
        code: z.string().trim().min(1),
        record: z.any() as z.ZodType<RecordResult>,
      })
    )
    .optional(),
});

export type Settings = z.infer<typeof SettingsSchema>;
