import { isNil, isString, omitBy, times, get, sum } from 'lodash';
import qs from 'qs';
import { AdjustmentType, Currency } from '../types';

export const isPromise = (p) => p && p.then && typeof p.then === 'function';

export const tryCatch = (fn) => {
  let result;

  try {
    result = fn();
  } catch (e) {
    return [undefined, e];
  }

  if (isPromise(result)) {
    return result.then((promiseResult) => [promiseResult, undefined]).catch((promiseError) => [undefined, promiseError]);
  }

  return [result, undefined];
};

export const pick = (config, value) => (value in config ? config[value] : config.default);

export const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const isBlank = (str) => isString(str) && str.trim() === '';

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getObjFromLocalStorage = <T = any>(name: string): T | Partial<T> => {
  const item = localStorage.getItem(name)
  try {
    return JSON.parse(item ?? '{}') as T;
  } catch {
    return {};
  }
}
export const existsInLocalStorage = (name: string): boolean => localStorage.getItem(name) !== null;
export const saveObjToLocalStorage = <T>(name: string, value: T): void => {
  localStorage.setItem(name, JSON.stringify(value))
};
export const removeObjFromLocalStorage = (name: string): void => localStorage.removeItem(name);

const COUPONS_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');

export const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const sample = (list) => list[randomInt(0, list.length - 1)];

export const randomCodes = (opts: { amountOfGroups?: [number, number], groupsLength?: [number, number] } = {}) => {
  const amountOfGroups = randomInt(...(opts.amountOfGroups || [1, 1]));
  const groupsLength = randomInt(...(opts.groupsLength || [1, 10]));

  return times(amountOfGroups, () => times(groupsLength, () => sample(COUPONS_CHARS)).join('')).join('-');
}

export const randomCouponCode = () => {
  return randomCodes({
    amountOfGroups: [1, 4],
    groupsLength: [3, 5],
  });
};

export const randomReferralCode = () => {
  return randomCodes({
    amountOfGroups: [1, 1],
    groupsLength: [5, 10],
  });
}

export const buildQueryString = (obj) => {
  const paramWithNoNil = omitBy(obj, isNil);
  // return new URLSearchParams(paramWithNoNil).toString();
  return qs.stringify(paramWithNoNil);
};

export const money = (m) => Number(m).toLocaleString('en-US');

export const calculateAdjustment = ({
  value,
  adjustmentType,
  adjustmentValue,
}: {
  value: number;
  adjustmentType: AdjustmentType;
  adjustmentValue: number;
}): number => {
  if (adjustmentType === AdjustmentType.Percentage) {
    const percentageValue = getPartFromPercentage({
      percent: adjustmentValue,
      total: value,
    });
    return Number(percentageValue.toFixed(2));
  }

  if (adjustmentType === AdjustmentType.Fixed) {
    return adjustmentValue;
  }

  throw new Error(`Missing implementation for adjustment type "${adjustmentType}"`);
};

export const getPercentage = ({ part, total }: { part: number; total: number }) =>
  (part * 100) / total;

export const getTotalFromPercentage = ({ part, percent }: { part: number; percent: number }) =>
  (part * 100) / percent;

export const getPartFromPercentage = ({ percent, total }: { percent: number; total: number }) =>
  (total * percent) / 100;

export const getQueryString = () => {
  let queryString = window.location.search;
  if (queryString.startsWith('?')) {
    queryString = queryString.substring(1);
  }

  const result = qs.parse(queryString);
  return result as Record<string, any>;
}

export const toString = (item: any): string => item === undefined || item === null ? '' : `${item}`;

export const getProp = <T>(prop: keyof T) => prop.toString();
export const getPropFrom = <T>(obj: T, prop: keyof T) => prop.toString();

export const setEmptyArrayInFormData = <T = any>(data: Partial<T>, propPaths: (keyof T)[]): void => {
  propPaths.forEach(propPath => {
    const currentValue = get(data, propPath);

    if (Array.isArray(currentValue) && currentValue.length === 0) {
      (data as any)._setEmptyArrayInFormData ||= [];
      (data as any)._setEmptyArrayInFormData.push(propPath)
    }
  })
}

export const batchPromises = <T>(tasks: (() => Promise<T>)[], { concurrency, delayInSeconds }: { concurrency: number, delayInSeconds: number }): Promise<T[]> => {
  return new Promise(async (resolve, reject) => {
    const results: T[] = [];
    let index = 0;

    while (index < tasks.length) {
      const timer = new Promise(resolve => setTimeout(resolve, delayInSeconds * 1000));

      const batch = tasks.slice(index, index + concurrency);
      try {
        const batchResults = await Promise.all(batch.map(promise => promise()));
        results.push(...batchResults);
      } catch (error) {
        return reject(error); // If any promise rejects, the entire operation fails
      }

      index += concurrency;

      await timer;
    }

    resolve(results);
  });
}

export const getRgb = ({ value, min, max }: { value: number, min: number, max: number }): { r: number, g: number, b: number } => {
  // Function to interpolate between two colors (MUI Green and MUI Red)
  function interpolateColor(color1, color2, factor) {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }

    const [r, g, b] = result;
    return { r, g, b }
  }

  // Function to convert hex color to RGB array
  function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  }

  const ratio = (value - min) / (max - min);
  const green = hexToRgb('#4caf50'); // MUI Green
  const red = hexToRgb('#f44336');   // MUI Red

  return interpolateColor(green, red, ratio);
}

export const displayPrice = (value: number|string, adjustmentType: AdjustmentType, currency: Currency): string => {
  return adjustmentType === AdjustmentType.Percentage
    ? `${value}%`
    : `${currency} ${value}`;
}
