import { Navigate, Outlet, Route } from 'react-router-dom';

import NotFoundPage from '../NotFoundPage';
import ErrorPage from '../ErrorPage';

import AdminLayout from './AdminLayout';
import DashboardPage from './DashboardPage';
import ProtectedRoute from '../auth/ProtectedRoute';
import { ListOrders } from './OrdersPage';
import { ListMessages } from './MessagesPage';
import { CreateCoupons, EditCoupons, ListCoupons } from './CouponsPage';
import { CreateFees, EditFees, ListFees } from './FeesPage';
import { ListLogs } from './LogsPage';
import { CreateBanner, EditBanner, ListBanners } from './BannersPage';
import { CreateTopHotels, EditTopHotels, ListTopHotels } from './TopHotels';
import { CreateReferrals, EditReferrals, ListReferrals } from './ReferralsPage';
import { EditSettings, ListSettings } from './SettingsPage';
import { UserRole } from '../../types/auth';
import { EditUsers, ListUsers } from './UsersPage';

const routes = (name: string, { List, Create, Edit }: { List: any; Create?: any; Edit?: any }) => (
  <>
    <Route path={name} element={<List />} errorElement={<ErrorPage />} />
    {Create && <Route path={`${name}/new`} element={<Create />} errorElement={<ErrorPage />} />}
    {Edit && <Route path={`${name}/edit/:id`} element={<Edit />} errorElement={<ErrorPage />} />}
  </>
);

const adminRouter = (
  <Route
    path="/admin"
    element={
      <ProtectedRoute requiredRoles={[UserRole.Admin]}>
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      </ProtectedRoute>
    }
    errorElement={<ErrorPage />}
  >
    <Route index element={<Navigate to="dashboard" />} errorElement={<ErrorPage />} />
    <Route path="dashboard" element={<DashboardPage />} errorElement={<ErrorPage />} />

    {routes('settings', {
      List: ListSettings,
      Edit: EditSettings,
    })}

    {routes('referrals', {
      List: ListReferrals,
      Create: CreateReferrals,
      Edit: EditReferrals,
    })}

    {routes('coupons', {
      List: ListCoupons,
      Create: CreateCoupons,
      Edit: EditCoupons,
    })}

    {routes('orders', {
      List: ListOrders,
    })}

    {routes('logs', {
      List: ListLogs,
    })}

    {routes('messages', {
      List: ListMessages,
    })}

    {routes('fees', {
      List: ListFees,
      Create: CreateFees,
      Edit: EditFees,
    })}

    {routes('banners', {
      List: ListBanners,
      Create: CreateBanner,
      Edit: EditBanner,
    })}

    {routes('top-hotels', {
      List: ListTopHotels,
      Create: CreateTopHotels,
      Edit: EditTopHotels,
    })}

    {routes('users', {
      List: ListUsers,
      Edit: EditUsers,
    })}
    <Route path="*" element={<NotFoundPage />} />
  </Route>
);

export default adminRouter;
