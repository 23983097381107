import { Box, Typography } from '@mui/material';
import Navbar from './Navbar';
import { Language } from '../../types';
import { language } from '../../translations';

const privacyContentEn = (
  <Box maxWidth="1200px" margin="0 auto" p={4} pt={6}>
    <h1 id="privacy-cookie-policy">Privacy &amp; Cookie Policy</h1>
    <p>
      Your privacy is important to us. We value your trust and are committed to protecting and safeguarding any personal information you
      give us. This document, which we update from time to time, describes how we use and process your personal data and how we use cookies.
      It also tells you how you can contact us if you have questions about your personal information.
    </p>
    <p>
      Bellitours provides online travel services through its own websites and mobile apps and through other online platforms such as
      partners' websites and social media. The information that follows applies to all of these platforms. This privacy policy applies to
      data collected on <strong>bellitours.com</strong>.
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>What information we collect from you</li>
      <li>How we use your information</li>
      <li>With whom we share your information</li>
      <li>How you can access your information</li>
      <li>Your choices with respect to the collection and use of your information</li>
      <li>Cookies and other Web technologies</li>
      <li>Display of tailored advertising/Your choices</li>
      <li>How we protect your information</li>
      <li>External links</li>
      <li>Changes to this Privacy Policy</li>
      <li>How you can contact us</li>
    </Box>
    <p>
      <strong>What information we collect from you</strong>
      <br />
      In General we receive and store any information you enter on our website or give us in any other way. This includes information that
      can identify you (&quot;personal information&quot;), including your first and last name, telephone number, postal and email addresses,
      fax number, and, if you make a reservation through our site, credit card information (such as credit card number, cardholder name, and
      expiration date). We also may request information about your hotel room preferences, loyal- ty, frequent flyer and/or car rental
      program information. You can choose not to provide information to us, but in general some information about you is required in order
      for you to register as a member; purchase products or services; complete a traveler pro- file; participate in a survey, competition or
      promotion; ask us a question; or initiate other transactions on our site.
    </p>
    <p>
      <strong>Travel Companion Information</strong>
      <br />
      When you make a reservation for someone else through bellitours.com, we will request personal information and travel prefer- ences
      about that individual. You should obtain the consent of other individuals prior to providing us with their personal infor- mation and
      travel preferences, as any access to view or change their information will be available only through your account.
    </p>
    <p>
      <strong>Information from Other Sources</strong>
      <br />
      We also may periodically obtain both personal and non-personal information about you from affiliated entities, business partners and
      other independent third-party sources and add it to our account information. Examples of information we may receive include: updated
      delivery and address information, purchase history, and demographic information.
    </p>
    <p>
      <strong>Call Monitoring</strong>
      <br />
      Calls to and from our customer service help desk may be recorded or monitored for the purpose of quality control and for staff
      training. Call recordings will be maintained as long as reasonably necessary and will then be deleted. Any personal information ob-
      tained from you during the call will be treated in accordance with the provisions of this Privacy Policy.
    </p>

    <strong>What personal data we process</strong>
    <br />
    <p>2.1. Common personal data</p>
    <p>
      For the purposes indicated in this Information, the Data Controller processes common personal data such as personal data (name,
      surname, tax code, address, telephone number, e-mail and other contact details).
    </p>
    <p>2.2. Personal data of family members or third parties</p>
    <p>
      Finally, to follow up on the requests you have made from time to time through the Site, the Data Controller may need to acquire per-
      sonal data of his family members and / or third parties, including minor subjects.
    </p>
    <p>
      In this sense, you undertake to inform your family members of these treatments, including by providing them with this information.
    </p>
    <p>2.3. Source of personal data.</p>
    <p>
      Your personal data processed by the Data Controller are those provided by you directly by filling in the various forms on the Site.
    </p>
    <strong>
      Purpose and legal basis of the processing, nature of the provision and consequences of a refusal to provide personal data
    </strong>
    <br />
    <p>3.1 Request for quotation and fulfillment of legal obligations</p>
    <p>
      Your personal data will be processed by BELLITOURS for activities strictly connected and instrumental to the generation of the esti-
      mate required for the trip you have selected and any booking and purchase of the same, as well as for the fulfillment of obligations
      under laws, regulations and / or Community regulations, or by supervisory and control bodies or by other legitimate authorities.
    </p>
    <p>
      The provision of your personal data is mandatory for this purpose. Your refusal will, in fact, make it impossible for the Data
      Controller to follow up on your request.
    </p>
    <p>
      For the treatments necessary for the pursuit of these purposes, it will not be necessary to obtain your consent because the legal
      basis of the treatment consists, pursuant to current legislation, in the satisfaction of a request made by you and in the fulfillment
      of a legal obligation.
    </p>
    <p>
      In any case, your personal data will be processed until the end of the trip and, after that date, will be kept exclusively for the
      period of time necessary to comply with current legislation (including the provisions of statute of limitations).
    </p>
    <p>3.2. Registration on the site</p>
    <p>
      Your personal data may also be processed by BELLITOURS to satisfy your request to register on the Site and to be able to provide you
      with the related services.
    </p>
    <p>
      Also in this case, the treatment does not require your prior consent as the legal basis of the treatment consists, in accordance with
      current legislation, in the satisfaction of the request made by you. The provision of your personal data for the purpose referred to
      in this Chapter is mandatory and your refusal will make it impossible for BELLITOURS to follow up on your request to register on the
      Site and to use the related services.
    </p>
    <p>
      The data collected for this purpose by the Data Controller will be kept for the period in which you decide to remain registered on the
      Site.
    </p>

    <p>
      3.3. <strong>Request for services through the Site</strong>
    </p>
    <p>
      Furthermore, your personal data may be processed by BELLITOURS to satisfy further requests made by you from time to time by filling in
      the appropriate forms available on the Site (for example, requests for information).
    </p>
    <p>
      The provision of data is mandatory and your refusal will make it impossible for the Data Controller to follow up on the requests you
      have made from time to time.
    </p>
    <p>
      The treatment does not require your consent as the legal basis consists in the satisfaction of the owner of a request made by you.
    </p>
    <p>
      The data collected for this purpose by the Data Controller may be processed for the time strictly necessary to satisfy the request
      made by you and, in any case, will be deleted 6 months after the request has been processed.
    </p>
    <p>
      <strong>How we use your information</strong>
      <br />
      We use credit card information (such as cardholder name, credit card number, and expiration date) for the purpose of completing the
      travel bookings you conduct on our site. We use other information about you for the following general purposes: to provide you with
      the products and services you request; to provide you with travel confirmation and updates; to manage your account, including
      processing bills and providing travel notifications; to communicate with you in general; to respond to your questions and comments; to
      allow us to contact you for customer service, if necessary; to measure interest in and improve our products, ser- vices, and website;
      to notify you by email or post about special offers and travel-related products and services that may be of in- terest to you; to
      otherwise customize your experience with bellitours.com; to reward you as part of any reward and recognition program you choose to
      join; to solicit information from you, including through surveys; to resolve disputes, collect fees, or trouble- shoot problems; to
      prevent potentially prohibited or illegal activities; to enforce our Terms of Use; and as otherwise described to you at the point of
      collection.
    </p>
    <p>
      <strong>Email Communications</strong>
      <br />
      We want to make it easy for you to take advantage of travel-related opportunities on our website. One way we do this is by send- ing
      you email messages that contain information about your travel-related interests. For example, if you search for a flight to Flori- da
      on our website and have saved your itinerary or otherwise not yet booked your travel, we may send you an email message re- minding you
      about your saved travel itinerary or about a special airfare to Florida. Similarly, if you receive an email from us about Florida
      travel and demonstrate an interest in Florida hotels by affirmatively clicking on a link about Florida hotels in the email, you may
      receive an email about Florida hotel offers or other destination information. We believe these email messages will provide you with
      useful information about travel-related special offers available through our sites. Please note that you will have the op- portunity
      to choose not to receive these email messages in any such email we send.
    </p>
    <p>
      <strong>With whom we share your information</strong>
      <br />
      Bellitours.com may share your information with the following entities:
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>
        Suppliers, such as hotel, airline, car rental, and activity providers, who fulfill your travel reservations. Throughout
        bellitours.com, all services provided by a third-party supplier are described as such. We encourage you to review the privacy
        policies of any third- party travel supplier whose products you purchase through bellitours.com. Please note that these suppliers
        also may contact you as necessary to obtain additional information about you, facilitate your travel reservation, or respond to a
        review you may submit.
      </li>
      <li>
        Third-party vendors who provide services or functions on our behalf, including credit card processing, business analytics, custom-
        er service, marketing, distribution of surveys or sweepstakes programs, and fraud prevention. We may also authorize third-party
        vendors to collect information on our behalf, including as necessary to operate features of our website or to facilitate the
        delivery of online advertising tailored to your interests. Third-party vendors have access to and may collect information only as
        needed to perform their functions and are not permitted to share or use the information for any other purpose.
      </li>
      <li>
        Business partners with whom we may jointly offer products or services. You can tell when a third party is involved in a product or
        service you have requested because their name will appear with ours. If you choose to access these optional services, we may share
        information about you, including your personal information, with those partners so that they can contact you directly by email or
        post about their products and services. Please note that we do not control the privacy practices of these third-party busi- ness
        partners.
      </li>
      <li>
        Referring websites. If you were referred to bellitours.com from another website, we may share some information about you with that
        referring website so that they can contact you directly by email or post about their products and services. We have not placed
        limitations on the referring websites’ use of your personal information and we encourage you to review the privacy policies of any
        website that referred you to bellitours.com
      </li>
      <li>
        Companies within our corporate family. We may share your personal information with our corporate affiliates in the leisure and
        travel sector, including bedsonline.com, TripAdvisor LLC; , Amadeus, Hertz, among others. This sharing enables us to provide you
        with information about products and services, both leisure- and travel-related, which might interest you. To the extent that our
        corporate affiliates have access to your information, they will follow practices that are at least as restrictive as the practices
        de- scribed in this Privacy Policy.
      </li>
    </Box>
    <p>
      <strong>We also may share your information:</strong>
    </p>

    <Box component="ul" sx={{ ml: 3 }}>
      <li>
        When we believe it is appropriate to investigate, prevent, or take action regarding illegal or suspected illegal activities; to
        protect and defend the rights, property, or safety of Bellitours, the website or users of the website; and in connection with our
        Terms of Use and other agreements.
      </li>
      <li>
        In connection with a corporate transaction, such as a sale of a subsidiary or a division, merger, consolidation, or asset sale, or
        in the unlikely event of bankruptcy. We also may share aggregate or anonymous information with third parties, including advertisers
        and investors. For example, we may tell our advertisers the number of visitors our websites receive or the most popular hotels and
        holiday destinations. This infor- mation does not contain any personal information and is used to develop content and services we
        hope you will find of interest and to audit the efficacy of our advertising.
      </li>
    </Box>
    <p>Your choices with respect to collection and use of your information</p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>
        As discussed above, you can choose not to provide us with any information, although it may be needed to make travel bookings or to
        take advantage of certain features offered on bellitours.com.
      </li>
      <li>
        When you accept to join the bellitours.com newsletter, you will be given a choice as to whether you want to receive email mes- sages
        from us about Travel special offers as well as email messages about products and services of selected third parties.
      </li>
      <li>
        When you subscribe the bellitours.com newsletter, you also will be given the opportunity to unsubscribe from messages in any such
        email we send. Please note that we reserve the right to send you other communications, including service announcements,
        administrative messages, and surveys relating either to your transactions on Bellitours.com, without offering you the opportunity to
        opt out of receiving them.
      </li>
    </Box>
    <p>
      We automatically collect some information about you and your computer when you visit bellitours.com. For example, we will col- lect
      your IP address, Web browser software (such as Firefox, Internet Explorer or Safari), and referring website. We also may collect
      information about your online activity, such as trips viewed and purchases made. One of our goals in collecting this automatic in-
      formation is to help customize your user experience. In addition to the above cookies, we use Local Shared Objects, also referred to
      as ”flash cookies,” on our website. These are used to enhance your user experience, for example, by storing your user preferences and
      settings, such as your volume/mute settings, and in connection with animated content on our website. Local Shared Objects are similar
      to browser cookies, but can store data more complex than simple text. By themselves, they cannot do anything to or with the data on
      your computer. Like other cookies, they can only access personally identifiable information that you have provided on this site, and
      cannot be accessed by other web- sites. To find out more about flash cookies or how to disable them, please click here.
    </p>
    <p>
      If you have any questions about our use of cookies or other technologies, please email us at our customer care : admin@bellitours.com
    </p>
    <p>
      <strong>Display of tailored advertising/Your choices</strong>
      <br />
      Data collected by business partners and ad networks to serve you with relevant advertising. The advertisements you see on this website
      are served by us or by our service providers. But we also allow third parties to collect information about your online. These third
      parties include: (1) other Bellitours companies; (2) Business partners, who collect information when you view or interact with one of
      their advertisements on our sites; and (3) Advertising networks, which collect information about your interests when you view or
      interact with one of the advertisements they place on many different websites on the Internet. The information gathered by these third
      parties is used to make predictions about your characteristics, interests or preferences and to display advertise- ments on our sites
      and across the Internet tailored to your apparent interests. We do not permit these third parties to collect per- sonal information
      about you (such as email address) on our site, nor do we share with them any personal information about you.
    </p>
    <p>
      Please note that we do not have access to or control over cookies or other technologies these third parties may use to collect in-
      formation about your interests, and the information practices of these third parties are not covered by this Privacy Policy. Some of
      these companies are members of the Network Advertising Initiative, which offers a single location to opt out of ad targeting from
      member companies. To learn more, please click here and here.
    </p>
    <p>
      Data collected by companies that operate cookie-based exchanges to serve you with relevant advertising. Like other companies operating
      online, this site participates in cookie-based exchanges where anonymous information is collected about your browsing behavior through
      cookies and other technologies and segmented into different topics of interest (such as travel). These topics of interest are then
      shared with third parties, including advertisers and ad networks, so they can tailor advertisements to your appar- ent interests. We
      do not share personal information (such as your email address) with these companies and we do not permit these companies to collect
      any such information about you on our site. Please click here to learn more about cookie-based ex- changes, including how to access
      information about the topics of interest associated with cookies on your computer and how to decline participation in these programs.
    </p>
    <p>
      <strong>How we protect your information</strong>
      <br />
      We want you to feel confident about using bellitours.com to make hotel and travel bookings, and we are committed to protecting the
      information we collect. While no website can guarantee security, we have implemented appropriate administrative, technical, and
      physical security procedures to help protect the personal information you provide to us. For example, only authorized employ- ees are
      permitted to access personal information, and they only may do so for permitted business functions. In addition, we use encryption
      when transmitting personal information that is sensitive to you (e.g., credit card details) between your system and ours, and we
      employ firewalls and intrusion detection systems to help prevent unauthorized persons from gaining access to your infor- mation.
    </p>
    <p>
      <strong>External links</strong>
      <br />
      If any part of the bellitours.com website links you to other websites, those websites do not operate under this Privacy Policy. We
      recommend you examine the privacy statements posted on those other websites to understand their procedures for collecting, using, and
      disclosing personal information.
    </p>
    <p>
      <strong>Changes to this Privacy Policy</strong>
      <br />
      We may update this Privacy Policy in the future. We will notify you about material changes to this Privacy Policy by sending a no-
      tice to the email address you provided to us or by placing a prominent notice on our website.
    </p>
    <p>
      <strong>How you can contact us</strong>
      <br />
      If you have questions about this Privacy Policy (or your travel planning or purchases), please contact us at: admin@bellitours.com
      Call: (305) 244-3651 from 8am - 7pm Monday to Friday. In addition, you may directly contact the travel supplier at the phone number or
      address provided on your travel itinerary.
    </p>
    <p>Write: You may also write to Bellitours, office: 15420 SW 136th ST UNIT 3 , Miami, FL,US, 33196</p>
  </Box>
);

const privacyContentEs = (
  <Box maxWidth="1200px" margin="0 auto" p={4} pt={6}>
    <h1 id="privacy-cookie-policy">Política de Privacidad y Cookies</h1>
    <p>
      Su privacidad es importante para nosotros. Valoramos su confianza y estamos comprometidos a proteger y salvaguardar cualquier
      información personal que nos proporcione. Este documento, que actualizamos de vez en cuando, describe cómo usamos y procesamos sus
      datos personales y cómo usamos las cookies. También le indica cómo puede contactarnos si tiene preguntas sobre su información
      personal.
    </p>
    <p>
      Bellitours proporciona servicios de viaje en línea a través de sus propios sitios web y aplicaciones móviles y a través de otras
      plataformas en línea como sitios web de socios y redes sociales. La información que sigue se aplica a todas estas plataformas. Esta
      política de privacidad se aplica a los datos recopilados en <strong>bellitours.com</strong>.
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>Qué información recopilamos de usted</li>
      <li>Cómo usamos su información</li>
      <li>Con quién compartimos su información</li>
      <li>Cómo puede acceder a su información</li>
      <li>Sus opciones con respecto a la recopilación y uso de su información</li>
      <li>Cookies y otras tecnologías web</li>
      <li>Exhibición de publicidad personalizada/Sus opciones</li>
      <li>Cómo protegemos su información</li>
      <li>Enlaces externos</li>
      <li>Cambios a esta Política de Privacidad</li>
      <li>Cómo puede contactarnos</li>
    </Box>
    <p>
      <strong>Qué información recopilamos de usted</strong>
      <br />
      En general, recibimos y almacenamos cualquier información que ingrese en nuestro sitio web o que nos proporcione de cualquier otra
      manera. Esto incluye información que puede identificarle (&quot;información personal&quot;), incluyendo su nombre y apellido, número
      de teléfono, direcciones postal y de correo electrónico, número de fax y, si realiza una reserva a través de nuestro sitio,
      información de la tarjeta de crédito (como el número de la tarjeta de crédito, nombre del titular de la tarjeta y fecha de
      vencimiento). También podemos solicitar información sobre sus preferencias de habitación de hotel, programas de lealtad, de viajero
      frecuente y/o de alquiler de coches. Puede elegir no proporcionarnos información, pero en general, se requiere cierta información
      sobre usted para registrarse como miembro; comprar productos o servicios; completar un perfil de viajero; participar en una encuesta,
      competencia o promoción; hacernos una pregunta; o iniciar otras transacciones en nuestro sitio.
    </p>
    <p>
      <strong>Información de Compañeros de Viaje</strong>
      <br />
      Cuando realiza una reserva para otra persona a través de bellitours.com, solicitaremos información personal y preferencias de viaje
      sobre esa persona. Debe obtener el consentimiento de otras personas antes de proporcionarnos su información personal y preferencias de
      viaje, ya que cualquier acceso para ver o cambiar su información estará disponible solo a través de su cuenta.
    </p>
    <p>
      <strong>Información de Otras Fuentes</strong>
      <br />
      También podemos obtener periódicamente información personal y no personal sobre usted de entidades afiliadas, socios comerciales y
      otras fuentes independientes de terceros y agregarla a la información de nuestra cuenta. Ejemplos de información que podemos recibir
      incluyen: información actualizada de entrega y dirección, historial de compras e información demográfica.
    </p>
    <p>
      <strong>Monitoreo de Llamadas</strong>
      <br />
      Las llamadas a y desde nuestro centro de atención al cliente pueden ser grabadas o monitoreadas con el propósito de control de calidad
      y para el entrenamiento del personal. Las grabaciones de llamadas se mantendrán durante el tiempo razonablemente necesario y luego
      serán eliminadas. Cualquier información personal obtenida de usted durante la llamada será tratada de acuerdo con las disposiciones de
      esta Política de Privacidad.
    </p>
    <strong>Qué datos personales procesamos</strong>
    <br />
    <p>2.1. Datos personales comunes</p>
    <p>
      Para los fines indicados en esta Información, el Responsable del Tratamiento procesa datos personales comunes como datos personales
      (nombre, apellido, código fiscal, dirección, número de teléfono, correo electrónico y otros datos de contacto).
    </p>
    <p>2.2. Datos personales de familiares o terceros</p>
    <p>
      Finalmente, para dar seguimiento a las solicitudes que ha realizado de vez en cuando a través del Sitio, el Responsable del
      Tratamiento puede necesitar adquirir datos personales de sus familiares y / o terceros, incluidos menores de edad.
    </p>
    <p>En este sentido, se compromete a informar a sus familiares sobre estos tratamientos, incluyendo proporcionando esta información.</p>
    <p>2.3. Fuente de los datos personales.</p>
    <p>
      Sus datos personales procesados por el Responsable del Tratamiento son los proporcionados por usted directamente al completar los
      diversos formularios en el Sitio.
    </p>
    <strong>
      Propósito y base legal del tratamiento, naturaleza de la provisión y consecuencias de la negativa a proporcionar datos personales
    </strong>
    <br />
    <p>3.1 Solicitud de cotización y cumplimiento de obligaciones legales</p>
    <p>
      Sus datos personales serán procesados por BELLITOURS para actividades estrictamente conectadas e instrumentales a la generación de la
      cotización requerida para el viaje que ha seleccionado y cualquier reserva y compra del mismo, así como para el cumplimiento de
      obligaciones bajo leyes, regulaciones y / o regulaciones comunitarias, o por organismos de supervisión y control u otras autoridades
      legítimas.
    </p>
    <p>
      La provisión de sus datos personales es obligatoria para este propósito. Su negativa, de hecho, hará imposible que el Responsable del
      Tratamiento dé seguimiento a su solicitud.
    </p>
    <p>
      Para los tratamientos necesarios para la consecución de estos fines, no será necesario obtener su consentimiento porque la base legal
      del tratamiento consiste, de conformidad con la legislación vigente, en la satisfacción de una solicitud realizada por usted y en el
      cumplimiento de una obligación legal.
    </p>
    <p>
      En cualquier caso, sus datos personales serán procesados hasta el final del viaje y, después de esa fecha, se mantendrán
      exclusivamente por el período de tiempo necesario para cumplir con la legislación vigente (incluidas las disposiciones de los plazos
      de prescripción).
    </p>
    <p>3.2. Registro en el sitio</p>
    <p>
      Sus datos personales también pueden ser procesados por BELLITOURS para satisfacer su solicitud de registro en el Sitio y poder
      proporcionarle los servicios relacionados.
    </p>
    <p>
      También en este caso, el tratamiento no requiere su consentimiento previo, ya que la base legal del tratamiento consiste, de
      conformidad con la legislación vigente, en la satisfacción de la solicitud realizada por usted. La provisión de sus datos personales
      para el propósito mencionado en este capítulo es obligatoria y su negativa hará imposible que BELLITOURS dé seguimiento a su solicitud
      de registro en el Sitio y utilice los servicios relacionados.
    </p>
    <p>
      Los datos recopilados para este propósito por el Responsable del Tratamiento se mantendrán durante el período en el que decida
      permanecer registrado en el Sitio.
    </p>

    <p>
      3.3. <strong>Solicitud de servicios a través del sitio</strong>
    </p>
    <p>
      Además, sus datos personales pueden ser procesados por BELLITOURS para satisfacer solicitudes adicionales que realice de vez en cuando
      completando los formularios correspondientes disponibles en el Sitio (por ejemplo, solicitudes de información).
    </p>
    <p>
      La provisión de datos es obligatoria y su negativa hará imposible que el Responsable del Tratamiento dé seguimiento a las solicitudes
      que haya realizado de vez en cuando.
    </p>
    <p>
      El tratamiento no requiere su consentimiento, ya que la base legal consiste en la satisfacción del propietario de una solicitud
      realizada por usted.
    </p>
    <p>
      Los datos recopilados para este propósito por el Responsable del Tratamiento pueden ser procesados por el tiempo estrictamente
      necesario para satisfacer la solicitud realizada por usted y, en cualquier caso, serán eliminados 6 meses después de que la solicitud
      haya sido procesada.
    </p>
    <p>
      <strong>Cómo usamos su información</strong>
      <br />
      Utilizamos la información de la tarjeta de crédito (como el nombre del titular de la tarjeta, el número de la tarjeta de crédito y la
      fecha de vencimiento) con el propósito de completar las reservas de viaje que realiza en nuestro sitio. Utilizamos otra información
      sobre usted para los siguientes propósitos generales: para proporcionarle los productos y servicios que solicita; para proporcionarle
      confirmaciones de viaje y actualizaciones; para gestionar su cuenta, incluyendo el procesamiento de facturas y el envío de
      notificaciones de viaje; para comunicarnos con usted en general; para responder a sus preguntas y comentarios; para permitirnos
      contactarlo para atención al cliente, si es necesario; para medir el interés y mejorar nuestros productos, servicios y sitio web; para
      notificarle por correo electrónico o postal sobre ofertas especiales y productos y servicios relacionados con viajes que puedan ser de
      su interés; para personalizar su experiencia con bellitours.com; para recompensarlo como parte de cualquier programa de recompensas y
      reconocimiento al que decida unirse; para solicitar información de usted, incluyendo a través de encuestas; para resolver disputas,
      cobrar tarifas o solucionar problemas; para prevenir actividades potencialmente prohibidas o ilegales; para hacer cumplir nuestros
      Términos de Uso; y como se le haya descrito de otra manera en el punto de recopilación.
    </p>
    <p>
      <strong>Comunicaciones por correo electrónico</strong>
      <br />
      Queremos facilitarle aprovechar las oportunidades relacionadas con viajes en nuestro sitio web. Una forma en que hacemos esto es
      enviándole mensajes de correo electrónico que contienen información sobre sus intereses relacionados con viajes. Por ejemplo, si busca
      un vuelo a Florida en nuestro sitio web y ha guardado su itinerario o aún no ha reservado su viaje, podemos enviarle un mensaje de
      correo electrónico recordándole sobre su itinerario de viaje guardado o sobre una tarifa especial a Florida. De manera similar, si
      recibe un correo electrónico de nuestra parte sobre viajes a Florida y muestra interés en hoteles en Florida haciendo clic en un
      enlace sobre hoteles en Florida en el correo electrónico, puede recibir un correo electrónico sobre ofertas de hoteles en Florida u
      otra información sobre el destino. Creemos que estos mensajes de correo electrónico le proporcionarán información útil sobre ofertas
      especiales relacionadas con viajes disponibles a través de nuestros sitios. Tenga en cuenta que tendrá la oportunidad de optar por no
      recibir estos mensajes de correo electrónico en cualquier correo electrónico que enviemos.
    </p>
    <p>
      <strong>Con quién compartimos su información</strong>
      <br />
      Bellitours.com puede compartir su información con las siguientes entidades:
    </p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>
        Proveedores, como hoteles, aerolíneas, proveedores de alquiler de coches y actividades, que cumplen con sus reservas de viaje. A lo
        largo de bellitours.com, todos los servicios proporcionados por un proveedor externo se describen como tales. Le animamos a revisar
        las políticas de privacidad de cualquier proveedor de viajes externo cuyos productos compre a través de bellitours.com. Tenga en
        cuenta que estos proveedores también pueden contactarlo según sea necesario para obtener información adicional sobre usted,
        facilitar su reserva de viaje o responder a una reseña que pueda enviar.
      </li>
      <li>
        Proveedores externos que proporcionan servicios o funciones en nuestro nombre, incluyendo procesamiento de tarjetas de crédito,
        análisis empresarial, atención al cliente, marketing, distribución de encuestas o programas de sorteos, y prevención de fraudes.
        También podemos autorizar a proveedores externos a recopilar información en nuestro nombre, según sea necesario para operar
        características de nuestro sitio web o para facilitar la entrega de publicidad en línea adaptada a sus intereses. Los proveedores
        externos tienen acceso y pueden recopilar información solo en la medida necesaria para realizar sus funciones y no se les permite
        compartir o utilizar la información para cualquier otro propósito.
      </li>
      <li>
        Socios comerciales con quienes podemos ofrecer productos o servicios conjuntamente. Puede saber cuándo un tercero está involucrado
        en un producto o servicio que ha solicitado porque su nombre aparecerá junto al nuestro. Si elige acceder a estos servicios
        opcionales, podemos compartir información sobre usted, incluyendo su información personal, con esos socios para que puedan
        contactarlo directamente por correo electrónico o postal sobre sus productos y servicios. Tenga en cuenta que no controlamos las
        prácticas de privacidad de estos socios comerciales externos.
      </li>
      <li>
        Sitios web de referencia. Si fue referido a bellitours.com desde otro sitio web, podemos compartir alguna información sobre usted
        con ese sitio web de referencia para que puedan contactarlo directamente por correo electrónico o postal sobre sus productos y
        servicios. No hemos impuesto limitaciones en el uso de su información personal por parte de los sitios web de referencia y le
        animamos a revisar las políticas de privacidad de cualquier sitio web que lo haya referido a bellitours.com
      </li>
      <li>
        Empresas dentro de nuestra familia corporativa. Podemos compartir su información personal con nuestros afiliados corporativos en el
        sector del ocio y viajes, incluyendo bedsonline.com, TripAdvisor LLC, Amadeus, Hertz, entre otros. Este intercambio nos permite
        proporcionarle información sobre productos y servicios, tanto relacionados con el ocio como con los viajes, que puedan interesarle.
        En la medida en que nuestros afiliados corporativos tengan acceso a su información, seguirán prácticas que son al menos tan
        restrictivas como las prácticas descritas en esta Política de Privacidad.
      </li>
    </Box>
    <p>
      <strong>También podemos compartir su información:</strong>
    </p>

    <Box component="ul" sx={{ ml: 3 }}>
      <li>
        Cuando creemos que es apropiado investigar, prevenir o tomar medidas con respecto a actividades ilegales o sospechosas de ser
        ilegales; para proteger y defender los derechos, la propiedad o la seguridad de Bellitours, el sitio web o los usuarios del sitio
        web; y en conexión con nuestros Términos de Uso y otros acuerdos.
      </li>
      <li>
        En relación con una transacción corporativa, como la venta de una subsidiaria o una división, fusión, consolidación o venta de
        activos, o en el improbable caso de quiebra. También podemos compartir información agregada o anónima con terceros, incluidos
        anunciantes e inversores. Por ejemplo, podemos informar a nuestros anunciantes el número de visitantes que reciben nuestros sitios
        web o los hoteles y destinos de vacaciones más populares. Esta información no contiene información personal y se utiliza para
        desarrollar contenido y servicios que esperamos le resulten de interés y para auditar la eficacia de nuestra publicidad.
      </li>
    </Box>
    <p>Sus opciones con respecto a la recopilación y uso de su información</p>
    <Box component="ul" sx={{ ml: 3 }}>
      <li>
        Como se mencionó anteriormente, puede optar por no proporcionarnos ninguna información, aunque puede ser necesaria para realizar
        reservas de viaje o aprovechar ciertas funciones ofrecidas en bellitours.com.
      </li>
      <li>
        Cuando acepte unirse al boletín de bellitours.com, se le dará la opción de si desea recibir mensajes de correo electrónico de
        nuestra parte sobre ofertas especiales de viajes, así como mensajes de correo electrónico sobre productos y servicios de terceros
        seleccionados.
      </li>
      <li>
        Cuando se suscriba al boletín de bellitours.com, también se le dará la oportunidad de darse de baja de los mensajes en cualquiera de
        esos correos electrónicos que enviemos. Tenga en cuenta que nos reservamos el derecho de enviarle otras comunicaciones, incluidos
        anuncios de servicio, mensajes administrativos y encuestas relacionadas con sus transacciones en Bellitours.com, sin ofrecerle la
        oportunidad de optar por no recibirlos.
      </li>
    </Box>
    <p>
      Recopilamos automáticamente alguna información sobre usted y su computadora cuando visita bellitours.com. Por ejemplo, recopilaremos
      su dirección IP, el software de su navegador web (como Firefox, Internet Explorer o Safari) y el sitio web de referencia. También
      podemos recopilar información sobre su actividad en línea, como viajes vistos y compras realizadas. Uno de nuestros objetivos al
      recopilar esta información automática es ayudar a personalizar su experiencia de usuario. Además de las cookies mencionadas,
      utilizamos Objetos Compartidos Locales, también conocidos como “cookies de flash,” en nuestro sitio web. Estos se utilizan para
      mejorar su experiencia de usuario, por ejemplo, almacenando sus preferencias y configuraciones, como sus configuraciones de
      volumen/silencio, y en conexión con contenido animado en nuestro sitio web. Los Objetos Compartidos Locales son similares a las
      cookies del navegador, pero pueden almacenar datos más complejos que texto simple. Por sí mismos, no pueden hacer nada con los datos
      en su computadora. Al igual que otras cookies, solo pueden acceder a información personal identificable que usted haya proporcionado
      en este sitio, y no pueden ser accedidos por otros sitios web. Para obtener más información sobre las cookies de flash o cómo
      desactivarlas, haga clic aquí.
    </p>
    <p>
      Si tiene alguna pregunta sobre nuestro uso de cookies u otras tecnologías, envíenos un correo electrónico a nuestro servicio de
      atención al cliente: admin@bellitours.com
    </p>
    <p>
      <strong>Exhibición de publicidad personalizada/Sus opciones</strong>
      <br />
      Datos recopilados por socios comerciales y redes de anuncios para servirle publicidad relevante. Los anuncios que ve en este sitio web
      son servidos por nosotros o por nuestros proveedores de servicios. Pero también permitimos que terceros recopilen información sobre su
      actividad en línea. Estos terceros incluyen: (1) otras empresas de Bellitours; (2) Socios comerciales, que recopilan información
      cuando usted visualiza o interactúa con uno de sus anuncios en nuestros sitios; y (3) Redes publicitarias, que recopilan información
      sobre sus intereses cuando usted visualiza o interactúa con uno de los anuncios que colocan en muchos sitios web diferentes en
      Internet. La información recopilada por estos terceros se utiliza para hacer predicciones sobre sus características, intereses o
      preferencias y para mostrar anuncios en nuestros sitios y en toda la Internet adaptados a sus aparentes intereses. No permitimos que
      estos terceros recopilen información personal sobre usted (como su dirección de correo electrónico) en nuestro sitio, ni compartimos
      con ellos ninguna información personal sobre usted.
    </p>
    <p>
      Tenga en cuenta que no tenemos acceso ni control sobre las cookies u otras tecnologías que estos terceros pueden usar para recopilar
      información sobre sus intereses, y las prácticas de información de estos terceros no están cubiertas por esta Política de Privacidad.
      Algunas de estas empresas son miembros de la Network Advertising Initiative, que ofrece una ubicación única para optar por no recibir
      anuncios personalizados de empresas miembros. Para obtener más información, haga clic aquí y aquí.
    </p>
    <p>
      Datos recopilados por empresas que operan intercambios basados en cookies para servirle publicidad relevante. Al igual que otras
      empresas que operan en línea, este sitio participa en intercambios basados en cookies donde se recopila información anónima sobre su
      comportamiento de navegación a través de cookies y otras tecnologías y se segmenta en diferentes temas de interés (como viajes). Estos
      temas de interés se comparten con terceros, incluidos anunciantes y redes publicitarias, para que puedan personalizar anuncios según
      sus aparentes intereses. No compartimos información personal (como su dirección de correo electrónico) con estas empresas y no
      permitimos que estas empresas recopilen dicha información sobre usted en nuestro sitio. Haga clic aquí para obtener más información
      sobre los intercambios basados en cookies, incluidos cómo acceder a la información sobre los temas de interés asociados con las
      cookies en su computadora y cómo rechazar la participación en estos programas.
    </p>
    <p>
      <strong>Cómo protegemos su información</strong>
      <br />
      Queremos que se sienta seguro al usar bellitours.com para realizar reservas de hotel y viajes, y estamos comprometidos con la
      protección de la información que recopilamos. Si bien ningún sitio web puede garantizar la seguridad, hemos implementado
      procedimientos de seguridad administrativos, técnicos y físicos apropiados para ayudar a proteger la información personal que nos
      proporciona. Por ejemplo, solo empleados autorizados están permitidos para acceder a información personal, y solo pueden hacerlo para
      funciones comerciales permitidas. Además, utilizamos cifrado al transmitir información personal sensible (por ejemplo, detalles de
      tarjetas de crédito) entre su sistema y el nuestro, y empleamos cortafuegos y sistemas de detección de intrusos para ayudar a prevenir
      que personas no autorizadas accedan a su información.
    </p>
    <p>
      <strong>Enlaces externos</strong>
      <br />
      Si alguna parte del sitio web de bellitours.com lo vincula a otros sitios web, esos sitios no operan bajo esta Política de Privacidad.
      Recomendamos que examine las declaraciones de privacidad publicadas en esos otros sitios web para comprender sus procedimientos de
      recopilación, uso y divulgación de información personal.
    </p>
    <p>
      <strong>Cambios a esta Política de Privacidad</strong>
      <br />
      Podemos actualizar esta Política de Privacidad en el futuro. Le notificaremos sobre cambios materiales a esta Política de Privacidad
      enviando un aviso a la dirección de correo electrónico que nos proporcionó o colocando un aviso destacado en nuestro sitio web.
    </p>
    <p>
      <strong>Cómo puede contactarnos</strong>
      <br />
      Si tiene preguntas sobre esta Política de Privacidad (o su planificación de viajes o compras), contáctenos en: admin@bellitours.com
      Llame: (305) 244-3651 de 8am a 7pm de lunes a viernes. Además, puede contactar directamente al proveedor de viajes en el número de
      teléfono o dirección proporcionada en su itinerario de viaje.
    </p>
    <p>Escriba: También puede escribir a Bellitours, oficina: 15420 SW 136th ST UNIT 3, Miami, FL, EE.UU., 33196</p>
  </Box>
);

const PRIVACY_CONTENT_CONFIG = {
  [Language.EN]: privacyContentEn,
  [Language.ES]: privacyContentEs,
};

const PrivacyPage = () => (
  <>
    <Navbar withNavbar />
    {PRIVACY_CONTENT_CONFIG[language] || privacyContentEn}
  </>
);

export default PrivacyPage;
