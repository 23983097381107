import { Box, Link, Typography } from '@mui/material';
import { withDataLoader } from '../../hooks/use-data-loader';
import { t } from '../../translations';
import AdminAPI from '../../api/admin';

const DashboardPage = ({ data: dashboardsData }) => (
  <Box height="100%" sx={{ pt: 5, pl: 5 }}>
    <Typography variant="h6" mb={2}>
      {t.AdminLinks}
    </Typography>
    <Box component="ul" pl={4}>
      <Box component="li" mb={1}>
        <Link href="/">{t.Website}</Link>
      </Box>
      <Box component="li" mb={1}>
        <Link href="/admin/fees">{t.Fees}</Link>
      </Box>
      <Box component="li" mb={1}>
        <Link href="/admin/orders">{t.Orders}</Link>
      </Box>
      <Box component="li" mb={1}>
        <Link href="/admin/messages">{t.Messages}</Link>
      </Box>
      <Box component="li" mb={1}>
        <Link href="/admin/coupons">{t.Coupons}</Link>
      </Box>
      <Box component="li" mb={1}>
        <Link href="/admin/banners">{t.Banners}</Link>
      </Box>
      <Box component="li" mb={1}>
        <Link href="/admin/referrals">{t.Referrals}</Link>
      </Box>
      <Box component="li" mb={1}>
        <Link href="/admin/users">{t.Users}</Link>
      </Box>
      <Box component="li" mb={1}>
        <Link href="/admin/settings">{t.Settings}</Link>
      </Box>
      <Box component="li" mb={1}>
        <Link href="/admin/logs">{t.Logs}</Link>
      </Box>
    </Box>
  </Box>
);

export default withDataLoader(DashboardPage, AdminAPI.dashboards.getAll);
