import { IpInfo } from '../api/ip-info';
import countries from '../components/CountryInput/countries';
import { existsInLocalStorage } from '../helpers/utils';
import { Language } from '../types';
import { en } from './en';
import { es } from './es';
import { it } from './it';

export type Translations = typeof en;

export const translationsPerLanguage: { [key in Language]: Translations } = {
  [Language.EN]: en,
  [Language.ES]: es,
  [Language.IT]: it,
}

export const languageNamesPerLanguage: { [key in Language]: string } = {
  [Language.EN]: 'English',
  [Language.ES]: 'Español',
  [Language.IT]: 'Italian',
}

const LANGUAGE_KEY = 'LANGUAGE_v1';

export const setLanguage = (language: Language, opts: { skipReload?: boolean } = {}) => {
  localStorage.setItem(LANGUAGE_KEY, language);
  document.cookie = `locale=${language}; path=/;`;

  if (!opts.skipReload) {
    window.location.reload();
  }
}

export const initLanguage = (ipInfo?: IpInfo): boolean => {
  if (existsInLocalStorage(LANGUAGE_KEY)) {
    return false;
  }

  const country = ipInfo?.country
    ? countries.find(country => country.code === ipInfo.country)
    : null;

  const languageToSelect = isValidLanguage(country?.language)
    ? country?.language as Language
    : Language.EN

  setLanguage(languageToSelect);
  return true;
}

const isValidLanguage = (language: string | undefined | null): boolean => Object.values(Language).includes(language as Language)

export const language = isValidLanguage(localStorage.getItem(LANGUAGE_KEY))
  ? localStorage.getItem(LANGUAGE_KEY) as Language
  : Language.EN;

export const languageName = languageNamesPerLanguage[language];

export const t = translationsPerLanguage[language];
