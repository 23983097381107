import { Box, Typography } from '@mui/material';
import { t } from '../../translations';

const ErrorMsg = (props) => (
  <Box>
    <Typography component="h1" variant="h1">
      Oops!
    </Typography>
    <Typography>{props.msg || t.SorryAnUnexpectedErrorHasOccurred}</Typography>
  </Box>
);

export default ErrorMsg;
