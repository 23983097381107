import { Link as LinkRouter } from 'react-router-dom';
import AdminAPI from '../../api/admin';
import { getListComponent } from '../../components/CRUDAdmin/list';
import { getEditorComponent } from '../../components/CRUDAdmin/edit';
import { t } from '../../translations';
import { Box, Card, CardContent, MenuItem, TextField, Link } from '@mui/material';
import { FormComponentType } from '../../components/CRUDAdmin/edit';
import { z } from 'zod';
import { formatToDate, getNextWeekend } from '../../helpers/date-time';
import { times } from 'lodash';

const { startDate, endDate } = getNextWeekend({ daysAhead: 30 });

const DESTINATION_TYPES = ['Country', 'Province', 'City'] as const;

const TopHotelSchema = z.object({
  destinationType: z.enum(DESTINATION_TYPES),
  destinationCode: z.string().min(1),
  hotels: z.array(z.object({
    hotelCode: z.string().min(1),
  }))
});

const columns = [
  {
    field: 'destinationType',
    headerName: t.DestinationType,
    width: 200,
  },
  {
    field: 'destinationCode',
    headerName: t.DestinationCode,
    width: 200,
  },
  {
    field: 't.hotels',
    headerName: t.Hotels,
    width: 200,
    renderCell: ({ row }) => {
      const item = row as z.infer<typeof TopHotelSchema>;

      return (
        <ul>
          {(item.hotels || []).map((hotel) => (
            <li>
              <Link
                component={LinkRouter}
                to={`/availability/${hotel.hotelCode}/${formatToDate(startDate)}/${formatToDate(endDate)}`}
                underline="none"
              >
                {hotel.hotelCode}
              </Link>
            </li>
          ))}
        </ul>
      );
    },
  },
];

const TopHotelsForm: FormComponentType = ({ data, errors, showErrors, editable, onChange, onInputChange }) => {
  return (
    <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative' }}>
      <CardContent>
        <Box sx={{ mt: 4 }}>
        <pre>{JSON.stringify(data, null, 2)}</pre>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              select
              label={t.DestinationType}
              value={data.destinationType || ''}
              error={!!(showErrors && errors.destinationType)}
              helperText={showErrors && errors.destinationType}
              onChange={onInputChange('destinationType')}
              InputProps={{
                readOnly: !editable,
              }}
            >
              {DESTINATION_TYPES.map((destinationType) => (
                <MenuItem key={destinationType} value={destinationType}>
                  {destinationType}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label={t.DestinationCode}
              value={data.destinationCode || ''}
              error={!!(showErrors && errors.destinationCode)}
              helperText={showErrors && errors.destinationCode}
              onChange={onInputChange('destinationCode')}
              InputProps={{
                readOnly: !editable,
              }}
            />
          </Box>

          {times(3).map(i => {
            const hotelCodeError = errors[`hotels.${i}.hotelCode`];
            return (<Box sx={{ mt: 2 }} key={i}>
              <pre>value: {data.hotels[i].hotelCode}</pre>
              <TextField
                fullWidth
                label={t.HotelCode}
                value={data.hotels[i].hotelCode || ''}
                error={!!(showErrors && hotelCodeError)}
                helperText={showErrors && hotelCodeError}
                onChange={(ev) => onChange(currentData => {
                  const a = {
                    ...currentData,
                    hotels: currentData.hotels.map((item, j) => ({
                      ...item,
                      hotelCode: i === j
                        ? ev.target.value
                        : item.hotelCode
                    }))
                  }
                  console.log(a);
                  return a
                })}
                InputProps={{
                  readOnly: !editable,
                }}
              />
            </Box>)
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

const baseProps = {
  name: 'Top Hotels',
  baseRoute: 'top-hotels',
  FormComponent: TopHotelsForm,
  schema: TopHotelSchema,
}

export const ListTopHotels = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  dataLoader: AdminAPI.topHotels.getAll,
  destroy: AdminAPI.topHotels.delete,
});

export const CreateTopHotels = getEditorComponent({
  ...baseProps,
  dataLoader: () => Promise.resolve({ hotels: times(3).map(() => ({ hotelCode: '' })) }),
  save: AdminAPI.topHotels.create,
});

export const EditTopHotels = getEditorComponent({
  ...baseProps,
  dataLoader: (params) => AdminAPI.topHotels.find(params.id),
  save: (props) => AdminAPI.topHotels.update(props._id, props),
});
