import { Box, Paper, Typography } from '@mui/material';
import { t } from '../../translations';

const HotelImportantInfo = (props) => {
  const { hotel } = props;

  return (
    <Paper sx={{ px: 3, py: 2, mb: 2 }}>
      <Typography variant="h5" mb={3}>
        {t.ImportantInfo}
      </Typography>

      <Box>
        {hotel.checkin && (
          <Typography component="span">
            {t.CheckIn}:{' '}
            <Typography component="span" fontWeight="bold">
              {hotel.checkin}
            </Typography>
          </Typography>
        )}
        {hotel.checkin && hotel.checkout && ' / '}
        {hotel.checkout && (
          <Typography component="span">
            {t.CheckOut}:{' '}
            <Typography component="span" fontWeight="bold">
              {hotel.checkout}
            </Typography>
          </Typography>
        )}
      </Box>

      {hotel.maxChildAge && (
        <Box mt={2}>
          <Typography>
            {t.hotelConsiderChildrenFrom({
              from: (
                <Typography component="span" fontWeight="bold">
                  {hotel.minChildAge || 0}
                </Typography>
              ),
              to: (
                <Typography component="span" fontWeight="bold">
                  {hotel.maxChildAge || 0}
                </Typography>
              )
            })}
          </Typography>
        </Box>
      )}

      {hotel.cityTax && (
        <Box mt={2}>
          <Typography component="span" fontWeight="bold">
            {t.CityTax}:{' '}
            <Typography component="span" color="text.secondary">
              {hotel.cityTax}
            </Typography>
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default HotelImportantInfo;
