import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import 'react-international-phone/style.css';
import { isFunction, isString, sum } from 'lodash';
import HTMLReactParser from 'html-react-parser';
import { COLOR } from '../../helpers/styles';
import { useState } from 'react';
import { calculateAdjustment, displayPrice, money } from '../../helpers/utils';
import Coupon from '../Coupon/Coupon';
import { language, t } from '../../translations';
import { AdjustmentType } from '../../types';
import { useAuth } from '../../pages/auth/AuthProvider';
import { UserRole } from '../../types/auth';
import { z } from 'zod';
import { useForm } from '../../hooks/useForm';

const getFeeValue = (fee, price) => {
  return calculateAdjustment({
    value: price,
    adjustmentType: fee.chargeType,
    adjustmentValue: fee.amount,
  });
};

const CommissionSchema = z.object({
  amount: z.coerce.number().gte(0, t.Invalid),
  type: z.nativeEnum(AdjustmentType),
});

type Commission = z.infer<typeof CommissionSchema>;

const PaymentReservationDetails = (props) => {
  const { paxes, cardInfo, order, expenseFees, editable, clientName, agentName, paymentDetailsRef, sx } = props;

  const [coupon, setCoupon] = useState<any>(order?.coupon);

  const { currentUser } = useAuth();
  const allowEnterCommission = [UserRole.Admin, UserRole.Agent].includes(currentUser?.role!);
  const [getInitialCommission] = useState(
    () => () => Promise.resolve<Commission>(order?.commission || { amount: 0, type: AdjustmentType.Percentage })
  );
  const {
    hasError: commissionHasError,
    data: commission,
    textInput,
    selectInput,
  } = useForm({
    schema: CommissionSchema,
    showErrorsOnChange: true,
    isEditable: true,
    dataLoader: getInitialCommission,
  });
  const commissionIsValid = !commissionHasError;

  const currency = order?.currency || paxes[0].pkg.currency;
  const price = order?.priceWithMarkup || sum(paxes.flatMap(({ pkg }) => Number(pkg.finalPrice)));

  const commissionAdjustment = commission
    ? calculateAdjustment({
        value: price,
        adjustmentType: commission.type,
        adjustmentValue: Math.max(commission.amount, 0),
      })
    : 0;

  let priceWithCoupon;
  if (order?.priceWithCoupon) {
    priceWithCoupon = order?.priceWithCoupon;
  } else if (coupon?.applicable) {
    const adjustment = calculateAdjustment({
      value: price,
      adjustmentType: coupon.discountType,
      adjustmentValue: coupon.discountAmount,
    });
    priceWithCoupon = price - adjustment;
    priceWithCoupon = Number(Number(Math.max(priceWithCoupon, 0)).toFixed(2));
  }
  const couponDiscount = priceWithCoupon ? Number(Number(price - priceWithCoupon).toFixed(2)) : undefined;

  const subtotal = order?.subtotal || Number(Number((priceWithCoupon || price) + commissionAdjustment).toFixed(2));
  const orderExpenses =
    order?.orderExpenses ||
    expenseFees.map((fee) => ({
      amount: fee.amount,
      chargeType: fee.chargeType,
      text: fee?.textPerLanguage?.[language] || fee.text,
      feePrice: getFeeValue(fee, subtotal),
    }));
  const total = order?.total || Number((subtotal + sum(orderExpenses.map((item) => item.feePrice))).toFixed(2));

  paymentDetailsRef.current = {
    currency,
    price,
    priceWithCoupon,
    couponDiscount,
    subtotal,
    total,
    orderExpenses,
    coupon,
    commissionIsValid,
    commission: allowEnterCommission ? commission : undefined,
  };

  const details = [
    ...(cardInfo ? [[t.PaymentType, `${cardInfo.brand} ${cardInfo.type}`]] : []),
    ...(cardInfo ? [[t.CreditCardLast4, `${'xxxx-'.repeat(3)}${cardInfo.last4}`]] : []),
    ...(cardInfo ? [[t.CreditCardExp, `${cardInfo.expMonth}/${cardInfo.expYear}`]] : []),
    ...paxes.flatMap(({ pax, room, pkg }, i) => [
      [t.PropertyPrice + (paxes.length > 1 ? `, ${i + 1}` : ''), `${pkg.currency} ${money(pkg.finalPrice)}`],
    ]),
    ...(coupon?.applicable
      ? [
          [
            `${t.Coupon} (${
              coupon.discountType === AdjustmentType.Percentage ? `${coupon.discountAmount}%` : `${currency} ${coupon.discountAmount}`
            } ${t.DiscountOff})`,
            () => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <span>
                  - {currency} {couponDiscount}
                </span>
                {editable && (
                  <Button variant="text" sx={{ marginLeft: 'auto' }} onClick={() => setCoupon(null)}>
                    {t.RemoveCoupon}
                  </Button>
                )}
              </Box>
            ),
          ],
        ]
      : []),
    ...(allowEnterCommission
      ? [
          [
            t.Commission,
            () => {
              if (editable) {
                return (
                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    {selectInput(
                      'type',
                      [
                        <MenuItem value={AdjustmentType.Percentage}>{t.Percentage}</MenuItem>,
                        <MenuItem value={AdjustmentType.Fixed}>{t.Fixed}</MenuItem>,
                      ],
                      { label: t.DiscountType, sx: { maxWidth: '120px' } }
                    )}
                    <Box sx={{ ml: 1 }}>
                      {textInput('amount', {
                        label: t.Amount,
                        type: 'number',
                        sx: { maxWidth: '120px' },
                        InputProps: { inputProps: { min: 0 } },
                      })}
                    </Box>
                  </Box>
                );
              }

              if (order.commission) {
                return <Box>{displayPrice(order.commission.amount, order.commission.type, currency)}</Box>;
              }

              return <Box></Box>;
            },
          ],
        ]
      : []),
    [
      `<b>${t.Subtotal}</b>`,
      () => (
        <Typography variant="h6">
          {currency} {money(subtotal)}
        </Typography>
      ),
      { liSx: { background: COLOR.GRAY_ITEM_OVER } },
    ],
    ...orderExpenses.map((expense) => [
      `${expense?.textPerLanguage?.[language] || expense.text}`,
      `${currency} ${expense.feePrice}`,
    ]),
    [
      `<b>${t.Total}</b>`,
      () => (
        <Typography variant="h6">
          {currency} {money(total)}
        </Typography>
      ),
      { liSx: { background: COLOR.GRAY_ITEM_OVER } },
    ],
  ];

  return (
    <>
      <Box pt={2} sx={{ border: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`, ...sx }}>
        <Typography variant="h5" mb={2} ml={3}>
          {t.PaymentInformation}
        </Typography>

        <Box component="ul" sx={{ borderTop: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}` }}>
          {details.map(([key, value, props]) => (
            <Box
              key={key}
              component="li"
              sx={{ listStyle: 'none', p: 2, borderBottom: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`, ...props?.liSx }}
            >
              <Stack direction="row" justifyContent="flex-start" alignItems="center">
                <Typography sx={{ flex: 2 }}>{HTMLReactParser(key)}</Typography>
                <Box sx={{ flex: 3, pl: 2 }}>
                  {isString(value) ? <Typography>{value}</Typography> : isFunction(value) ? value() : value}
                </Box>
              </Stack>
            </Box>
          ))}
        </Box>
      </Box>

      <Box mt={2} mr={1}></Box>
      {editable && <Coupon coupon={coupon} price={price} clientName={clientName} agentName={agentName} onSetCoupon={setCoupon} />}
    </>
  );
};

export default PaymentReservationDetails;
