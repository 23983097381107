import { Box, Chip, Grid, Paper, Rating, Stack, Typography } from '@mui/material';
import Image from 'mui-image';
import { FEATURED_ICONS, HOTEL_SERVICES_ICONS } from '../icons';
import { COLOR } from '../../helpers/styles';
import { useState } from 'react';
import { t } from '../../translations';

const HotelOverview = (props) => {
  const { hotel } = props;

  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const minAmountToShow = 6;

  const establishmentType = hotel.type?.toLowerCase();
  const chips = [
    {
      component: FEATURED_ICONS[establishmentType],
      text: establishmentType || '',
      visible: true,
    },
    {
      component: FEATURED_ICONS.certificated,
      text: t.Certificated,
      visible: hotel.certificated,
    },
  ];

  const popularAmenities = [...(hotel.services || [])].map((service) => ({
    serviceCode: service.serviceCode,
    Icon: HOTEL_SERVICES_ICONS[service.serviceCode] || HOTEL_SERVICES_ICONS.default,
    description: service.description,
  }));

  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
      {/* overview */}
      <Grid container>
        <Grid xs={12} md={7} item>
          <Typography component="div" variant="h4" color="primary" mb={1} mt={1}>
            {hotel.hotelName}
          </Typography>
          <Stack direction="row" spacing={{ xs: 2 }} mb={2}>
            {hotel.category && <Rating name="read-only" value={hotel.category} readOnly precision={1} size="large" />}
            {chips.map(
              ({ visible, text, component: IconComponent }) =>
                visible && IconComponent && <Chip icon={<IconComponent />} label={text} {...{size: 'large'} as any} />
            )}
          </Stack>
          <Box>
            <Typography>{t.Phone}: {hotel.phone}</Typography>
          </Box>
          {popularAmenities.length > 0 && (
            <Box maxWidth="400px">
              <Typography
                component="div"
                variant="h5"
                color="text.secondary"
                mb={2}
                mt={2}
                borderBottom={`1px solid ${COLOR.GRAY_LINE_SEPARATOR}`}
                display="inline-block"
              >
                {t.PopularAmenities}
              </Typography>
              <Grid container>
                {popularAmenities
                  .slice(0, showAllAmenities ? popularAmenities.length + 1 : minAmountToShow)
                  .map(({ serviceCode, Icon, description }) => (
                    <Grid xs={6} key={description} item>
                      <Stack direction="row" mb={0.5}>
                        <Box mr={1} component="span">
                          <Icon />
                        </Box>
                        <Typography display="none">serviceCode: {serviceCode}</Typography>
                        {description}
                      </Stack>
                    </Grid>
                  ))}
                <Grid xs={12} item>
                  <Typography
                    onClick={() => setShowAllAmenities(!showAllAmenities)}
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    {popularAmenities.length > minAmountToShow && (showAllAmenities ? t.ShowLess : t.ShowAll)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>

        {hotel.map && (
          <Grid xs={12} md={5} item textAlign="center" mb={2}>
            <Box sx={{ p: 0.3, maxWidth: '400px' }}>
              <Typography color="text.secondary">{hotel.address}</Typography>
              {hotel.map && (
                <Paper variant="outlined" sx={{ p: 0.3, height: '250px' }}>
                  <Image src={hotel.map} duration={0} />
                </Paper>
              )}
            </Box>
          </Grid>
        )}

        <Grid xs={12} item>
          <Typography variant="subtitle2" mt={1} color="text.secondary">
            {hotel.hotelDescription}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HotelOverview;
