/* eslint-disable eqeqeq */
import FullScreenDialog from '../Dialogs/FullScreenDialog';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Link, Paper, Stack, TextField, Grid, FormControlLabel, Checkbox } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { isMobile } from 'react-device-detect';
import { Image } from 'mui-image';
import { toast } from 'react-toastify';
import { cancelPreReserve, confirmReserve, getExpenseFees, preReserve } from '../../api/hotels';
import HotelReservationDetails from '../HotelReservationDetails/HotelReservationDetails';
import { Link as LinkRouter } from 'react-router-dom';
import ClientInfoInput from '../Inputs/ClientInfoInput';
import Guests from '../Inputs/GuestsInput';
import CreditCardInput from '../Inputs/CreditCardInput';
import PaymentReservationDetails from '../PaymentReservationDetails/PaymentReservationDetails';
import AccordionDownloadableLink from '../Accordion/AccordionDownloadableLink';
import logoImage from '../../components/assets/logo.png';
import { getReferralId } from '../../pages/Referral';
import AnimatedSVG from '../Controllers/AnimatedSVG';
import useBreakpoints from '../../hooks/use-breakpoints';
import { useLoading } from '../../contexts/LoadingContext/LoadingContext';
import { t } from '../../translations';

const HOTELS_API_URL = process.env.REACT_APP_HOTELS_API_URL;

const Checkout = ({ hotel, paxes, checkin, checkout, openRef, closeRef, onClosed }) => {
  const isXs = useBreakpoints().only.xs;
  const { loading, setLoading } = useLoading();
  const containerRef = useRef<any>(null);
  const openedRef = useRef<any>(null);

  const [expenseFees, setExpenseFees] = useState<any>(undefined);
  const [form, setForm] = useState<any>({ contact: null, guests: null, hotelRequest: null, agent: null });
  const [formErrors, setFormErrors] = useState({});
  const createCardToken = useRef<any>(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const paymentDetailsRef = useRef<any>({});
  const [order, setOrder] = useState<any>(null);
  const openConfirmOrderDialogRef = useRef<any>(null);
  const [isOrderConfirmed, setIsOrderConfirmed] = useState(false);

  useEffect(function loadExpenses() {
    setLoading(true);
    getExpenseFees()
      .then((data) => setExpenseFees(data.expenseFees))
      .finally(() => setLoading(false));
  }, []);

  const onChangeFormProp = (prop) => (value) =>
    setForm((currentForm) => ({
      ...currentForm,
      [prop]: value,
    }));

  const onFormValidationChange = (prop) => (value) =>
    setFormErrors((currentErrors) => ({
      ...currentErrors,
      [prop]: value,
    }));

  const onCloseCheckout = () => {
    setForm({ contact: null, guests: null, hotelRequest: null, agent: null });
    setFormErrors({});
    setAcceptedTerms(false);
    setShowErrors(false);

    paymentDetailsRef.current = {};
    setOrder(null);
    setIsOrderConfirmed(false);

    return true;
  };

  const confirmOrder = async (order) => {
    setLoading(true);
    try {
      await confirmReserve({
        params: { orderId: order._id },
      });
      setIsOrderConfirmed(true);
      try {
        containerRef.current?.scrollTo?.({ top: 0 });
      } catch {}
    } catch (error: any) {
      setOrder(null);

      toast.error(t.AnErrorHasOccurredTryAgain(error?.response?.data?.message));
      console.error(error);
    }
    setLoading(false);
  };

  const cancelOrder = async (order) => {
    setLoading(true);
    try {
      await cancelPreReserve({
        params: {
          orderId: order._id,
        },
      });
    } catch (error: any) {
      console.error(error);
    }
    setOrder(null);
    setLoading(false);
  };

  const completeCheckout = async () => {
    setShowErrors(true);

    const hasFormErrors = Object.values(formErrors).find(Boolean);
    if (hasFormErrors || !acceptedTerms || !paymentDetailsRef.current.commissionIsValid) {
      toast.error(t.PleaseCompleteTheRequiredInformation);
      return;
    }

    const { token, cardInfo, error } = await createCardToken.current();
    if (error) {
      toast.error(t.ThereWasAnErrorWithYourCreditCard);
      return false;
    }

    const { coupon } = paymentDetailsRef.current;
    const invalidCoupon =
      (coupon?.clientName && coupon?.clientName !== form.contact.fullName) || (coupon?.agentName && coupon?.agentName !== form.agent);
    if (invalidCoupon) {
      toast.error(t.TheCouponDoesNotApplyPleaseRemoveTheCouponBeforeProceed);
      return false;
    }

    setLoading(true);
    let orderResult;
    try {
      orderResult = await preReserve({
        params: {
          // order preferences
          language: 'en',
          // hotel info
          hotelCode: hotel.code,
          isAnApartment: hotel.isAnApartment,
          // dates
          checkin: checkin,
          checkout: checkout,
          // rooms info
          amountOfRooms: paxes.length,
          lines: paxes.flatMap(({ pkg }) => pkg.nights.map((night) => night.line)),
          roomPrices: paxes.flatMap(({ pax, room, pkg }) => pkg),
          roomDetails,
          // client info
          client: form.contact,
          observations: form.hotelRequest || '',
          agent: form.agent || '',
          guests: form.guests,
          contactToPickupAptKeys: {
            fullName: form.contact.fullName,
            phoneAndEmail: `${form.contact.phone} ${form.contact.email}`,
          },
          // payment info
          stripeCardTokenId: token.id,
          cardInfo,
          // referral
          referralId: getReferralId(),
          // prices shown to the user
          pricesShownToTheUser: paymentDetailsRef.current,
        },
      });
      setOrder(orderResult);
    } catch (error: any) {
      setLoading(false);
      setOrder(null);

      toast.error(t.AnErrorHasOccurred(error?.response?.data?.message));
      console.error(error);

      return;
    }

    if (paymentDetailsRef.current.total == orderResult.total) {
      confirmOrder(orderResult);
      return;
    } else {
      setLoading(false);
      openConfirmOrderDialogRef.current();
    }
  };

  const onConfirmedOrder = () => {
    confirmOrder(order);
    return true;
  };

  const onCancelOrderConfirmation = () => {
    cancelOrder(order);
    return true;
  };

  const roomDetails = paxes.map(({ pax, room, pkg }, i) => {
    const multipleRooms = paxes.length > 1;
    const roomName = t.Room + (multipleRooms ? ` ${i + 1}` : '');

    return {
      name: roomName,
      description:
        `${room.roomDescription}, ${pkg.accommodationType} (${pax.adults} ${pax.adults === 1 ? t.adult : t.adults}` +
        (pax.children ? `,${pax.children} ${t.children}` : '') +
        ')',
      policiesDetails: pkg.cancellationFees.map((policy, idx) => ({
        name: t.CancellationPolicy + ' ' + (pkg.cancellationFees.length > 1 ? ` ${idx + 1} ` : '') + (multipleRooms ? `(${roomName})` : ''),
        description: policy.summary,
      })),
    };
  });

  const linkTarget = isMobile ? '_self' : '_blank';

  if (!expenseFees) {
    return null;
  }

  return (
    <>
      <FullScreenDialog
        hideOpenDialogBtn
        containerRef={containerRef}
        openedRef={openedRef}
        openRef={openRef}
        closeRef={closeRef}
        customTitle={() => (
          <Box sx={{ width: 'calc(100vw - 100px)' }}>
            <Box
              sx={{
                width: '160px',
                position: 'absolute',
                ml: 4,
                mt: -3,
                marginLeft: 'auto',
                marginRight: 'auto',
                left: 0,
                right: 0,
              }}
            >
              <Image src={logoImage} duration={0} width="100%" />
            </Box>
          </Box>
        )}
        onCloseDialog={onCloseCheckout}
        onClosed={onClosed}
      >
        <Box sx={{ px: isXs ? 1 : 4, pt: 2, pb: isXs ? 2 : 6, mb: 3, maxWidth: '1440px', margin: '0 auto' }}>
          {isOrderConfirmed ? (
            <Box mt={4}>
              <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}></Grid>
              <BoxPaper title="">
                <Typography variant="h4" color="success.main" textAlign="center">
                  {t.ReservationConfirmed}
                </Typography>
                <Typography variant="h6" textAlign="center" mt={2} color="gray">
                  {t.BookingAndPaymentReceiptsSentToEmail}
                </Typography>
                <AnimatedSVG />
                <Box textAlign="center" p={2}>
                  <Link component={LinkRouter} to="/" fontSize={16}>
                    {t.SearchMoreProperties}
                  </Link>
                </Box>

                <Box mb={2}>
                  <AccordionDownloadableLink
                    labelTitle={t.BookingDetails}
                    accordionId="booking-accordion"
                    initialExpanded={true}
                    link={`${HOTELS_API_URL}hotels/voucher/reservation/${order._id}`}
                  >
                    <HotelReservationDetails
                      hotel={hotel}
                      checkin={checkin}
                      checkout={checkout}
                      roomDetails={roomDetails}
                      clientInfo={order.clientRequest}
                      bookingReference={order.preReserve.reservationNumber}
                      additionalInfo={order.preReserve.additionalInfo}
                      sx={{ background: '#ffffffb0' }}
                    />
                  </AccordionDownloadableLink>
                </Box>

                <Box mb={4}>
                  <AccordionDownloadableLink
                    labelTitle={t.PaymentDetails}
                    accordionId="booking-accordion"
                    initialExpanded={true}
                    link={`${HOTELS_API_URL}hotels/voucher/payment/${order._id}`}
                  >
                    <PaymentReservationDetails
                      editable={false}
                      paymentDetailsRef={paymentDetailsRef}
                      paxes={paxes}
                      expenseFees={expenseFees}
                      order={order}
                      cardInfo={order.clientRequest.cardInfo}
                      sx={{ background: '#ffffffb0' }}
                    />
                  </AccordionDownloadableLink>
                </Box>
              </BoxPaper>
            </Box>
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                <BoxPaper title={t.YourContactInfo}>
                  <ClientInfoInput
                    displayValidationErrors={showErrors}
                    onChange={onChangeFormProp('contact')}
                    onValidationChange={onFormValidationChange('contact')}
                  />
                </BoxPaper>
                <BoxPaper title="">
                  <Guests
                    displayValidationErrors={showErrors}
                    minGuests={paxes.reduce((acc, { pax: { adults, children } }) => acc + Number(adults) + Number(children), 0)}
                    onChange={onChangeFormProp('guests')}
                    onValidationChange={onFormValidationChange('guests')}
                  />
                </BoxPaper>
                <BoxPaper title="">
                  <Typography variant="h6" fontSize={16} ml={1} mb={1}>
                    {t.HotelRequest}
                  </Typography>
                  <TextField
                    label={t.SpecialRequestForTheHotel}
                    fullWidth
                    sx={{ mb: 4 }}
                    multiline
                    rows={2}
                    value={form.hotelRequest || ''}
                    onChange={(ev) => onChangeFormProp('hotelRequest')(ev.target.value)}
                  />

                  <Typography variant="h6" fontSize={16} ml={1} mb={1}>
                    {t.Agent}
                  </Typography>
                  <TextField
                    label={t.ReferringAgent}
                    fullWidth
                    // sime="small"
                    sx={{ mb: 0.5 }}
                    value={form.agent || ''}
                    onChange={(ev) => onChangeFormProp('agent')(ev.target.value)}
                  />
                  <Typography color="text.secondary" fontSize={12}>
                    {t.IfYouWereAssistedByAnAgentProvideTheirName}
                  </Typography>
                </BoxPaper>
                <BoxPaper title={t.YourPaymentInfo}>
                  <CreditCardInput
                    withAddress
                    onSetCreateToken={(createToken) => {
                      createCardToken.current = createToken;
                    }}
                  />
                </BoxPaper>
                <BoxPaper title={t.ConfirmBooking}>
                  <Box sx={{ display: 'inline-block' }}>
                    <Stack alignItems="flex-start" direction="row">
                      <FormControlLabel
                        control={<Checkbox onChange={(ev) => setAcceptedTerms(ev.target.checked)} checked={acceptedTerms} />}
                        sx={{ mr: 0 }}
                        label={undefined}
                      />
                      <Typography component="span">
                        {t.acceptTerms({
                          privacyStatementLink: (
                            <Link href="/privacy" target={linkTarget}>
                              {t.PrivacyStatement}
                            </Link>
                          ),
                          termsOfServiceLink: (
                            <Link href="/terms" target={linkTarget}>
                              {t.TermsOfService}
                            </Link>
                          )
                        })}
                      </Typography>
                    </Stack>
                    {showErrors && !acceptedTerms && (
                      <Typography color="red" sx={{ ml: 2 }}>
                        {t.ThisFieldIsRequired}
                      </Typography>
                    )}
                  </Box>

                  <Button
                    disabled={loading}
                    variant="contained"
                    size="large"
                    onClick={completeCheckout}
                    startIcon={<LockIcon />}
                    sx={{ mb: 2, mt: 2 }}
                  >
                    {t.CompleteBooking}
                  </Button>
                  <Typography variant="body2">
                    <LockIcon sx={{ fontSize: 16, verticalAlign: 'sub' }} />
                    {t.protectionOfPersonalInfoMsg}
                  </Typography>
                </BoxPaper>
              </Grid>
              <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                <HotelReservationDetails
                  hotel={hotel}
                  checkin={checkin}
                  checkout={checkout}
                  roomDetails={roomDetails}
                  sx={{ background: '#ffffffb0' }}
                />
                <Box mb={4} />
                {paxes?.length > 0 && (
                  <PaymentReservationDetails
                    editable
                    paymentDetailsRef={paymentDetailsRef}
                    paxes={paxes}
                    expenseFees={expenseFees}
                    clientName={form.contact?.fullName}
                    agentName={form.contact?.agent}
                    sx={{ background: '#ffffffb0' }}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </FullScreenDialog>

      <ConfirmationDialog
        hideOpenDialogBtn
        maxWidth="sm"
        fullWidth
        paperProps={{ maxHeight: 600 }}
        titleLabel={t.ImportantNotice}
        okLabel={t.Accept}
        cancelLabel={t.Cancel}
        openRef={openConfirmOrderDialogRef}
        onSave={onConfirmedOrder}
        onCancel={onCancelOrderConfirmation}
      >
        <Stack direction="column" alignContent="space-between" gap={3}>
          {order?.preReserve?.additionalInfo && (
            <Box border="1px solid gray" p={2}>
              <Typography mb={1}>
                <b>
                  {t.TheHotelHasProvidedTheFollowingImportantInfo}
                </b>
              </Typography>
              <Typography>{order.preReserve.additionalInfo}</Typography>
            </Box>
          )}
          {order && order.total !== paymentDetailsRef.current.total && (
            <Box border="1px solid gray" p={2} pb={0}>
              {order.total < paymentDetailsRef.current.total ? (
                <Typography>
                  <b>
                    {t.GoodNewsPriceHasDecreasedMsg}
                  </b>
                </Typography>
              ) : (
                <Typography mb={2}>
                  {t.goodNewsPriceHasDecreasedToNewTotalMsg(order.total)}
                </Typography>
              )}
              <PaymentReservationDetails
                editable={false}
                paymentDetailsRef={paymentDetailsRef}
                paxes={paxes}
                expenseFees={expenseFees}
                order={order}
                clientName={form.contact?.fullName}
                agentName={form.contact?.agent}
                sx={{ background: '#ffffffb0' }}
              />
            </Box>
          )}
        </Stack>
      </ConfirmationDialog>
    </>
  );
};

const BoxPaper = ({ title, children }) => (
  <Paper variant="outlined" sx={{ px: { xs: 1, sm: 4 }, pt: 2, pb: 2, mb: 3 }}>
    <Typography variant="h6" mb={2} ml={1}>
      {title}
    </Typography>

    {children}
  </Paper>
);

export default Checkout;
