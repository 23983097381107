import { Box, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import RoomDescription from './RoomDescription';
import { t } from '../../translations';

// TODO: replace this for custom Popover
const RoomDescriptionPopover = ({ roomsDescriptions }) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          fontSize: 13,
          cursor: 'pointer',
          textDecoration: 'underline',
          display: 'inline-block',
          width: 'fit-content',
        }}
      >
        {t.viewMore}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 2 }}>
          {roomsDescriptions.map((desc) => (
            <Box sx={{ mb: 2 }} key={desc}>
              <RoomDescription key={desc} desc={desc} />
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default RoomDescriptionPopover;
