import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';

import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)',
  },
});

// fix for draggable issue with react StrictMode
// https://github.com/atlassian/react-beautiful-dnd/issues/2350#issuecomment-1318179729
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = React.useState(false);
  React.useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

const DraggableListItem = ({ item, index, renderItem, getItemId }) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={`${getItemId(item)}`} index={index}>
      {(provided, snapshot) => (
        <ListItem sx={{p: 0}}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          {renderItem(item)}
          {/* <ListItemAvatar>

          </ListItemAvatar>
          <ListItemText primary={item.text} secondary={item.text} /> */}
          {/*
           */}
        </ListItem>
      )}
    </Draggable>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableList = ({ items, onChange, renderItem, getItemId }) => {
  const onDragEnd = ({ destination, source }) => {
    if (!destination) {
      // dropped outside the list
      return;
    }

    const newItems = reorder(items, source.index, destination.index);
    onChange(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableListItem item={item} index={index} key={getItemId(item)} renderItem={renderItem} getItemId={getItemId} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export default DraggableList;
