import { useState } from 'react';
import Button from '@mui/material/Button';

const BaseDialog = (props) => {
  // renderDialog is preferable to avoid the lost-focus issue with the inputs
  const { openedRef, openRef, closeRef, Dialog, onOpenDialog, onCloseDialog, onOpened, onClosed, renderDialog } = props;

  const [open, setOpen] = useState(false);

  const openDialog = () => {
    if (onOpenDialog && !onOpenDialog()) {
      return;
    }

    setOpen(true);
    onOpened?.();
  };

  const closeDialog = () => {
    if (onCloseDialog && !onCloseDialog()) {
      return;
    }

    setOpen(false);
    onClosed?.();
  }

  if (openRef) {
    openRef.current = openDialog;
  }
  if (closeRef) {
    closeRef.current = closeDialog;
  }
  if (openedRef) {
    openedRef.current = open;
  }

  const OpenDialogCmp =
    props.openDialogCmp ||
    (() => (
      <Button variant="outlined" onClick={openDialog} {...(props.openDialogButtonProps || {})}>
        {props.openDialogLabel}
      </Button>
    ));

  props.onSetCloseDialog?.(closeDialog);
  props.onSetOpenDialog?.(openDialog);

  return (
    <>
      {!props.hideOpenDialogBtn && <OpenDialogCmp openDialog={openDialog} />}
      {renderDialog ? renderDialog({ open, closeDialog, ...props }) : <Dialog open={open} closeDialog={closeDialog} {...props} />}
    </>
  );
};

export default BaseDialog;
