import { Box, Popover as MuiPopover, Popper, Paper, ClickAwayListener } from '@mui/material';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';

// https://mui.com/material-ui/react-popper/
const Popover = (props) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [id] = useState(() => uuid());

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    props.onOpened?.();
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.onClosed?.();
  };

  const open = Boolean(anchorEl);
  const openOn = props.openOn || 'mouseHover';
  const openOnMouseHover = openOn === 'mouseHover';
  const openOnClick = openOn === 'click';

  const popoverTarget = (
    <Box
      aria-owns={open ? id : undefined}
      aria-describedby={open ? id : undefined}
      aria-haspopup="true"
      onMouseEnter={(ev) => openOnMouseHover && handleOpen(ev)}
      onMouseLeave={(ev) => openOnMouseHover && handleClose(/*ev*/)}
      onClick={(ev) => openOnClick && !props.renderTarget && handleOpen(ev)}
      sx={{ width: props.fullWidth ? '100%' : 'fit-content', ...(props.inline && { display: 'inline-block' })}}
    >
      {props.renderTarget ? props.renderTarget({ open: handleOpen }) : props.target}
    </Box>
  );

  const clickAway = () => {
    if (((openOnClick && !props.disableCloseOnClickAway) || openOnMouseHover) && open) {
      // console.log('click away');
      handleClose();
    }
  };

  const popoverContent = (
    <MuiPopover
      id={id}
      sx={{
        ...(openOnMouseHover && {
          pointerEvents: 'none',
        }),
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        // horizontal: 'left',
        horizontal: props.horizontal ?? 100,
      }}
      onClose={() => ((openOnClick && !props.disableCloseOnClickAway) || openOnMouseHover) && handleClose()}
      // disableRestoreFocus
      placement="bottom"
      {...props.popoverProps}
    >
      {/* <ClickAwayListener onClickAway={clickAway}> */}
        <Paper>
          {props.renderChildren
            ? props.renderChildren({
                close: handleClose,
              })
            : props.children}
        </Paper>
      {/* </ClickAwayListener> */}
    </MuiPopover>
  );

  return (
    <>
      {popoverTarget}
      {popoverContent}
    </>
  );
};

export default Popover;
