import AdminAPI from '../../api/admin';
import { Box, Button, Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { t } from '../../translations';
import { AdjustmentType, Currency } from '../../types';
import { z } from 'zod';
import { FormComponentType, getEditorComponent } from '../../components/CRUDAdmin/edit';
import { randomReferralCode } from '../../helpers/utils';
import { getListComponent } from '../../components/CRUDAdmin/list';
import { toast } from 'react-toastify';
import { formatToDate } from '../../helpers/date-time';

const website = process.env.REACT_APP_BELLITOURS_WEBSITE;

const renderValuesByCurrencies = (valuesByCurrencies) => {
  const entries = Object.entries(valuesByCurrencies);
  return (
    <ul>
      {entries.length ? (
        entries.map(([currency, value]) => (
          <li key={currency}>
            {currency}: {value as any}
          </li>
        ))
      ) : (
        <li>0</li>
      )}
    </ul>
  );
};

const getReferralOrderDetails = (referral) => {
  const totalOfOrders = referral.orders.length;

  let paidOrders = 0;
  let pendingOrders = 0;
  let cancelledOrders = 0;

  let totalPayedByCurrency = {};
  let pendingToPaidByCurrency = {};

  referral.orders.forEach((order) => {
    if (order.paid) {
      paidOrders += 1;

      totalPayedByCurrency[order.currency] ||= 0;
      totalPayedByCurrency[order.currency] += order.amountToPay;
    }

    if (order.cancelled) {
      cancelledOrders += 1;
    }

    if (!order.paid && !order.cancelled) {
      pendingOrders += 1;

      pendingToPaidByCurrency[order.currency] ||= 0;
      pendingToPaidByCurrency[order.currency] += order.amountToPay;
    }
  });

  return {
    totalOfOrders,
    paidOrders,
    pendingOrders,
    cancelledOrders,
    totalPayedByCurrency,
    pendingToPaidByCurrency,
  };
};

let onUpdateItemCb;

const columns = [
  {
    field: 'name',
    headerName: t.Name,
    width: 120,
  },
  {
    field: 'referralCode',
    headerName: t.Referral,
    width: 270,
    renderCell: ({ row: referral }) => {
      return (
        <Box>
          <Box mb={0.5}>
            {website}?referral=<b>{referral.referralCode}</b>
          </Box>
          <Box mb={0.5}>
            <Button
              size="small"
              onClick={(ev) => {
                ev.stopPropagation();
                navigator.clipboard.writeText(`${website}?referral=${referral.referralCode}`);
              }}
            >
              {t.CopyLink}
            </Button>
          </Box>
          <Box mb={0.5}>
            <Button
              size="small"
              onClick={(ev) => {
                ev.stopPropagation();
                navigator.clipboard.writeText(referral.referralCode);
              }}
            >
              {t.CopyReferralCode}
            </Button>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'profitType',
    headerName: t.Profit,
    width: 120,
    renderCell: ({ row: referral }) => {
      return (
        <Box>
          <Box>
            profit: {referral.profitType === AdjustmentType.Percentage ? '%' : `${Currency.USD} `} {referral.profitAmount}
          </Box>
          <Box>
            duration: {referral.durationInDays} {t.days}
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'any',
    headerName: t.Details,
    width: 160,
    renderCell: ({ row: referral }) => {
      const details = getReferralOrderDetails(referral);

      return (
        <Box>
          <Box>total of orders: {details.totalOfOrders}</Box>
          <Box>paid orders: {details.paidOrders}</Box>
          <Box>pending orders: {details.pendingOrders}</Box>
          <Box>cancelled orders: {details.cancelledOrders}</Box>
        </Box>
      );
    },
  },
  {
    field: 'totalPayedByCurrency',
    headerName: t.PaymentDetails,
    width: 160,
    renderCell: ({ row: referral }) => {
      const details = getReferralOrderDetails(referral);

      return (
        <Box>
          <Box mb={2}>
            <Box>
              <b>pending to pay:</b>
            </Box>
            <Box>{renderValuesByCurrencies(details.pendingToPaidByCurrency)}</Box>
          </Box>
          <Box>
            <Box>
              <b>total payed:</b>
            </Box>
            <Box>{renderValuesByCurrencies(details.totalPayedByCurrency)}</Box>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'orders',
    headerName: t.Orders,
    width: 1000,
    renderCell: ({ row: referral }) => {
      return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 460 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Checkin</TableCell>
                <TableCell>Profit</TableCell>
                <TableCell>To Pay</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {referral.orders.map((order) => (
                <TableRow>
                  <TableCell>
                    <Box>order id: {order.orderId}</Box>
                    {order.orderInfo?.hotelName && <Box>hotel: {order.orderInfo.hotelName}</Box>}
                    {order.orderInfo?.clientName && <Box>client: {order.orderInfo.clientName}</Box>}
                  </TableCell>
                  <TableCell>
                    {order.price} {order.currency}
                  </TableCell>
                  <TableCell>{formatToDate(order.checkin)}</TableCell>
                  <TableCell>
                    {order.profitType === AdjustmentType.Percentage ? '%' : `${Currency.USD} `} {order.profitAmount}
                  </TableCell>
                  <TableCell>
                    {order.profitType === AdjustmentType.Percentage ? order.currency : `${Currency.USD} `} {order.amountToPay}
                  </TableCell>
                  <TableCell>{order.cancelled ? t.Cancelled : order.paid ? t.Payed : t.PendingToPay}</TableCell>
                  <TableCell>
                    <Button
                      disabled={order.updating || (order.cancelled && !order.paid)}
                      onClick={async (ev) => {
                        ev.stopPropagation();
                        order.updating = true;

                        try {
                          const newItem = await AdminAPI.referrals.paidUnpaidOrder(referral._id, order.orderId, !order.paid);
                          onUpdateItemCb?.(newItem);
                        } catch (error: any) {
                          toast.error(t.AnUnexpectedErrorHasOccurredPleaseReloadThePage(error.response?.data?.message));
                        } finally {
                          order.updating = false;
                        }
                      }}
                    >
                      {order.paid ? t.SetAsUnpaid : t.SetAsPaid}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    },
  },
];

const ReferralSchema = z.object({
  name: z.string().min(1),
  referralCode: z.string().min(1),
  durationInDays: z.coerce.number(),
  profitType: z.nativeEnum(AdjustmentType),
  profitAmount: z.coerce.number().gt(0),
  orders: z.any(),
});

const ReferralForm: FormComponentType = ({ setData, textInput, booleanInput, selectInput, imageInput }) => {
  return (
    <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative' }}>
      <CardContent>
        <Box mb={3}>{textInput('name', { label: `${t.Name}*` })}</Box>
        <Box mb={3}>
          {textInput('referralCode', { label: `${t.ReferralCode}*` })}
          <Button
            sx={{ mt: 1 }}
            variant="outlined"
            onClick={() => setData((currenData) => ({ ...currenData, referralCode: randomReferralCode() }))}
          >
            {t.Generate}
          </Button>
        </Box>
        <Box mb={3}>{textInput('durationInDays', { label: `${t.DurationInDays}*`, type: 'number' })}</Box>
        <Box mb={3}>{selectInput('profitType', Object.values(AdjustmentType), { label: `${t.TypeOfProfit}*` })}</Box>
        <Box mb={3}>{textInput('profitAmount', { label: `${t.DiscountAmount}*`, type: 'number' })}</Box>
      </CardContent>
    </Card>
  );
};

const baseProps = {
  name: t.Referrals,
  baseRoute: 'referrals',
  schema: ReferralSchema,
  FormComponent: ReferralForm,
};

export const ListReferrals = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  dataLoader: AdminAPI.referrals.getAll,
  destroy: AdminAPI.referrals.delete,
  onUpdatedItem: (cb) => {
    onUpdateItemCb = cb;
  },
});

export const CreateReferrals = getEditorComponent({
  ...baseProps,
  dataLoader: () => Promise.resolve({ orders: [] }),
  save: AdminAPI.referrals.create,
});

export const EditReferrals = getEditorComponent({
  ...baseProps,
  dataLoader: (params) => AdminAPI.referrals.find(params.id),
  save: (props) => AdminAPI.referrals.update(props._id, props),
});
