import { Box } from '@mui/material';
import ReactJson from 'react-json-view';

const PrettyJson = (props) => {
  const { obj, ...otherProps } = props;
  return (
    <Box marginY={2} sx={{ fontSize: 11 }}>
      <ReactJson
        quotesOnKeys={false}
        src={obj}
        name={null}
        iconStyle="square"
        indentWidth={2}
        collapsed={false}
        collapseStringsAfterLength={50}
        groupArraysAfterLength={100}
        displayDataTypes={false}
        enableClipboard={true}
        onEdit={false}
        onAdd={false}
        displayObjectSize={false}
        {...otherProps}
      />
    </Box>
  );
};

export default PrettyJson;
