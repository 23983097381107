import HotelDetails from '../HotelDetails';
import HotelPaxSelect from '../HotelPaxSelect/HotelPaxSelect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Paper, Typography, Link, Button, Collapse } from '@mui/material';
import { formatToDate, humanizeRange, parseDate } from '../../helpers/date-time';
import { paxesObjToString, paxesStringToObj, Room } from '../../helpers/paxes';
import { useNavigate } from 'react-router-dom';
import HotelAvailabilityPage from '../../pages/home/HotelAvailabilityPage';
import { toast } from 'react-toastify';
import SearchHotelsInputs from '../Inputs/SearchHotelsInput';
import { getQueryString } from '../../helpers/utils';
import Checkout from '../Checkout/Checkout';
import { useLoading } from '../../contexts/LoadingContext/LoadingContext';
import { t } from '../../translations';
import AdvancedSearchPrices from '../AdvancedSearchPrices';
import { isString } from 'lodash';
import CmptReloader from '../core/CmptReloader';
import Image from 'mui-image';
import loadingGif from '../../components/assets/loading.gif';

const getReturnUrl = () => {
  const { returnUrl } = getQueryString();
  if (!returnUrl) {
    return undefined;
  }

  const isFullUrl = returnUrl.startsWith('http://') || returnUrl.startsWith('https://');
  const isSameOrigin = returnUrl.startsWith(window.location.origin);

  return isSameOrigin || !isFullUrl ? returnUrl : undefined;
};

const HotelAvailability = ({
  hotelCode,
  hotel,
  availability,
  checkin: initialCheckin,
  checkout: initialCheckout,
  paxes: paxesStr,
  urlParams,
  searchId,
  markupFees,
  rooms,
}) => {
  const { setLoading } = useLoading();
  const openCheckoutRef = useRef<any>(null);
  const closeCheckoutRef = useRef<any>(null);
  const cleanupSelectedPackageRef = useRef<any>(null);

  const reloadSearchBoxRef = useRef<() => void>(() => {});
  const [checkin, setCheckin] = useState(initialCheckin);
  const [checkout, setCheckout] = useState(initialCheckout);

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const paxesRef = useRef<any>(null);
  const [searching, setSearching] = useState(false);

  const [paxes, setPaxes] = useState<any[]>([]);
  const updateSelectedPaxes = ({ packages, completed }) => {
    setPaxes(packages);

    if (completed) {
      setLoading(true);
      setTimeout(() => {
        openCheckoutRef.current();
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }, 100);
    }
  };

  useEffect(() => {
    setSearching(false);
    if (!availability) {
      toast.info(t.ThereIsNoAvailabilityInTheSelectedDates);
    }
  }, [availability, searchId]);

  const navigate = useNavigate();

  const returnUrl = getReturnUrl();

  const search = ({ dates, rooms }: { dates: { startDate: Date; endDate: Date }; rooms: string | Room[] }) => {
    setSearching(true);

    const newUrlParams = {
      ...urlParams,
      code: urlParams.code,
      checkin: formatToDate(dates.startDate),
      checkout: formatToDate(dates.endDate),
      rooms: isString(rooms) ? rooms : paxesObjToString(rooms),
    };

    const newUrl = HotelAvailabilityPage.link(newUrlParams, returnUrl);
    navigate(newUrl);
  };

  const [paxesAsObj, roomsPerPaxes, totalOfRooms] = useMemo(() => {
    const paxesAsObj = paxesStringToObj(paxesStr);
    const roomsPerPaxes = paxesAsObj.reduce((acc, { adults, children }) => {
      const paxStr = `${adults}-${children}`;
      acc[paxStr] = (acc[paxStr] || 0) + 1;
      return acc;
    }, {});

    return [paxesAsObj, roomsPerPaxes, paxesAsObj.length];
  }, [paxesStr]);

  return (
    <Box px={1}>
      <Box sx={{ mb: 1, mt: 1 }}>
        {returnUrl && (
          <Link sx={{ float: 'right' }} href={returnUrl}>
            {t.BackToHotelResults}
          </Link>
        )}
        <Box>
          <Typography sx={{ ml: 1 }} variant="h6">
            {t.FindAnotherDateInThisProperty}
          </Typography>
          <Box>
            <CmptReloader
              cmpt={SearchHotelsInputs}
              reloadRef={reloadSearchBoxRef}
              omitLocation
              initialDates={{ startDate: parseDate(checkin), endDate: parseDate(checkout) }}
              initialRooms={paxesAsObj}
              searching={searching}
              onSearch={search}
            />
          </Box>
        </Box>
      </Box>

      <HotelDetails hotel={hotel}></HotelDetails>

      <Box sx={{ mb: 2 }}>
        <Box textAlign="center">
          <Button onClick={toggleVisibility}>{isVisible ? t.HideAdvancedSearch : t.ShowAdvancedSearch}</Button>
        </Box>

        <Collapse in={isVisible}>
          <Paper
            sx={{
              mt: 2,
              transition: 'all 0.3s ease-in-out',
              pt: 2,
            }}
          >
            <AdvancedSearchPrices
              hotelCode={hotelCode}
              startDate={parseDate(checkin)}
              endDate={parseDate(checkout)}
              rooms={rooms}
              onSelectedDates={(dates) => {
                search({ dates, rooms });
                setCheckin(formatToDate(dates.startDate));
                setCheckout(formatToDate(dates.endDate));
                reloadSearchBoxRef.current();
              }}
            />
          </Paper>
        </Collapse>
      </Box>

      {(searching || !availability) && (
        <Paper variant="outlined" sx={{ p: 2, mb: 5 }}>
          <Typography textAlign="center" variant="h4">
            {searching ? `${t.LookingAvailabilitiesInTheSelectedDates}...` : t.ThereIsNoAvailabilityInTheSelectedDates}
          </Typography>

          {searching && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Image src={loadingGif} duration={0} width={'70%'} height={'100%'} />
            </Box>
          )}
        </Paper>
      )}

      {!searching && availability && (
        <>
          <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
            {humanizeRange(parseDate(checkin), parseDate(checkout))}
          </Typography>
          <Box
            ref={(ref) => {
              paxesRef.current = ref;
            }}
          >
            <HotelPaxSelect
              availability={availability}
              onChange={updateSelectedPaxes}
              roomsPerPaxes={roomsPerPaxes}
              totalOfRooms={totalOfRooms}
              onSetCleanupSelectedPackage={(value) => {
                cleanupSelectedPackageRef.current = value;
              }}
            />

            <Checkout
              hotel={hotel}
              paxes={paxes}
              checkin={checkin}
              checkout={checkout}
              openRef={openCheckoutRef}
              closeRef={closeCheckoutRef}
              onClosed={() => cleanupSelectedPackageRef.current()}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default HotelAvailability;
