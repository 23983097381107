import React, { Component } from 'react';
import { t } from '../translations';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if ((this.state as any).hasError) {
      return <h1>{t.UpsSomethingWasWrong}</h1>;
    }
    return (this.props as any).children;
  }
}

export default ErrorBoundary;
