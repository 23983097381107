import { Route, Outlet } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import ErrorPage from '../ErrorPage';
import AuthLayout from './AuthLayout';
import SigninPage from './SigninPage';
import SignupPage from './SignupPage';
import EmailVerificationPage from './EmailVerificationPage';
import ResendEmailVerificationPage from './ResendEmailVerificationPage';
import SendResetPasswordPage from './SendResetPasswordPage';
import RecoverPasswordPage from './RecoverPasswordPage';

const authRouter = (
  <Route
    path="/auth"
    element={
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    }
    errorElement={<ErrorPage />}
  >
    <Route path="signin" element={<SigninPage />} />
    <Route path="signup" element={<SignupPage />} />
    <Route path="email-verification/:token" element={<EmailVerificationPage />} />
    <Route path="email-verification" element={<ResendEmailVerificationPage />} />
    <Route path="pass-recovery/:token" element={<RecoverPasswordPage />} />
    <Route path="pass-reset" element={<SendResetPasswordPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Route>
);

export default authRouter;
