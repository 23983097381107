import { Box, Typography } from '@mui/material';
import { COLOR } from '../../../helpers/styles';

const OrSeparator = () => {
  return (
    <Box sx={{ position: 'relative', height: '50px', mb: 2 }}>
      <Box
        sx={{
          height: '1px',
          border: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`,
          position: 'absolute',
          top: '50%',
          width: '100%',
        }}
      ></Box>
      <Box
        sx={{
          textAlign: 'center',
          position: 'absolute',
          display: 'block',
          width: '100%',
        }}
      >
        <Typography
          color={'gray'}
          sx={{
            px: 2,
            py: 2,
            background: 'white',
            display: 'inline-block',
          }}
        >
          Or
        </Typography>
      </Box>
    </Box>
  );
};

export default OrSeparator;
