import HotelAvailabilityCard from '../HotelAvailabilityCard/HotelAvailabilityCard';
import { Box, Skeleton, Stack } from '@mui/material';
import times from 'lodash/times';

const HotelAvailabilitySkeleton = () => {
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ border: '0px solid', mb: 4 }}
      spacing={2}
      width="100%"
    >
      <Box sx={{ width: { xs: '100%', md: 250 } }}>
        <Skeleton animation="wave" sx={{ animationDuration: '0.3s' }} variant="rectangular" /*with="100%"*/ height={160}></Skeleton>
      </Box>
      <Box sx={{ ml: 3, p: 1, pt: 2, width: '100%' }}>
        <Stack direction="row" alignItems="center">
          <Skeleton animation="wave" sx={{ animationDuration: '0.3s' }} width={320} height={25} variant="rounded"></Skeleton>
        </Stack>
        <Stack direction="row" alignItems="center">
          {times(3, () => (
            <Skeleton
              animation="wave"
              width={100}
              height={20}
              variant="rounded"
              sx={{ animationDuration: '0.1s', orderRadius: 15, mr: 1, mt: 1 }}
            ></Skeleton>
          ))}
        </Stack>
        <Skeleton animation="wave" width="100%" height={50} variant="rounded" sx={{ animationDuration: '0.1s', mt: 3 }}></Skeleton>
      </Box>
    </Stack>
  );
};

const Li = ({ children }) => (
  <Box component="li" mb={3} sx={{ listStyle: 'none' }}>
    {children}
  </Box>
);

const HotelAvailabilityCardList = (props) => {
  return (
    <Box>
      <Box component="ul">
        {props.skeleton
          ? times(props.skeleton, () => (
              <>
                <Li>
                  <HotelAvailabilitySkeleton />
                </Li>
              </>
            ))
          : props.hotelAvailabilities?.map((hotelAvailability) => (
              <Li key={hotelAvailability.hotelCode}>
                <HotelAvailabilityCard hotelAvailability={hotelAvailability} nights={props.nights} />
              </Li>
            ))}
      </Box>
    </Box>
  );
};

// HotelAvailabilityCardList.propTypes = {
//   skeleton: PropTypes.number,
//   hotelAvailabilities: PropTypes.arrayOf(PropTypes.shape(HotelAvailabilityProps)),
// };

export default HotelAvailabilityCardList;
