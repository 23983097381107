import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { setMonth, getMonth, setYear, getYear } from 'date-fns';

import { MARKERS } from './DateRangePicker';
import { t } from '../../translations';

const useStyles = makeStyles(() => ({
  iconContainer: {
    padding: 5,
  },
  icon: {
    padding: 10,
    '&:hover': {
      background: 'none',
    },
  },
}));

const generateYears = (relativeTo, count) => {
  const half = Math.floor(count / 2);
  return Array(count)
    .fill(0)
    .map((y, i) => relativeTo.getFullYear() - half + i); // TODO: make part of the state
};

const Header = (props) => {
  const {
    date,
    // classes,
    setDate,
    nextDisabled,
    prevDisabled,
    monthSelectorDisabled,
    yearSelectorDisabled,
    onClickNext,
    onClickPrevious,
  } = props;
  const classes = useStyles();

  const handleMonthChange = (event) => {
    setDate(setMonth(date, parseInt(event.target.value)));
  };

  const handleYearChange = (event) => {
    setDate(setYear(date, parseInt(event.target.value)));
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item className={classes.iconContainer}>
        <IconButton
          className={classes.icon}
          disabled={prevDisabled}
          onClick={onClickPrevious}
          style={props.navigateBimonthly && props.marker === MARKERS.SECOND_MONTH ? { display: 'none' } : {}}
        >
          <ArrowBackIosRoundedIcon color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
      {monthSelectorDisabled ? (
        <Grid item>
          <span>
            {t.listOfMonths[getMonth(date)]}
            {yearSelectorDisabled ? ', ' + getYear(date) : ''}
          </span>
        </Grid>
      ) : (
        <Grid item>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
            <Select value={getMonth(date)} onChange={handleMonthChange}>
              {t.listOfMonths.map((month, idx) => (
                <MenuItem key={month} value={idx}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {yearSelectorDisabled ? (
        monthSelectorDisabled ? (
          ''
        ) : (
          <Grid item>
            <span>{getYear(date)}</span>
          </Grid>
        )
      ) : (
        <Grid item>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
            <Select value={getYear(date)} onChange={handleYearChange}>
              {generateYears(date, 30).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item className={classes.iconContainer}>
        <IconButton
          className={classes.icon}
          disabled={nextDisabled}
          onClick={onClickNext}
          style={
            props.navigateBimonthly && props.marker === MARKERS.FIRST_MONTH && !props.onlyFirstMonthVisible ? { display: 'none' } : {}
          }
        >
          <ArrowForwardIosRoundedIcon color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;
